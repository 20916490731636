import { useEffect, useMemo, useState } from 'react'
import { useInvoices } from 'src/hooks/useInvoices'

import { InvoiceTableProps } from './InvoiceTable.ui'
import useInvoiceFilters from './useInvoiceFilters.hooks'
import { filterInvoices, InvoiceTableRow } from './utils'

export const useInvoiceTable = (props: {
  isActive: boolean
  index: number
}): InvoiceTableProps => {
  const { isLoading, isError, error, invoices } = useInvoices()

  const [data, setData] = useState<InvoiceTableRow[] | []>([])
  const [filters, dispatch] = useInvoiceFilters()

  const { isActive, index } = props

  const filteredInvoices = useMemo(
    () =>
      filters.regionId
        ? invoices.filter((invoice) =>
            invoice.regionIds?.includes(filters.regionId),
          )
        : invoices,
    [invoices, filters],
  )

  useEffect(() => {
    if (!isLoading) {
      const {
        allVisibleInvoices,
        paidInvoiceTableData,
        outstandingInvoiceTableData,
        pastDueInvoiceTableData,
      } = filterInvoices(filteredInvoices)

      if (isActive) {
        switch (index) {
          case 0:
            setData(allVisibleInvoices)
            break
          case 1:
            setData(outstandingInvoiceTableData)
            break
          case 2:
            setData(pastDueInvoiceTableData)
            break
          case 3:
            setData(paidInvoiceTableData)
            break
        }
      }
    }
  }, [isLoading, index, isActive, filteredInvoices])

  return {
    index,
    data,
    isLoading: isLoading,
    isError,
    error,
    filters,
    dispatch,
  }
}
