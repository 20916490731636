import {
  CreateInvitationsData,
  Shift,
  ShiftInvitation,
  WorkerShiftWithWorkerDetails,
  ForwardFillMax,
  LocationResponse,
  ClientShift,
} from '@traba/types'
import { AxiosError } from 'axios'
import { differenceInHours } from 'date-fns'
import { useState } from 'react'
import { UseMutateFunction } from 'react-query'
import { useMutation, useQueryClient } from 'react-query'
import { useUserContext } from 'src/context/user/UserContext'
import { useLocations } from 'src/hooks/useLocations'
import { useMembers } from 'src/hooks/useMembers'
import useMobile from 'src/hooks/useMobile'
import { useShiftInvitations } from 'src/hooks/useShiftInvitations'
import { updateShiftById, useShiftById } from 'src/hooks/useShifts'
import { useHotSettings } from 'src/hooks/useSystem'
import { useUserPermissions } from 'src/hooks/useUser'
import { useWorkerShifts } from 'src/hooks/workerShiftHooks'
import { UpdateSlotsRequestedProps } from 'src/screens/EditShifts/EditWorkerSlotsModal'
import { UpdateShiftData, UserRolePermission } from 'src/types'
import { calculateExpectedCost } from 'src/utils/moneyUtils'
import { hasPermissions } from 'src/utils/userUtils'

import { UserData } from '../../types'

export interface UpcomingShiftDetailsProps {
  loading: boolean
  isMobileViewOrReactNative: boolean
  shift: Shift | ClientShift
  shiftToUpdate: UpdateShiftData
  setShiftToUpdate: React.Dispatch<React.SetStateAction<UpdateShiftData>>
  expectedCost: {
    minEstimatedCost: { amount: number; currency: string }
    maxEstimatedCost: { amount: number; currency: string }
  }
  pointOfContact?: UserData | undefined
  location?: LocationResponse | undefined
  parkingLocation?: LocationResponse | undefined
  workerShifts?: WorkerShiftWithWorkerDetails[]
  updateShift: UseMutateFunction<Shift, AxiosError, UpdateShiftData>

  editing: boolean
  setEditing: React.Dispatch<React.SetStateAction<boolean>>
  userCanManageShift: boolean
  userCanManageWorkers: boolean
  userCanViewWages: boolean
  shiftInvitations: ShiftInvitation[]
  rescindInvitation: UseMutateFunction<
    ShiftInvitation,
    AxiosError,
    ShiftInvitation
  >
  sendInvitations: UseMutateFunction<
    ShiftInvitation[],
    AxiosError,
    CreateInvitationsData
  >
  showHeader?: boolean
  isEditSlotsEnabled: boolean
  isEditWorkerSlotsModalOpen: boolean
  setIsEditWorkerSlotsModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  handleUpdateSlotsRequested: (
    props: UpdateSlotsRequestedProps,
  ) => Promise<void>
}

export const useUpcomingShiftDetails = (
  shiftId: string,
  onShiftUpdateSuccess: () => void,
  onShiftUpdateError: (error: any) => void,
  showHeader?: boolean,
): UpcomingShiftDetailsProps => {
  const [editing, setEditing] = useState(false)
  const [shiftToUpdate, setShiftToUpdate] = useState({ shiftId })
  const [isEditWorkerSlotsModalOpen, setIsEditWorkerSlotsModalOpen] =
    useState<boolean>(false)
  const userContext = useUserContext()
  const userCanManageShift = hasPermissions(userContext.state.userProfile, [
    UserRolePermission.ManageShifts,
  ])
  const userCanManageWorkers = hasPermissions(userContext.state.userProfile, [
    UserRolePermission.ManageWorkers,
  ])
  const userCanViewWages = useUserPermissions([UserRolePermission.ViewPay])
  const { isMobileViewOrReactNative } = useMobile()
  const { getMemberById } = useMembers()
  const { getLocationById } = useLocations()
  const { hotSettings } = useHotSettings()
  const { isLoading, shift } = useShiftById(shiftId)
  const { shiftInvitations, rescindInvitation, sendInvitations } =
    useShiftInvitations({ shiftIds: [shiftId] })
  const queryClient = useQueryClient()
  const {
    data: workerShifts,
    isLoading: workerShiftsLoading,
    refetch: refetchWorkerShifts,
  } = useWorkerShifts(shift?.id)

  const expectedCost = shift
    ? calculateExpectedCost(
        {
          startTime: shift.businessStartTime ?? shift.startTime,
          endTime: shift.endTime,
          payRate: shift.payRate,
          payType: shift.payType,
          scheduledBreaks: shift.scheduledBreaks,
          minSlotsRequested: shift.minSlotsRequested,
          slotsRequested: shift.slotsRequested,
          calculatedMarkup: shift.calculatedMarkup,
          breakType: shift.breakType,
          numberOfUnits: shift.numberOfUnits || 0,
        },
        shift.businessStartTime,
      )
    : {
        minEstimatedCost: {
          amount: 0,
          currency: 'USD',
        },
        maxEstimatedCost: {
          amount: 0,
          currency: 'USD',
        },
      }

  const previousShift = queryClient.getQueryData<Shift>(['shiftById', shiftId])

  const updateShiftMutation = useMutation<Shift, AxiosError, UpdateShiftData>(
    updateShiftById,
    {
      async onMutate(updateData) {
        const { shiftId, ...body } = updateData
        // Optimistic update
        queryClient.setQueryData<Shift | undefined>(
          ['shiftById', shiftId],
          (prev) => {
            if (prev) {
              return { ...prev, ...body }
            }
          },
        )
      },
      onSuccess: (response: Shift) => {
        queryClient.setQueryData<Shift>(
          ['shiftById', shiftId],
          (currentShift) => ({ ...currentShift, ...response }),
        )
        onShiftUpdateSuccess()
      },
      onError: (error) => {
        // Revert optimistic update
        queryClient.setQueryData<Shift | undefined>(
          ['shiftById', shiftId],
          () => previousShift,
        )
        onShiftUpdateError(error)
      },
    },
  )

  const handleUpdateSlotsRequested = async (
    props: UpdateSlotsRequestedProps,
  ): Promise<void> => {
    if (!shift?.shiftId) {
      return
    }
    const {
      newSlotsRequested,
      autoRemoveWorkersFromShift,
      workerIdsToRemove,
      updateForwardFillMaxToNone,
    } = props

    await updateShiftMutation.mutate({
      shiftId: shift.shiftId,
      slotsRequested: newSlotsRequested,
      minSlotsRequested: newSlotsRequested,
      ...(autoRemoveWorkersFromShift && {
        removeWorkersFromShift: { shouldAutoRemoveWorkers: true },
      }),
      ...(workerIdsToRemove && {
        removeWorkersFromShift: {
          workerIdsToRemove: workerIdsToRemove,
        },
      }),
      ...(updateForwardFillMaxToNone && {
        forwardFillMax: ForwardFillMax.INVITED_FIRST,
      }),
    })
    if (autoRemoveWorkersFromShift || workerIdsToRemove) {
      refetchWorkerShifts()
    }
  }

  const isEditSlotsEnabled =
    (hotSettings?.isEditSlotsEnabled &&
      shift &&
      differenceInHours(
        shift.businessStartTime ?? shift.startTime,
        new Date(),
      ) > 1) ??
    false

  return {
    loading: isLoading || workerShiftsLoading,
    shift: shift || ({} as Shift),
    shiftToUpdate,
    setShiftToUpdate,
    expectedCost,
    pointOfContact: getMemberById(shift?.supervisorId || ''),
    location: getLocationById(shift?.locationId || ''),
    parkingLocation: getLocationById(shift?.parkingLocationId || ''),
    workerShifts,
    updateShift: updateShiftMutation.mutate,
    editing,
    setEditing,
    userCanManageShift,
    userCanManageWorkers,
    userCanViewWages,
    shiftInvitations,
    rescindInvitation,
    sendInvitations,
    isMobileViewOrReactNative,
    showHeader,
    isEditSlotsEnabled,
    isEditWorkerSlotsModalOpen,
    setIsEditWorkerSlotsModalOpen,
    handleUpdateSlotsRequested,
  }
}
