import { RoleAttributeStatus, ShiftAttribute } from '@traba/types'
import { useRoleAttributes } from 'src/hooks/useAttributes'
import {
  certificationName,
  useCertifications,
} from 'src/hooks/useCertifications'

import { Badge } from '..'
import { RoleData } from '../../types'

interface RoleBadgesProps {
  requiredCertifications?: RoleData['requiredCertifications']
  requiredAttributes?: ShiftAttribute[] | string[]
}

export function RoleBadges(props: RoleBadgesProps) {
  const { roleAttributes } = useRoleAttributes(RoleAttributeStatus.Active)
  const { certifications } = useCertifications()

  const { requiredCertifications, requiredAttributes } = props
  return requiredCertifications?.length && certifications ? (
    <>
      {requiredCertifications.map((rc) => (
        <Badge
          key={rc}
          variant="certification"
          title={certificationName(rc, certifications)}
          style={{ marginLeft: 6 }}
        />
      ))}
    </>
  ) : requiredAttributes?.length ? (
    <div>
      {requiredAttributes.map((a) => {
        const attr = roleAttributes?.find(
          (attr) => attr.type === (typeof a === 'string' ? a : a.type),
        )
        if (!attr) {
          return null
        }
        return (
          <Badge
            key={typeof a === 'string' ? a : a.type}
            title={attr.displayName}
            style={{ marginRight: 6, marginTop: 6 }}
          />
        )
      })}
    </div>
  ) : null
}
