import { FIVE_MINUTES_IN_MS } from '@traba/consts'
import { useAlert } from '@traba/context'
import { AxiosError } from 'axios'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { trabaApi } from 'src/api/helpers'
import { CreateOrUpdateTrainingVideoData, TrainingVideo } from 'src/types'

const getTrainingVideos = async () => {
  try {
    const response = await trabaApi.get(`/my-company/training-video`)
    return response.data
  } catch (error) {
    console.log(error)
  }
}

export const useTrainingVideos = () => {
  const queryClient = useQueryClient()
  const { handleError } = useAlert()
  const {
    isLoading,
    isError,
    data: trainingVideos,
    error,
    isFetched,
  } = useQuery<TrainingVideo[], Error>('training-video', getTrainingVideos, {
    staleTime: FIVE_MINUTES_IN_MS,
  })
  const createTrainingVideoMutation = useMutation<
    TrainingVideo,
    AxiosError,
    CreateOrUpdateTrainingVideoData
  >(
    async (video) => {
      const res = await trabaApi.post(`/my-company/training-video`, video)
      return res.data
    },
    {
      onSuccess: (data) => {
        queryClient.setQueryData(
          'training-video',
          (existingVideos: TrainingVideo[] | undefined) => {
            if (existingVideos) {
              return [...existingVideos, data]
            }
            return [data]
          },
        )
      },
    },
  )

  const removeTrainingVideoMutation = useMutation<
    TrainingVideo,
    AxiosError,
    string,
    TrainingVideo
  >((videoId) => trabaApi.delete(`/my-company/training-video/${videoId}`), {
    onSuccess: (_, values: string) => {
      queryClient.setQueryData(
        'training-video',
        (existingVideos: TrainingVideo[] | undefined) => {
          if (existingVideos) {
            return existingVideos.filter(
              (video: TrainingVideo) => video.id !== values,
            )
          }
          return []
        },
      )
    },
    onError: (error) => {
      handleError(
        error,
        'useTrainingVideos -> removeTrainingVideoMutation()',
        'Please try again or contact support if the issue persists.',
        'An error has occurred',
      )
    },
  })

  return {
    isLoading,
    isError,
    error,
    isFetched,
    trainingVideos: trainingVideos ?? [],
    requiredForAllTrainingVideos: trainingVideos?.filter(
      (tv) => tv.requiredForAll,
    ),
    createTrainingVideo: createTrainingVideoMutation.mutate,
    removeTrainingVideo: removeTrainingVideoMutation.mutate,
  }
}
