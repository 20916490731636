import { theme } from 'src/libs/theme'
import styled from 'styled-components'

export const SingleSelectorContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: ${({ theme }) => theme.space.xxs}px;
`

export const OptionItem = styled.div<{
  isActive: boolean
}>`
  width: 100%;
  border: 1px solid;
  border-color: ${(props) =>
    props.isActive ? theme.colors.Violet20 : theme.border.color};
  :hover {
    box-shadow: 0px 2px 5px rgba(196, 204, 212, 0.55);
  }
  box-shadow: 0px 1px 2px rgba(196, 204, 212, 0.4);
  background-color: ${({ isActive, theme }) =>
    isActive ? theme.colors.Violet10 : '#ffffff'};
  border-radius: ${theme.border.radius};
  padding: ${({ theme }) => theme.space.sm / 2}px
    ${({ theme }) => theme.space.xs}px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.MidnightBlue};
  cursor: pointer;

  @media only screen and (max-width: (${({ theme }) =>
      theme.media.maxExtraSmall})) {
    padding: ${({ theme }) => theme.space.sm / 2}px
      ${({ theme }) => theme.space.xxs}px;
  }
`

export const OptionItemContainer = styled.div<any>`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
`
