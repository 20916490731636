import { theme } from '@traba/theme'
import { Schedule } from '@traba/types'
import { WeekdayStr } from 'rrule'

function getRepeatsOnText(schedules: Schedule[]) {
  if (!schedules.length) {
    return ''
  }

  const rRuleWeekdayStrToDisplayableWeekdayStrMap = new Map<WeekdayStr, string>(
    [
      ['SU', 'Su'],
      ['MO', 'M'],
      ['TU', 'Tu'],
      ['WE', 'W'],
      ['TH', 'Th'],
      ['FR', 'F'],
      ['SA', 'Sa'],
    ],
  )
  const weekDaysOrder = Array.from(
    rRuleWeekdayStrToDisplayableWeekdayStrMap.keys(),
  )
  const getDisplayableWeekdayStr = (day: WeekdayStr) => {
    if (!rRuleWeekdayStrToDisplayableWeekdayStrMap.has(day)) {
      return ''
    }
    return rRuleWeekdayStrToDisplayableWeekdayStrMap.get(day)
  }
  const sortWeekDays = (days: WeekdayStr[]) => {
    return days
      .slice()
      .sort((a, b) => weekDaysOrder.indexOf(a) - weekDaysOrder.indexOf(b))
  }
  const week1RepeatsOn = schedules[0].recurringSchedule?.repeatOn
    ? sortWeekDays(schedules[0].recurringSchedule.repeatOn)
        .map(getDisplayableWeekdayStr)
        .join(', ')
    : ''
  const week2RepeatsOn =
    schedules.length > 1 && schedules[1].recurringSchedule?.repeatOn
      ? sortWeekDays(schedules[1].recurringSchedule.repeatOn)
          .map(getDisplayableWeekdayStr)
          .join(', ')
      : ''

  if (schedules.length === 1) {
    return [week1RepeatsOn]
  }

  return [week1RepeatsOn, week2RepeatsOn]
}

export const ShiftTileScheduleCadenceText = ({
  schedules,
}: {
  schedules: Schedule[]
}) => {
  const textChunks = getRepeatsOnText(schedules)

  if (textChunks.length === 1) {
    return <span>{`Weekly | ${textChunks[0]}`}</span>
  }

  return (
    <>
      <span style={{ display: 'block' }}>Bi-Weekly |</span>
      <div style={{ marginLeft: theme.space.sm }}>
        <span style={{ display: 'block', marginBottom: -theme.space.xxxs }}>
          W1: {textChunks[0]}
        </span>
        <span style={{ display: 'block', marginTop: -theme.space.xxs }}>
          W2: {textChunks[1]}
        </span>
      </div>
    </>
  )
}
