import { ShiftRequestParentWithShiftRequest } from '@traba/types'
import { useQuery } from 'react-query'
import { trabaApi } from 'src/api/helpers'
import { FIVE_MINUTES_IN_MS } from '../libs/constants'

const getShiftRequestParent = async (shiftRequestParentId: string) => {
  try {
    const response = await trabaApi.get(
      `my-company/shift-request-parents/${shiftRequestParentId}`,
    )
    return response.data
  } catch (error) {
    console.error(error)
  }
}

export const useShiftRequestParent = (shiftRequestParentId: string) => {
  const {
    isLoading,
    isError,
    data: shiftRequestParent,
    error,
    isFetched,
    refetch,
  } = useQuery<ShiftRequestParentWithShiftRequest, Error>(
    `shift-request-parent_${shiftRequestParentId}`,
    () => getShiftRequestParent(shiftRequestParentId),
    {
      staleTime: FIVE_MINUTES_IN_MS,
      enabled: !!shiftRequestParentId,
    },
  )

  return {
    isLoading,
    isError,
    error,
    isFetched,
    shiftRequestParent,
    refetch,
  }
}
