import { LoadingSpinner } from '@traba/react-components'
import { makePlural } from '@traba/string-utils'
import { theme } from '@traba/theme'
import { JobStatus } from '@traba/types'
import { endOfDay, startOfDay } from 'date-fns'
import { useState } from 'react'
import {
  Button,
  ButtonVariant,
  Col,
  Icon,
  Row,
  SvgIcon,
  Text,
} from 'src/components/base'
import { AppScreen } from 'src/hooks/useMobile'
import {
  useTimeSheetMutations,
  useWorkerShiftsForTimesheetWithPagination,
} from 'src/hooks/useTimesheet'
import { TimesheetsPage, TimesheetsGroupBy } from 'src/screens/TimeSheets/types'
import { getWorkerShiftStringId } from 'src/utils/workerShiftUtils'
import {
  ExpandableShiftTileCard,
  ShiftTileWorkerListContainer,
} from '../ShiftTile/ShiftTile.styles'
import { TimeSheetsTable } from '../TimeSheetsTable/TimeSheetsTable'
import { TimeSheetsTableLoggingSource } from '../TimeSheetsTable/types'
interface Props {
  locationName: string
  activeLocationId: string
  numShifts: number
  numWorkerShifts: number
  tabIndex: number
  timePeriod: [Date | null, Date | null]
  refetchSummary: () => void
  userCanManageTimesheets: boolean
}

export const DesktopTimeSheetApprovalLocationTile = (props: Props) => {
  const {
    locationName,
    numShifts,
    numWorkerShifts,
    tabIndex,
    timePeriod,
    activeLocationId,
    refetchSummary,
    userCanManageTimesheets,
  } = props
  const [isExpanded, setIsExpanded] = useState(false)
  const isPendingTab = tabIndex === 0

  const query = {
    activeLocationIds: [activeLocationId],
    after: timePeriod[0] ? startOfDay(timePeriod[0]) : undefined,
    before: timePeriod[1] ? endOfDay(timePeriod[1]) : undefined,
    groupBy: TimesheetsGroupBy.WORKER,
    hideApproved: isPendingTab,
    hideUnapproved: !isPendingTab,
    enabled: isExpanded,
  }

  const {
    data: response,
    refetch: refetchWorkerShifts,
    isLoading: isLoadingWorkerShifts,
  } = useWorkerShiftsForTimesheetWithPagination(query, {
    limit: numWorkerShifts,
    startAt: 0,
  })

  const data = response?.result
  const { approveWorkerShifts } = useTimeSheetMutations()
  const refetch = () => {
    refetchWorkerShifts()
    refetchSummary()
  }

  const handleApproveAllWorkerShifts = async () => {
    if (data) {
      const workerShiftIds = data?.flatMap((item) =>
        item.workerShifts
          .filter((ws) => !ws.isApproved)
          .map((ws) => ({
            workerId: ws.workerId,
            shiftId: ws.shiftId,
          })),
      )
      await approveWorkerShifts(workerShiftIds)
      window.analytics.track('Timesheets Approval Approve All Clicked', {
        workerShiftIds: workerShiftIds.map((workerShiftId) =>
          getWorkerShiftStringId(workerShiftId.workerId, workerShiftId.shiftId),
        ),
        activeLocationId,
        page: TimesheetsPage.APPROVAL,
        screen: AppScreen.DESKTOP,
      })
      refetch()
    }
  }

  const disableApproveAll =
    (isExpanded && (isLoadingWorkerShifts || !data?.length)) ||
    !!data?.filter(
      (item) =>
        !!item.workerShifts.filter((ws) => ws.jobStatus !== JobStatus.Complete)
          .length,
    ).length

  return (
    <>
      <ExpandableShiftTileCard
        isExpanded={isExpanded}
        style={{ marginTop: theme.space.sm }}
      >
        <Row
          py={theme.space.sm}
          px={theme.space.xs}
          justifyBetween
          onClick={() => {
            window.analytics.track(
              'Timesheets Approval Location Tile clicked',
              {
                expanded: !isExpanded,
                screen: AppScreen.DESKTOP,
                activeLocationId,
                page: TimesheetsPage.APPROVAL,
              },
            )
            setIsExpanded((isExpanded) => !isExpanded)
          }}
          style={{ cursor: 'pointer' }}
          wrap
        >
          <Row alignCenter>
            <Col>
              <Row alignCenter>
                <Icon name="location" width={20} height={20} />
                <Text variant="h6" ml={theme.space.xxxs}>
                  {locationName}
                </Text>
              </Row>
            </Col>
            <Col mx={theme.space.xs}>
              <Row alignCenter>
                <Icon name="company" width={20} height={20} />
                <Text
                  variant="h6"
                  ml={theme.space.xxxs}
                  style={{
                    whiteSpace: 'nowrap',
                  }}
                >{`${numShifts} shift${makePlural(numShifts)}`}</Text>
              </Row>
            </Col>
            <Col mx={theme.space.xs}>
              <Row alignCenter>
                <Icon name="two_user" width={20} height={20} />
                <Text variant="h6" ml={theme.space.xxxs} mr={theme.space.xxs}>
                  {`${numWorkerShifts} worker${makePlural(numWorkerShifts)} time${makePlural(numWorkerShifts)} ${isPendingTab ? 'unapproved' : 'approved'}`}
                </Text>
                <Icon
                  name={isExpanded ? 'chevronUp' : 'chevronDown'}
                  width={16}
                  height={16}
                />
              </Row>
            </Col>
          </Row>
          <Row alignCenter>
            {isExpanded && isPendingTab && userCanManageTimesheets && (
              <Button
                rightIcon={<SvgIcon name="check" />}
                variant={ButtonVariant.BRANDLINK}
                iconPadding={`${theme.space.xxs}px`}
                disabled={disableApproveAll}
                onClick={(e) => {
                  e.stopPropagation()
                  e.preventDefault()
                  handleApproveAllWorkerShifts()
                }}
              >
                {'Approve All'}
              </Button>
            )}
          </Row>
        </Row>
      </ExpandableShiftTileCard>
      {isExpanded && (!!data?.length || isLoadingWorkerShifts) && (
        <ShiftTileWorkerListContainer>
          {isLoadingWorkerShifts ? (
            <LoadingSpinner />
          ) : (
            <TimeSheetsTable
              groupBy={TimesheetsGroupBy.WORKER}
              data={data}
              refetchData={refetch}
              approveWorkerShifts={approveWorkerShifts}
              isLoading={isLoadingWorkerShifts}
              editMode={false}
              hideHeaderRow={true}
              hideTotalsRow={true}
              hideEmptyState={true}
              stickyApprovalsColumn={isPendingTab}
              loggingContext={{
                source: TimeSheetsTableLoggingSource.TIMESHEETS_APPROVAL_SCREEN,
                screen: AppScreen.DESKTOP,
              }}
            />
          )}
        </ShiftTileWorkerListContainer>
      )}
    </>
  )
}
