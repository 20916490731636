import { useMutation, useQuery } from '@tanstack/react-query'
import { FIVE_MINUTES_IN_MS } from '@traba/consts'
import { useAlert } from '@traba/context'
import {
  ScheduleInvitations,
  ScheduleShiftRequestInvitation,
} from '@traba/types'

import { trabaApi } from 'src/api/helpers'

const getScheduleInvitations = async (shiftRequestParentId: string) => {
  try {
    const response = await trabaApi.get(
      `my-company/shift-request-parents/${shiftRequestParentId}/invitations`,
    )
    return response.data
  } catch (error) {
    console.error(error)
  }
}

interface RescindScheduleInvitationParams {
  shiftRequestId: string
  workerId: string
}

const rescindScheduleInvitation = async ({
  shiftRequestId,
  workerId,
}: RescindScheduleInvitationParams) => {
  const response = await trabaApi.patch(
    `/my-company/shift-request/${shiftRequestId}/invitations/${workerId}/rescind`,
  )
  return response.data
}

export const useScheduleInvitations = (shiftRequestParentId: string) => {
  const { showSuccess, showError } = useAlert()
  const {
    isLoading,
    isError,
    data: scheduleInvitations,
    error,
    isFetched: isRolesFetched,
    refetch,
  } = useQuery<ScheduleInvitations, Error>({
    queryKey: ['schedule-invitations', shiftRequestParentId],
    queryFn: () => getScheduleInvitations(shiftRequestParentId),
    staleTime: FIVE_MINUTES_IN_MS,
  })

  const rescindScheduleInvitationMutation = useMutation<
    ScheduleShiftRequestInvitation,
    Error,
    RescindScheduleInvitationParams
  >({
    mutationFn: rescindScheduleInvitation,
    onSuccess: () => {
      refetch()
      showSuccess('Invitation rescinded')
    },
    onError: (error) => {
      console.error(error)
      showError('Failed to rescind invitation')
    },
  })

  return {
    isLoading,
    isError,
    error,
    isRolesFetched,
    scheduleInvitations,
    refetch,
    rescindScheduleInvitation: rescindScheduleInvitationMutation.mutate,
  }
}
