import { FormControlLabel, FormGroup, Switch } from '@mui/material'
import { ShiftStatus } from '@traba/types'
import { Text } from 'src/components'
import { ShowWorkersToggle } from 'src/components/ShiftCalendar/ShowWorkersToggle'
import { FilterDropdown } from 'src/components/ShiftFilters/FilterDropdown'
import { useMembers } from 'src/hooks/useMembers'
import useMobile from 'src/hooks/useMobile'
import {
  CalendarFilters as CalendarFiltersType,
  FiltersAction,
} from 'src/hooks/useShiftFilters.hooks'
import { theme } from 'src/libs/theme'

interface Props {
  dispatch: React.Dispatch<FiltersAction>
  filters: CalendarFiltersType
  isShowWorkersToggled?: boolean
  setIsShowWorkersToggled?: React.Dispatch<React.SetStateAction<boolean>>
}

export const CalendarFilters = ({
  dispatch,
  filters,
  isShowWorkersToggled,
  setIsShowWorkersToggled,
}: Props) => {
  const { members } = useMembers()
  const { isMobileViewOrReactNative } = useMobile()

  return (
    <>
      <Text variant="h5" mt={theme.space.med} mb={theme.space.xs}>
        Filter by
      </Text>
      <FilterDropdown
        label="Onsite contact"
        selected={filters.supervisorId}
        items={members
          .map((m) => ({
            value: m.uid || '',
            label: `${m.firstName} ${m.lastName}`,
          }))
          .sort((a, b) => (a.label < b.label ? -1 : 1))}
        onSelectItem={(value) =>
          dispatch({ type: 'SET_SUPERVISOR', supervisorId: value })
        }
      />
      <FormGroup
        style={{
          marginRight: theme.space.xs,
          marginTop: theme.space.xs,
        }}
      >
        <FormControlLabel
          labelPlacement="start"
          style={{ marginLeft: 0 }}
          control={
            <Switch
              checked={filters.statuses === undefined}
              onChange={() =>
                dispatch({
                  type: 'SET_STATUSES',
                  statuses:
                    filters.statuses === undefined
                      ? [ShiftStatus.ACTIVE, ShiftStatus.COMPLETE]
                      : undefined,
                })
              }
              inputProps={{ 'aria-label': 'controlled' }}
            />
          }
          label={
            <Text variant="h6" color={theme.colors.MidnightBlue}>
              Show canceled shifts
            </Text>
          }
        />
      </FormGroup>
      {isMobileViewOrReactNative &&
        isShowWorkersToggled !== undefined &&
        setIsShowWorkersToggled && (
          <ShowWorkersToggle
            isShowWorkersToggled={isShowWorkersToggled}
            setIsShowWorkersToggled={setIsShowWorkersToggled}
          />
        )}
    </>
  )
}
