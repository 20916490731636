import { useHotSettings } from '@traba/hooks'
import { theme } from '@traba/theme'
import { WorkerShiftWithCharges } from '@traba/types'
import { calculateTimeWorkedInMinutes } from '@traba/utils'
import { TableFooter, Td } from 'src/components/Table/Table'
import {
  TimeSheetsWorkerCharge,
  WorkerShiftIdDto,
} from 'src/hooks/useTimesheet'
import { TimesheetsGroupBy } from 'src/screens/TimeSheets/types'
import { formatDuration } from 'src/shared/utils/dateUtils'
import { Button, ButtonVariant, SvgIcon, Text } from '../../base'
import { TimeSheetsWorkerEarning } from './TimeSheetsWorkerEarning'

interface TimeSheetsTableTotalsRowProps {
  groupBy: TimesheetsGroupBy
  workerShifts?: WorkerShiftWithCharges[]
  editMode: boolean
  userCanViewWages: boolean
  workerCharge: TimeSheetsWorkerCharge | undefined
  isLoadingEstimatedCharge?: boolean
  numberOfColumns: number
  showApproveAll: boolean
  handleApproveAllWorkerShifts: (
    workerShiftIds: WorkerShiftIdDto[],
  ) => Promise<void>
}
export const TimeSheetsTableTotalsRow = (
  props: TimeSheetsTableTotalsRowProps,
) => {
  const { hotSettings } = useHotSettings()
  const {
    groupBy,
    workerShifts,
    userCanViewWages,
    numberOfColumns,
    showApproveAll,
    handleApproveAllWorkerShifts,
  } = props
  const totalTimeWorked = workerShifts?.reduce(
    (sum, ws) =>
      sum + calculateTimeWorkedInMinutes(ws, hotSettings?.enableWorkerEditTime),
    0,
  )

  const showEstimatedCharge = workerShifts?.some((ws) => ws.hasPendingEdits)
  const numLeftEmptyCols = groupBy === TimesheetsGroupBy.WORKER ? 4 : 2
  const numRightEmptyCols =
    numberOfColumns -
    (numLeftEmptyCols + Number(userCanViewWages) + Number(showApproveAll) + 3)

  return (
    <TableFooter
      style={{ backgroundColor: theme.colors.Grey20 }}
      noBorderRadius={true}
    >
      {Array(numLeftEmptyCols)
        .fill(null)
        .map((_, index) => (
          <Td key={index}>&nbsp;</Td>
        ))}
      <Td colSpan={2}>
        <Text
          variant="caption"
          style={{ textAlign: 'right' }}
        >{`${groupBy} TOTALS`}</Text>
      </Td>
      <Td>
        <Text variant="h7">{formatDuration(totalTimeWorked ?? 0)}</Text>
      </Td>
      {userCanViewWages && (
        <Td>
          <TimeSheetsWorkerEarning
            workerCharge={props.workerCharge}
            isLoading={!!props.isLoadingEstimatedCharge}
            showEstimatedCharge={showEstimatedCharge}
            hideBizPendingAdjustmentTooltip={true}
          />
        </Td>
      )}
      {showApproveAll && (
        <Td>
          <Button
            rightIcon={<SvgIcon name="check" />}
            variant={ButtonVariant.BRANDLINK}
            iconPadding={`${theme.space.xxs}px`}
            onClick={async () => {
              workerShifts &&
                handleApproveAllWorkerShifts(
                  workerShifts
                    .filter((ws) => !ws.isApproved)
                    .map((ws) => ({
                      workerId: ws.workerId,
                      shiftId: ws.shiftId,
                    })),
                )
            }}
          >
            {'Approve All'}
          </Button>
        </Td>
      )}
      {Array(numRightEmptyCols)
        .fill(null)
        .map((_, index) => (
          <Td key={index}>&nbsp;</Td>
        ))}
    </TableFooter>
  )
}
