import { FIVE_MINUTES_IN_MS } from '@traba/consts'
import { Company } from '@traba/types'
import { AxiosError } from 'axios'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { trabaApi } from 'src/api/helpers'

const getCompany = async () => {
  try {
    const response = await trabaApi.get(`/my-company`, {
      validateStatus: function (status) {
        return (status >= 200 && status < 300) || status === 404 // Resolve typical results as valid plus 404 since it didn't fail it just didn't have a company
      },
    })
    if (response.status === 404) {
      return
    }

    return response.data
  } catch (error) {
    console.log(error)
  }
}

export const useCompany = (enabled = true) => {
  const queryClient = useQueryClient()
  const {
    isLoading,
    isError,
    data: company,
    error,
    isFetched,
    refetch,
    isFetching,
  } = useQuery<Company, Error>('company', getCompany, {
    enabled,
    staleTime: FIVE_MINUTES_IN_MS,
    refetchOnWindowFocus: 'always',
  })

  const patchCompanyMutation = useMutation<
    Company,
    AxiosError,
    Partial<Company>,
    Company
  >((updatedCompany) => trabaApi.patch(`/my-company`, updatedCompany), {
    onSuccess: (response: any) => {
      queryClient.setQueryData('company', response.data)
      refetch()
    },
  })

  return {
    isLoading,
    isFetching,
    isError,
    error,
    isFetched,
    company,
    refetch,
    markup: company?.calculatedMarkup || company?.baseMarkup || 0.4,
    patchCompany: patchCompanyMutation.mutateAsync,
  }
}
