import * as Sentry from '@sentry/react'
import { Region } from '@traba/types'
import { useQuery } from 'react-query'
import { trabaApi } from 'src/api/helpers'

const getWorkerRegion = async (
  workerId: string,
): Promise<Region | undefined> => {
  try {
    const response = await trabaApi.get(`/my-company/worker/${workerId}/region`)
    return response.data
  } catch (error) {
    Sentry.captureException(error)
  }
}

export const useWorkerRegion = (workerId: string) => {
  const {
    isLoading,
    isError,
    data: region,
    error,
    isFetched,
  } = useQuery<Region | undefined, Error>(['worker-region', workerId], () =>
    getWorkerRegion(workerId),
  )

  return {
    isLoading,
    isError,
    error,
    isFetched,
    region,
  }
}
