import { Envs, MapKey } from '@traba/types'
import { handleDates } from '@traba/utils'
import axios, { InternalAxiosRequestConfig } from 'axios'
import { getAuth } from 'firebase/auth'

export const BACKEND_URL = import.meta.env['VITE_BACKEND_URL']

const APP = import.meta.env['VITE_APP']
let firebaseToken: string | undefined

const devBackendUrl = import.meta.env[
  `VITE_${Envs.DEVELOPMENT.toUpperCase()}_BACKEND_URL`
]
const prodBackendUrl = import.meta.env[
  `VITE_${Envs.PRODUCTION.toUpperCase()}_BACKEND_URL`
]

export const trabaApis = new Map([
  [Envs.DEVELOPMENT, axios.create({ baseURL: devBackendUrl })],
  [Envs.PRODUCTION, axios.create({ baseURL: prodBackendUrl })],
] as const)

export type trabaApisKeyType = MapKey<typeof trabaApis>

export const trabaApi = axios.create({
  baseURL: BACKEND_URL,
})

// OPS console auth token
async function createOpsGoogleTokenConfig(
  config: InternalAxiosRequestConfig,
): Promise<InternalAxiosRequestConfig> {
  const googleToken = localStorage.getItem('opsConsoleIdToken')
  config.headers['Authorization'] = `Bearer ${googleToken}`
  config.headers['Accept'] = 'application/json'
  config.headers['Content-Type'] = 'application/json'
  return config
}

// Biz auth token
async function createFirebaseTokenConfig(
  config: InternalAxiosRequestConfig,
): Promise<InternalAxiosRequestConfig> {
  const auth = getAuth()
  firebaseToken = await auth.currentUser?.getIdToken()
  config.headers['Authorization'] = `Bearer ${firebaseToken}`
  config.headers['Accept'] = 'application/json'
  config.headers['Content-Type'] = 'application/json'
  return config
}

trabaApi.interceptors.request.use(
  APP === 'OPS' ? createOpsGoogleTokenConfig : createFirebaseTokenConfig,
  (error) => {
    Promise.reject(error)
  },
)

for (const [_, value] of trabaApis) {
  value.interceptors.request.use(
    APP === 'OPS' ? createOpsGoogleTokenConfig : createFirebaseTokenConfig,
    (error) => {
      Promise.reject(error)
    },
  )
}
// OPS error handling
async function handleErrorOps(error: any) {
  if (error.response) {
    console.error(error.response.data)
    throw error.response.data
  } else {
    console.error('Error', error.message)
    throw error
  }
}
// Biz error handling
async function handleErrorBiz(error: any) {
  if (error.response) {
    if (error.response.status === 401 && !error.config._retry) {
      const auth = getAuth()
      const newToken = await auth.currentUser?.getIdToken(true)

      // Update the auth token in the original request headers
      error.config.headers['Authorization'] = `Bearer ${newToken}`
      error.config._retry = true

      return trabaApi(error.config)
    }
    console.error(error.response.data)
    throw error.response.data
  } else if (error.request) {
    console.error(error.request)
    throw error.request
  } else {
    console.error('Error', error.message)
    throw error
  }
}

trabaApi.interceptors.response.use(
  (originalResponse) => {
    handleDates(originalResponse.data)
    return originalResponse
  },
  async (error) => {
    if (APP === 'OPS') {
      return handleErrorOps(error)
    } else {
      return handleErrorBiz(error)
    }
  },
)
