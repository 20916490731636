import { theme } from '@traba/theme'
import { WeekdayStr } from 'rrule'
import Row from '../base-components/Row'
import { Text } from '../base-components/Text'

type ScheduleDaysProps = {
  repeatOn: WeekdayStr[]
}

const REPEAT_ON: WeekdayStr[] = ['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA']

const REPEAT_ON_TO_LETTER: Record<WeekdayStr, string> = {
  SU: 'S',
  MO: 'M',
  TU: 'T',
  WE: 'W',
  TH: 'T',
  FR: 'F',
  SA: 'S',
}

const DaySquare = ({
  isSelected,
  repeatOnDay,
}: {
  isSelected: boolean
  repeatOnDay: WeekdayStr
}) => {
  const dayLetter = REPEAT_ON_TO_LETTER[repeatOnDay]
  return (
    <div
      style={{
        height: theme.space.xs,
        width: theme.space.xs,
        background: isSelected
          ? 'var(--Violet-10, #F5EBFF)'
          : 'var(--Midnight-Blue-Grey-White, #FFF)',
        textAlign: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        borderRadius: theme.space.us,
        ...(!isSelected && {
          border: '1px solid var(--Midnight-Blue-Grey-20, #E2E5E9)',
          boxShadow: '0px 1px 2px 0px rgba(196, 204, 212, 0.50)',
        }),
      }}
    >
      <Text variant="h6">{dayLetter}</Text>
    </div>
  )
}

export function ScheduleDays(props: ScheduleDaysProps) {
  const { repeatOn } = props
  return (
    <Row alignCenter gap={theme.space.xxxs}>
      {REPEAT_ON.map((day) => (
        <DaySquare
          key={day}
          isSelected={repeatOn.some((ro) => ro === day)}
          repeatOnDay={day}
        />
      ))}
    </Row>
  )
}
