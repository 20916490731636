import * as Sentry from '@sentry/react'
import {
  OnTimeStatus,
  TransitStatus,
  WorkerShift,
  WorkerShiftTimeToDestinationResponseDto,
} from '@traba/types'
import { isFuture, subHours } from 'date-fns'
import React from 'react'
import { SvgIconName } from 'src/assets/svg/icons'
import { Badge } from 'src/components'
import { JobStatus } from 'src/types'

import { BadgeVariant } from '../base/Badge/Badge.styles'

export interface WorkerOnShiftBadgeProps {
  workerShift: WorkerShift
  timeToDestination?: WorkerShiftTimeToDestinationResponseDto
  isBackup?: boolean
}

const TIME_TO_ALLOW_CONFIRMATION_HOURS = 2

type BadgeTitle =
  | 'COMPLETE'
  | 'ON SHIFT'
  | 'SCHEDULED'
  | 'ON BREAK'
  | 'REMOVED'
  | 'NO SHOW'
  | 'BACKUP'
  | 'ABANDONED'
  | 'REJECTED'
  | 'BACKUP'
  | 'LOCATION UNAVAILABLE'
  | 'NOT LEFT'
  | 'ON THE WAY'
  | 'ARRIVED'
  | 'RUNNING LATE'

type baseStatusKeys = Exclude<JobStatus, JobStatus.ToDo>

export const statusToBadgeVariant: {
  [K in baseStatusKeys]: BadgeVariant
} = {
  COMPLETE: 'success',
  IN_PROGRESS: 'success',
  ON_BREAK: 'darkOrange',
  CANCELED: 'darkRed',
  NO_SHOW: 'darkRed',
  APPEASED: 'info',
  ABANDONED: 'darkRed',
  REJECTED: 'darkRed',
}

export const statusToBadgeTitle: {
  [K in baseStatusKeys]: BadgeTitle
} = {
  COMPLETE: 'COMPLETE',
  IN_PROGRESS: 'ON SHIFT',
  ON_BREAK: 'ON BREAK',
  CANCELED: 'REMOVED', // Canceled status is only shown when WS has cancellationSource Business
  NO_SHOW: 'NO SHOW',
  APPEASED: 'BACKUP',
  ABANDONED: 'ABANDONED',
  REJECTED: 'REJECTED',
}

export const statusToBadgeIcon: {
  [K in baseStatusKeys]: SvgIconName
} = {
  COMPLETE: 'complete',
  IN_PROGRESS: 'on_shift',
  ON_BREAK: 'on_break',
  CANCELED: 'rejected', // Canceled status is only shown when WS has cancellationSource Business
  NO_SHOW: 'no_show',
  APPEASED: 'backup',
  ABANDONED: 'abandoned',
  REJECTED: 'rejected',
}

export type BadgeData = {
  variant: BadgeVariant
  title: BadgeTitle
  iconName: SvgIconName
}

export function getBadgeData(
  status: JobStatus,
  shiftStartTime: Date,
  timeToDestination?: WorkerShiftTimeToDestinationResponseDto,
  isBackup?: boolean,
): BadgeData {
  if (status === JobStatus.ToDo) {
    if (isFuture(subHours(shiftStartTime, TIME_TO_ALLOW_CONFIRMATION_HOURS))) {
      if (isBackup) {
        return {
          variant: 'info',
          title: 'BACKUP',
          iconName: 'backup',
        }
      } else {
        return {
          variant: 'brand',
          title: 'SCHEDULED',
          iconName: 'scheduled',
        }
      }
    }
    if (!timeToDestination) {
      return {
        variant: 'disabled',
        title: 'LOCATION UNAVAILABLE',
        iconName: 'location_unavailable',
      }
    }
    switch (timeToDestination.transitStatus) {
      case TransitStatus.Arrived:
        return {
          variant: 'success',
          title: 'ARRIVED',
          iconName: 'arrived',
        }
      case TransitStatus.NotLeft:
        return {
          variant: 'yellow',
          title: 'NOT LEFT',
          iconName: 'not_left',
        }
      case TransitStatus.InTransit:
        switch (timeToDestination.onTimeStatus) {
          case OnTimeStatus.ExpectedOnTime:
          case OnTimeStatus.OnTime:
            return {
              variant: 'info',
              title: 'ON THE WAY',
              iconName: 'on_the_way',
            }
          case OnTimeStatus.ExpectedLate:
          case OnTimeStatus.Late:
            return {
              variant: 'danger',
              title: 'RUNNING LATE',
              iconName: 'running_late',
            }
        }
    }
  } else {
    const variant = statusToBadgeVariant[status]
    const title = statusToBadgeTitle[status]
    const iconName = statusToBadgeIcon[status]
    if (!variant || !title || !iconName) {
      Sentry.captureException(`Badge not supported for status ${status}`)
      return {
        variant: 'brand',
        title: 'SCHEDULED',
        iconName: 'scheduled',
      }
    }
    return {
      variant,
      title,
      iconName,
    }
  }
}

export function WorkerOnShiftBadge({
  workerShift,
  timeToDestination,
  isBackup,
}: WorkerOnShiftBadgeProps) {
  const { title, variant, iconName } = getBadgeData(
    workerShift.jobStatus,
    workerShift.shiftInfo.startTime,
    timeToDestination,
    isBackup,
  )
  return (
    <Badge
      title={title}
      variant={variant}
      iconName={iconName}
      style={{ whiteSpace: 'nowrap' }}
    />
  )
}
