import { useForm } from '@traba/utils'
import { useFormik } from 'formik'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { trabaApi } from 'src/api/helpers'
import { SignUpSmsConsent } from 'src/components/auth/SignUpSmsConsent'
import {
  Alert,
  Button,
  Input,
  InputPhone,
  Row,
  Text,
} from 'src/components/base'
import PlacesAutocomplete from 'src/components/PlacesAutocomplete/PlacesAutocomplete'
import { useUserContext } from 'src/context/user/UserContext'
import useAppAuth from 'src/hooks/authHook'
import useMobile from 'src/hooks/useMobile'
import { PREFILLED_INFO_CITY, REQUIRED_FIELD_MESSAGE } from 'src/libs/constants'
import { Address } from 'src/types'
import { phoneNumber as phoneNumberValidation, name } from 'src/utils/formUtils'
import * as yup from 'yup'

type BasicDetailsFormProps = {
  cta: string
}

export function BasicDetailsForm(props: BasicDetailsFormProps) {
  const { cta } = props
  const [isLoading, setIsLoading] = useState(false)
  const [agreedToSmsConsent, setAgreedToSmsConsent] = useState(false)
  const userContext = useUserContext()
  const { isReactNativeApp } = useMobile()
  const navigate = useNavigate()
  const { handleLogout } = useAppAuth()
  const { handleOnSubmitError } = useForm()

  const { phoneNumber, firstName, lastName, companyId } =
    userContext.state.userProfile ?? {}

  const formik = useFormik({
    initialValues: {
      phoneNumber,
      firstName,
      lastName,
      city: '',
    },
    validationSchema: yup.object({
      phoneNumber: phoneNumberValidation(),
      firstName: name(),
      lastName: name(),
      city: yup.string().required(REQUIRED_FIELD_MESSAGE),
    }),
    onSubmit: async (values) => {
      try {
        setIsLoading(true)
        localStorage.setItem(PREFILLED_INFO_CITY, values.city)
        formik.setStatus({})
        // If the user is accepting an invitation, they will already have a
        // company so we can mark them as having completed onboarding at this
        // point and bypass the company details step.
        const onboardingComplete = !!companyId
        const update = {
          ...values,
          ...(onboardingComplete
            ? { onboarding: { hasCompleted: true } }
            : undefined),
          ...(agreedToSmsConsent
            ? { smsConsent: { agreedToSmsConsent: true } }
            : undefined),
        }
        const { data } = await trabaApi.patch(`/me`, update)
        userContext.dispatch({ type: 'EDIT_USER', value: data })

        // If onboarding is complete, we can short circuit here. We'll be
        // redirected automatically. Otherwise, we'll handle company details.
        if (onboardingComplete) {
          return
        }
        navigate('/company-details', {
          state: { isFromBasicDetailOrEmailVerification: true },
        })
      } catch (err: any) {
        setIsLoading(false)
        handleOnSubmitError(err, formik)
      }
    },
  })

  function handleChangePlacesAutocomplete(val: Address) {
    formik.setFieldValue('address', val.city)
  }

  const { errors, touched } = formik
  return (
    <form onSubmit={formik.handleSubmit}>
      <Input
        full
        label="First Name"
        {...formik.getFieldProps('firstName')}
        inputStatus={touched.firstName && errors.firstName ? 3 : 1}
        errorMessage={errors.firstName}
      />
      <Input
        full
        label="Last Name"
        {...formik.getFieldProps('lastName')}
        inputStatus={touched.lastName && errors.lastName ? 3 : 1}
        errorMessage={errors.lastName}
      />
      <Row mt={24}>
        <PlacesAutocomplete
          onSelect={handleChangePlacesAutocomplete}
          onChange={(val) => formik.setFieldValue('city', val)}
          value={formik.values?.city}
          label="City"
          errorMessage={touched.city && errors.city ? errors.city : null}
          placesApiType="(cities)"
        />
      </Row>
      <InputPhone
        value={formik.values.phoneNumber}
        onChange={(val) =>
          formik.handleChange({
            target: { value: val, name: 'phoneNumber' },
          })
        }
        error={touched.phoneNumber ? errors.phoneNumber : null}
      />

      <SignUpSmsConsent
        agreedToSmsConsent={agreedToSmsConsent}
        setAgreedToSmsConsent={setAgreedToSmsConsent}
      />
      {formik.status && formik.status.error && (
        <Alert mt={24}>{formik.status.message}</Alert>
      )}
      <Row my={32}>
        <Button
          type="submit"
          style={{ width: '100%' }}
          disabled={isLoading}
          loading={isLoading}
        >
          {cta}
        </Button>
      </Row>
      {!isReactNativeApp && (
        <Text variant="body2" center>
          Already have a business account?{' '}
          <Text variant="link" onClick={handleLogout}>
            Log in
          </Text>
        </Text>
      )}
    </form>
  )
}
