import * as Sentry from '@sentry/react'
import { useQuery } from '@tanstack/react-query'
import { useAlert } from '@traba/context'
import { WorkerJobPlacement } from '@traba/types'
import { trabaApi } from 'src/api/helpers'
import { FIVE_MINUTES_IN_MS } from 'src/libs/constants'

export type WorkerJobPlacementDetails = {
  WorkerJobPlacement: WorkerJobPlacement
}

const HIRED_WORKERS_STATUS_QUERY_KEY = 'HIRED_WORKERS_STATUS_QUERY_KEY'
const HIRED_WORKERS_QUERY_KEY = 'HIRED_WORKERS_QUERY_KEY'

async function getWorkerHiredStatus(
  workerId: string,
): Promise<WorkerJobPlacement | undefined> {
  const res = await trabaApi.get(
    `my-company/worker-job-placements/worker/${workerId}`,
  )
  return res.data
}

async function getHiredWorkers(): Promise<{ workerIds: string[] | undefined }> {
  const res = await trabaApi.get(`my-company/worker-job-placements/`)
  return res.data
}

export function useCheckHiredStatus(workerId: string) {
  const fetchFunction = () => getWorkerHiredStatus(workerId)

  const { showError } = useAlert()

  const { isError, data, error } = useQuery<WorkerJobPlacement | undefined>({
    queryKey: [HIRED_WORKERS_STATUS_QUERY_KEY, { workerId }],
    queryFn: fetchFunction,
    staleTime: FIVE_MINUTES_IN_MS,
  })

  if (isError) {
    Sentry.captureException(error)
    showError('Error Fetching worker hired status')
  }

  return {
    data,
  }
}

export function useHiredWorkers() {
  const fetchFunction = () => getHiredWorkers()

  const { showError } = useAlert()

  const { isLoading, isError, data, error } = useQuery<{
    workerIds: string[] | undefined
  }>({
    queryKey: [HIRED_WORKERS_QUERY_KEY],
    queryFn: fetchFunction,
    staleTime: FIVE_MINUTES_IN_MS,
  })

  if (isError) {
    Sentry.captureException(error)
    showError('Error Fetching Job Placements')
  }

  return {
    isLoading,
    data,
  }
}
