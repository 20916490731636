import { theme } from '@traba/theme'
import { User, UserRole } from '@traba/types'
import React from 'react'
import { ModalPaddingContainer } from '../../../base-components/Modal/Modal.styles'

import Row from '../../../base-components/Row'
import { SvgIcon } from '../../../base-components/SvgIcon'
import { Text } from '../../../base-components/Text'
import { ChangeMemberRoleRow } from './ChangeMemberRoleRow'

export interface ChangeMemberRoleModalContentProps {
  member: Pick<User, 'role' | 'firstName' | 'lastName'>
  handleClose: () => void
  onChangeMemberRole: (role: UserRole) => void
  loading?: boolean
}

export function ChangeMemberRoleModalContent(
  props: ChangeMemberRoleModalContentProps,
) {
  const { member, handleClose, onChangeMemberRole, loading } = props

  return (
    <ModalPaddingContainer>
      <Row justifyBetween pb={theme.space.xs}>
        <Text variant="h2">Change role</Text>
        <SvgIcon
          size={24}
          color={theme.colors.Grey50}
          name="cancel"
          onClick={handleClose}
        />
      </Row>

      <Text variant="body2">
        Change the role assigned to the selected member below.
      </Text>

      <ChangeMemberRoleRow
        member={member}
        changeMemberRole={onChangeMemberRole}
        isLoading={loading}
        onBack={handleClose}
      />
    </ModalPaddingContainer>
  )
}
