import { BasicOpsWorkerDetails } from './workers'

export type ShiftInvitation = {
  id: string
  createdAt: Date
  updatedAt: Date
  status: ShiftInvitationStatus
  index: number
  batch: number
  workerId: string
  worker: { firstName: string; lastName: string; photoUrl?: string }
  shiftId: string
  companyId: string
  invitedAt: Date | null
  notificationError: ShiftInvitationNotificationError | null
  failedEligibilityChecks: string[]
}

export type ShiftInvitationDto = {
  workerId: string
  index: number
}

export type ShiftInvitationWithWorkerInfo = ShiftInvitation & {
  worker: BasicOpsWorkerDetails
}

export type ShiftInvitationsDto = {
  workers: ShiftInvitationDto[]
  batch: number
  sendInvitationsAt?: Date
}

export enum ShiftInvitationStatus {
  Pending = 'PENDING',
  Sent = 'SENT',
  Accepted = 'ACCEPTED',
  Declined = 'DECLINED',
  Rescinded = 'RESCINDED',
}

export enum ShiftInvitationNotificationError {
  WorkerIneligible = 'WORKER_INELIGIBLE',
  TooManyNotifications = 'TOO_MANY_NOTIFICATIONS',
  SmsFailure = 'SMS_FAILURE',
}

export type CreateInvitationsData = {
  shiftId: string
  workerIds: string[]
  includeRescinded?: boolean
  isFromChat?: boolean
}

export type ScheduleShiftRequestWorkerInvitation = {
  id: string
  createdAt: Date
  updatedAt: Date
  status: ScheduleInvitationStatus
  workerId: string
  shiftRequestId: string
  invitedAt: Date
  notificationError: ShiftInvitationNotificationError | null
  failedEligibilityChecks: string[]
  worker: { firstName: string; lastName: string; photoUrl?: string }
}

export enum ScheduleInvitationStatus {
  PENDING = 'PENDING',
  SENT = 'SENT',
  DECLINED = 'DECLINED',
  ACCEPTED = 'ACCEPTED',
  RESCINDED = 'RESCINDED',
}

export type ScheduleShiftRequestInvitation = {
  shiftRole: string
  roleId: string
  shiftRequestId: string
  invitations: ScheduleShiftRequestWorkerInvitation[]
}

export type ScheduleInvitations = ScheduleShiftRequestInvitation[]
