import { CardTile } from '@traba/react-components'
import { makePlural } from '@traba/string-utils'
import { useNavigate } from 'react-router-dom'

import { WorkerDetails } from 'src/hooks/useCompanyWorkers'
import useMobile from 'src/hooks/useMobile'
import { theme } from 'src/libs/theme'
import { getInitials } from 'src/utils/stringUtils'
import { Image, Text } from '../base'
import { TdMultiLeft } from '../InvoiceTable/InvoiceTable.styles'
import { ReviewWorkerModalContentProps } from '../ReviewWorker/components/ReviewWorkerModalContent.legacy'

import { Td, Tr } from '../Table/Table'
import WorkerConnectionMenu, {
  WorkerConnectionActionButton,
} from '../WorkerConnectionMenu/WorkerConnectionMenu'
import { WorkerLinkText } from '../WorkerDetails/WorkerLinkText'

export type WorkerTableRowProps = {
  userCanManageWorkers: boolean
  setReviewModal: (
    data: Pick<ReviewWorkerModalContentProps, 'worker' | 'workerShift'>,
  ) => void
  isBlocked: (workerId: string) => boolean
  isFavorite: (workerId: string) => boolean
  isIneligible: (workerId: string) => boolean
  isScheduledBlock: (workerId: string) => boolean
  favoriteWorker: (workerId: string) => void
  hideConnections?: boolean
} & WorkerDetails

export const WorkerTableRow = (props: WorkerTableRowProps) => {
  const {
    worker,
    shiftHistory,
    userCanManageWorkers,
    setReviewModal,
    favoriteWorker,
    hideConnections,
  } = props
  const { isReactNativeApp } = useMobile()
  const navigate = useNavigate()
  const { firstName, lastName, photoUrl } = worker
  const { mostRecentShiftStart, totalShiftCount, totalMinutesWorked } =
    shiftHistory
  const hours = Math.round((totalMinutesWorked || 0) / 60)
  const totalShiftsWithYouText = `${totalShiftCount} shift${makePlural(
    totalShiftCount,
  )} (${hours.toString()} hr${makePlural(hours)})`

  const initials = getInitials(firstName, lastName)
  const isBlocked = props.isBlocked(worker.uid)
  const isFavorite = props.isFavorite(worker.uid)
  const isIneligible = props.isIneligible(worker.uid)
  const isScheduledBlock = props.isScheduledBlock(worker.uid)

  return (
    <Tr key={worker.uid}>
      <TdMultiLeft style={{ overflow: 'hidden', whiteSpace: 'normal' }}>
        <CardTile size="56px" style={{ minWidth: 56 }}>
          {photoUrl ? (
            <Image
              style={{
                width: '56px',
                height: '56px',
                borderRadius: theme.space.xxs,
              }}
              src={photoUrl}
              alt="worker-photo"
            />
          ) : (
            <Text variant="h5">{initials}</Text>
          )}
        </CardTile>
        <WorkerLinkText
          variant="h5"
          style={{
            marginLeft: theme.space.sm,
            textOverflow: 'ellipsis',
            overflow: 'hidden',
          }}
          onClick={() => navigate(`/worker/${worker.uid}`)}
        >
          {`${firstName} ${lastName}`}
        </WorkerLinkText>
      </TdMultiLeft>
      {!isReactNativeApp && (
        <>
          <Td style={{ textAlign: 'center' }}>
            {mostRecentShiftStart ? mostRecentShiftStart.toDateString() : '-'}
          </Td>
          <Td style={{ textAlign: 'center' }}>{totalShiftsWithYouText}</Td>
          {!hideConnections && userCanManageWorkers && (
            <Td>
              {shiftHistory.mostRecentShift ? (
                <WorkerConnectionMenu
                  worker={worker}
                  isFavorited={isFavorite}
                  isBlocked={isBlocked}
                  isIneligible={isIneligible}
                  isScheduledBlock={isScheduledBlock}
                  hidden={!userCanManageWorkers}
                  onFavorite={() => {
                    favoriteWorker(worker.uid)
                    setReviewModal({
                      worker,
                      workerShift: shiftHistory.mostRecentShift,
                    })
                  }}
                  workerShift={shiftHistory.mostRecentShift}
                  alignEnd
                  buttonsToShow={[
                    WorkerConnectionActionButton.AddToRoster,
                    WorkerConnectionActionButton.Block,
                    WorkerConnectionActionButton.Favorite,
                    WorkerConnectionActionButton.MessageWorker,
                  ]}
                />
              ) : undefined}
            </Td>
          )}
        </>
      )}
    </Tr>
  )
}
