import {
  JobStatus,
  ShiftWithWorkerShiftsAndCharges,
  Worker,
  WorkerShiftWithWorkerAndCharges,
  WorkerWithWorkerShiftAndCharges,
} from '@traba/types'
import React, { useCallback, useEffect, useState } from 'react'
import { useConnections } from 'src/hooks/useConnections'
import useMobile, { AppScreen } from 'src/hooks/useMobile'
import {
  WorkerShiftIdDto,
  useTimeSheetWorkerShiftCharges,
} from 'src/hooks/useTimesheet'
import { TimesheetsGroupBy, TimesheetsPage } from 'src/screens/TimeSheets/types'
import { getWorkerShiftStringId } from 'src/utils/workerShiftUtils'
import { TimeSheetsTableBaseProps } from '../types'
import { combineMultipleEstimatedWorkerShiftsCharges } from '../utils'
import { MobileTimeSheetsTableWorkerShiftListItem } from './MobileTimeSheetsTableWorkerShiftListItem'
import { TimeSheetsTableShiftHeaderListItem } from './TimeSheetsTableShiftHeaderListItem'
import { TimeSheetsTableTotalsRow } from './TimeSheetsTableTotalsRow'
import { TimeSheetsTableWorkerHeaderListItem } from './TimeSheetsTableWorkerHeaderListItem'
import { TimeSheetsTableWorkerShiftListItem } from './TimeSheetsTableWorkerShiftListItem'

interface TimeSheetsTableSectionProps extends TimeSheetsTableBaseProps {
  item: WorkerWithWorkerShiftAndCharges | ShiftWithWorkerShiftsAndCharges
  userCanViewWages: boolean
  userCanManageTimesheets: boolean
  numberOfColumns: number
  isInShiftSummary?: boolean
}

export const TimeSheetsTableWorkerOrShiftSection = (
  props: TimeSheetsTableSectionProps,
) => {
  const {
    item,
    groupBy,
    onEditWorkerShift,
    refetchData,
    editMode,
    approveWorkerShifts,
    hideHeaderRow,
    hideTotalsRow,
    userCanViewWages,
    userCanManageTimesheets,
    numberOfColumns,
    stickyApprovalsColumn,
    loggingContext,
    isInShiftSummary,
  } = props
  const { isMobileViewOrReactNative, isReactNativeApp } = useMobile()
  const [isExpanded, setIsExpanded] = useState(!isReactNativeApp)
  const isGroupedByWorker = groupBy === TimesheetsGroupBy.WORKER
  const worker = isGroupedByWorker ? (item as Worker) : undefined // pull worker from item; otherwise pull from worker shift below
  const workerShifts = item.workerShifts.sort(
    (a, b) => b.shiftInfo.startTime.getTime() - a.shiftInfo.startTime.getTime(),
  )
  const workerShiftCharges = useTimeSheetWorkerShiftCharges(workerShifts ?? [])
  const connections = useConnections()

  // Refetch charges when edit mode is toggled off.
  // Intentionally not adding a dependency of workerShiftCharges so that it doesn't refetch every time the useQueries result updates its reference.
  useEffect(() => {
    if (!editMode) {
      workerShiftCharges.forEach((charge) => charge.refetch())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editMode])

  const anyChargesLoading = workerShiftCharges.some(
    (charge) => charge.isLoading,
  )

  const showApproveAll =
    userCanManageTimesheets &&
    workerShifts?.some(
      (ws) => !ws.isApproved && ws.jobStatus === JobStatus.Complete,
    ) &&
    !editMode

  const handleApproveAllWorkerShifts = useCallback(
    async (workerShiftIds: WorkerShiftIdDto[]) => {
      await approveWorkerShifts(workerShiftIds)
      window.analytics.track(
        'Timesheets Table Totals Row Approve All Clicked',
        {
          workerShiftIds: workerShiftIds.map((workerShiftId) =>
            getWorkerShiftStringId(
              workerShiftId.workerId,
              workerShiftId.shiftId,
            ),
          ),
          page: TimesheetsPage.TOTALS,
          screen: isMobileViewOrReactNative
            ? AppScreen.MOBILE
            : AppScreen.DESKTOP,
          groupBy,
        },
      )
      refetchData()
    },
    [approveWorkerShifts, refetchData, groupBy, isMobileViewOrReactNative],
  )

  return (
    <React.Fragment key={worker?.workerId ?? item.id}>
      {!hideHeaderRow && !isInShiftSummary ? (
        isGroupedByWorker ? (
          <TimeSheetsTableWorkerHeaderListItem
            worker={item as Worker}
            connections={connections}
            editMode={editMode}
            latestWorkerShift={workerShifts[0]}
            numberOfColumns={numberOfColumns}
            showApproveAll={showApproveAll && isMobileViewOrReactNative}
            isMobileViewOrReactNative={isMobileViewOrReactNative}
            isExpanded={isExpanded}
            setIsExpanded={setIsExpanded}
          />
        ) : (
          <TimeSheetsTableShiftHeaderListItem
            shift={item as ShiftWithWorkerShiftsAndCharges}
            isMobileViewOrReactNative={isMobileViewOrReactNative}
            isExpanded={isExpanded}
            setIsExpanded={setIsExpanded}
          />
        )
      ) : null}
      {workerShifts.map((ws, idx) => {
        const workerShiftCharge = workerShiftCharges[idx]
        if (isMobileViewOrReactNative && !isExpanded) {
          return null
        }
        return !isMobileViewOrReactNative ? (
          <TimeSheetsTableWorkerShiftListItem
            key={
              ws.workedShiftId ??
              `${getWorkerShiftStringId(ws.workerId, ws.shiftId)}`
            }
            workerShift={ws}
            editMode={editMode}
            worker={worker ?? (ws as WorkerShiftWithWorkerAndCharges).worker}
            onEditWorkerShift={(newWorkerShift) => {
              onEditWorkerShift && onEditWorkerShift(newWorkerShift)
            }}
            connections={connections}
            refetchWorkerShifts={refetchData}
            userCanViewWages={userCanViewWages}
            groupBy={groupBy}
            approveWorkerShifts={approveWorkerShifts}
            hideHeaderRow={hideHeaderRow}
            workerCharge={workerShiftCharge.data}
            isLoadingEstimatedCharge={workerShiftCharge.isLoading}
            userCanManageTimesheets={userCanManageTimesheets}
            numberOfColumns={numberOfColumns}
            stickyApprovalsColumn={stickyApprovalsColumn}
            loggingContext={loggingContext}
          />
        ) : (
          <MobileTimeSheetsTableWorkerShiftListItem
            key={
              ws.workedShiftId ??
              `${getWorkerShiftStringId(ws.workerId, ws.shiftId)}`
            }
            workerShift={ws}
            editMode={editMode}
            worker={worker ?? (ws as WorkerShiftWithWorkerAndCharges).worker}
            onEditWorkerShift={(newWorkerShift) => {
              onEditWorkerShift && onEditWorkerShift(newWorkerShift)
            }}
            connections={connections}
            refetchWorkerShifts={refetchData}
            userCanViewWages={userCanViewWages}
            groupBy={groupBy}
            approveWorkerShifts={approveWorkerShifts}
            hideHeaderRow={hideHeaderRow}
            userCanManageTimesheets={userCanManageTimesheets}
          />
        )
      })}
      {!hideTotalsRow && (
        <TimeSheetsTableTotalsRow
          groupBy={groupBy}
          editMode={editMode}
          userCanViewWages={userCanViewWages}
          workerShifts={workerShifts}
          workerCharge={combineMultipleEstimatedWorkerShiftsCharges(
            workerShiftCharges,
          )}
          isLoadingEstimatedCharge={anyChargesLoading}
          numberOfColumns={numberOfColumns}
          showApproveAll={showApproveAll}
          handleApproveAllWorkerShifts={handleApproveAllWorkerShifts}
        />
      )}
    </React.Fragment>
  )
}
