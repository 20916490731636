import styled from 'styled-components'

const Anchor = styled.a`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.004em;
  text-decoration: none;
  color: #8000ff;
`

export default Anchor
