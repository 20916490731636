import { WorkerShift, Money } from '@traba/types'
import { StrikeThrough } from 'src/components/base/StrikeThrough'
import { useWorkerEarningEstimate } from 'src/hooks/useWorkerEarningEstimate'
import { theme } from 'src/libs/theme'
import { getMoneyString, multiplyMoney } from 'src/utils/moneyUtils'

export const LiveWorkerEarning = (props: {
  workerShift: WorkerShift
  workerShiftCharge?: Money
  editMode?: boolean
  shiftMarkup: number
}) => {
  const { workerShift, editMode, workerShiftCharge, shiftMarkup } = props
  const workerClockedInAndOut =
    workerShift.clockInTime && workerShift.clockOutTime
  const { isLoading: isLoadingWorkerShiftEstimate, data: workerShiftEstimate } =
    useWorkerEarningEstimate({ workerShift, enabled: !!workerClockedInAndOut })

  const markupToMultiply = 1 + shiftMarkup
  const originalMoneyString = getMoneyString(
    // ideally this would be calculated on the server, but the markup isn't added until the
    // invoice is created which is long after we need to display the charge here
    multiplyMoney(workerShiftCharge ?? workerShift.grossPay, markupToMultiply),
  )
  if (!editMode) {
    return <>{originalMoneyString}</>
  }

  const updatedMoney = workerShiftEstimate?.grossPay
  if (updatedMoney?.amount === workerShift.grossPay?.amount) {
    return <>{originalMoneyString}</>
  }
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
      }}
    >
      <StrikeThrough
        strikeThrough
        color={theme.colors.Grey60}
        style={{ color: theme.colors.Grey60 }}
      >
        {getMoneyString(multiplyMoney(workerShift.grossPay, markupToMultiply))}
      </StrikeThrough>

      <div>
        {isLoadingWorkerShiftEstimate
          ? '...' // ellipses instead of a loading spinner to reduce jarring-ness
          : getMoneyString(multiplyMoney(updatedMoney, markupToMultiply))}
      </div>
    </div>
  )
}
