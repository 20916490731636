import React, { useEffect } from 'react'
import { MainLayout } from 'src/components'
import { Text } from 'src/components/base'
import {
  RegionalFilterStatus,
  useAppContext,
} from 'src/context/appContext/AppContext'
import { useQueryParams } from 'src/helpers'
import { useUserPermissions } from 'src/hooks/useUser'
import { UserRolePermission } from 'src/types/index'
import { TimeSheetTotalsLayout } from './TimeSheetTotalsLayout'
import { TimesheetsPage } from './types'

export default function TimeSheetTotalsScreen() {
  const { dispatch: appContextDispatch } = useAppContext()
  const userCanManageTimesheets = useUserPermissions([
    UserRolePermission.ManageTimesheets,
  ])
  const userCanDownloadReport = useUserPermissions([
    UserRolePermission.ViewInvoices,
  ])
  const userCanViewWages = useUserPermissions([UserRolePermission.ViewPay])
  const queryParams = useQueryParams()
  const isTotalsView = queryParams.get('view') === 'totals'
  useEffect(() => {
    window.analytics.track('Timesheets Loaded Totals Screen', {
      page: isTotalsView ? TimesheetsPage.TOTALS : TimesheetsPage.DAILY,
    })
  }, [isTotalsView])

  useEffect(() => {
    appContextDispatch({
      type: 'SET_REGIONAL_FILTER_STATUS',
      value: RegionalFilterStatus.ENABLED,
    })
    return () => {
      appContextDispatch({
        type: 'SET_REGIONAL_FILTER_STATUS',
        value: RegionalFilterStatus.HIDE,
      })
    }
  }, [appContextDispatch])

  return (
    <MainLayout title="Timesheet Approval" contentStyle={{ maxWidth: '100%' }}>
      <Text variant="h2">{isTotalsView ? 'Totals View' : 'Daily View'}</Text>
      <TimeSheetTotalsLayout
        userCanManageTimesheets={userCanManageTimesheets}
        userCanDownloadReport={userCanDownloadReport}
        userCanViewWages={userCanViewWages}
      />
    </MainLayout>
  )
}
