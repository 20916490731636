import { LoadingSpinner } from '@traba/react-components'
import { useMemberRoleSettings } from 'src/hooks/useMembers'
import { theme } from 'src/libs/theme'
import { USER_ROLE_NAMES, UserRole, UserRolePermission } from 'src/types'

import { GreenCheck } from '../assets/svg'
import { Text } from '../base'

const ORDERED_ROLES = [
  UserRole.Supervisor,
  UserRole.LimitedShiftCreator,
  UserRole.ShiftCreator,
  UserRole.Accounting,
  UserRole.Admin,
]

const ABILITIES = [
  {
    title: 'Can view shifts',
    permissions: [UserRolePermission.ViewShifts],
  },
  {
    title: 'Can view timesheets',
    permissions: [UserRolePermission.ViewTimesheets],
  },
  {
    title: 'Can favorite & block workers',
    permissions: [UserRolePermission.ManageWorkers],
  },
  {
    title: 'Can approve timesheets',
    permissions: [UserRolePermission.ManageTimesheets],
  },
  {
    title: 'Can post shifts',
    permissions: [UserRolePermission.ManageShifts],
  },
  {
    title: 'Can view pay',
    permissions: [UserRolePermission.ViewPay],
  },
  {
    title: 'Can manage invoices',
    permissions: [UserRolePermission.ManageInvoices],
  },
  {
    title: 'Can manage team members',
    permissions: [UserRolePermission.ManageUserRoles],
  },
]

export function MemberRoleInfo() {
  const { settings, isLoading, error } = useMemberRoleSettings()
  const settingsMap = new Map(
    settings?.map((s) => [s.role, new Set(s.permissions)]),
  )
  return isLoading ? (
    <div
      style={{
        height: 300,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <LoadingSpinner />
    </div>
  ) : error ? (
    <div
      style={{
        height: 300,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Text variant="body2">There was an error loading roles.</Text>
    </div>
  ) : (
    <div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Text
          variant="h7"
          style={{
            flex: 3,
            padding: `${theme.space.xs}px ${theme.space.xxs / 2}px ${
              theme.space.xs
            }px ${theme.space.sm}px`,
            color: theme.colors.Grey60,
          }}
        >
          Ability
        </Text>
        {ORDERED_ROLES.map((r) => (
          <div
            style={{
              flex: 1,
              display: 'flex',
              alignSelf: 'stretch',
              alignItems: 'center',
              justifyContent: 'center',
              borderLeft: `1px solid ${theme.colors.Grey20}`,
              padding: `${theme.space.xs}px ${theme.space.xxs / 2}px`,
              overflow: 'hidden',
            }}
          >
            <Text
              variant="h7"
              style={{
                color: theme.colors.Grey60,
                textAlign: 'center',
                overflow: 'wrap',
              }}
            >
              {USER_ROLE_NAMES[r]}
            </Text>
          </div>
        ))}
      </div>
      {ABILITIES.map((a) => (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            borderTop: `1px solid ${theme.colors.Grey20}`,
          }}
        >
          <Text
            variant="h7"
            style={{
              flex: 3,
              padding: `${theme.space.xs}px ${theme.space.xxs / 2}px ${
                theme.space.xs
              }px ${theme.space.sm}px`,
            }}
          >
            {a.title}
          </Text>
          {ORDERED_ROLES.map((r) => (
            <div
              style={{
                flex: 1,
                display: 'flex',
                alignSelf: 'stretch',
                alignItems: 'center',
                justifyContent: 'center',
                borderLeft: `1px solid ${theme.colors.Grey20}`,
                padding: `${theme.space.xs}px ${theme.space.xxs / 2}px`,
              }}
            >
              {a.permissions.every((p) => settingsMap.get(r)?.has(p)) ? (
                <GreenCheck />
              ) : null}
            </div>
          ))}
        </div>
      ))}
    </div>
  )
}
