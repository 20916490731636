import { ModalPaddingContainer } from '@traba/react-components'
import { useState } from 'react'
import { Divider, Row, Text } from 'src/components/base'
import { RoleForm } from 'src/components/RoleForm'
import { RoleStepper } from 'src/components/RoleStepper'
import { useRoles } from 'src/hooks/useRoles'
import { PAY_RATE_DEFAULT } from 'src/hooks/useShiftRequests'
import { theme } from 'src/libs/theme'
import { CreateRoleData, RoleData } from 'src/types'

import { ButtonIcon } from '../../PersonalProfile/PersonalProfile.styles'

const INITIAL_ROLE_STATE = {
  roleName: '',
  roleDescription: '',
  requiredAttire: '',
  requiredCertifications: [],
  requiredAttributes: [],
  freeformAttributes: {},
  defaultPayRate: PAY_RATE_DEFAULT,
}

export type CreateRoleModalProps = {
  onClose: () => void
  onCreate?: (r: RoleData) => void
  isEventCompany: boolean
  fromPrevious: boolean
}

export const CreateRoleModal = (props: CreateRoleModalProps) => {
  const { onCreate, onClose, isEventCompany, fromPrevious } = props
  const [submitLoading, setSubmitLoading] = useState(false)
  const { createRole } = useRoles()

  function handleCreate(role: CreateRoleData) {
    setSubmitLoading(true)
    createRole(role, {
      onSuccess: (r) => {
        if (onCreate) {
          onCreate(r)
        }
        onClose()
      },
      onSettled: () => setSubmitLoading(false),
    })
  }

  return isEventCompany ? (
    <ModalPaddingContainer>
      <Row justifyBetween style={{ marginBottom: theme.space.xs }}>
        <Text variant="h2">Create role</Text>
        <ButtonIcon name="cancel" onClick={props.onClose} />
      </Row>
      <RoleForm
        onConfirm={handleCreate}
        onCancel={onClose}
        submitLoading={submitLoading}
        isEdit={false}
      />
    </ModalPaddingContainer>
  ) : (
    <div style={{ width: '100%' }}>
      <Row justifyBetween alignCenter style={{ margin: theme.space.sm }}>
        <Text variant="h4">
          {!fromPrevious
            ? 'Create a new role'
            : 'Create a role using your previous roles'}
        </Text>
        <ButtonIcon name="cancel" onClick={onClose} />
      </Row>
      <Divider />
      <RoleStepper
        initialRoleState={INITIAL_ROLE_STATE}
        fromPrevious={fromPrevious}
        onConfirm={handleCreate}
        onCancel={onClose}
        submitLoading={submitLoading}
        isEdit={false}
      />
    </div>
  )
}
