import { HotSettings } from '@traba/types'
import { useQuery } from 'react-query'
import { trabaApi } from 'src/api/helpers'

const getHotSettings = async () => {
  try {
    const response = await trabaApi.get(`/system/hot-settings`)
    return response.data
  } catch (error) {
    console.error(error)
  }
}

export function useHotSettings() {
  const {
    isLoading,
    isError,
    data: hotSettings,
    error,
    isFetched,
  } = useQuery<HotSettings, Error>('hot-settings', () => getHotSettings())

  return {
    isLoading,
    isError,
    error,
    isFetched,
    hotSettings,
  }
}
