import { ChatSuggestionsResponse } from '@traba/types'
import { useQuery } from 'react-query'
import { trabaApi } from 'src/api/helpers'

const getChatSuggestions = async (query?: string) => {
  try {
    const response = await trabaApi.get(
      query
        ? `/my-company/new-chat-suggestions?query=${query}`
        : `/my-company/new-chat-suggestions`,
    )
    return response.data
  } catch (error) {
    console.error(error)
  }
}

export const clearSuggestionCache = async () => {
  try {
    const response = await trabaApi.post(
      `/my-company/clear-new-chat-suggestions-cache`,
    )
    return response.data
  } catch (error) {
    console.error(error)
  }
}

export const useChatSuggestions = (query?: string) => {
  const { isLoading, isError, data, error, isFetched } = useQuery<
    ChatSuggestionsResponse,
    Error
  >(['new-chat-suggestions', query], () => getChatSuggestions(query))

  return {
    isLoading,
    isError,
    error,
    isFetched,
    suggestions: data?.suggestions,
  }
}
