import { DEFAULT_TIMEZONE } from '@traba/consts'
import { CreateShiftRequest, RoleInfoForCreateShiftRequest } from '@traba/types'
import { EditableContentCard } from 'src/components/EditableContentCard/EditableContentCard'
import { getScheduleCardEntries } from 'src/screens/BookShifts/utils'

export default function AddToExistingScheduleConfirmContent(props: {
  shiftRequest: CreateShiftRequest
  recurringRoles: RoleInfoForCreateShiftRequest[]
  onNavigate: (step: number) => void
}) {
  const { shiftRequest, recurringRoles, onNavigate } = props
  return recurringRoles.map((r) => (
    <EditableContentCard
      sections={[
        {
          title: 'Schedule',
          onEdit: () => {
            onNavigate(1)
            // TODO(gavin): add analytics
            // window.analytics.track(`User Clicked Edit Schedule`, { isEdit })
          },
          entries: getScheduleCardEntries(
            { ...shiftRequest, ...r },
            shiftRequest.schedules[0].timeZone || DEFAULT_TIMEZONE,
          ),
        },
      ]}
    />
  ))
}
