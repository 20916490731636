import { Collapse } from '@mui/material'
import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import {
  Button,
  ButtonVariant,
  Icon,
  Input,
  SvgIcon,
  Text,
} from 'src/components/base'
import { SelectDropdown } from 'src/components/base/Select/Select'
import { MemberRoleInfo } from 'src/components/MemberRoleInfo/MemberRoleInfo'
import { useInvitations } from 'src/hooks/useMembers'
import { REQUIRED_FIELD_MESSAGE } from 'src/libs/constants'
import { theme } from 'src/libs/theme'
import { USER_ROLE_OPTIONS, USER_ROLES, UserData, UserRole } from 'src/types'

import { email as emailValidation } from 'src/utils/formUtils'
import * as yup from 'yup'
import { ButtonIcon } from '../../PersonalProfile/PersonalProfile.styles'

function isRole(str: string): str is UserRole {
  return USER_ROLES.includes(str as UserRole)
}

export interface CreateInvitationModalProps {
  member: UserData | null
  handleClose: () => void
}

export const CreateInvitationModal = (props: CreateInvitationModalProps) => {
  const { createInvitation } = useInvitations()
  const [isLoading, setIsLoading] = useState(false)
  const [roleInfoExpanded, setRoleInfoExpanded] = useState(false)

  const formik = useFormik({
    initialValues: {
      email: props.member?.email ?? '',
      role: '',
      invitedUserId: props.member?.uid ?? undefined,
    },
    validationSchema: yup.object({
      email: emailValidation(),
      role: yup.string().required(REQUIRED_FIELD_MESSAGE),
    }),
    onSubmit: ({ role, email, invitedUserId }) => {
      if (!isRole(role)) {
        return
      }
      formik.setStatus({})
      setIsLoading(true)
      createInvitation(
        { role, email, invitedUserId },
        {
          onSettled: () => {
            setIsLoading(false)
            props.handleClose()
          },
        },
      )
      window.analytics.track(`User Sent Invitation`, {
        role,
        email,
        invitedUserId,
      })
    },
  })
  const { errors, touched } = formik

  useEffect(() => {
    formik.setFieldValue('email', props.member?.email ?? '')
    formik.setFieldValue('invitedUserId', props.member?.uid ?? undefined)
  }, [props.member])

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: theme.space.xs,
          padding: theme.space.lg,
          paddingBottom: 0,
        }}
      >
        <Text variant="h2">Invite member</Text>
        <ButtonIcon
          name="cancel"
          style={{ cursor: 'pointer' }}
          onClick={() => props.handleClose()}
        />
      </div>
      <Text variant="body2" style={{ padding: `0 ${theme.space.lg}px` }}>
        Enter an email address and choose a role for your new member below and
        we’ll send them an invitation to join your company’s account.
      </Text>
      <form onSubmit={formik.handleSubmit}>
        <div
          style={{
            display: 'flex',
            alignItems: 'flex-start',
            padding: `0 ${theme.space.lg}px`,
          }}
        >
          <div style={{ flex: 2, marginRight: theme.space.xs }}>
            <Input
              label="Email address"
              name="email"
              full
              disabled={!!props.member?.email}
              value={formik.values.email}
              onChange={formik.handleChange}
              inputStatus={touched.email && errors.email ? 3 : 1}
              errorMessage={errors.email}
            />
          </div>
          <div style={{ flex: 1 }}>
            <SelectDropdown
              label="Role"
              name="role"
              menuItems={USER_ROLE_OPTIONS}
              value={formik.values.role}
              handleSelect={(val) => formik.setFieldValue('role', val)}
              inputStatus={touched.role && errors.role ? 3 : 1}
              errorMessage={errors.role}
              style={{ marginTop: theme.space.sm }}
            />
          </div>
        </div>
        <Button
          variant={ButtonVariant.TEXT}
          type="button"
          leftIcon={<SvgIcon name="info" />}
          rightIcon={
            <Icon
              name={
                roleInfoExpanded ? 'arrowTop_primary' : 'arrowBottom_primary'
              }
            />
          }
          iconWidth={20}
          onClick={() => {
            setRoleInfoExpanded(!roleInfoExpanded)
            window.analytics.track(`User Clicked Learn More About Member Roles`)
          }}
          iconPadding={`${theme.space.xxs}px`}
          style={{
            padding: `0 ${theme.space.lg}px`,
            color: theme.colors.brand,
            fontSize: 12,
            marginTop: theme.space.xs,
          }}
        >
          Learn more about team member roles
        </Button>
        <Collapse in={roleInfoExpanded}>
          <div
            style={{
              borderTop: `1px solid ${theme.border.color}`,
              borderBottom: `1px solid ${theme.border.color}`,
              marginTop: theme.space.xs,
            }}
          >
            <MemberRoleInfo />
          </div>
        </Collapse>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: theme.space.lg,
            paddingTop: theme.space.med,
          }}
        >
          <Button
            type="button"
            variant={ButtonVariant.OUTLINED}
            onClick={() => props.handleClose()}
          >
            Back
          </Button>
          <Button variant={ButtonVariant.FILLED} loading={isLoading}>
            Send invitation
          </Button>
        </div>
      </form>
    </div>
  )
}
