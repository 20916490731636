import { addPixelUnit } from '@traba/theme'
import React from 'react'
import styled from 'styled-components'

type Props = {
  mt?: string | number
  mb?: string | number
  mr?: string | number
  ml?: string | number
  my?: string | number
  mx?: string | number
  m?: string | number
  pt?: string | number
  pb?: string | number
  pr?: string | number
  pl?: string | number
  py?: string | number
  px?: string | number
  p?: string | number
  gap?: string | number
  width?: string | number
  children: React.ReactNode
  style?: { [key: string]: string | number }
  className?: string
}

const StyledCol = styled.div<Props>`
  display: flex;
  flex-direction: column;
  gap: ${(props) => addPixelUnit(props.gap ?? props.gap ?? 0)};

  /* Margins */
  margin-top: ${(props) => addPixelUnit(props.mt ?? props.my ?? props.m ?? 0)};
  margin-bottom: ${(props) =>
    addPixelUnit(props.mb ?? props.my ?? props.m ?? 0)};
  margin-left: ${(props) => addPixelUnit(props.ml ?? props.mx ?? props.m ?? 0)};
  margin-right: ${(props) =>
    addPixelUnit(props.mr ?? props.mx ?? props.m ?? 0)};

  /* Paddings */
  padding-top: ${(props) => addPixelUnit(props.pt ?? props.py ?? props.p ?? 0)};
  padding-bottom: ${(props) =>
    addPixelUnit(props.pb ?? props.py ?? props.p ?? 0)};
  padding-left: ${(props) =>
    addPixelUnit(props.pl ?? props.px ?? props.p ?? 0)};
  padding-right: ${(props) =>
    addPixelUnit(props.pr ?? props.px ?? props.p ?? 0)};

  ${(props) =>
    props.width ? `width: ${addPixelUnit(props.width)} !important;` : ''}
`

export function Col({ className = '', ...props }: Props) {
  return <StyledCol {...props} className={`Col ${className}`} />
}
