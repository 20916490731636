import { JobStatus, ShiftStatus } from '@traba/types'

export const REQUIRED_FIELD_MESSAGE = 'This field is required'
export const INPUT_PASSWORD_MISMATCH = 'Must match password.'
export const INPUT_INVALID_EMAIL = 'Must be a valid email address.'
export const INPUT_INVALID_PASSWORD = 'Must be a valid password.'
export const INPUT_INVALID_PHONE = 'Must be a valid phone number.'
export const INPUT_INVALID_ZIPCODE = 'Must be a valid ZIP code.'
export const VALID_ADDRESS = 'Please choose an address from the dropdown'

export const ONE_MINUTE_IN_MS = 60 * 1000 * 1
export const TWO_MINUTES_IN_MS = 60 * 1000 * 2
export const FIVE_MINUTES_IN_MS = 1000 * 60 * 5

export const EMPTY_ADDRESS_OBJECT = {
  street1: '',
  street2: '',
  city: '',
  state: '',
  postalCode: '',
  location: undefined,
}

export const releaseToWorkerVerbiage = {
  FAVORITES_ONLY: 'FAVORITES ONLY',
  FAVORITES_FIRST: 'FAVORITES FIRST',
}

export const shiftStatusBadge = {
  [ShiftStatus.COMPLETE]: 'COMPLETE',
  [ShiftStatus.CANCELED]: 'CANCELED',
  EDITS_PROCESSING: 'EDITS PROCESSING',
  [JobStatus.Abandoned]: 'ABANDONED',
}

export const shiftStatus = {
  CANCELED: 'CANCELED',
  ACTIVE: 'ACTIVE',
}

export const CURRENT_TIMEZONE = Intl.DateTimeFormat().resolvedOptions().timeZone

export const PREFILLED_INFO_BUSINESS_NAME = 'PREFILLED_INFO_BUSINESS_NAME'
export const PREFILLED_INFO_CITY = 'PREFILLED_INFO_CITY'
export const HAS_SHOWN_SMS_CONSENT_MODAL = 'HAS_SHOWN_SMS_CONSENT_MODAL'
export const USER_CALENDER_VIEW = 'USER_CALENDER_VIEW'

export const BULLET_CHAR = '\u2022'

export const SHIFT_ID_IS_INFINITE_SHIFT_INSTANCE_ID_ERROR_CODE = 474
