import { useHotSettings } from '@traba/hooks'
import { recurringSchedulesEnabled } from '@traba/utils'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, ButtonVariant } from 'src/components/base'
import { Text } from 'src/components/base/Text'

import { useCompany } from 'src/hooks/useCompany'
import { ModalWithImage } from '../ModalWithImage'

export type RoleCreatedModalContentProps = {
  setShowRoleModal: React.Dispatch<React.SetStateAction<boolean>>
}

export const RoleCreatedModalContent = (
  props: RoleCreatedModalContentProps,
) => {
  const navigate = useNavigate()
  const { hotSettings } = useHotSettings()
  const { company } = useCompany()

  return (
    <ModalWithImage
      imgSrc="/img/worker-with-one-box.png"
      handleClose={() => props.setShowRoleModal(false)}
    >
      <Text variant="h3" style={{ marginTop: '32px', marginBottom: '16px' }}>
        New role has been created
      </Text>
      <Text variant="body2" style={{ marginBottom: '64px' }}>
        Congratulations, you just created a role. How amazing is that! Would you
        like to create your shift now?
      </Text>
      <Text variant="h5" style={{ marginBottom: '16px' }}>
        Thanks to that you'll be able to:
      </Text>
      <Text variant="body2" style={{ marginBottom: '8px' }}>
        ✅ Book a shift
      </Text>
      <Text variant="body2" style={{ marginBottom: '8px' }}>
        ✅ Onboard your first Traba Pro
      </Text>
      <Text variant="body2" style={{ marginBottom: '32px' }}>
        ✅ Get more work done!
      </Text>
      <Button
        style={{ margin: '4px 0' }}
        onClick={() =>
          navigate(
            recurringSchedulesEnabled({ hotSettings, company })
              ? '/new'
              : '/book-shifts',
          )
        }
      >
        Book a shift
      </Button>
      <Button
        variant={ButtonVariant.OUTLINED}
        style={{ margin: '4px 0' }}
        onClick={() => props.setShowRoleModal(false)}
        aria-label="skip"
      >
        Maybe Later
      </Button>
    </ModalWithImage>
  )
}
