import * as Sentry from '@sentry/react'
import { useQuery, UseQueryResult } from 'react-query'
import { WorkerShift } from 'shared/types/src/lib/worker-shift'
import { trabaApi } from 'src/api/helpers'
import { SearchParams } from 'src/types'

export interface WorkerHistoryWithCompanyDto {
  shiftsWithCompany: number
  minutesWithCompany: number
  lastWorkedAtCompany?: Date
}

const getWorkerHistoryWithMe = async (
  workerId: string,
): Promise<WorkerHistoryWithCompanyDto | undefined> => {
  try {
    const response = await trabaApi.get(
      `/my-company/workers/${workerId}/history-with-me`,
    )
    return response.data
  } catch (error) {
    Sentry.captureException(error)
  }
}

export const useWorkerHistoryWithMe = (workerId: string) => {
  const {
    isLoading,
    isError,
    data: historyWithMe,
    error,
    isFetched,
  } = useQuery<WorkerHistoryWithCompanyDto | undefined, Error>(
    `history-with-me_${workerId}`,
    () => getWorkerHistoryWithMe(workerId),
  )

  return {
    isLoading,
    isError,
    error,
    isFetched,
    historyWithMe,
  }
}

export const getWorkerShiftHistoryWithMe = async (
  params: SearchParams,
): Promise<WorkerShift[]> => {
  const { startAt, startAtIndex, limit, workerId } = params
  try {
    const res = await trabaApi.get(
      `my-company/workers/${workerId}/worker-shifts`,
      {
        params: { startAt, startAtIndex, limit },
      },
    )
    const { workerShifts } = res.data
    return workerShifts
  } catch (error) {
    Sentry.captureException(error, {
      tags: { action: 'workers/:workerId/worker-shifts' },
      extra: { params },
    })
    throw error
  }
}

export const useWorkerShiftHistoryWithMe = (
  searchParams: SearchParams,
): UseQueryResult<WorkerShift[], Error> => {
  const queryKey = [
    'shifts',
    searchParams.limit,
    searchParams.startAt,
    searchParams.startAtIndex,
    searchParams.workerId,
  ]
  return useQuery<WorkerShift[], Error>(queryKey, () => {
    return getWorkerShiftHistoryWithMe(searchParams)
  })
}
