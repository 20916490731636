import { Card } from '@traba/react-components'
import { Button, Row } from 'src/components'
import { theme } from 'src/libs/theme'
import styled, { css } from 'styled-components'

const BOOK_SHIFTS_COMPONENT_CONTAINER_WIDTH = 800

export const BookShiftsContainer = styled.div`
  display: flex;
  height: 100%;
  overflow: hidden;
`

export const BookShiftsNavContainer = styled.div`
  max-width: 200px;
  margin-left: auto;
  padding: ${({ theme }) => theme.space.sm}px 0;
  @media only screen and (min-width: ${({ theme }) => theme.media.minSmall}) {
    padding: ${({ theme }) => theme.space.xl}px 0;
  }
`

export const BookShiftsNav = styled.div`
  padding-left: ${({ theme }) => theme.space.sm}px;
  @media only screen and (min-width: ${({ theme }) => theme.media.minSmall}) {
    padding-left: ${({ theme }) => theme.space.xl}px;
  }
`

export const BookShiftsContent = styled.div`
  padding: ${({ theme }) => theme.space.sm}px;
  @media only screen and (min-width: ${({ theme }) => theme.media.minSmall}) {
    padding: ${({ theme }) => theme.space.xl}px;
  }
`

export const EditShiftsReturnButton = styled(Button)`
  margin: ${({ theme }) => theme.space.sm}px 0px;
  @media only screen and (min-width: ${({ theme }) => theme.media.minSmall}) {
    margin: ${({ theme }) => theme.space.xl}px 0px;
  }
`

export const BookShiftsComponentContainer = styled.div<{ active: boolean }>`
  ${({ active, theme }) =>
    active
      ? css`
          width: 100%;
          @media only screen and (min-width: ${theme.media.minMedium}) {
            max-width: ${BOOK_SHIFTS_COMPONENT_CONTAINER_WIDTH}px;
          }
        `
      : css`
          display: none;
        `}
`

export const BookShiftsNavTitle = styled.button<{
  active?: boolean
  disabled?: boolean
}>`
  width: 100%;
  padding: ${({ theme }) => theme.space.xxs}px 0;
  border: none;
  border-bottom: 1px solid ${({ theme }) => theme.colors.Grey20};
  background: none;
  text-align: right;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;

  &:first-child {
    border-top: ${({ theme }) => `1px solid ${theme.colors.Grey20}`};
  }

  ${({ active, disabled, theme }) =>
    active
      ? css`
          color: ${theme.colors.MidnightBlue};
        `
      : disabled
        ? css`
            color: ${theme.colors.Grey40};
          `
        : css`
            cursor: pointer;
            color: ${theme.colors.MidnightBlue};
            opacity: 0.7;

            :hover {
              opacity: 1;
            }
          `}
`

const CONFIRM_BLOCK_SCREEN_WIDTH =
  (BOOK_SHIFTS_COMPONENT_CONTAINER_WIDTH +
    theme.space.xl +
    theme.space.med * 2 +
    320) /
  0.75

export const BookShiftsConfirmBlockRight = styled.div`
  ${({ theme }) => css`
    display: none;

    @media only screen and (min-width: ${CONFIRM_BLOCK_SCREEN_WIDTH}px) {
      display: block;
      position: absolute;
      top: ${theme.space.xl}px;
      left: ${BOOK_SHIFTS_COMPONENT_CONTAINER_WIDTH +
      theme.space.xl +
      theme.space.med}px;
      width: 320px;
    }
  `}
`

export const BookShiftsConfirmBlockBottom = styled.div`
  ${({ theme }) => css`
    display: none;

    @media only screen and (max-width: ${CONFIRM_BLOCK_SCREEN_WIDTH - 1}px) {
      display: block;
      margin-top: ${theme.space.lg}px;
    }
  `}
`

export const ClickableRow = styled(Row)`
  ${({ onClick }) =>
    !!onClick &&
    css`
      :hover {
        cursor: pointer;
      }
    `}
`

export const ClickableCard = styled(Card)<{ isClickable?: boolean }>`
  ${({ isClickable }) =>
    isClickable &&
    css`
      :hover {
        border-color: ${({ theme }) => theme.colors.Grey30};
        cursor: pointer;
      }
    `}

  margin-bottom: ${({ theme }) => theme.space.xxs}px;
  margin-top: ${({ theme }) => theme.space.xxs}px;
`
