import { Company, KioskModeType } from '@traba/types'
import { useQuery } from 'react-query'
import { trabaApi } from 'src/api/helpers'
import { useCompany } from 'src/hooks/useCompany'

import { CompanyProfileProps } from './CompanyProfile.ui'

export const useCompanyProfile = (): CompanyProfileProps => {
  const { company, patchCompany, isLoading } = useCompany()
  return { company, patchCompany, isLoading }
}

export const useCompanyKioskPin = (company: Company | undefined) => {
  const { data, isLoading, isError, error } = useQuery(
    'kiosk-pin',
    async () => {
      if (company?.kioskModeType !== KioskModeType.None) {
        const response = await trabaApi.get('my-company/kiosk-pin')
        return response.data
      }
      return null
    },
  )

  return { data, isLoading, isError, error }
}
