import React from 'react'
import { UserData } from 'src/types'

import { CreateMemberUI } from './CreateMember.ui'

// This is where the logic meets the UI. This separation allows use to pass the data directly to the UI in StoryBook
export type CreateMemberProps = {
  setTabIdx?: (tab: number) => void
  onCreate?: (member: UserData) => void
  setShowModal?: React.Dispatch<React.SetStateAction<boolean>>
}

export const CreateMember = (props: CreateMemberProps) => {
  return <CreateMemberUI {...props} />
}
