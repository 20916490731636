import { theme } from '@traba/theme'
import { LocationResponse } from '@traba/types'
import React from 'react'
import { EmptyPlaceholderTile } from 'src/components/base'
import { EmptyDataLocationSubtitle } from 'src/components/base/RegionalFilterSelect/EmptyDataLocationSubtitle'
import { DesktopTimeSheetApprovalLocationTile } from 'src/components/TimeSheets/DesktopTimesheetApprovalLocationTile'
import { MobileTimeSheetApprovalLocationTile } from 'src/components/TimeSheets/MobileTimesheetApprovalLocationTile'
import { useHotSettings } from 'src/hooks/useSystem'
import { TimesheetSummaryResponse } from '../types'

export const LocationTilesList = ({
  locationIds,
  summary,
  getLocationById,
  tabIndex,
  timePeriod,
  refetchSummary,
  isMobileViewOrReactNative,
  userCanManageTimesheets,
}: {
  locationIds: string[]
  summary: TimesheetSummaryResponse
  getLocationById: (id: string) => LocationResponse | undefined
  tabIndex: number
  timePeriod: [Date | null, Date | null]
  refetchSummary: () => void
  isMobileViewOrReactNative: boolean
  userCanManageTimesheets: boolean
}) => {
  const { hotSettings } = useHotSettings()
  const isPendingTab = tabIndex === 0
  const cards = locationIds
    .map((locationId, idx) => {
      const location = getLocationById(locationId)
      const locationName =
        location?.name || location?.shortLocation || 'Unknown Location'
      const shiftsAtLocation = summary.shifts.filter((s) => {
        return s.activeLocationId === (location?.activeLocationId ?? locationId)
      })

      const numWorkerShifts = shiftsAtLocation.reduce((sum, shift) => {
        if (isPendingTab) {
          return sum + (shift.numWorkerShifts - shift.numApprovedWorkerShifts)
        }
        return sum + shift.numApprovedWorkerShifts
      }, 0)

      if (numWorkerShifts === 0) {
        return null
      }

      return !isMobileViewOrReactNative ? (
        <DesktopTimeSheetApprovalLocationTile
          key={locationId}
          activeLocationId={location?.activeLocationId ?? locationId}
          locationName={locationName}
          numShifts={shiftsAtLocation.length ?? 0}
          numWorkerShifts={numWorkerShifts}
          tabIndex={tabIndex}
          timePeriod={timePeriod}
          refetchSummary={refetchSummary}
          userCanManageTimesheets={userCanManageTimesheets}
        />
      ) : (
        <MobileTimeSheetApprovalLocationTile
          key={locationId}
          activeLocationId={location?.activeLocationId ?? locationId}
          locationName={locationName}
          numWorkerShifts={numWorkerShifts}
          tabIndex={tabIndex}
          timePeriod={timePeriod}
          refetchSummary={refetchSummary}
          isLastTile={idx === locationIds.length - 1}
        />
      )
    })
    .filter((card) => card !== null)

  if (!cards.length) {
    return (
      <EmptyPlaceholderTile
        iconName="info"
        title={
          isPendingTab
            ? 'There are no timesheets to approve for this location and date range.'
            : 'There are no approved timesheets for this location and date range.'
        }
        subtitle={
          hotSettings?.regionalFiltersEnabled && (
            <EmptyDataLocationSubtitle
              isMobileView={false}
              extraText="to view timesheets for other locations."
            />
          )
        }
        style={{ marginTop: theme.space.sm }}
      />
    )
  }
  return cards
}
