import { Collapse } from '@mui/material'
import { CreateShiftRequest, RecurringShiftsOutput } from '@traba/types'
import { combineRecurringShifts, getRecurringShifts } from '@traba/utils'
import { formatInTimeZone } from 'date-fns-tz'
import { useMemo, useState } from 'react'
import { Button, ButtonVariant, Icon, Row, Text } from 'src/components'
import { theme } from 'src/libs/theme'

// Component to display Shifts returned from RRule in an expandable list
export default function ShiftSchedule(props: {
  shiftRequest: CreateShiftRequest
  shiftTimeZone: string
  subdued?: boolean
}) {
  const { shiftRequest, subdued, shiftTimeZone } = props
  const [scheduleA, scheduleB] = shiftRequest.schedules
  const { startTime, recurringSchedule } = scheduleA
  const [expandedShifts, setExpandedShifts] = useState(false)

  const { allShiftDates, shiftCount } = useMemo((): RecurringShiftsOutput => {
    if (!recurringSchedule) {
      return {
        shiftCount: 1,
        allShiftDates: [startTime],
        shiftCountWeekly: 1,
      }
    }
    const recurringShiftsA = getRecurringShifts(scheduleA)
    if (scheduleB) {
      const recurringShiftsB = getRecurringShifts(scheduleB)
      return combineRecurringShifts(recurringShiftsA, recurringShiftsB)
    }
    return recurringShiftsA
  }, [scheduleA, scheduleB, recurringSchedule, startTime])

  if (!recurringSchedule) {
    return null
  }

  return (
    <div>
      <Row alignCenter justifyBetween>
        <Text
          variant={!subdued ? 'h6' : 'body1'}
          style={!subdued ? undefined : { fontSize: 13 }}
        >
          {formatInTimeZone(startTime, shiftTimeZone, 'MMM d, yyyy')}
          {' - '}
          {!!recurringSchedule.endDate &&
            formatInTimeZone(
              recurringSchedule.endDate,
              shiftTimeZone,
              'MMM d, yyyy',
            )}{' '}
          ({shiftCount} {shiftCount > 1 ? 'shifts' : 'shift'})
        </Text>
        <Button
          variant={ButtonVariant.TEXT}
          style={{
            color: theme.colors.brand,
            padding: 0,
            fontSize: 12,
          }}
          onClick={() => {
            setExpandedShifts(!expandedShifts)
            window.analytics.track(
              `User ${
                expandedShifts ? 'Collapsed' : 'Expanded'
              } Shift Schedule`,
            )
          }}
        >
          <Icon
            name={expandedShifts ? 'arrowTop_primary' : 'arrowBottom_primary'}
            style={{ paddingRight: !subdued ? 10 : 0 }}
          />
          {subdued ? null : (
            <span className="xs-hide sm-show" style={{ fontWeight: 500 }}>
              {expandedShifts ? 'Hide' : 'Show'} all shifts
            </span>
          )}
        </Button>
      </Row>
      <Collapse in={expandedShifts}>
        {allShiftDates.map((shift, i) => (
          <Text
            key={i}
            variant="body1"
            style={!subdued ? { marginTop: theme.space.xxs } : { fontSize: 13 }}
          >
            {formatInTimeZone(shift, shiftTimeZone, 'EEEE, MMM d, yyyy')}
          </Text>
        ))}
      </Collapse>
    </div>
  )
}
