import { MODAL_SIZE } from '@traba/react-components'
import { Card } from '@traba/react-components'
import { LocationResponse } from '@traba/types'
import React, { useState } from 'react'
import {
  Button,
  ButtonVariant,
  EmptyPlaceholderTile,
  Modal,
  Text,
} from 'src/components/base'
import { useUserPermissions } from 'src/hooks/useUser'
import { theme } from 'src/libs/theme'

import { UserRolePermission } from '../../types'
import { Plus } from '../assets/svg'
import { Location } from '../Location'
import { ArchiveLocationModal } from '../Modals/ArchiveLocationModal'
import { CreateOrEditLocationModal } from '../Modals/CreateOrEditLocationModal/CreateOrEditLocationModal'
import * as S from './LocationsProfile.styles'

export type LocationsProfileProps = {
  locations: LocationResponse[]
}

export const LocationsProfileUI: React.FC<LocationsProfileProps> = (
  props: LocationsProfileProps,
) => {
  const [showCreateModal, setShowCreateModal] = useState(false)
  const userCanEditLocations = useUserPermissions([
    UserRolePermission.ManageShifts,
  ])

  const [showArchiveModal, setShowArchiveModal] = useState(false)
  const [locationToArchive, setLocationToArchive] = useState<
    LocationResponse | undefined
  >(undefined)

  const handleArchiveModalClose = () => {
    setShowArchiveModal(false)
    setLocationToArchive(undefined)
  }

  const handleArchive = (location: LocationResponse) => {
    setShowArchiveModal(true)
    setLocationToArchive(location)
  }

  const [showEditModal, setShowEditModal] = useState(false)
  const [locationToEdit, setLocationToEdit] = useState<
    LocationResponse | undefined
  >(undefined)

  const handleEditModalClose = () => {
    setShowEditModal(false)
    setLocationToEdit(undefined)
  }

  const handleEdit = (location: LocationResponse) => {
    setShowEditModal(true)
    setLocationToEdit(location)
  }

  return (
    <S.LocationsProfileContainer>
      <Text style={{ marginBottom: theme.space.xxs }} variant="h5">
        Locations
      </Text>
      <Text variant="body2">
        {userCanEditLocations ? 'Manage' : 'View'} your company‘s locations.
      </Text>
      {userCanEditLocations ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Button
            leftIcon={<Plus />}
            onClick={() => {
              setShowCreateModal(true)
            }}
            reverse
            variant={ButtonVariant.TEXT}
          >
            Create new location
          </Button>
        </div>
      ) : null}
      {props.locations.length ? (
        props.locations
          .sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime())
          .map((location: LocationResponse, index: number) => (
            <Card
              key={index}
              style={{
                marginTop: '8px',
                width: '100%',
                display: 'flex',
              }}
            >
              <Location
                location={location}
                archive={handleArchive}
                edit={handleEdit}
                userCanEdit={userCanEditLocations}
              />
            </Card>
          ))
      ) : (
        <EmptyPlaceholderTile
          title="Your locations will show up here"
          subtitle="You can add a location above."
          iconName="folder"
        />
      )}
      <Modal
        size={MODAL_SIZE.LARGE}
        isOpen={showCreateModal}
        handleClose={() => setShowCreateModal(false)}
      >
        <CreateOrEditLocationModal setShowModal={setShowCreateModal} />
      </Modal>
      <Modal
        size={MODAL_SIZE.MEDIUM}
        isOpen={showArchiveModal}
        handleClose={handleArchiveModalClose}
      >
        {locationToArchive && (
          <ArchiveLocationModal
            location={locationToArchive}
            handleClose={handleArchiveModalClose}
          />
        )}
      </Modal>
      <Modal
        size={MODAL_SIZE.LARGE}
        isOpen={showEditModal}
        handleClose={handleEditModalClose}
      >
        {locationToEdit && (
          <CreateOrEditLocationModal
            location={locationToEdit}
            setShowModal={setShowEditModal}
          />
        )}
      </Modal>
    </S.LocationsProfileContainer>
  )
}
