/**
 * TODO(polyphilz): This should be a shared hook for when we need this in the
 * Ops Console.
 */

import { useQuery } from '@tanstack/react-query'
import { trabaApi } from '@traba/api-utils'
import { FIVE_MINUTES_IN_MS } from '@traba/consts'
import { ShiftRequestParentWithShiftRequest } from '@traba/types'
import { useAppContext } from 'src/context/appContext/AppContext'

export interface ShiftRequestParentsParams {
  includeCompletedSchedules?: boolean
  locationIds?: string[]
}

const getShiftRequestParents = async ({
  includeCompletedSchedules = true,
  locationIds,
}: ShiftRequestParentsParams) => {
  try {
    const response = await trabaApi.post(
      `my-company/query-shift-request-parents`,
      {
        includeCompletedSchedules,
        locationIds,
      },
    )
    return response.data
  } catch (error) {
    console.error(
      "Error querying for company's shift request parents: ",
      error,
      'Query body: ',
      { includeCompletedSchedules },
    )
    throw error
  }
}

export const useShiftRequestParents = ({
  includeCompletedSchedules = true,
}: {
  includeCompletedSchedules?: boolean
  locationIds?: string[]
}) => {
  const { state: appContextState } = useAppContext()
  const activeLocationIds = appContextState?.regionalFilter
    ? Object.values(appContextState?.regionalFilter || {}).flat()
    : undefined

  const {
    isLoading,
    isError,
    data: shiftRequestParents,
    error,
    isFetched,
    refetch,
  } = useQuery<ShiftRequestParentWithShiftRequest[], Error>({
    queryKey: [
      'shift-request-parents',
      { includeCompletedSchedules, locationIds: activeLocationIds },
    ],
    queryFn: () =>
      getShiftRequestParents({
        includeCompletedSchedules,
        locationIds: activeLocationIds,
      }),
    staleTime: FIVE_MINUTES_IN_MS,
  })

  return {
    isLoading,
    isError,
    error,
    isFetched,
    shiftRequestParents,
    refetch,
  }
}
