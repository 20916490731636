import { useMutation, useQuery } from '@tanstack/react-query'
import { trabaApi, trabaApis, trabaApisKeyType } from '@traba/api-utils'
import { FIVE_MINUTES_IN_MS } from '@traba/consts'
import { useAlert } from '@traba/context'
import { HotSettings } from '@traba/types'
import { useQueryClient } from 'react-query'

export const HOT_SETTINGS_QUERY_KEY = 'hot-settings'

const getHotSettings = async (environment?: trabaApisKeyType) => {
  try {
    const response = await (
      (environment && trabaApis.get(environment)) ??
      trabaApi
    ).get(`/system/hot-settings`)
    return response.data
  } catch (error) {
    console.error(error)
  }
}

export function useHotSettings(environment?: trabaApisKeyType) {
  const {
    isLoading,
    isError,
    data: hotSettings,
    error,
    isFetched,
  } = useQuery<HotSettings, Error>({
    queryKey: [HOT_SETTINGS_QUERY_KEY, environment],
    queryFn: () => getHotSettings(environment),
    staleTime: FIVE_MINUTES_IN_MS,
    refetchOnWindowFocus: 'always',
  })

  return {
    isLoading,
    isError,
    error,
    isFetched,
    hotSettings,
  }
}

export const useHotSettingsMutation = () => {
  const { showSuccess, showError } = useAlert()
  const queryClient = useQueryClient()
  const mutation = useMutation<
    { payload: Partial<HotSettings>; env?: trabaApisKeyType },
    Error,
    { payload: Partial<HotSettings>; env?: trabaApisKeyType }
  >({
    mutationFn: ({ payload, env }) => {
      const apiInstance = (env && trabaApis.get(env)) ?? trabaApi
      return apiInstance.put(`/system/hot-settings`, payload)
    },
    onSuccess: () => {
      // optimistic update
      queryClient.invalidateQueries([HOT_SETTINGS_QUERY_KEY])
      showSuccess('Success!')
    },
    onError: (error) => {
      console.error(error)
      showError('There was an error')
    },
  })

  function updateHotSettings(
    payload: Partial<HotSettings>,
    onSuccess: () => void,
    env?: trabaApisKeyType,
  ) {
    mutation.mutateAsync({ payload, env }, { onSuccess })
  }

  return {
    updateHotSettings: updateHotSettings,
    mutation,
    mutateAsync: mutation.mutateAsync,
    isError: mutation.isError,
    isSuccess: mutation.isSuccess,
  }
}
