import { useForm } from '@traba/utils'
import { useFormik } from 'formik'
import React, { useState } from 'react'
import {
  Button,
  ButtonVariant,
  Col,
  Input,
  InputPhone,
  Row,
} from 'src/components/base'
import { useMembers } from 'src/hooks/useMembers'
import { theme } from 'src/libs/theme'
import { UserData } from 'src/types'
import { email, name, phoneNumber } from 'src/utils/formUtils'
import * as yup from 'yup'

import * as S from './CreateMember.styles'
import { CreateMemberProps } from '.'

export const CreateMemberUI: React.FC<CreateMemberProps> = (props) => {
  const { createMember } = useMembers()
  const { handleOnSubmitError } = useForm()
  const [loading, setLoading] = useState(false)
  const { onCreate } = props

  const defaultUser = {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
  }
  const formik = useFormik({
    initialValues: defaultUser,
    validationSchema: yup.object({
      phoneNumber: phoneNumber(),
      email: email(),
      firstName: name(),
      lastName: name(),
    }),
    onSubmit: async (values) => {
      try {
        setLoading(true)
        formik.setStatus({})
        createMember(values, {
          onSuccess: (member: UserData) => {
            if (onCreate) {
              onCreate(member)
            }
            if (props.setShowModal) {
              props.setShowModal(false)
            }
          },
          onSettled: () => setLoading(false),
        })
      } catch (err: unknown) {
        setLoading(false)
        handleOnSubmitError(err, formik)
      }
    },
  })
  const { errors, touched } = formik
  return (
    <form onSubmit={formik.handleSubmit}>
      <S.CreateMemberContainer>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            columnGap: theme.space.xxs,
          }}
        >
          <Col style={{ flex: 1 }}>
            <Input
              label="First name"
              name="firstName"
              value={formik.values.firstName}
              onChange={formik.handleChange}
              inputStatus={touched.firstName && errors.firstName ? 3 : 1}
              errorMessage={errors.firstName}
              full
              containerStyle={{ marginTop: 0 }}
            />
          </Col>
          <Col style={{ flex: 1 }}>
            <Input
              label="Last name"
              name="lastName"
              value={formik.values.lastName}
              onChange={formik.handleChange}
              inputStatus={touched.lastName && errors.lastName ? 3 : 1}
              errorMessage={errors.lastName}
              full
              containerStyle={{ marginTop: 0 }}
            />
          </Col>
        </div>
        <InputPhone
          value={formik.values.phoneNumber}
          onChange={(val: string) =>
            formik.handleChange({
              target: { value: val, name: 'phoneNumber' },
            })
          }
          error={touched.phoneNumber ? errors.phoneNumber : null}
          containerStyle={{ marginTop: theme.space.xs }}
        />
        <Input
          label="Email address"
          name="email"
          width="100%"
          value={formik.values.email}
          onChange={formik.handleChange}
          inputStatus={touched.email && errors.email ? 3 : 1}
          errorMessage={errors.email}
          containerStyle={{ marginTop: theme.space.xs }}
        />
        <Row justifyBetween style={{ paddingTop: theme.space.sm }}>
          <Button
            type="button"
            variant={ButtonVariant.OUTLINED}
            onClick={() => {
              if (props.setTabIdx) {
                props.setTabIdx(0)
              }
              if (props.setShowModal) {
                props.setShowModal(false)
              }
            }}
          >
            Cancel
          </Button>
          <Button variant={ButtonVariant.FILLED} loading={loading}>
            Create
          </Button>
        </Row>
      </S.CreateMemberContainer>
    </form>
  )
}
