import { WorkerScores } from '@traba/types'
import { useQuery } from 'react-query'
import { trabaApi } from 'src/api/helpers'
import { FIVE_MINUTES_IN_MS } from '../libs/constants'

async function getWorkerScores(
  workerId: string,
): Promise<WorkerScores | undefined> {
  try {
    const res = await trabaApi.get(`my-company/workers/${workerId}/scores`)
    return res.data
  } catch (error) {
    console.error('useWorker -> getWorkerById() ERROR', error)
  }
}

export function useWorkerScores(workerId: string) {
  // Get Shift By Id
  const {
    isLoading,
    isError,
    data: workerScores,
    error,
    isFetched,
    refetch,
  } = useQuery<WorkerScores | undefined, Error>(
    ['workerMetrics', workerId],
    () => getWorkerScores(workerId),
    {
      staleTime: FIVE_MINUTES_IN_MS,
    },
  )

  return {
    isLoading,
    isError,
    workerScores,
    error,
    isFetched,
    refetch,
  }
}
