import React from 'react'

import {
  StripeInvoiceButtonProps,
  StripeInvoiceButtonUI,
} from './InvoiceButtons.ui'
import { useStripeInvoiceButton } from './StripeInvoiceButton.hooks'

// This is where the logic meets the UI. This separation allows use to pass the data directly to the UI in StoryBook
export const StripeInvoiceButton = (props: StripeInvoiceButtonProps) => {
  const logic = useStripeInvoiceButton()

  return <StripeInvoiceButtonUI {...logic} {...props} />
}
