import { REQUIRED_FIELD_MESSAGE } from '@traba/consts'
import { theme } from '@traba/theme'
import { User, USER_ROLE_OPTIONS, UserRole } from '@traba/types'
import { useFormik } from 'formik'
import React, { useEffect } from 'react'
import * as yup from 'yup'
import { Button, ButtonVariant } from '../../../base-components/Button/Button'
import Input from '../../../base-components/Input/Input'
import Row from '../../../base-components/Row'
import { SelectDropdown } from '../../../base-components/Select/Select'

export interface ChangeMemberRoleProps {
  member: Pick<User, 'role' | 'firstName' | 'lastName'>
  changeMemberRole: (role: UserRole) => void
  isLoading?: boolean
  onBack?: () => void
}

export function ChangeMemberRoleRow({
  member,
  changeMemberRole,
  isLoading = false,
  onBack,
}: ChangeMemberRoleProps) {
  const { firstName, lastName, role } = member
  const formik = useFormik({
    initialValues: { role },
    validationSchema: yup.object({
      role: yup.string().required(REQUIRED_FIELD_MESSAGE),
    }),
    onSubmit: ({ role: newRole }) => {
      formik.setStatus({})
      changeMemberRole(newRole)
    },
  })
  const { errors, touched } = formik

  useEffect(() => {
    formik.setFieldValue('role', role)
  }, [role])

  return (
    <form onSubmit={formik.handleSubmit}>
      <Row gap={theme.space.xs}>
        <div style={{ flex: 2 }}>
          <Input
            label="Selected member"
            name="member"
            full
            disabled
            onChange={() => undefined}
            value={`${firstName} ${lastName}`}
          />
        </div>
        <div style={{ flex: 1 }}>
          <SelectDropdown
            label="Role"
            name="role"
            menuItems={USER_ROLE_OPTIONS}
            value={formik.values.role}
            handleSelect={(val) => formik.setFieldValue('role', val)}
            inputStatus={touched.role && errors.role ? 3 : 1}
            errorMessage={errors.role}
            style={{ marginTop: theme.space.sm }}
          />
        </div>
      </Row>

      <Row justifyBetween pt={theme.space.med}>
        {onBack && (
          <Button
            type="button"
            variant={ButtonVariant.OUTLINED}
            onClick={onBack}
          >
            Back
          </Button>
        )}
        <Button variant={ButtonVariant.FILLED} loading={isLoading}>
          Save change
        </Button>
      </Row>
    </form>
  )
}
