import { RecordStatus } from './base'
import { UserRole } from './user-role'

export interface User {
  phoneNumber?: string
  email?: string
  firstName?: string
  lastName?: string
  password?: string
  photoUrl?: string
  uid: string
  companyId?: string
  onboarding?: {
    hasCompleted?: boolean
    timeOfCompletion?: Date
  }
  role: UserRole
  communicationPermissions?: CommunicationPermissions
  smsConsent?: SmsConsent
  recordStatus?: RecordStatus
}

export type SmsConsent = {
  agreedToSmsConsent?: boolean
  timeOfSmsConsent?: Date
}

export type CommunicationPermissions = {
  receiveAllShiftCodesSms?: boolean
}

export interface UpsertUser {
  phoneNumber: string
  email?: string
  firstName: string
  lastName: string
  companyId: string
  photoUrl?: string
}

export interface UpdateUser {
  firstName?: string
  lastName?: string
  profilePicture?: string
}

export interface DateRange {
  startDate?: Date
  endDate?: Date
}

// NOTE: Viewing / managing the following resources is considered part of
// viewing / managing shifts, so the appropriate shift permission is used
// to determine access to them: roles, locations, supervisors.
export enum UserRolePermission {
  ViewShifts = 'VIEW_SHIFTS',
  ManageShifts = 'MANAGE_SHIFTS',
  ViewWorkers = 'VIEW_WORKERS',
  ManageWorkers = 'MANAGE_WORKERS',
  ViewTimesheets = 'VIEW_TIMESHEETS',
  ManageTimesheets = 'MANAGE_TIMESHEETS',
  ViewInvoices = 'VIEW_INVOICES',
  ManageInvoices = 'MANAGE_INVOICES',
  ViewCompanySettings = 'VIEW_COMPANY_SETTINGS',
  ManageCompanySettings = 'MANAGE_COMPANY_SETTINGS',
  ViewPaymentSettings = 'VIEW_PAYMENT_SETTINGS',
  ManagePaymentSettings = 'MANAGE_PAYMENT_SETTINGS',
  ViewUserRoles = 'VIEW_USER_ROLES',
  ManageUserRoles = 'MANAGE_USER_ROLES',
  ViewPay = 'VIEW_PAY',
}
