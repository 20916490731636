import React from 'react'

export const ScheduleDailyViewWorkers = () => {
  return (
    <div>
      <h1>i am workers</h1>
      <h1>nice</h1>
    </div>
  )
}
