import { Skeleton } from '@mui/material'
import { CompanyCategory, ShiftPayType } from '@traba/types'
import { recurringSchedulesEnabled } from '@traba/utils'
import { useEffect, useState } from 'react'
import {
  Button,
  ButtonVariant,
  InlineBanner,
  Modal,
  Row,
  Text,
} from 'src/components'
import { CreateMemberModal } from 'src/components/Modals/CreateMemberModal'
import { CreateOrEditLocationModal } from 'src/components/Modals/CreateOrEditLocationModal/CreateOrEditLocationModal'
import { CreateRoleModal } from 'src/components/Modals/CreateRoleModal'
import { CreateOrEditInvoiceGroupModal } from 'src/components/Modals/InvoiceGroups/CreateOrEditInvoiceGroupModal'
import { useCompany } from 'src/hooks/useCompany'
import { useInvoiceGroups } from 'src/hooks/useInvoiceGroups'
import { getActiveRegion, useLocations } from 'src/hooks/useLocations'
import { useMembers } from 'src/hooks/useMembers'
import { useRoles } from 'src/hooks/useRoles'
import { PAY_RATE_DEFAULT } from 'src/hooks/useShiftRequests'
import { useHotSettings } from 'src/hooks/useSystem'
import { theme } from 'src/libs/theme'
import EditShiftsSaveButton from 'src/screens/EditShifts/EditShiftsSaveButton'

import { BookShiftsProps } from '../BookShiftsScreen'
import { BookShiftStepSection, Section } from '../components/BookShiftSection'
import {
  validateShiftEdits,
  validateSiteStep,
  validateSiteStepShiftDataModel,
} from '../validation'
import { BookShiftsInvoiceGroupSection } from './sections/BookShiftsInvoiceGroupSection'
import { BookShiftsParkingLocationSection } from './sections/BookShiftsParkingLocationSection'
import { BookShiftsSiteContactSection } from './sections/BookShiftsSiteContactSection'
import { BookShiftsSiteLocationSection } from './sections/BookShiftsSiteLocationSection'
import { BookShiftsSiteRoleSection } from './sections/BookShiftsSiteRoleSection'

type BookShiftsSiteContentModal =
  | 'ROLE'
  | 'ROLE_PREVIOUS'
  | 'LOCATION'
  | 'PARKING_LOCATION'
  | 'CONTACT'
  | 'INVOICE_GROUP'

export function BookShiftsDetailsContent(props: BookShiftsProps) {
  const {
    shiftRequest,
    setShiftRequest,
    shiftRequestMetadata,
    setShiftRequestMetadata,
    onContinue,
    onSaveChanges,
    selectedShifts,
    setShowSelectModal,
    shiftUpdates,
    isEdit,
    defaultShiftRequest,
    userHasLimitedShiftManagementAbility,
  } = props
  const { roles, isLoading: isLoadingRoles } = useRoles()
  const { activeLocations, isLoading: isLoadingLocations } = useLocations()
  const { members, isLoading: isLoadingMembers } = useMembers()
  const { company, isLoading: isLoadingCompany } = useCompany()
  const { hotSettings } = useHotSettings()

  const isEventCompany = company?.category === CompanyCategory.EVENTS
  const {
    activeInvoiceGroups: invoiceGroups,
    isLoading: isLoadingInvoiceGroups,
  } = useInvoiceGroups()

  const [modalType, setModalType] = useState<BookShiftsSiteContentModal | null>(
    null,
  )
  function closeModal() {
    setModalType(null)
  }

  const {
    roleId,
    locationId,
    parkingLocationId,
    supervisorId,
    parentInvoiceGroupId,
    payType,
  } = shiftRequest

  const postalCode = activeLocations.find((l) => l.locationId === locationId)
    ?.address?.postalCode

  const parkingPostalCode = activeLocations.find(
    (l) => l.locationId === parkingLocationId,
  )?.address?.postalCode

  useEffect(() => {
    async function checkActiveRegion() {
      if (postalCode) {
        const active = await getActiveRegion(postalCode)
        setShiftRequestMetadata({ activeRegion: !!active })
        !active &&
          window.analytics.track(
            `User Selected Location Outside of Active Regions`,
            { postalCode, isEdit },
          )
      }
    }

    checkActiveRegion()
  }, [postalCode, setShiftRequestMetadata, isEdit])

  useEffect(() => {
    async function checkActiveRegion() {
      if (parkingPostalCode) {
        const active = await getActiveRegion(parkingPostalCode)
        setShiftRequestMetadata({ activeParkingRegion: !!active })
        !active &&
          window.analytics.track(
            `User Selected Parking Location Outside of Active Regions`,
            { parkingPostalCode, isEdit },
          )
      }
    }

    checkActiveRegion()
  }, [parkingPostalCode, setShiftRequestMetadata, isEdit])

  const baseLocationOptions = {
    singular: 'location',
    plural: 'locations',
    options: activeLocations.map((l) =>
      l.name
        ? {
            value: l.locationId,
            label: l.name,
            subtitle: `${l.address.street1}, ${l.address.city}, ${l.address.state}`,
          }
        : {
            value: l.locationId,
            label: `${l.address.street1}, ${l.address.city}, ${l.address.state}`,
          },
    ),
  }

  function onCreateRole() {
    setModalType('ROLE')
    window.analytics.track(`User Clicked Create New Role`, { isEdit })
  }
  function onCreateRoleFromPrevious() {
    setModalType('ROLE_PREVIOUS')
    window.analytics.track(`User Clicked Create Role From Templates`, {
      isEdit,
    })
  }

  const roleCreationContent = () =>
    userHasLimitedShiftManagementAbility ? (
      roles.length ? null : (
        <InlineBanner
          severity="warning"
          text="Your role does not have permissions to create a new role. Please reach out to your administrator"
          style={{ marginTop: theme.space.xxs }}
        />
      )
    ) : !roles.length || isEventCompany ? (
      <Button
        variant={ButtonVariant.LINK}
        onClick={onCreateRole}
        style={{ marginTop: theme.space.xxs }}
      >
        Create new role
      </Button>
    ) : (
      <Row
        alignCenter
        style={{ gap: theme.space.xxs }}
        mt={theme.space.xxs}
        wrap
      >
        <Button variant={ButtonVariant.LINK} onClick={onCreateRole}>
          Create new role
        </Button>
        <Text variant="body1">OR</Text>
        <Button variant={ButtonVariant.LINK} onClick={onCreateRoleFromPrevious}>
          Create role using your previous roles
        </Button>
      </Row>
    )

  const sections: Array<Section> = [
    // TODO(gavin): shift data model - this goes into it's own section in the flow
    ...(recurringSchedulesEnabled({
      company,
      hotSettings,
    })
      ? []
      : [
          {
            title: 'Role',
            singular: 'role',
            plural: 'roles',
            options: roles
              .map((r) => ({ value: r.roleId, label: r.roleName }))
              .sort((a, b) => a.label.localeCompare(b.label)),
            selected: roleId,
            onChange: (v: string) => {
              const selectedRole = roles.find((r) => r.roleId === v)
              const rolePayRate =
                selectedRole?.defaultPayRate ?? PAY_RATE_DEFAULT
              setShiftRequest({
                roleId: v,
                ...(payType === ShiftPayType.HOURLY && {
                  payRate: rolePayRate,
                }),
                genderPreference: selectedRole?.genderPreference,
              })
              window.analytics.track(`User Selected Role`, {
                roleId: v,
                isEdit,
              })
            },
            onCreate: onCreateRole,
            optional: false,
            hide: false,
            contentExpanded: true,
            errorMessage:
              'The role in the previous shift request was archived. Please select a new role.',
            Content: BookShiftsSiteRoleSection,
            CreationContent: () => roleCreationContent(),
          },
        ]),
    {
      ...baseLocationOptions,
      title: 'Work site location',
      selected: locationId,
      onChange: (v: string) => {
        const location = activeLocations.find((l) => l.locationId === v)
        setShiftRequest({
          locationId: v,
          schedules: location
            ? [
                {
                  ...shiftRequest.schedules[0],
                  timeZone: location.timezone,
                },
              ]
            : shiftRequest.schedules,
        })
        window.analytics.track(`User Selected Location`, {
          locationId: v,
          isEdit,
        })
      },
      onCreate: () => {
        setModalType('LOCATION')
        window.analytics.track(`User Clicked Create New Location`, { isEdit })
      },
      optional: false,
      hide: false,
      contentExpanded: true,
      errorMessage:
        'The location in the previous shift request was archived. Please select a new location.',
      Content: BookShiftsSiteLocationSection,
    },
    {
      ...baseLocationOptions,
      title: 'Parking location',
      selected: parkingLocationId || '',
      onChange: (v: string) => {
        setShiftRequest({
          parkingLocationId: v,
        })
        window.analytics.track(`User Selected Parking Location`, {
          parkingLocationId: v,
          isEdit,
        })
      },
      onCreate: () => {
        setModalType('PARKING_LOCATION')
        window.analytics.track(`User Clicked Create New Parking Location`, {
          isEdit,
        })
      },
      hide: false,
      contentExpanded: shiftRequestMetadata.parkingLocationExpanded,
      checkboxLabel: 'Same as work site location',
      errorMessage:
        'The parking location in the previous shift request was archived. Please select a new parking location.',
      onClickCheckbox: () => {
        shiftRequestMetadata.parkingLocationExpanded &&
          setShiftRequest({ parkingLocationId: '' })
        setShiftRequestMetadata({
          parkingLocationExpanded:
            !shiftRequestMetadata.parkingLocationExpanded,
        })
      },
      Content: BookShiftsParkingLocationSection,
    },
    {
      title: 'Onsite contact',
      description:
        "Onsite contact will also receive text messages with shift clock in & out codes 30min before shift start time, unless they've opted out of Traba messages.",
      singular: 'contact',
      plural: 'contacts',
      options: members.map((m) => ({
        value: m.uid!,
        label: `${m.firstName} ${m.lastName}`,
      })),
      selected: supervisorId,
      onChange: (v: string) => {
        setShiftRequest({ supervisorId: v })
        window.analytics.track(`User Selected Contact`, {
          supervisorId: v,
          isEdit,
        })
      },
      onCreate: () => {
        setModalType('CONTACT')
        window.analytics.track(`User Clicked Create New Contact`, { isEdit })
      },
      optional: false,
      contentExpanded: true,
      hide: false,
      errorMessage:
        'The contact in the previous shift request was archived. Please select a new contact.',
      Content: BookShiftsSiteContactSection,
    },
    // TODO(gavin): shift data model - this goes into it's own section in the flow
    ...(recurringSchedulesEnabled({
      company,
      hotSettings,
    })
      ? []
      : [
          {
            title: 'Invoice group',
            singular: 'invoice group',
            plural: 'invoice groups',
            options: invoiceGroups.map((group) => ({
              value: group.id,
              label: group.name,
            })),
            selected: parentInvoiceGroupId,
            onChange: (groupId: string) => {
              setShiftRequest({
                parentInvoiceGroupId: groupId,
              })
              window.analytics.track(`User Selected Invoice Group`, {
                parentInvoiceGroupId: groupId,
                isEdit,
              })
            },
            onCreate: () => {
              setModalType('INVOICE_GROUP')
              window.analytics.track(`User Clicked Create New Invoice Group`, {
                isEdit,
              })
            },
            description:
              "Use invoice groups to customize how you are invoiced and better reconcile your spend. If the group's shifts span over a week, it will be split into multiple invoices. Choose from an existing invoice group or create a new one below.",
            tooltip:
              'You can choose which shifts are grouped together to receive separate invoices by role, location, or event if desired. For example, if you had two locations for your company, you could create one reusable invoice group for each. Then, you would add all shifts at Location 1 to the Location 1 invoice group and would receive invoices grouped by location.',
            optional: true,
            contentExpanded: true,
            hide: false,
            Content: BookShiftsInvoiceGroupSection,
          },
        ]),
  ]

  const validationError = recurringSchedulesEnabled({
    company,
    hotSettings,
  })
    ? validateSiteStepShiftDataModel(shiftRequest, shiftRequestMetadata)
    : validateSiteStep(shiftRequest, shiftRequestMetadata)

  const editsError =
    selectedShifts &&
    defaultShiftRequest &&
    validateShiftEdits(defaultShiftRequest, selectedShifts, shiftUpdates)

  if (
    isLoadingRoles ||
    isLoadingLocations ||
    isLoadingMembers ||
    isLoadingCompany ||
    isLoadingInvoiceGroups
  ) {
    return (
      <>
        {Array.from(Array(4)).map(() => (
          <>
            <Skeleton animation="pulse" width="100%" height="60px" />
            <Skeleton animation="pulse" width="100%" height="120px" />
          </>
        ))}
      </>
    )
  }

  return (
    <>
      {sections
        .filter((s) => !s.hide)
        .map((s, i, arr) => (
          <BookShiftStepSection
            section={s}
            bookShiftProps={props}
            noDivider={i === arr.length - 1}
          />
        ))}

      {isEdit && editsError && (
        <InlineBanner
          style={{ marginTop: theme.space.xs }}
          severity={'error'}
          text={editsError.message}
        />
      )}

      <Row style={{ justifyContent: 'flex-end', marginTop: theme.space.lg }}>
        {isEdit ? (
          <EditShiftsSaveButton
            onSaveChanges={onSaveChanges}
            validationError={editsError || validationError}
            selectedShifts={selectedShifts}
            setShowSelectModal={setShowSelectModal}
          />
        ) : (
          <Button onClick={onContinue} disabled={!!validationError}>
            Continue
          </Button>
        )}
      </Row>
      <Modal isOpen={modalType !== null} handleClose={closeModal}>
        {modalType && ['ROLE', 'ROLE_PREVIOUS'].includes(modalType) ? (
          <CreateRoleModal
            onClose={closeModal}
            onCreate={(r) =>
              setShiftRequest({
                roleId: r.roleId,
                payRate: r.defaultPayRate,
                genderPreference: r.genderPreference,
              })
            }
            isEventCompany={isEventCompany}
            fromPrevious={modalType === 'ROLE_PREVIOUS'}
          />
        ) : modalType === 'LOCATION' ? (
          <CreateOrEditLocationModal
            setShowModal={closeModal}
            onCreate={(l) =>
              setShiftRequest({
                locationId: l.locationId,
                schedules: [
                  {
                    ...shiftRequest.schedules[0],
                    timeZone: l.timezone,
                  },
                ],
              })
            }
          />
        ) : modalType === 'PARKING_LOCATION' ? (
          <CreateOrEditLocationModal
            setShowModal={closeModal}
            onCreate={(l) =>
              setShiftRequest({ parkingLocationId: l.locationId })
            }
          />
        ) : modalType === 'CONTACT' ? (
          <CreateMemberModal
            setShowModal={closeModal}
            onCreate={(c) => setShiftRequest({ supervisorId: c.uid! })}
          />
        ) : modalType === 'INVOICE_GROUP' ? (
          <CreateOrEditInvoiceGroupModal
            onClose={closeModal}
            onCreateOrEdit={(newGroup) =>
              setShiftRequest({
                parentInvoiceGroupId: newGroup.id,
              })
            }
          />
        ) : null}
      </Modal>
    </>
  )
}
