import { Worker } from '@traba/types'
import { useQuery } from 'react-query'
import { trabaApi } from 'src/api/helpers'

async function getWorkerById(workerId: string): Promise<Worker | undefined> {
  try {
    const res = await trabaApi.get(`workers/${workerId}`)
    return res.data
  } catch (error) {
    console.error('useWorker -> getWorkerById() ERROR', error)
  }
}

async function getWorkersByIds(
  workerIds: string[],
): Promise<Worker[] | undefined> {
  try {
    const res = await trabaApi.get(`workers`, {
      params: { workerIds: workerIds },
    })
    return res.data
  } catch (error) {
    console.error('useWorker -> getWorkersByIds() ERROR', error)
  }
}

export function useWorkerById(workerId: string) {
  // Get Shift By Id
  const { isLoading, isError, data, error, isFetched } = useQuery<
    Worker | undefined,
    Error
  >(['workerById', workerId], () => getWorkerById(workerId))

  return {
    isLoading,
    isError,
    data,
    error,
    isFetched,
  }
}

export function useWorkersByIds(workerIds: string[]) {
  // Get Workers By Ids
  const { isLoading, isError, data, error, isFetched } = useQuery<
    Worker[] | undefined,
    Error
  >(['workersByIds', workerIds], () => getWorkersByIds(workerIds))

  const getWorkerById = (uid: string) => {
    const worker = data?.find((w) => w.uid === uid)
    if (worker) {
      return worker
    }
    return {
      uid,
      firstName: 'Unknown',
      lastName: 'Worker',
    } as unknown as Worker
  }

  return {
    isLoading,
    isError,
    data,
    error,
    isFetched,
    getWorkerById,
  }
}
