import { InputStatus } from '@traba/types'
import { theme } from 'src/libs/theme'
import styled, { createGlobalStyle } from 'styled-components'
import { RequiredProps } from '../Required'

export const SearchSelectContainer = styled.div`
  width: ${(props) => props.style?.width};
  margin: ${(props) => props.style?.margin};

  #search-select {
    font-family: 'Poppins';
    color: #08105e;
    font-size: 14;
  }
  #search-select-label {
    font-family: 'Poppins';
  }
  .MuiOutlinedInput-root {
    height: 40px;
    border-radius: 8px;
    background-color: white;
  }
`

export const SearchBoxStyling = createGlobalStyle`
  #search-text-field {
    font-family: 'Poppins';
  }
  .MuiOutlinedInput-root.Mui-focused fieldset {
    border: 1px solid #8000ff !important;
  }
  .MuiOutlinedInput-root fieldset {
    border-radius: 8px !important;
  }
`
export const Label = styled.label<RequiredProps>`
  top: -10px;
  left: 12px;
  position: absolute;
  background-color: white;
  border-radius: 4px;
  padding: 0px 5px 0px 5px;
  font-size: 1.1em;
  transition: 0.2s;
  pointer-events: none;
  font-size: 14px;
  font-weight: 500;
  color: ${(props) => {
    switch (props.inputStatus) {
      case InputStatus.error:
        return props.theme?.error.border
      default:
        return theme.colors.Grey50
    }
  }};
`
