import { Tooltip } from '@mui/material'
import { MODAL_SIZE } from '@traba/react-components'
import { recurringSchedulesEnabled } from '@traba/utils'
import { startOfDay } from 'date-fns'
import { useState } from 'react'
import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { MainLayout } from 'src/components'
import { Button, ButtonVariant, Col, Row, Text } from 'src/components/base'
import { Modal } from 'src/components/base/Modal/Modal'
import { RegionalFilterButton } from 'src/components/base/RegionalFilterSelect/RegionalFilterButton'
import { TimesheetsAnnouncementContent } from 'src/components/Modals/FeatureAnnouncementModal/TimesheetsAnnouncementContent'
import { FeatureAnnouncementType } from 'src/components/Modals/FeatureAnnouncementModal/utils'
import { RoleCreatedModalContent } from 'src/components/Modals/RoleCreatedModalContent'
import { SmsConsentModalContent } from 'src/components/Modals/SmsConsentModal/SmsConsentModalContent'
import { ShiftCalendar } from 'src/components/ShiftCalendar'
import {
  RegionalFilterStatus,
  useAppContext,
} from 'src/context/appContext/AppContext'
import { useUserContext } from 'src/context/user/UserContext'
import { useCompany } from 'src/hooks/useCompany'
import useMobile from 'src/hooks/useMobile'
import { useHotSettings } from 'src/hooks/useSystem'
import { HAS_SHOWN_SMS_CONSENT_MODAL } from 'src/libs/constants'
import { theme } from 'src/libs/theme'
import { UserRolePermission } from 'src/types'
import { hasPermissions } from 'src/utils/userUtils'

export const ACTIVE_FEATURE = FeatureAnnouncementType.Timesheets // FeatureAnnouncementType.YOUR_FEATURE

export default function DashboardScreen() {
  const { dispatch: appContextDispatch } = useAppContext()
  const { hotSettings } = useHotSettings()
  const { isMobileViewOrReactNative, isReactNativeApp } = useMobile()
  const { state } = useUserContext()
  const navigate = useNavigate()
  const location = useLocation()
  const { company } = useCompany()
  const { modalType } = location.state || {}
  const [showRoleModal, setShowRoleModal] = useState(false)
  const [showSmsConsentModal, setShowSmsConsentModal] = useState(false)
  // Note: Comment out for adding new feature announcement
  const [showFeatureAnnouncementModal, setShowFeatureAnnouncementModal] =
    useState(false)

  // Show sms consent modal after feature announcement
  // Only show once for user who hasn't opted in, unless they're chosen to receive all messages by admin
  const shouldShowSmsConsentModal =
    !state.userProfile?.smsConsent?.agreedToSmsConsent &&
    (!localStorage.getItem(HAS_SHOWN_SMS_CONSENT_MODAL) ||
      state.userProfile?.communicationPermissions?.receiveAllShiftCodesSms)

  useEffect(() => {
    switch (modalType) {
      case 'create-role-success':
        setShowRoleModal(true)
        window.history.replaceState({}, '') // clears modalType so it doesn't show on refresh
        return
    }
    if (!ACTIVE_FEATURE || isMobileViewOrReactNative) {
      return
    }
    const featureKey = `FEATURE_ANNOUNCEMENT_${state.userProfile?.uid}_${ACTIVE_FEATURE}`
    const hasSeenFeatureAnnouncement = !!localStorage.getItem(featureKey)
    if (hasSeenFeatureAnnouncement) {
      if (shouldShowSmsConsentModal) {
        setShowSmsConsentModal(true)
        localStorage.setItem(
          HAS_SHOWN_SMS_CONSENT_MODAL,
          new Date().toISOString(),
        )
        window.analytics.track(`User Viewed SMS Consent Modal`)
      }
    } else {
      setShowFeatureAnnouncementModal(true)
      localStorage.setItem(featureKey, new Date().toISOString())
      window.analytics.track(
        `User Viewed Worker Search Feature Announcement Modal`,
      )
    }
  }, [])

  useEffect(() => {
    appContextDispatch({
      type: 'SET_REGIONAL_FILTER_STATUS',
      value: RegionalFilterStatus.ENABLED,
    })
    return () => {
      appContextDispatch({
        type: 'SET_REGIONAL_FILTER_STATUS',
        value: RegionalFilterStatus.HIDE,
      })
    }
  }, [appContextDispatch])

  const shouldFreezeShiftPosting = hotSettings?.freezeShiftsPosting

  const userCanCreate = hasPermissions(state.userProfile, [
    UserRolePermission.ManageShifts,
  ])
  const createButtons = isReactNativeApp ? null : (
    <Tooltip
      title={
        shouldFreezeShiftPosting ? `Shift posting temporarily disabled` : ''
      }
    >
      <Button
        variant={ButtonVariant.FILLED}
        style={{ flexShrink: 0 }}
        aria-label="Book a shift"
        trackingEvent={{
          message: 'User Clicked Book Shifts',
          properties: {
            source: 'Dashboard',
          },
        }}
        onClick={() =>
          navigate(
            recurringSchedulesEnabled({ hotSettings, company })
              ? '/new'
              : '/book-shifts',
          )
        }
        disabled={shouldFreezeShiftPosting}
      >
        Book a shift
      </Button>
    </Tooltip>
  )
  // get all shifts starting at the last midnight in local time zone to make sure whole day shifts are included
  const startOfTodayInLocalTime = startOfDay(new Date())

  return (
    <MainLayout title="Dashboard">
      <div
        style={{ marginTop: isMobileViewOrReactNative ? 0 : theme.space.xs }}
      >
        <Row
          justifyBetween
          alignCenter
          mb={theme.space.med}
          style={{
            columnGap: theme.space.xxs,
            marginBottom:
              hotSettings?.regionalFiltersEnabled && isMobileViewOrReactNative
                ? theme.space.xs
                : undefined,
          }}
        >
          <Text variant="h2">{`Welcome ${state.userProfile?.firstName}!`}</Text>

          {userCanCreate ? <Col>{createButtons}</Col> : null}
        </Row>

        {hotSettings?.regionalFiltersEnabled && isReactNativeApp && (
          <Row
            style={{
              marginLeft: theme.space.zero,
              marginBottom: theme.space.xxs,
            }}
          >
            <RegionalFilterButton />
          </Row>
        )}

        <ShiftCalendar after={startOfTodayInLocalTime} />
      </div>
      <Modal
        size={MODAL_SIZE.LARGE}
        isOpen={showRoleModal}
        handleClose={() => setShowRoleModal(false)}
      >
        <RoleCreatedModalContent setShowRoleModal={setShowRoleModal} />
      </Modal>
      <Modal
        size={MODAL_SIZE.LARGE}
        isOpen={showSmsConsentModal}
        handleClose={() => setShowSmsConsentModal(false)}
      >
        <SmsConsentModalContent
          setShowSmsConsentModal={setShowSmsConsentModal}
        />
      </Modal>
      <Modal
        size={MODAL_SIZE.EXTRA_EXTRA_LARGE}
        isOpen={showFeatureAnnouncementModal}
        handleClose={() => setShowFeatureAnnouncementModal(false)}
        style={{ overflow: 'hidden' }}
        disableCloseOnBackdropClick={true}
      >
        <TimesheetsAnnouncementContent
          activeFeature={ACTIVE_FEATURE}
          handleClose={() => setShowFeatureAnnouncementModal(false)}
        />
      </Modal>
    </MainLayout>
  )
}
