import { memo } from 'react'

import { Text } from 'src/components/base'
import { theme } from 'src/libs/theme'
import { getShiftStatusBadgeConfig } from '../ShiftTile/ShiftTileTimebarUtils'

interface ShiftStatusBadgeProps {
  shiftStartTime: Date
  shiftEndTime: Date
  shiftIsCanceled: boolean
  style?: React.CSSProperties
}

export const ShiftStatusBadge: React.FC<ShiftStatusBadgeProps> = memo(
  ({ shiftStartTime, shiftEndTime, shiftIsCanceled, style }) => {
    const config = getShiftStatusBadgeConfig({
      shiftStartTime,
      shiftEndTime,
      shiftIsCanceled,
    })
    if (!config) {
      return null
    }
    const { text, backgroundColor, textColor } = config

    return (
      <div
        style={{
          backgroundColor,
          position: 'absolute',
          paddingLeft: theme.space.xxs,
          paddingRight: theme.space.xxs,
          alignItems: 'center',
          display: 'flex',
          borderBottomRightRadius: 10,
          userSelect: 'none',
          ...style,
        }}
      >
        <Text
          variant="body1"
          style={{
            color: textColor,
            fontSize: 10,
            fontWeight: 'bold',
          }}
        >
          {text}
        </Text>
      </div>
    )
  },
)
