import { useAlert } from '@traba/context'
import { AxiosError } from 'axios'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { trabaApi } from 'src/api/helpers'
import { useUserContext } from 'src/context/user/UserContext'
import { UserProfile, UserRolePermission } from 'src/types'
import { hasPermissions } from 'src/utils/userUtils'

const getUser = async () => {
  try {
    const response = await trabaApi.get(`/me`)
    return response.data
  } catch (error) {
    console.log(error)
  }
}

export const useUser = () => {
  const queryClient = useQueryClient()
  const { handleError } = useAlert()

  const {
    isLoading,
    isError,
    data: user,
    error,
    isFetched,
    refetch,
  } = useQuery<UserProfile, Error>('user', getUser)

  const patchUserMutation = useMutation<
    UserProfile,
    AxiosError,
    Partial<UserProfile>,
    UserProfile
  >((updatedUser) => trabaApi.patch(`/me`, updatedUser), {
    onSuccess: (data: any) => {
      queryClient.setQueryData('user', data.data)
    },
    onError: (error) => {
      handleError(
        error,
        'useUser -> patchUserMutation()',
        'There was an error updating your profile. Please try again or contact support if the issue persists.',
        'Error updating user profile',
      )
    },
  })

  return {
    isLoading,
    isError,
    error,
    isFetched,
    user,
    patchUser: patchUserMutation.mutate,
    refetch,
  }
}

export function useUserPermissions(requiredPermissions: UserRolePermission[]) {
  const userContext = useUserContext()
  return hasPermissions(userContext.state.userProfile, requiredPermissions)
}
