import {
  REQUIRED_FIELD_MESSAGE,
  INPUT_INVALID_EMAIL,
  INPUT_INVALID_PHONE,
  INPUT_INVALID_ZIPCODE,
  INPUT_PASSWORD_MISMATCH,
} from 'src/libs/constants'
import { ref, string } from 'yup'

export const rePhoneNumber =
  /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/
const reZipCode = /^\d{5}(-\d{4})?$/
export const PASSWORD_RULES = {
  min: 8,
  max: 50,
}

const minLengthValidation = (min: number) =>
  `Must have at least ${min} characters.`

const maxLengthValidation = (max: number) =>
  `Must have no more than ${max} characters.`

export const required = (str: string) =>
  string().required(REQUIRED_FIELD_MESSAGE).validateSync(str)

export const name = () =>
  string()
    .required(REQUIRED_FIELD_MESSAGE)
    .min(1, minLengthValidation(1))
    .max(30, maxLengthValidation(30))

export const email = (required = true) =>
  string()
    .when([], {
      is: () => required,
      then: string().required(REQUIRED_FIELD_MESSAGE),
      otherwise: string(),
    })
    .email(INPUT_INVALID_EMAIL)

export const phoneNumber = (required = true) =>
  string()
    .when([], {
      is: () => required,
      then: string().required(REQUIRED_FIELD_MESSAGE),
      otherwise: string(),
    })
    .matches(rePhoneNumber, INPUT_INVALID_PHONE)

export const zipCode = () =>
  string()
    .required(REQUIRED_FIELD_MESSAGE)
    .matches(reZipCode, INPUT_INVALID_ZIPCODE)

export const password = () =>
  string()
    .required(REQUIRED_FIELD_MESSAGE)
    .min(PASSWORD_RULES.min, minLengthValidation(PASSWORD_RULES.min))
    .max(PASSWORD_RULES.max, maxLengthValidation(PASSWORD_RULES.max))

export const passwordConfirm = () =>
  string()
    .required(REQUIRED_FIELD_MESSAGE)
    .oneOf([ref('Password')], INPUT_PASSWORD_MISMATCH)
