import { makePlural } from '@traba/string-utils'
import { Worker } from '@traba/types'
import { useState } from 'react'
import { WorkerDetails } from 'src/hooks/useCompanyWorkers'
import { theme } from 'src/libs/theme'
import { formatDateString } from 'src/shared/utils/dateUtils'
import { sortWorkersByFirstNameThenLastName } from 'src/shared/utils/sortUtils'
import { getInitials } from 'src/utils/stringUtils'

import { ButtonVariant, Input, Row, Text } from '../base'
import { SelectWorkerCard, SelectWorkerCardAction } from '../SelectWorkerCard'
import * as S from './SelectWorkers.styles'

interface WorkerRowProps {
  workerDetails: WorkerDetails | Worker
  onClickWorker: (workerId: string) => void
  isSelected: boolean
  isDisabled: boolean
  action: SelectWorkerCardAction
}

const WorkerRow = ({
  workerDetails,
  onClickWorker,
  isSelected,
  isDisabled,
  action,
}: WorkerRowProps) => {
  const hasShiftHistory = 'shiftHistory' in workerDetails
  const { uid, photoUrl, firstName, lastName } = hasShiftHistory
    ? workerDetails.worker
    : workerDetails
  const initials = getInitials(firstName, lastName)
  const shiftHistoryText = hasShiftHistory
    ? `${workerDetails.shiftHistory.totalShiftCount} shift${makePlural(
        workerDetails.shiftHistory.totalShiftCount,
      )} with you${
        workerDetails.shiftHistory.mostRecentShift &&
        workerDetails.shiftHistory.mostRecentShiftId &&
        workerDetails.shiftHistory.mostRecentShiftStart
          ? ` • Last shift: ${
              workerDetails.shiftHistory.mostRecentShift.shiftInfo.shiftRole
            } on ${formatDateString(
              workerDetails.shiftHistory.mostRecentShiftStart,
              workerDetails.shiftHistory.mostRecentShift.shiftInfo.timezone,
            )}`
          : ''
      }`
    : undefined

  return (
    <SelectWorkerCard
      onClick={() => onClickWorker(uid)}
      title={`${firstName} ${lastName}`}
      description={shiftHistoryText}
      selected={isSelected}
      photoUrl={photoUrl}
      icon={<Text variant="h5">{initials}</Text>}
      disabled={isDisabled}
      action={action}
    />
  )
}

export function SelectWorkers({
  selectedWorkers,
  setSelectedWorkers,
  onClickWorker,
  workers,
  disabledWorkerIdsSet,
  workersOnList,
  selectedWorkersRemove,
}: {
  selectedWorkers: Set<string>
  setSelectedWorkers: (value: React.SetStateAction<Set<string>>) => void
  onClickWorker: (workerId: string) => void
  workers: (WorkerDetails | Worker)[] | undefined
  disabledWorkerIdsSet?: Set<string>
  workersOnList?: Set<string>
  selectedWorkersRemove?: Set<string>
}) {
  const [searchFilter, setSearchFilter] = useState('')

  /**
   * 'shiftHistory' check is necessary because this modal is used on screens where we have
   * a) worker as WorkerDetails (on WorkersScreen)
   * b) worker as Worker (from the worker shift response on TimeSheetDetailsScreen, UpcomingShiftDetails)
   */
  const getWorker = (worker: WorkerDetails | Worker) => {
    return 'worker' in worker ? worker.worker : worker
  }

  const filteredWorkers = searchFilter.length
    ? workers?.filter((w) =>
        new RegExp(searchFilter, 'i').test(
          `${getWorker(w).firstName} ${getWorker(w).lastName}`,
        ),
      )
    : workers

  const onClickSelectUnselectAll = () => {
    const workersToSet = new Set<string>()
    if (
      selectedWorkers.size !==
      (workers?.length || 0) - (workersOnList?.size || 0)
    ) {
      workers?.forEach((w) => {
        if (
          !disabledWorkerIdsSet?.has(getWorker(w).uid) &&
          !workersOnList?.has(getWorker(w).uid)
        ) {
          workersToSet.add(getWorker(w).uid)
        }
      })
    }
    setSelectedWorkers(workersToSet)
    window.analytics?.track(
      `User Clicked ${
        selectedWorkers.size === workers?.length ? 'Unselect' : 'Select'
      } All`,
    )
  }

  filteredWorkers?.sort((a, b) => {
    return sortWorkersByFirstNameThenLastName(getWorker(a), getWorker(b))
  })

  return (
    <>
      <Row justifyBetween alignCenter style={{ marginBottom: theme.space.xs }}>
        <Text variant="h5">{`Add Workers (${selectedWorkers.size})${
          workersOnList
            ? ` - Remove Workers (${selectedWorkersRemove?.size || 0})`
            : ''
        }`}</Text>
        <S.ButtonText
          variant={ButtonVariant.LINK}
          style={{ padding: 0, cursor: 'pointer' }}
          onClick={onClickSelectUnselectAll}
        >
          {`${
            selectedWorkers.size !==
            (workers?.length || 0) - (workersOnList?.size || 0)
              ? 'Select'
              : 'Unselect'
          } All`}
        </S.ButtonText>
      </Row>
      <Input
        placeholder="Search..."
        leftIconName="search"
        name="rosterSearch"
        containerStyle={{
          marginTop: 0,
          marginBottom: theme.space.xxs,
        }}
        type="text"
        defaultValue=""
        width="100%"
        value={searchFilter}
        onChange={(e) => {
          e.preventDefault()
          setSearchFilter(e.target.value)
        }}
        onClear={() => setSearchFilter('')}
      />
      {filteredWorkers &&
        filteredWorkers.map((worker) => (
          <WorkerRow
            key={getWorker(worker).uid}
            workerDetails={worker}
            onClickWorker={onClickWorker}
            isDisabled={!!disabledWorkerIdsSet?.has(getWorker(worker).uid)}
            isSelected={
              !!(
                disabledWorkerIdsSet?.has(getWorker(worker).uid) ||
                selectedWorkers.has(getWorker(worker).uid) ||
                selectedWorkersRemove?.has(getWorker(worker).uid)
              )
            }
            action={
              workersOnList?.has(getWorker(worker).uid)
                ? SelectWorkerCardAction.Remove
                : SelectWorkerCardAction.Add
            }
          />
        ))}
    </>
  )
}
