import { MODAL_SIZE } from '@traba/react-components'
import { DotMenu } from '@traba/react-components'
import { CompanyInvitation, CompanyInvitationStatus } from '@traba/types'
import React, { HTMLProps, useState } from 'react'
import {
  Button,
  ButtonVariant,
  Modal,
  Table,
  Td,
  Text,
  Tr,
} from 'src/components'
import { useUserContext } from 'src/context/user/UserContext'
import { useInvitations, useMembers } from 'src/hooks/useMembers'
import useMobile from 'src/hooks/useMobile'
import { useHotSettings } from 'src/hooks/useSystem'
import { theme } from 'src/libs/theme'
import { sortByDate } from 'src/shared/utils/dateUtils'
import {
  USER_ROLE_NAMES,
  UserData,
  UserRole,
  UserRolePermission,
  UserWithRole,
} from 'src/types'
import { formatPhoneNumber } from 'src/utils/phoneNumberUtils'
import { sortByName } from 'src/utils/stringUtils'
import { hasPermissions } from 'src/utils/userUtils'

import SvgIcon from '../base/SvgIcon'
import { MobileDisclaimer } from '../Disclaimer/MobileDisclaimer'
import { ArchiveUserModal } from '../Modals/ArchiveUserModal'
import { ChangeMemberRoleModal } from '../Modals/ChangeMemberRoleModal'
import { CreateInvitationModal } from '../Modals/CreateInvitationModal'
import { CreateMemberModal } from '../Modals/CreateMemberModal'
import { DeactivateUserModal } from '../Modals/DeactivateUserModal'
import { MemberRoleInfoModal } from '../Modals/MemberRoleInfoModal'
import * as S from './MembersProfile.styles'
import { MembersProfilePermission } from './MembersProfilePermission'

function hasRole(u: UserData): u is UserWithRole {
  return !!u.role
}

function isAfterTwoWeeksAgo(d: Date) {
  const twoWeeksAgo = +new Date() - 1000 * 60 * 60 * 24 * 14
  return +d > twoWeeksAgo
}

function isStatusAcceptable(status: CompanyInvitation['status']) {
  return [
    CompanyInvitationStatus.PENDING,
    CompanyInvitationStatus.SENT,
  ].includes(status)
}

function isInvitationExpired(i: CompanyInvitation): boolean {
  return +i.expiresAt < Date.now()
}

function shouldDisplayInvitation(i: CompanyInvitation): boolean {
  return isStatusAcceptable(i.status) && isAfterTwoWeeksAgo(i.expiresAt)
}

export function matchesOutstandingOrAcceptedInvitation(
  m: UserData,
  i: CompanyInvitation,
): boolean {
  if (i.email !== m.email) {
    return false
  }
  if (i.status === CompanyInvitationStatus.ACCEPTED) {
    return true
  }
  if (!isStatusAcceptable(i.status)) {
    return false
  }
  return !isInvitationExpired(i)
}

// Sort invitations by expiration date. Filter out invitations that have been
// accepted, rescinded, expired for more than two weeks, or have a more recent
// invitation for the same email.
function getDisplayInvitations(
  invitations?: CompanyInvitation[],
): CompanyInvitation[] {
  if (!invitations) {
    return []
  }
  invitations.sort((a, b) => sortByDate(a.expiresAt, b.expiresAt, 'DESC'))
  const displayInvitations = []
  const emails = new Set<string>()
  for (const i of invitations) {
    if (!shouldDisplayInvitation(i)) {
      continue
    }
    if (emails.has(i.email)) {
      continue
    }
    displayInvitations.push(i)
    emails.add(i.email)
  }
  return displayInvitations
}

const MEMBERS_COPY = {
  ACTIVE_MEMBERS_TITLE: 'Active members',
  ACTIVE_MEMBERS_EDITOR_DESCRIPTION:
    'Manage your company’s members below. You may update their roles or remove them entirely.',
  ACTIVE_MEMBERS_VIEWER_DESCRIPTION: 'View your company’s members below.',
  ACTIVE_MEMBERS_ACTION: 'Invite member',
  INVITATIONS_TITLE: 'Invitations',
  INVITATIONS_EDITOR_DESCRIPTION:
    'Manage your company’s invitations below. You may resend or revoke outstanding invitations. Invitations expire a week after they‘re sent.',
  INVITATIONS_VIEWER_DESCRIPTION: 'View your company’s invitations below.',
  CONTACTS_TITLE: 'Additional contacts',
  CONTACTS_EDITOR_DESCRIPTION:
    'Manage your company’s additional contacts below. They do not have Traba accounts but may be listed as the onsite point of contact for shifts.',
  CONTACTS_VIEWER_DESCRIPTION:
    'View your company’s additional contacts below. They do not have Traba accounts but may be listed as the onsite point of contact for shifts.',
  CONTACTS_ACTION: 'Add contact',
}

interface MembersSubheaderProps {
  title: string
  description: string
  action?: string
  onClick?: () => void
  userCanTakeAction?: boolean
}

const MembersSubheader: React.FC<MembersSubheaderProps> = (props) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: theme.space.xs,
      }}
    >
      <div style={{ flexGrow: 1, marginRight: theme.space.sm }}>
        <Text style={{ marginBottom: theme.space.xxs }} variant="h5">
          {props.title}
        </Text>
        <Text style={{ marginBottom: theme.space.xxs }} variant="body2">
          {props.description}
        </Text>
      </div>
      {props.action && props.userCanTakeAction ? (
        <Button
          style={{ flexShrink: 0 }}
          variant={ButtonVariant.FILLED}
          onClick={props.onClick}
          disabled={!props.onClick}
        >
          {props.action}
        </Button>
      ) : null}
    </div>
  )
}

interface ActiveMembersMenuProps {
  member: UserWithRole
  onChangeRole: (member: UserWithRole) => void
  onDeactivate: (member: UserWithRole) => void
  onArchive: (member: UserWithRole) => void
}

function ActiveMembersMenu({
  member,
  onChangeRole,
  onDeactivate,
  onArchive,
}: ActiveMembersMenuProps) {
  const menuItems = [
    {
      title: 'Change role',
      onClick: () => {
        onChangeRole(member)
        window.analytics.track(`User Clicked Change Member Role`, {
          member,
        })
      },
    },
    {
      title: 'Deactivate account',
      onClick: () => {
        onDeactivate(member)
        window.analytics.track(`User Clicked Deactivate Member`, {
          member,
        })
      },
    },
    {
      title: 'Archive member',
      onClick: () => {
        onArchive(member)
        window.analytics.track(`User Clicked Archive Member`, {
          member,
        })
      },
      color: theme.colors.Red60,
    },
  ]
  return (
    <Td>
      <DotMenu
        type="active-members"
        dotMenuKey={member.uid}
        menuItems={menuItems}
      />
    </Td>
  )
}

export function MemberRoleInfoButton(props: HTMLProps<HTMLDivElement>) {
  const [showMemberRoleInfoModal, setShowMemberRoleInfoModal] = useState(false)
  return (
    <>
      <div {...props}>
        <Button
          variant={ButtonVariant.TEXT}
          leftIcon={<SvgIcon name="info" />}
          iconWidth={20}
          onClick={() => {
            setShowMemberRoleInfoModal(true)
            window.analytics.track(`User Clicked Learn More About Member Roles`)
          }}
          iconPadding={`${theme.space.xxs}px`}
          style={{
            padding: 0,
            color: theme.colors.brand,
            fontSize: 12,
          }}
        >
          Learn more about team member roles
        </Button>
      </div>
      <Modal
        size={MODAL_SIZE.LARGE}
        isOpen={showMemberRoleInfoModal}
        handleClose={() => setShowMemberRoleInfoModal(false)}
      >
        <MemberRoleInfoModal
          handleClose={() => setShowMemberRoleInfoModal(false)}
        />
      </Modal>
    </>
  )
}

interface ActiveMembersTableProps
  extends Pick<
    ActiveMembersMenuProps,
    'onChangeRole' | 'onDeactivate' | 'onArchive'
  > {
  members: UserWithRole[]
  userCanEdit: boolean
}

const ActiveMembersTable: React.FC<ActiveMembersTableProps> = (props) => {
  const userContext = useUserContext()
  const { hotSettings } = useHotSettings()
  const self = userContext.state.userProfile?.uid
  const adminCount = props.members.filter(
    (m) => m.role === UserRole.Admin,
  ).length
  return (
    <>
      <Table
        headers={[
          'Name',
          'Email',
          'Phone',
          'Role',
          ...(hotSettings?.enableNotificationPreferencesV2
            ? []
            : ['Permissions']),
          ...(props.userCanEdit ? [''] : []),
        ]}
        itemType="members"
        showEmptyState
      >
        {props.members.map((m) => (
          <Tr key={m.uid}>
            <Td>
              {m.firstName} {m.lastName}
              {self === m.uid ? ' (You)' : ''}
            </Td>
            <Td>{m.email}</Td>
            <Td>
              {m.phoneNumber ? formatPhoneNumber(m.phoneNumber, true) : ''}
            </Td>
            <Td>{USER_ROLE_NAMES[m.role]}</Td>
            {!hotSettings?.enableNotificationPreferencesV2 && (
              <Td>
                <MembersProfilePermission member={m} />
              </Td>
            )}

            {props.userCanEdit &&
            self !== m.uid &&
            !(adminCount === 1 && m.role === UserRole.Admin) ? (
              <ActiveMembersMenu
                member={m}
                onChangeRole={props.onChangeRole}
                onDeactivate={props.onDeactivate}
                onArchive={props.onArchive}
              />
            ) : null}
          </Tr>
        ))}
      </Table>
      <MemberRoleInfoButton
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginTop: theme.space.xs,
        }}
      />
    </>
  )
}

interface InvitationsTableMenuProps {
  invitation: CompanyInvitation
  onResend: (invitationId: CompanyInvitation['invitationId']) => void
  onRescind: (invitationId: CompanyInvitation['invitationId']) => void
}

function InvitationsTableMenu({
  invitation,
  onResend,
  onRescind,
}: InvitationsTableMenuProps) {
  const expired = +invitation.expiresAt < Date.now()
  const menuItems = [
    {
      title: 'Resend invitation',
      onClick: () => {
        onResend(invitation.invitationId)
        window.analytics.track(`User Clicked Resend Invitation`, {
          invitation,
          expired,
        })
      },
    },
    ...(!expired
      ? [
          {
            title: 'Revoke invitation',
            onClick: () => {
              onRescind(invitation.invitationId)
              window.analytics.track(`User Clicked Revoke Invitation`, {
                invitation,
              })
            },
          },
        ]
      : []),
  ]
  return (
    <Td style={{ display: 'flex', justifyContent: 'flex-end' }}>
      <DotMenu
        type="invitations"
        dotMenuKey={invitation.invitationId}
        menuItems={menuItems}
      />
    </Td>
  )
}

interface InvitationsTableProps
  extends Omit<InvitationsTableMenuProps, 'invitation'> {
  invitations: CompanyInvitation[]
  userCanEdit: boolean
}

const InvitationsTable: React.FC<InvitationsTableProps> = ({
  invitations,
  userCanEdit,
  ...rest
}) => {
  return (
    <Table
      headers={['Email', 'Role', 'Expires on', ...(userCanEdit ? [''] : [])]}
      style={{ marginBottom: theme.space.lg }}
      itemType="invitations"
      showEmptyState
    >
      {invitations.map((i) => {
        const expired = isInvitationExpired(i)
        return (
          <Tr key={i.invitationId}>
            <Td>{i.email}</Td>
            <Td>{USER_ROLE_NAMES[i.role]}</Td>
            <Td style={{ color: expired ? theme.colors.Red60 : undefined }}>
              {expired ? 'Expired ' : ''}
              {i.expiresAt.toLocaleDateString('en-us')}
            </Td>
            {userCanEdit ? (
              <InvitationsTableMenu invitation={i} {...rest} />
            ) : null}
          </Tr>
        )
      })}
    </Table>
  )
}

interface ContactTableMenuProps {
  contact: UserData
  isInvitable: boolean
  onInvite: (contact: UserData) => void
  onArchive: (contact: UserData) => void
}

function ContactTableMenu({
  contact,
  isInvitable,
  onInvite,
  onArchive,
}: ContactTableMenuProps) {
  const menuItems = [
    {
      title: isInvitable ? 'Invite contact' : 'Already invited',
      onClick: () => {
        onInvite(contact)
        window.analytics.track(`User Clicked Invite Contact`, {
          contact,
        })
      },
    },
    {
      title: 'Archive contact',
      onClick: () => {
        onArchive(contact)
        window.analytics.track(`User Clicked Archive Contact`, {
          contact,
        })
      },
      color: theme.colors.Red60,
    },
  ]
  return (
    <Td style={{ display: 'flex', justifyContent: 'flex-end' }}>
      <DotMenu type="contact" dotMenuKey={contact.uid} menuItems={menuItems} />
    </Td>
  )
}

interface ContactTableProps
  extends Pick<ContactTableMenuProps, 'onInvite' | 'onArchive'> {
  members: UserData[]
  isLoading: boolean
  error: Error | null
  userCanEdit: boolean
  isContactInvitable: (c: UserData) => boolean
}

const ContactsTable: React.FC<ContactTableProps> = ({
  members,
  onInvite,
  onArchive,
  isContactInvitable,
  userCanEdit,
  ...rest
}) => {
  return (
    <Table
      headers={['Name', 'Email', 'Phone', ...(userCanEdit ? [''] : [])]}
      style={{ marginBottom: theme.space.lg }}
      itemType="contacts"
      showEmptyState
      {...rest}
    >
      {members.map((m) => (
        <Tr key={m.uid}>
          <Td>
            {m.firstName} {m.lastName}
          </Td>
          <Td>{m.email}</Td>
          <Td>{m.phoneNumber ? formatPhoneNumber(m.phoneNumber, true) : ''}</Td>
          {userCanEdit ? (
            <ContactTableMenu
              contact={m}
              onInvite={onInvite}
              onArchive={onArchive}
              isInvitable={isContactInvitable(m)}
            />
          ) : null}
        </Tr>
      ))}
    </Table>
  )
}

export const MembersProfile: React.FC = () => {
  const { isExclusivelyMobileView } = useMobile()

  const userContext = useUserContext()
  const userCanEditActiveMembersAndInvitations = hasPermissions(
    userContext.state.userProfile,
    [UserRolePermission.ManageUserRoles],
  )
  const userCanAddContacts = hasPermissions(userContext.state.userProfile, [
    UserRolePermission.ManageShifts,
  ])
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { members, changeMemberRole, ...membersRest } = useMembers()
  const {
    invitations,
    resendInvitation,
    rescindInvitation,
    ...invitationsRest
  } = useInvitations()
  const [memberToInvite, setMemberToInvite] = useState<UserData | null>(null)
  const [memberToUpdate, setMemberToUpdate] = useState<UserWithRole | null>(
    null,
  )
  const [memberToDeactivate, setMemberToDeactivate] =
    useState<UserWithRole | null>(null)
  const [memberToArchive, setMemberToArchive] = useState<
    UserWithRole | UserData | null
  >(null)
  const [showMemberModal, setShowMemberModal] = useState(false)
  const [showInvitationModal, setShowInvitationModal] = useState(false)
  const [showContactModal, setShowContactModal] = useState(false)
  const [showDeactivateModal, setShowDeactivateModal] = useState(false)
  const [showArchiveModal, setShowArchiveModal] = useState(false)
  const membersWithRoles = members.filter(hasRole).sort(sortByName)
  const membersWithoutRoles = members
    .filter((m) => !hasRole(m))
    .sort(sortByName)
  const recentInvitations = getDisplayInvitations(invitations)

  if (isExclusivelyMobileView) {
    return <MobileDisclaimer />
  }

  function onChangeRole(m: UserWithRole) {
    setMemberToUpdate(m)
    setShowMemberModal(true)
  }

  function onArchive(m: UserWithRole | UserData) {
    setMemberToArchive(m)
    setShowArchiveModal(true)
  }

  function onDeactivate(m: UserWithRole) {
    setMemberToDeactivate(m)
    setShowDeactivateModal(true)
  }

  function onInviteMember(m: UserData) {
    setMemberToInvite(m)
    setShowInvitationModal(true)
  }

  function onResend(i: CompanyInvitation['invitationId']) {
    resendInvitation(i)
  }

  function onRescind(i: CompanyInvitation['invitationId']) {
    rescindInvitation(i)
  }

  function handleContactModalOpen() {
    setShowContactModal(true)
    window.analytics.track(`User Clicked Add Contact`)
  }

  function handleInvitationModalOpen() {
    setShowInvitationModal(true)
    window.analytics.track(`User Clicked Invite Member`)
  }

  function handleMemberModalClose() {
    setShowMemberModal(false)
    setMemberToUpdate(null)
  }

  function handleInvitationModalClose() {
    setShowInvitationModal(false)
    setMemberToInvite(null)
  }

  function handleDeactivateModalClose() {
    setShowDeactivateModal(false)
    setMemberToDeactivate(null)
  }

  function handleArchiveModalClose() {
    setShowArchiveModal(false)
    setMemberToArchive(null)
  }

  function isContactInvitable(c: UserData) {
    return !invitations?.find((i) =>
      matchesOutstandingOrAcceptedInvitation(c, i),
    )
  }

  return (
    <S.MembersProfileContainer>
      <MembersSubheader
        title={MEMBERS_COPY.ACTIVE_MEMBERS_TITLE}
        description={
          userCanEditActiveMembersAndInvitations
            ? MEMBERS_COPY.ACTIVE_MEMBERS_EDITOR_DESCRIPTION
            : MEMBERS_COPY.ACTIVE_MEMBERS_VIEWER_DESCRIPTION
        }
        action={MEMBERS_COPY.ACTIVE_MEMBERS_ACTION}
        onClick={handleInvitationModalOpen}
        userCanTakeAction={userCanEditActiveMembersAndInvitations}
      />
      <ActiveMembersTable
        members={membersWithRoles}
        onChangeRole={onChangeRole}
        onDeactivate={onDeactivate}
        onArchive={onArchive}
        userCanEdit={userCanEditActiveMembersAndInvitations}
        {...membersRest}
      />
      <MembersSubheader
        title={MEMBERS_COPY.INVITATIONS_TITLE}
        description={
          userCanEditActiveMembersAndInvitations
            ? MEMBERS_COPY.INVITATIONS_EDITOR_DESCRIPTION
            : MEMBERS_COPY.INVITATIONS_VIEWER_DESCRIPTION
        }
      />
      <InvitationsTable
        invitations={recentInvitations}
        userCanEdit={userCanEditActiveMembersAndInvitations}
        {...invitationsRest}
        onResend={onResend}
        onRescind={onRescind}
      />
      <MembersSubheader
        title={MEMBERS_COPY.CONTACTS_TITLE}
        description={
          userCanAddContacts
            ? MEMBERS_COPY.CONTACTS_EDITOR_DESCRIPTION
            : MEMBERS_COPY.CONTACTS_VIEWER_DESCRIPTION
        }
        action={MEMBERS_COPY.CONTACTS_ACTION}
        onClick={handleContactModalOpen}
        userCanTakeAction={userCanAddContacts}
      />
      <ContactsTable
        userCanEdit={userCanEditActiveMembersAndInvitations}
        onInvite={onInviteMember}
        onArchive={onArchive}
        members={membersWithoutRoles}
        isContactInvitable={isContactInvitable}
        {...membersRest}
      />
      <Modal
        size={MODAL_SIZE.LARGE}
        isOpen={showInvitationModal}
        handleClose={handleInvitationModalClose}
      >
        <CreateInvitationModal
          handleClose={handleInvitationModalClose}
          member={memberToInvite}
        />
      </Modal>
      <Modal
        size={MODAL_SIZE.LARGE}
        isOpen={showMemberModal}
        handleClose={handleMemberModalClose}
      >
        {memberToUpdate ? (
          <ChangeMemberRoleModal
            handleClose={handleMemberModalClose}
            member={memberToUpdate}
          />
        ) : null}
      </Modal>
      <Modal
        size={MODAL_SIZE.LARGE}
        isOpen={showContactModal}
        handleClose={() => setShowContactModal(false)}
      >
        <CreateMemberModal setShowModal={setShowContactModal} />
      </Modal>
      <Modal
        size={MODAL_SIZE.MEDIUM}
        isOpen={showArchiveModal}
        handleClose={handleArchiveModalClose}
      >
        {memberToArchive && (
          <ArchiveUserModal
            member={memberToArchive}
            replacementMembers={members}
            handleClose={handleArchiveModalClose}
          />
        )}
      </Modal>
      <Modal
        size={MODAL_SIZE.MEDIUM}
        isOpen={showDeactivateModal}
        handleClose={handleDeactivateModalClose}
      >
        {memberToDeactivate && (
          <DeactivateUserModal
            member={memberToDeactivate}
            handleClose={handleDeactivateModalClose}
          />
        )}
      </Modal>
    </S.MembersProfileContainer>
  )
}
