import {
  ButtonVariant,
  Row,
  SvgIcon,
  Text,
  Breadcrumbs,
} from '@traba/react-components'
import { theme } from '@traba/theme'
import {
  DailyViewOfScheduleDetails,
  ShiftRequestParentWithShiftRequest,
} from '@traba/types'
import {
  formatRepeatsOnForSchedules,
  formatScheduleDateRange,
} from '@traba/utils'
import { format } from 'date-fns'
import { useNavigate } from 'react-router-dom'
import { Col, Button as TrabaButton } from 'src/components/base'
import useMobile from 'src/hooks/useMobile'
import {
  getDailyViewUrlSlug,
  getDateInfoFromDayOfShift,
} from 'src/shared/utils/dateUtils'

interface Props {
  shiftRequestParent: ShiftRequestParentWithShiftRequest | undefined
  dayOfShift: string
  shiftRequestParentDayDetails: DailyViewOfScheduleDetails | undefined
}

export const ScheduleDailyViewHeader = (props: Props) => {
  const { shiftRequestParent, dayOfShift, shiftRequestParentDayDetails } = props
  const { isMobileViewOrReactNative } = useMobile()
  const navigate = useNavigate()
  const { dayOfWeek, month, dayOfMonth, year } =
    getDateInfoFromDayOfShift(dayOfShift)
  const firstRecurringShiftRequest = shiftRequestParent?.shiftRequests.find(
    (sr) => sr.schedules.some((s) => s.recurringSchedule),
  )
  if (
    !firstRecurringShiftRequest ||
    !shiftRequestParent ||
    !shiftRequestParentDayDetails
  ) {
    return null
  }

  const leftNavButton = (
    <TrabaButton
      onClick={() => {
        if (
          shiftRequestParentDayDetails.dateOfPreviousOccurringShiftOnSchedule
        ) {
          navigate(
            `/schedule/${shiftRequestParent.shiftRequestParentId}/${getDailyViewUrlSlug(new Date(shiftRequestParentDayDetails.dateOfPreviousOccurringShiftOnSchedule))}`,
          )
        }
      }}
      style={{
        paddingLeft: theme.space.xs,
        paddingRight: theme.space.xs,
        marginRight: theme.space.xxs,
      }}
      variant={ButtonVariant.OUTLINED}
      slim={isMobileViewOrReactNative}
    >
      <SvgIcon name="left" color={theme.colors.Grey60} />
    </TrabaButton>
  )

  const rightNavButton = (
    <TrabaButton
      onClick={() => {
        if (shiftRequestParentDayDetails?.dateOfNextOccurringShiftOnSchedule) {
          navigate(
            `/schedule/${shiftRequestParent?.shiftRequestParentId}/${getDailyViewUrlSlug(new Date(shiftRequestParentDayDetails.dateOfNextOccurringShiftOnSchedule))}`,
          )
        }
      }}
      style={{
        paddingLeft: theme.space.xs,
        paddingRight: theme.space.xs,
        marginRight: theme.space.xxs,
      }}
      variant={ButtonVariant.OUTLINED}
      slim={isMobileViewOrReactNative}
    >
      <SvgIcon name="right" color={theme.colors.Grey60} />
    </TrabaButton>
  )

  const BREADCRUMB_ITEMS = [
    {
      label: shiftRequestParent?.title || 'Schedule',
      isLink: true,
      onClick: () => {
        navigate(`/schedule/${shiftRequestParent?.shiftRequestParentId}`)
      },
    },
    {
      label: format(new Date(dayOfShift), 'MMMM do, yyyy'),
      isLink: false,
    },
  ]

  return (
    <>
      <Row
        alignCenter
        pb={theme.space.sm}
        style={{ borderBottom: `1px solid ${theme.colors.Grey}` }}
      >
        <Breadcrumbs items={BREADCRUMB_ITEMS} />
      </Row>
      <Row
        justifyBetween
        alignCenter
        pt={theme.space.sm}
        pb={theme.space.sm}
        style={{ borderBottom: `1px solid ${theme.colors.Grey}` }}
        wrap
      >
        <Row alignCenter>
          <Text variant="h4">{`${dayOfWeek}, ${month} ${dayOfMonth} ${year}`}</Text>
          <Row ml={theme.space.sm}>
            {leftNavButton}
            {rightNavButton}
          </Row>
        </Row>
        <Col style={{ alignItems: 'flex-end' }}>
          <Text>
            {formatRepeatsOnForSchedules(firstRecurringShiftRequest.schedules)}
          </Text>

          <Text variant="body1">
            {formatScheduleDateRange(firstRecurringShiftRequest.schedules)}
          </Text>
        </Col>
      </Row>
    </>
  )
}
