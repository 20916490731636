import * as Sentry from '@sentry/react'
import { trabaApi } from 'src/api/helpers'
import { CommunicationPermissions } from 'src/types'

interface CommunicationPermissionsResponse {
  uid: string
  companyId: string
  communicationPermissions: CommunicationPermissions
}

export async function patchUserCommunicationPermissions(
  userId: string | undefined,
  request: { communicationPermissions: CommunicationPermissions },
): Promise<CommunicationPermissionsResponse | undefined> {
  try {
    const res = await trabaApi.patch(
      `/my-company/users/${userId}/communication-permissions`,
      request,
    )
    return res.data
  } catch (error) {
    Sentry.captureException(error, {
      tags: {
        action:
          'useUserCommunicationPermissions -> patchUserCommunicationPermissions()',
      },
    })
  }
}
