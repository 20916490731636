import { useEffect } from 'react'
import { Navigate, useRoutes } from 'react-router-dom'
import { useUserContext } from 'src/context/user/UserContext'
import { useLocationRegions } from 'src/hooks/useLocationRegions'
import { useLocations } from 'src/hooks/useLocations'
import { useRegionalFilter } from 'src/hooks/useRegionalFilter'
import { useHotSettings } from 'src/hooks/useSystem'
import AccountSettingsScreen from 'src/screens/AccountSettingsScreen'
import AddToExistingScheduleScreen from 'src/screens/AddToExistingSchedule/AddToExistingScheduleScreen'
import BillingScreen from 'src/screens/BillingScreen'
import { BookShiftsScreen } from 'src/screens/BookShifts/BookShiftsScreen'
import SelectBookingOption from 'src/screens/BookShifts/SelectBookingOption'
import { CalendarScreen } from 'src/screens/CalendarScreen'
import Dashboard from 'src/screens/DashboardScreen'
import { EditShiftsScreen } from 'src/screens/EditShifts/EditShiftsScreen'
import InvoiceDetailsScreen from 'src/screens/InvoiceDetailsScreen'
import MessagingScreen from 'src/screens/Messaging/MessagingScreen'
import ReferScreen from 'src/screens/ReferScreen'
import RosterScreen from 'src/screens/RosterScreen'
import ScheduleDailyViewScreen from 'src/screens/ScheduleDailyView/ScheduleDailyViewScreen'
import ScheduleDetailsScreen from 'src/screens/ScheduleDetails/ScheduleDetailsScreen'
import SchedulesScreen from 'src/screens/Schedules/SchedulesScreen'
import TimeSheetApprovalScreen from 'src/screens/TimeSheets/TimeSheetApprovalScreen'
import TimeSheetTotalsScreen from 'src/screens/TimeSheets/TimeSheetTotalsScreen'
import UpcomingShiftDetailsScreen from 'src/screens/UpcomingShiftDetailsScreen'
import WorkerDetailsScreen from 'src/screens/WorkerDetailsScreen'
import WorkersSearchScreen from 'src/screens/WorkerSearch/WorkersSearchScreen'
import WorkersScreen from 'src/screens/WorkersScreen'
import { UserRolePermission } from 'src/types'
import { hasPermissions } from 'src/utils/userUtils'

function LoggedIn() {
  const userContext = useUserContext()
  const { hotSettings } = useHotSettings()
  const userHasPermissions = (permissions: UserRolePermission[]) =>
    hasPermissions(userContext.state.userProfile, permissions)
  // prefetch for worker search
  useLocationRegions()

  const { isLoading: isLocationLoading, uniqueLocations } = useLocations()
  const { reloadRegionalFilter } = useRegionalFilter()

  useEffect(() => {
    // RegionalFilter context is not set yet
    if (hotSettings?.regionalFiltersEnabled && !isLocationLoading) {
      reloadRegionalFilter(uniqueLocations)
    }
  }, [isLocationLoading, hotSettings?.regionalFiltersEnabled])

  const navigateToDashboard = <Navigate to="/dashboard" />

  const routes = useRoutes([
    {
      path: '/',
      element: userHasPermissions([UserRolePermission.ViewShifts]) ? (
        <Dashboard />
      ) : (
        navigateToDashboard
      ),
    },
    {
      path: '/dashboard',
      element: userHasPermissions([UserRolePermission.ViewShifts]) ? (
        <Dashboard />
      ) : (
        navigateToDashboard
      ),
    },
    { path: '/create-shift-request', element: <Navigate to="/book-shifts" /> },
    {
      path: '/book-shifts',
      element: userHasPermissions([UserRolePermission.ManageShifts]) ? (
        <BookShiftsScreen />
      ) : (
        navigateToDashboard
      ),
    },
    {
      path: '/schedule/:shiftRequestParentId',
      element: userHasPermissions([UserRolePermission.ManageShifts]) ? (
        <ScheduleDetailsScreen />
      ) : (
        navigateToDashboard
      ),
    },
    {
      path: '/schedule/:shiftRequestParentId/:dayOfShift',
      element: userHasPermissions([UserRolePermission.ManageShifts]) ? (
        <ScheduleDailyViewScreen />
      ) : (
        navigateToDashboard
      ),
    },
    {
      path: '/schedule/:shiftRequestParentId/edit',
      element: userHasPermissions([UserRolePermission.ManageShifts]) ? (
        <AddToExistingScheduleScreen />
      ) : (
        navigateToDashboard
      ),
    },
    {
      path: '/new',
      element: userHasPermissions([UserRolePermission.ManageShifts]) ? (
        <SelectBookingOption />
      ) : (
        navigateToDashboard
      ),
    },
    {
      path: '/edit-shifts/:shiftId',
      element: userHasPermissions([UserRolePermission.ManageShifts]) ? (
        <EditShiftsScreen />
      ) : (
        navigateToDashboard
      ),
    },
    {
      path: '/calendar/:shiftId',
      element: userHasPermissions([UserRolePermission.ViewShifts]) ? (
        <UpcomingShiftDetailsScreen />
      ) : (
        navigateToDashboard
      ),
    },
    {
      path: '/calendar',
      element: userHasPermissions([UserRolePermission.ViewShifts]) ? (
        <CalendarScreen />
      ) : (
        navigateToDashboard
      ),
    },
    {
      path: '/messaging',
      element: userHasPermissions([UserRolePermission.ViewWorkers]) ? (
        <MessagingScreen />
      ) : (
        navigateToDashboard
      ),
    },
    {
      path: '/find-new-workers',
      element: userHasPermissions([UserRolePermission.ViewWorkers]) ? (
        <WorkersSearchScreen />
      ) : (
        navigateToDashboard
      ),
    },
    {
      path: '/workers',
      element: userHasPermissions([UserRolePermission.ViewWorkers]) ? (
        <WorkersScreen />
      ) : (
        navigateToDashboard
      ),
    },
    {
      path: '/roster/:rosterId',
      element: userHasPermissions([UserRolePermission.ViewWorkers]) ? (
        <RosterScreen />
      ) : (
        navigateToDashboard
      ),
    },
    {
      path: '/worker/:workerId',
      element: userHasPermissions([UserRolePermission.ViewWorkers]) ? (
        <WorkerDetailsScreen />
      ) : (
        navigateToDashboard
      ),
    },
    {
      path: '/timesheet/approval',
      element: userHasPermissions([UserRolePermission.ViewTimesheets]) ? (
        <TimeSheetApprovalScreen />
      ) : (
        navigateToDashboard
      ),
    },
    {
      path: '/timesheet/totals',
      element: userHasPermissions([UserRolePermission.ViewTimesheets]) ? (
        <TimeSheetTotalsScreen />
      ) : (
        navigateToDashboard
      ),
    },
    {
      path: '/billing/:invoiceId',
      element: userHasPermissions([UserRolePermission.ViewInvoices]) ? (
        <InvoiceDetailsScreen />
      ) : (
        navigateToDashboard
      ),
    },
    {
      path: '/billing',
      element: userHasPermissions([UserRolePermission.ViewInvoices]) ? (
        <BillingScreen />
      ) : (
        navigateToDashboard
      ),
    },
    {
      path: '/refer',
      element: <ReferScreen />,
    },
    {
      path: '/account-settings',
      element: <AccountSettingsScreen />,
    },
    {
      path: '/schedules',
      element: userHasPermissions([UserRolePermission.ViewShifts]) ? (
        <SchedulesScreen />
      ) : (
        navigateToDashboard
      ),
    },
    {
      path: '*',
      element: navigateToDashboard,
    },
  ])
  return routes
}

export default LoggedIn
