import { BrandColors, otherColors } from 'src/libs/theme'
import styled, { css } from 'styled-components'

export const colorVariants = {
  info: {
    color: otherColors.Blue80,
    backgroundColor: otherColors.Blue10,
  },
  brand: {
    color: BrandColors.Violet,
    backgroundColor: otherColors.Violet10,
  },
  disabled: {
    color: otherColors.Grey60,
    backgroundColor: otherColors.Grey10,
  },
  success: {
    color: otherColors.Green80,
    backgroundColor: otherColors.Green10,
  },
  warning: {
    color: otherColors.Yellow70,
    backgroundColor: otherColors.Yellow10,
  },
  additional: {
    color: otherColors.Violet70,
    backgroundColor: otherColors.Violet10,
  },
  business: {
    color: otherColors.Orange70,
    backgroundColor: otherColors.Orange10,
  },
  danger: {
    color: otherColors.Red60,
    backgroundColor: otherColors.Red10,
  },
  opaqueRed: {
    color: '#FFFFFF',
    backgroundColor: otherColors.Red40,
  },
  certification: {
    color: otherColors.Orange70,
    backgroundColor: otherColors.Orange10,
  },
  yellow: {
    color: otherColors.Yellow80,
    backgroundColor: otherColors.Yellow10,
  },
  darkOrange: {
    color: otherColors.Peach70,
    backgroundColor: otherColors.Peach10,
  },
  darkRed: {
    color: otherColors.Red70,
    backgroundColor: otherColors.Red10,
  },
  pink: {
    color: otherColors.Red80,
    backgroundColor: '#FFC0CB',
  },
} as const

export type BadgeVariant = keyof typeof colorVariants

type BadgeContainerProps = {
  round?: boolean
  variant: BadgeVariant
}

export const BadgeContainer = styled.div<BadgeContainerProps>`
  border-radius: 4px;
  padding: 6px 12px;
  background-color: ${({ variant }) => colorVariants[variant].backgroundColor};
  color: ${({ variant }) => colorVariants[variant].color};
  font-size: 10px;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 14px;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  ${({ round }) =>
    round &&
    css`
      border-radius: 100%;
      padding: 0;
      width: 24px;
      height: 24px;
    `};
`
