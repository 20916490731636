import { Box, CircularProgress } from '@mui/material'
import React from 'react'

import * as S from './Button.components'

export enum ButtonVariant {
  FILLED = 'filled',
  OUTLINED = 'outlined',
  TEXT = 'text',
  CANCEL = 'cancel',
  REDOUTLINED = 'redoutlined',
  BRANDLINK = 'brandLink',
  LINK = 'link',
  DASHED = 'dashed',
  GREEN = 'green',
  PRESSED = 'pressed',
  BRANDNEW = 'brandNew',
}

export type ButtonProps = {
  children: React.ReactNode
  leftIcon?: JSX.Element
  rightIcon?: JSX.Element
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  style?: React.CSSProperties
  variant?: ButtonVariant
  slim?: boolean
  reverse?: boolean
  full?: boolean
  iconPadding?: string
  iconWidth?: number
  disabled?: boolean
  loading?: boolean
} & React.ButtonHTMLAttributes<HTMLButtonElement>

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  function Button(props: ButtonProps, ref) {
    const {
      children,
      onClick,
      variant,
      rightIcon,
      leftIcon,
      slim,
      reverse,
      iconPadding,
      iconWidth,
      full,
      disabled,
      loading,
      className,

      ...rest
    } = props

    function handleOnClick(
      evt: React.MouseEvent<HTMLButtonElement, MouseEvent> | undefined,
    ) {
      if (typeof onClick === 'function' && !!evt) {
        onClick(evt)
      }
    }

    return (
      <S.ButtonContainer
        ref={ref}
        onClick={!disabled ? handleOnClick : undefined}
        variant={variant}
        style={props.style}
        slim={slim}
        reverse={reverse}
        full={full}
        disabled={disabled || loading}
        type={props.type}
        className={className}
        {...rest}
      >
        <Box sx={{ position: 'relative' }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            {leftIcon && (
              <S.ButtonIcon
                rightPadding={iconPadding}
                reverse={!!reverse}
                style={{ width: iconWidth }}
              >
                {leftIcon}
              </S.ButtonIcon>
            )}
            <S.ButtonLabelContainer variant={variant}>
              {children}
            </S.ButtonLabelContainer>
            {rightIcon && (
              <S.ButtonIcon
                reverse={!!reverse}
                style={{ width: iconWidth }}
                leftPadding={iconPadding}
              >
                {rightIcon}
              </S.ButtonIcon>
            )}
          </div>
          {loading && (
            <CircularProgress
              size={36}
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: '-18px',
                marginLeft: '-18px',
              }}
            />
          )}
        </Box>
      </S.ButtonContainer>
    )
  },
)
