import { UseMutateAsyncFunction } from '@tanstack/react-query'
import { useAlert } from '@traba/context'
import { useHotSettings } from '@traba/hooks'
import { LoadingSpinner, MODAL_SIZE } from '@traba/react-components'
import {
  AdjustmentSource,
  JobStatus,
  WorkerShift,
  WorkerShiftWithWorkerDetails,
  BreakType,
  ScheduledBreak,
  ShiftTime,
} from '@traba/types'
import {
  calculateBreaksInMinutes,
  calculateClockedInTimeInSeconds,
  getShiftTimeString,
  getTotalBreaksTimeString,
} from '@traba/utils'
import { addMinutes, differenceInMinutes } from 'date-fns'
import { useCallback, useState } from 'react'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import { trabaApi } from 'src/api/helpers'
import { Icon, Modal, Text } from 'src/components/base'
import useMobile from 'src/hooks/useMobile'
import { useWorkerShiftBreaks } from 'src/hooks/workerShiftHooks'
import { theme } from 'src/libs/theme'
import { EditBreaksModal } from 'src/screens/TimeSheetDetailsScreen/components/EditBreaksModal'
import { isBreaksEditable } from 'src/utils/workerShiftUtils'
import { BreakTimeInput } from './BreakTimeInput'

interface WorkerOnShiftTableBreaksFieldProps {
  scheduledBreaks: ScheduledBreak[]
  workerShift: WorkerShiftWithWorkerDetails
  timezone: string
  editMode: boolean | undefined
  onEditWorkerShift?: (
    newWorkerShift: Partial<WorkerShift> & {
      workerId: string
      shiftId: string
    },
  ) => void
  isFromTimesheetDetails?: boolean
  refetchWorkerShifts: () => void
}

export const WorkerOnShiftTableBreaksField = ({
  scheduledBreaks,
  workerShift,
  timezone,
  editMode,
  onEditWorkerShift,
  isFromTimesheetDetails = false,
  refetchWorkerShifts,
}: WorkerOnShiftTableBreaksFieldProps) => {
  const { hotSettings } = useHotSettings()
  const enableWorkerEditTime = hotSettings?.enableWorkerEditTime
  const { isReactNativeApp } = useMobile()
  const [thisEditMode, setThisEditMode] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const totalBreakTime = getTotalBreaksTimeString({
    workerShift,
    displayHours: true,
    enableWorkerEditTime,
  })
  const { showError } = useAlert()
  const { mutateAsync: updateWorkerShiftBreaks } = useWorkerShiftBreaks()

  const handleConfirm = useCallback(
    async (
      newBreakTime: number,
      workerShift: WorkerShiftWithWorkerDetails,
      updateWorkerShiftBreaks: UseMutateAsyncFunction<
        WorkerShiftWithWorkerDetails,
        Error,
        {
          shiftId: string
          workerId: string
          completedBreak: ShiftTime
        },
        unknown
      >,
      refetchWorkerShifts: () => void,
      showError: (title: string, message: string) => void,
      setIsLoading: (isLoading: boolean) => void,
    ) => {
      setIsLoading(true)
      const newBreak = {
        startTime: new Date(
          workerShift.clockInTime ?? workerShift.shiftInfo.startTime,
        ),
        endTime: addMinutes(
          new Date(workerShift.clockInTime ?? workerShift.shiftInfo.startTime),
          newBreakTime,
        ),
      }
      try {
        if (workerShift.jobStatus !== JobStatus.Complete) {
          await updateWorkerShiftBreaks({
            shiftId: workerShift.shiftId,
            workerId: workerShift.workerId,
            completedBreak: newBreak,
          })
        } else {
          await trabaApi.post(
            `/my-company/worker-shifts/${workerShift.shiftId}/adjustments`,
            {
              workerShiftBizAdjustments: [
                {
                  workerId: workerShift.workerId,
                  breaks: [newBreak],
                  adjustmentReason: 'Wrong break times',
                  source: AdjustmentSource.BUSINESS,
                },
              ],
            },
          )
        }
        await refetchWorkerShifts()
      } catch (e) {
        showError('Error editing breaks for the worker.', 'Please try again.')
      }
      setIsLoading(false)
    },
    [],
  )

  if (isLoading) {
    return <LoadingSpinner style={{ height: 25, width: 25 }} />
  }
  return (
    isBreaksEditable(
      workerShift.shiftInfo.breakType,
      workerShift.jobStatus,
    ) && (
      <>
        <a
          data-tooltip-id={`breaksTooltip-${workerShift.workerId}`}
          style={{ display: 'flex', alignItems: 'center' }}
        >
          {editMode && workerShift.shiftInfo.breakType !== BreakType.PAID ? (
            <BreakTimeInput
              currentBreakTime={calculateBreaksInMinutes(
                workerShift,
                enableWorkerEditTime,
              )}
              currentBreakTimeString={getTotalBreaksTimeString({
                workerShift,
                displayHours: false,
                enableWorkerEditTime,
              })}
              onUpdate={(newBreakTime) => {
                onEditWorkerShift &&
                  onEditWorkerShift({
                    workerId: workerShift.workerId,
                    shiftId: workerShift.shiftId,
                    breaks: [
                      {
                        startTime: new Date(
                          workerShift.clockInTime ??
                            workerShift.shiftInfo.startTime,
                        ),
                        endTime: addMinutes(
                          new Date(
                            workerShift.clockInTime ??
                              workerShift.shiftInfo.startTime,
                          ),
                          newBreakTime,
                        ),
                      },
                    ],
                  })
              }}
              maxBreakTime={
                workerShift.jobStatus !== JobStatus.Complete
                  ? differenceInMinutes(
                      new Date(),
                      new Date(
                        workerShift.clockInTime ??
                          workerShift.shiftInfo.startTime,
                      ),
                    )
                  : Math.floor(
                      calculateClockedInTimeInSeconds(workerShift) / 60,
                    )
              }
              isMultiEdit={false}
              isFromTimesheetDetails={isFromTimesheetDetails}
            />
          ) : isReactNativeApp ? (
            <Text
              style={{ marginTop: theme.space.xxxs }}
              variant="body3"
              color={theme.colors.Blue100}
            >
              {totalBreakTime || '00m'}
            </Text>
          ) : (
            <Text>{totalBreakTime || '00m'}</Text>
          )}
          {!isFromTimesheetDetails && !editMode ? (
            <Icon
              name="editPurple"
              style={{ marginLeft: 5 }}
              onClick={() => {
                setThisEditMode(true)
              }}
            />
          ) : null}
          {(isReactNativeApp && isFromTimesheetDetails) ||
          workerShift.jobStatus === JobStatus.Complete ? null : workerShift
              .breaks?.length ? (
            <>
              <Icon name="info" style={{ marginLeft: 5 }} />
              <ReactTooltip
                place="top"
                id={`breaksTooltip-${workerShift.workerId}`}
              >
                {workerShift.breaks.map((b, i) => {
                  if (!b.startTime || !b.endTime) {
                    return null
                  }
                  return (
                    <p style={{ lineHeight: '24px' }}>
                      Break {i + 1} (
                      {differenceInMinutes(b.endTime, b.startTime)} min){' '}
                      {getShiftTimeString(b.startTime, b.endTime, timezone)}
                    </p>
                  )
                })}
              </ReactTooltip>
            </>
          ) : null}
        </a>
        <Modal
          handleClose={() => setThisEditMode(false)}
          isOpen={thisEditMode}
          size={MODAL_SIZE.SMALL}
          style={{ overflowY: 'unset' }}
        >
          <EditBreaksModal
            handleClose={() => setThisEditMode(false)}
            onConfirm={(newBreakTime: number) =>
              handleConfirm(
                newBreakTime,
                workerShift,
                updateWorkerShiftBreaks,
                refetchWorkerShifts,
                showError,
                setIsLoading,
              )
            }
            scheduledBreaks={scheduledBreaks}
            workerShifts={[workerShift]}
            forIndividualWorkerShift={workerShift}
          />
        </Modal>
      </>
    )
  )
}
