import { Button, Text } from '@traba/react-components'
import { CardTile } from '@traba/react-components'
import { theme } from '@traba/theme'
import { useNavigate } from 'react-router-dom'
import { ButtonVariant, Icon, Row } from 'src/components'
import { SvgIcon } from 'src/components/base'

interface ScheduleTileHeaderProps {
  scheduleTitle: string
  shiftRequestParentId: string
}

export const ScheduleTileHeader: React.FC<ScheduleTileHeaderProps> = ({
  scheduleTitle,
  shiftRequestParentId,
}) => {
  const navigate = useNavigate()

  return (
    <Row
      justifyBetween
      alignCenter
      style={{
        padding: theme.space.sm,
        borderBottom: `1px solid ${theme.colors.Grey20}`,
      }}
    >
      <Row alignCenter>
        <CardTile size="56px" style={{ marginRight: theme.space.sm }}>
          <Icon name="calendar_active" />
        </CardTile>
        <Text variant="h5">{scheduleTitle || 'Untitled'}</Text>
      </Row>

      <Row
        style={{ width: '100%', flexWrap: 'wrap', justifyContent: 'flex-end' }}
      >
        <Row>
          <Button
            variant={ButtonVariant.TEXT}
            onClick={() => {
              navigate(`/schedule/${shiftRequestParentId}`)
            }}
            style={{
              color: theme.colors.brand,
              paddingTop: 0,
              paddingBottom: 0,
            }}
          >
            <SvgIcon
              name={'link'}
              color={theme.colors.brand}
              size={theme.space.xs}
              style={{ marginRight: theme.space.xxs }}
            />
            View details
          </Button>
        </Row>

        <Row>
          <Button
            variant={ButtonVariant.TEXT}
            onClick={() => {
              // TODO(polyphilz): Update link once implemented
              navigate('/schedules')
            }}
            style={{
              color: theme.colors.brand,
              paddingTop: 0,
              paddingBottom: 0,
            }}
          >
            <SvgIcon
              name={'edit'}
              color={theme.colors.brand}
              size={theme.space.xs}
              style={{ marginRight: theme.space.xxs }}
            />
            Edit
          </Button>
        </Row>
      </Row>
    </Row>
  )
}
