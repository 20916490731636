import { Region } from '@traba/types'
import { useQuery } from 'react-query'
import { trabaApi } from 'src/api/helpers'
import { FIVE_MINUTES_IN_MS } from '../libs/constants'

export const OUTSIDE_REGION_ID = 'outside-region'

const getRegions = async () => {
  try {
    const response = await trabaApi.get(`/my-company/shift-regions`)
    return response.data
  } catch (error) {
    console.log(error)
  }
}

export const useRegions = () => {
  const {
    isLoading,
    isError,
    data: regions,
    error,
    isFetched,
    refetch,
  } = useQuery<Region[], Error>('shift-regions', getRegions, {
    staleTime: FIVE_MINUTES_IN_MS,
  })

  return {
    isLoading,
    isError,
    error,
    isFetched,
    regions,
    refetch,
  }
}
