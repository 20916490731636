import { Shift } from '@traba/types'
import { getShiftDateString, getShiftTimeString } from '@traba/utils'
import {
  addDays,
  addHours,
  addMinutes,
  differenceInDays,
  isAfter,
  parseISO,
  startOfDay,
} from 'date-fns'
import { WeekdayStr } from 'rrule'
import { BULLET_CHAR } from 'src/libs/constants'

interface Dater {
  toDate(): Date
}

export function formatShiftTimeWithTime(time: Date, timezone: string) {
  const options: Intl.DateTimeFormatOptions = {
    timeZone: timezone,
    weekday: 'short',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
  }
  return time.toLocaleDateString('en-us', options)
}

// Returns a string of shift start/end time in the format MM/DD/YY, hh:mm - hh:mm (timezone)
// Will include (+1) if the shift is an overnight shift
export function getShiftNumericDateAndTimeString({
  startTime,
  endTime,
  timezone,
  separatorChar = ` ${BULLET_CHAR} `,
  dateOptions,
}: {
  startTime: Date
  endTime: Date
  timezone: string
  separatorChar?: string
  dateOptions?: Intl.DateTimeFormatOptions
}) {
  const startLocalTime = new Date(
    startTime.toLocaleString('en-us', { timeZone: timezone }),
  )
  const endLocalTime = new Date(
    endTime.toLocaleString('en-us', { timeZone: timezone }),
  )
  // Difference in number of days between date of the shift start/time
  // instead of the number of 24 hour 'day' periods
  const numDayDifference = differenceInDays(
    endLocalTime.setHours(0, 0, 0, 0),
    startLocalTime.setHours(0, 0, 0, 0),
  )
  return `${startTime.toLocaleDateString('en-us', {
    timeZone: timezone,
    year: '2-digit',
    month: '2-digit',
    day: '2-digit',
    ...dateOptions,
  })}${separatorChar}${getShiftTimeString(startTime, endTime, timezone)}${numDayDifference > 0 ? ` (+${numDayDifference})` : ''}`
}

export function getReadableTimeInTimezone(
  date: Date,
  timezone: string,
  hideTimeZoneName?: boolean,
): string {
  return date.toLocaleString('en-US', {
    timeZone: timezone,
    timeZoneName: hideTimeZoneName ? undefined : 'short',
    hour: 'numeric',
    minute: '2-digit',
  })
}

export function getTime(date: Date): string {
  return date.toLocaleString('en-US', {
    hour: 'numeric',
    minute: '2-digit',
  })
}

const isoDateFormat =
  /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)((-(\d{2}):(\d{2})|Z)?)$/

export function isIsoDateString(value: any): boolean {
  return value && typeof value === 'string' && isoDateFormat.test(value)
}

export function handleDates(body: any) {
  if (body === null || body === undefined || typeof body !== 'object') {
    return body
  }

  for (const key of Object.keys(body)) {
    const value = body[key]
    if (isIsoDateString(value)) {
      body[key] = parseISO(value)
    } else if (typeof value === 'object') {
      handleDates(value)
    }
  }
}

/**
 * Given a certain time duration in minutes, returns a string
 * with the formatted duration in hours and minutes.
 * E.g.: m = 70 => '1h 10m'
 * @param m time duration expressed in minutes
 * @returns time duration express in hours/minutes format
 */
export function formatDuration(m: number) {
  const hours = Math.floor(m / 60)
  return `${hours > 0 ? `${hours}h ` : ``}${m % 60 <= 9 ? '0' : ''}${m % 60}m`
}

export function reverseChronSort(a: Shift, b: Shift) {
  return isAfter(a.startTime, b.startTime) ? -1 : 1
}

export function anyToDate(
  timestampOrString?: Date | string | number | Dater | null,
): Date {
  if (!timestampOrString) {
    throw new Error(
      'dateUtils -> anyToDate() ERROR: timestanpOrString is undefined',
    )
  }
  if (
    typeof timestampOrString === 'string' ||
    typeof timestampOrString === 'number' ||
    timestampOrString instanceof Date
  ) {
    return new Date(timestampOrString)
  } else {
    return timestampOrString.toDate()
  }
}

export function sortByDate(a: Date, b: Date, order: 'ASC' | 'DESC' = 'ASC') {
  return order === 'ASC' ? +a - +b : +b - +a
}

export const WEEKDAY_NAMES: { [key in WeekdayStr]: string } = {
  MO: 'Monday',
  TU: 'Tuesday',
  WE: 'Wednesday',
  TH: 'Thursday',
  FR: 'Friday',
  SA: 'Saturday',
  SU: 'Sunday',
}

// Sets the date of current to be after reference while maintaining the time.
// Used to keep endTime always in front of startTime, but within 24 hours.
export function getTimeAfterTimeWithin24Hours(time: Date, reference: Date) {
  const timeInReference = changeUnderlyingDate(time, reference)
  return timeInReference <= reference
    ? addDays(timeInReference, 1)
    : timeInReference
}

export function changeUnderlyingDate(time: Date, reference: Date) {
  const hours = time.getHours()
  const minutes = time.getMinutes()
  const referenceDayStart = startOfDay(reference)
  const result = addMinutes(addHours(referenceDayStart, hours), minutes)
  return result
}

export function setTimeFromDate(fromDate: Date, date: Date) {
  const hours = fromDate.getHours()
  const minutes = fromDate.getMinutes()
  const seconds = fromDate.getSeconds()
  const milliseconds = fromDate.getMilliseconds()

  return anyToDate(date.setHours(hours, minutes, seconds, milliseconds))
}

/** Get Shortened Timezone Acronym eg: EST, PST, EDT */
export const getTimeZoneAbbreviation = (date: Date, timezone: string) => {
  try {
    const match = date
      .toLocaleTimeString('en-us', {
        timeZoneName: 'short',
        timeZone: timezone,
      })
      .match(/([A-Z]{3})$/)

    if (!match) {
      return undefined
    }
    return match[0]
  } catch (err) {
    //Will happen if invalid timezone is passed
    return undefined
  }
}

/** Simple formatted date time with time zone: 'Sun, Dec 4, 2022, 2:04 PM PST' */
export const formatTimeDateString = (date: Date, timezone: string) => {
  return date.toLocaleDateString('en-us', {
    timeZone: timezone,
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    weekday: 'short',
    timeZoneName: 'short',
    hour: 'numeric',
    minute: '2-digit',
  })
}

/** Simple formatted date time with time zone: 'Sun, Dec 4, 2022, 2:04 PM PST' */
export const formatDateString = (date: Date, timezone: string) => {
  return date.toLocaleDateString('en-us', {
    timeZone: timezone,
    month: 'short',
    day: 'numeric',
    weekday: 'short',
  })
}

export function getLocalTimezone() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone
}

export function dateMax(date1: Date, date2: Date): Date {
  return new Date(Math.max(date1.getTime(), date2.getTime()))
}

export function formatShiftTime(
  startTime: Date,
  endTime: Date,
  timezone: string,
) {
  return `${getShiftDateString(
    startTime,
    endTime,
    timezone,
  )}, ${getShiftTimeString(startTime, endTime, timezone)}`
}

export function getDateInfoFromDayOfShift(dayOfShift: string) {
  const date = new Date(dayOfShift)
  return {
    dayOfWeek: date.toLocaleDateString('en-US', { weekday: 'short' }),
    month: date.toLocaleDateString('en-US', { month: 'short' }),
    dayOfMonth: date.toLocaleDateString('en-US', { day: 'numeric' }),
    year: date.toLocaleDateString('en-US', { year: 'numeric' }),
  }
}

export function getDailyViewUrlSlug(date: Date) {
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  }
  return date.toLocaleDateString('en-us', options).replace(/\//g, '-')
}
