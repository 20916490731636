import { MODAL_SIZE } from '@traba/react-components'
import { Card } from '@traba/react-components'
import { CompanyCategory, UserRole } from '@traba/types'
import React, { useState } from 'react'
import {
  Button,
  ButtonVariant,
  EmptyPlaceholderTile,
  Modal,
  Text,
} from 'src/components/base'
import { useCompany } from 'src/hooks/useCompany'
import { useUser, useUserPermissions } from 'src/hooks/useUser'
import { theme } from 'src/libs/theme'
import { RoleData, UserRolePermission } from 'src/types'

import { Plus } from '../assets/svg'
import { ArchiveRoleModal } from '../Modals/ArchiveRoleModal'
import { CreateRoleModal } from '../Modals/CreateRoleModal'
import { EditRoleModal } from '../Modals/EditRoleModal'
import { Role } from '../Role'
import * as S from './RolesProfile.styles'

export type RolesProfileProps = {
  roles: RoleData[]
}

export const RolesProfileUI: React.FC<RolesProfileProps> = (
  props: RolesProfileProps,
) => {
  const { user } = useUser()
  const { company } = useCompany()
  const userCanViewWages = useUserPermissions([UserRolePermission.ViewPay])
  // ManagerUserRoles permission is not applicable as that is for managing roles attached to business app users
  const userCanEditShiftRoles =
    useUserPermissions([UserRolePermission.ManageShifts]) &&
    user?.role !== UserRole.LimitedShiftCreator
  const [isModalOpen, setIsModalOpen] = useState(false)
  function openModal() {
    setIsModalOpen(true)
  }
  function closeModal() {
    setIsModalOpen(false)
  }

  const [showEditModal, setShowEditModal] = useState(false)
  const [roleToEdit, setRoleToEdit] = useState<RoleData | undefined>(undefined)

  const [showArchiveModal, setShowArchiveModal] = useState(false)
  const [roleToArchive, setRoleToArchive] = useState<RoleData | undefined>(
    undefined,
  )

  const handleArchiveModalClose = () => {
    setShowArchiveModal(false)
    setRoleToArchive(undefined)
  }

  const handleArchive = (role: RoleData) => {
    setShowArchiveModal(true)
    setRoleToArchive(role)
  }

  const handleEdit = (role: RoleData) => {
    setShowEditModal(true)
    setRoleToEdit(role)
  }

  const handleEditModalClose = () => {
    setShowEditModal(false)
    setRoleToEdit(undefined)
  }
  const showGenderPreference = company?.allowGenderPreference

  return (
    <S.RolesProfileContainer>
      <Text style={{ marginBottom: theme.space.xxs }} variant="h5">
        Roles
      </Text>
      <Text variant="body2">
        {userCanEditShiftRoles ? 'Manage' : 'View'} your company‘s roles.
      </Text>
      {userCanEditShiftRoles ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Button
            leftIcon={<Plus />}
            onClick={openModal}
            reverse
            variant={ButtonVariant.TEXT}
          >
            Create new role
          </Button>
        </div>
      ) : null}
      {props.roles.length ? (
        props.roles.map((role: RoleData, index: number) => (
          <Card
            key={index}
            style={{
              marginTop: '8px',
              width: '100%',
            }}
          >
            <Role
              role={role}
              edit={handleEdit}
              archive={handleArchive}
              userCanViewWages={userCanViewWages}
              userCanEdit={userCanEditShiftRoles}
              showGenderPreference={showGenderPreference}
            />
          </Card>
        ))
      ) : (
        <EmptyPlaceholderTile
          title="Your roles will show up here"
          subtitle="You can add a role above."
          iconName="folder"
        />
      )}
      <Modal isOpen={isModalOpen} handleClose={closeModal}>
        <CreateRoleModal
          onClose={closeModal}
          fromPrevious={false}
          isEventCompany={company?.category === CompanyCategory.EVENTS}
        />
      </Modal>
      <Modal
        size={MODAL_SIZE.MEDIUM}
        isOpen={showArchiveModal}
        handleClose={handleArchiveModalClose}
      >
        {roleToArchive && (
          <ArchiveRoleModal
            role={roleToArchive}
            handleClose={handleArchiveModalClose}
          />
        )}
      </Modal>
      <Modal
        size={MODAL_SIZE.MEDIUM}
        isOpen={showEditModal}
        handleClose={handleEditModalClose}
      >
        {roleToEdit && (
          <EditRoleModal
            role={roleToEdit}
            handleClose={handleEditModalClose}
            isEventCompany={company?.category === CompanyCategory.EVENTS}
          />
        )}
      </Modal>
    </S.RolesProfileContainer>
  )
}
