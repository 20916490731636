import { Card } from '@traba/react-components'
import { recurringSchedulesEnabled } from '@traba/utils'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Col, MainLayout, Row, Text } from 'src/components'
import { RadioButton } from 'src/components/RadioButton'
import { useCompany } from 'src/hooks/useCompany'
import { useShiftRequestParents } from 'src/hooks/useShiftRequestParents'
import { useHotSettings } from 'src/hooks/useSystem'
import { theme } from 'src/libs/theme'
import * as S from './BookShiftsScreen.styles'
import { SelectScheduleCard } from './components/SelectScheduleCard'

export default function SelectBookingOption() {
  const { hotSettings } = useHotSettings()
  const { company } = useCompany()
  const navigate = useNavigate()
  const [selectedShiftRequestParentId, setSelectedShiftRequestParentId] =
    useState<string | null>(null)

  const { shiftRequestParents } = useShiftRequestParents({
    includeCompletedSchedules: true,
  })

  return (
    <MainLayout hideSidebar title="Book Shifts">
      <S.BookShiftsContainer>
        {/* STEP TITLE */}
        <div className="xs-hide md-show md-3">
          <S.BookShiftsNavContainer>
            <S.BookShiftsNav>
              <S.BookShiftsNavTitle>Select Option</S.BookShiftsNavTitle>
            </S.BookShiftsNav>
          </S.BookShiftsNavContainer>
        </div>

        {/* STEP CONTENT */}
        <div className="xs-12 md-9" style={{ position: 'relative' }}>
          <div style={{ width: '100%', overflow: 'auto' }}>
            <S.BookShiftsContent>
              <S.BookShiftsComponentContainer active={true}>
                <Col gap={theme.space.xs}>
                  <Card
                    isClickable
                    onClick={() => setSelectedShiftRequestParentId(null)}
                  >
                    <Row alignCenter fullWidth>
                      <RadioButton
                        selected={selectedShiftRequestParentId === null}
                      />
                      <Row style={{ marginLeft: theme.space.xs }}>
                        <Col>
                          <Text color={theme.colors.MidnightBlue} variant="h5">
                            Book a new shift or schedule
                          </Text>
                          <Text variant="body2">
                            Select this if you need to book new shifts (one or
                            more) or book a new recurring schedule.
                          </Text>
                        </Col>
                      </Row>
                    </Row>
                  </Card>
                  {recurringSchedulesEnabled({
                    company,
                    hotSettings,
                  }) &&
                    !!shiftRequestParents && (
                      <Card>
                        <Row alignCenter fullWidth justifyBetween>
                          <Col>
                            <Row
                              alignCenter
                              onClick={() => {
                                if (!selectedShiftRequestParentId) {
                                  setSelectedShiftRequestParentId(
                                    shiftRequestParents[0].shiftRequestParentId,
                                  )
                                }
                              }}
                            >
                              <RadioButton
                                selected={selectedShiftRequestParentId !== null}
                              />
                              <Col style={{ marginLeft: theme.space.xs }}>
                                <Text
                                  color={theme.colors.MidnightBlue}
                                  variant="h5"
                                >
                                  Add to existing schedule
                                </Text>
                                <Text variant="body2">
                                  Select this if you need to add more days,
                                  roles, or workers to any of your existing
                                  schedules below.
                                </Text>
                              </Col>
                            </Row>
                            {/* Start schedules rows */}
                            <Col
                              style={{
                                marginLeft: theme.space.sm,
                                justifyContent: 'between',
                                overflow: 'auto',
                                maxHeight: 500,
                              }}
                            >
                              {shiftRequestParents.map((srp) => (
                                <SelectScheduleCard
                                  key={srp.shiftRequestParentId}
                                  shiftRequestParent={srp}
                                  onSelect={() =>
                                    setSelectedShiftRequestParentId(
                                      srp.shiftRequestParentId,
                                    )
                                  }
                                  selected={
                                    srp.shiftRequestParentId ===
                                    selectedShiftRequestParentId
                                  }
                                />
                              ))}
                            </Col>
                            {/* end schedules rows */}
                          </Col>
                        </Row>
                      </Card>
                    )}
                </Col>
                <Row
                  style={{
                    justifyContent: 'flex-end',
                    marginTop: theme.space.lg,
                  }}
                >
                  <Button
                    aria-label="Continue with Selected Booking Option"
                    onClick={() =>
                      selectedShiftRequestParentId !== null
                        ? navigate(
                            `/schedule/${selectedShiftRequestParentId}/edit`,
                          )
                        : navigate('/book-shifts')
                    }
                  >
                    Continue
                  </Button>
                </Row>
              </S.BookShiftsComponentContainer>
            </S.BookShiftsContent>
          </div>
        </div>
      </S.BookShiftsContainer>
    </MainLayout>
  )
}
