import {
  FormControlLabel,
  FormGroup,
  Switch,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material'
import { DEFAULT_PAGE_SIZE } from '@traba/consts'
import { IMenuItem, IMenuItemAll, MODAL_SIZE } from '@traba/react-components'
import { JobStatus, WorkerShift } from '@traba/types'
import { truncateString } from '@traba/utils'
import { endOfDay, startOfDay, subDays } from 'date-fns'
import { cloneDeep } from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'
import React from 'react'
import { useSearchParams } from 'react-router-dom'
import {
  Button,
  ButtonVariant,
  Col,
  Icon,
  Modal,
  Row,
  SvgIcon,
  Text,
  useModal,
} from 'src/components/base'
import DatePicker from 'src/components/base/AriaDatePicker/DatePicker'
import DateRangePicker from 'src/components/base/AriaDatePicker/DateRangePicker'
import { useDownloadReportMutation } from 'src/components/DownloadReport/DownloadReport.hooks'
import useNavigationGuard from 'src/components/NavigationGuard/NavigationGuard'
import { MultiShiftSelector } from 'src/components/ShiftSelector/ShiftSelector'
import { TimeSheetsChargeRecalculationBadge } from 'src/components/TimeSheetsTable/components/TimeSheetsChargeRecalculationBadge'
import { TimeSheetsTable } from 'src/components/TimeSheetsTable/TimeSheetsTable'
import {
  TimeSheetsTableLoggingSource,
  TimeSheetsTableWorkerNameSearchSource,
} from 'src/components/TimeSheetsTable/types'
import { WorkerNameSearch } from 'src/components/WorkerNameSearch/WorkerNameSearch'
import { useAppContext } from 'src/context/appContext/AppContext'
import { useQueryParams } from 'src/helpers'
import { useDebouncedState } from 'src/hooks/useDebouncedState'
import { useMembers } from 'src/hooks/useMembers'
import useMobile, { AppScreen } from 'src/hooks/useMobile'
import { usePagination } from 'src/hooks/usePagination'
import { useShifts } from 'src/hooks/useShifts'
import {
  TIMESHEET_WORKER_SHIFT_QUERY_KEY,
  TimeSheetsWorkerShiftResponse,
  WorkerShiftsForTimesheetsProps,
  useTimeSheetMutations,
  useTimesheetSummary,
  useWorkerShiftsForTimesheetWithPagination,
} from 'src/hooks/useTimesheet'
import { theme } from 'src/libs/theme'
import { getWorkerShiftStringId } from 'src/utils/workerShiftUtils'
import { UpdateWorkerShiftTimeSheetsModal } from './components/UpdateWorkerShiftTimeSheetsModal'
import { TimeSheetSummaryCard } from './TimeSheetSummaryCard'
import { TimesheetsPage, TimesheetsGroupBy } from './types'

const DEFAULT_TIMESHEET_DOWNLOAD_REPORT_LIMIT = 10000

interface TimeSheetTotalsProps {
  userCanManageTimesheets?: boolean
  userCanDownloadReport?: boolean
  userCanViewWages?: boolean
}

export const TimeSheetTotalsLayout = (props: TimeSheetTotalsProps) => {
  const { userCanDownloadReport, userCanManageTimesheets, userCanViewWages } =
    props
  const { members = [] } = useMembers()
  const [supervisorId, setSupervisorId] = useState<string>('all')
  const { isMobileViewOrReactNative } = useMobile()
  const {
    isOpen: isUpdateModalOpen,
    handleClose: handleCloseUpdateModal,
    open: openUpdateModal,
  } = useModal()
  const [searchParams, setSearchParams] = useSearchParams()
  const [groupBy, setGroupBy] = useState(TimesheetsGroupBy.WORKER)
  const [shiftsInput, setShiftsInput] = useState('')
  const [selectedShiftIds, setSelectedShiftIds] = useState<string[]>(
    searchParams.getAll('shiftId'),
  )
  const [editMode, setEditMode] = useState(false)
  const [hideApproved, setHideApproved] = useState(false)
  const [timePeriod, setTimePeriod] = useState<[Date | null, Date | null]>([
    selectedShiftIds.length ? null : subDays(new Date(), 7),
    selectedShiftIds.length ? null : new Date(),
  ])
  const { mutate: downloadReport, isPending } = useDownloadReportMutation()
  const dayParam = searchParams.get('day')
  const [day, setDay] = useState<Date>(
    dayParam ? new Date(dayParam) : new Date(),
  )
  const [editedWorkerShifts, setEditedWorkerShifts] = useState<
    Record<string, Partial<WorkerShift>>
  >({})

  const cachedSupervisorNames: IMenuItem[] = useMemo(() => {
    return [
      ...IMenuItemAll,
      ...members
        .map((m) => ({
          value: m.uid || '',
          label: truncateString(`${m.firstName} ${m.lastName}`, 30),
        }))
        .sort((a, b) => (a.label < b.label ? -1 : 1)),
    ]
  }, [members])

  const view = searchParams.get('view')
  useEffect(() => {
    const currentUrlParams = {
      ...(view ? { view } : {}),
      shiftId: selectedShiftIds,
    }
    setSearchParams(currentUrlParams)
  }, [selectedShiftIds, setSearchParams, view])

  const [firstName, debouncedFirstName, setFirstName] = useDebouncedState(
    '',
    (newState) => {
      window.analytics.track(`Timesheets First Name Search Changed`, {
        firstName: newState,
        page: currentPage,
        source: TimeSheetsTableWorkerNameSearchSource.TIMESHEETS,
      })
    },
  )
  const [lastName, debouncedLastName, setLastName] = useDebouncedState(
    '',
    (newState) => {
      window.analytics.track(`Timesheets Last Name Search Changed`, {
        lastName: newState,
        page: currentPage,
        source: TimeSheetsTableWorkerNameSearchSource.TIMESHEETS,
      })
    },
  )

  const queryParams = useQueryParams()
  const {
    state: { regionalFilter },
  } = useAppContext()

  const isTotalsView = queryParams.get('view') === 'totals'
  const currentPage = isTotalsView
    ? TimesheetsPage.TOTALS
    : TimesheetsPage.DAILY
  const isDirty = Object.keys(editedWorkerShifts).length

  const dateQuery = useMemo(() => {
    return isTotalsView
      ? {
          after: timePeriod[0] ? startOfDay(timePeriod[0]) : undefined,
          before: timePeriod[1] ? endOfDay(timePeriod[1]) : undefined,
        }
      : {
          after: startOfDay(day),
          before: endOfDay(day),
        }
  }, [isTotalsView, timePeriod, day])

  useNavigationGuard({
    when: !!editMode && !!isDirty,
    message: 'You have unsaved edits, do you really want to leave?',
  })
  const activeLocationIds = regionalFilter
    ? Object.values(regionalFilter || {}).flat()
    : undefined

  const { data: summary, refetch: refetchSummary } = useTimesheetSummary({
    ...dateQuery,
    activeLocationIds,
  })
  const shiftIds = useMemo(
    () => summary?.shifts.map((shift) => shift.id) ?? [],
    [summary],
  )
  const {
    data: shifts,
    refetch: refetchShifts,
    isLoading: isLoadingShifts,
  } = useShifts(
    {
      shiftIds: shiftIds,
    },
    undefined,
    shiftIds.length > 0,
  )
  const selectedShifts = useMemo(() => {
    return shifts?.filter((s) => selectedShiftIds.includes(s.id)) ?? []
  }, [shifts, selectedShiftIds])

  const timeSheetQueryParams = {
    groupBy,
    hideApproved,
    ...dateQuery,
    activeLocationIds,
    firstName: debouncedFirstName.length === 0 ? undefined : debouncedFirstName,
    lastName: debouncedLastName.length === 0 ? undefined : debouncedLastName,
    shiftIds:
      selectedShiftIds.length > 0 ? Array.from(selectedShiftIds) : undefined,
    supervisorId: supervisorId !== 'all' ? supervisorId : undefined,
  }

  const {
    page,
    onPageLeft,
    onPageRight,
    data: response,
    resetPagination,
    isLoading,
    isRefetching,
    refetch: refetchWorkerShifts,
  } = usePagination<
    WorkerShiftsForTimesheetsProps,
    TimeSheetsWorkerShiftResponse
  >(
    TIMESHEET_WORKER_SHIFT_QUERY_KEY,
    timeSheetQueryParams,
    useWorkerShiftsForTimesheetWithPagination,
    DEFAULT_PAGE_SIZE,
    0,
  )

  const data = response?.result
  const disableApproveAll = !data?.some((item) =>
    item.workerShifts.some((ws) => !ws.isApproved),
  )

  const refetch = () => {
    refetchWorkerShifts()
    refetchSummary()
    refetchShifts()
  }

  const { approveWorkerShifts } = useTimeSheetMutations()
  const handleApproveAllWorkerShifts = async () => {
    if (data) {
      const workerShiftIds = data?.flatMap((item) =>
        item.workerShifts
          .filter((ws) => !ws.isApproved)
          .map((ws) => ({
            workerId: ws.workerId,
            shiftId: ws.shiftId,
          })),
      )
      await approveWorkerShifts(workerShiftIds)
      refetch()
      window.analytics.track('Timesheets Totals Approve All Clicked', {
        workerShiftIds: workerShiftIds.map((workerShiftId) =>
          getWorkerShiftStringId(workerShiftId.workerId, workerShiftId.shiftId),
        ),
        page: currentPage,
        screen: isMobileViewOrReactNative
          ? AppScreen.MOBILE
          : AppScreen.DESKTOP,
      })
    }
  }

  const resetEditedShifts = useCallback(() => {
    if (editMode && !!isDirty) {
      if (!window.confirm('You have unsaved edits. Continue?')) {
        return false
      }
    }
    setEditedWorkerShifts({})
    setEditMode(false)
    return true
  }, [setEditedWorkerShifts, setEditMode, editMode, isDirty])

  const description = `View all the times ${isTotalsView ? 'in a pay period, or any custom time period' : 'for a specific day'}. You can select which locations you want to see shifts and worker times for. `

  const shiftCount = summary?.shifts.length ?? 0
  const summaryStats = useMemo(
    () =>
      summary?.shifts.reduce(
        (acc, shift) => {
          return {
            uniqueWorkerIds: acc.uniqueWorkerIds.add(shift.uniqueWorkerIds),
            pendingApprovals:
              acc.pendingApprovals +
              (shift.numWorkerShifts - shift.numApprovedWorkerShifts),
            uniqueLocations: acc.uniqueLocations.add(shift.activeLocationId),
            totalTimeWorked: acc.totalTimeWorked + shift.totalTimeWorked,
          }
        },
        {
          pendingApprovals: 0,
          uniqueLocations: new Set(),
          uniqueWorkerIds: new Set(),
          totalTimeWorked: 0,
        },
      ),
    [summary],
  )

  const isRecalculatingCharges = data?.some((item) =>
    item.workerShifts.some((ws) => {
      const latestAdjustmentAt = ws.latestAdjustmentAt
      const noCharges =
        ws.charges.length === 0 &&
        ws.shiftInfo.payRate !== 0 &&
        ws.jobStatus === JobStatus.Complete

      if (latestAdjustmentAt) {
        const chargesOutOfDate = ws.charges.every(
          (item) => item.updatedAt < latestAdjustmentAt,
        )
        return chargesOutOfDate
      } else {
        return noCharges
      }
    }),
  )

  /** Merging the local+pending edited worker shifts and saving which ones need an estimated charge */
  const updatedData = useMemo(() => {
    if (!data) {
      return []
    }
    const clonedData = cloneDeep(data)
    // for each worker or shift
    for (let i = 0; i < clonedData.length; i++) {
      // for each workerShift
      for (let j = 0; j < clonedData[i].workerShifts.length; j++) {
        const ws = clonedData[i].workerShifts[j]
        const pendingEditWs = ws.pendingAdjustment
        const localEditWs =
          editedWorkerShifts[getWorkerShiftStringId(ws.workerId, ws.shiftId)]
        clonedData[i].workerShifts[j] = {
          ...ws,
          ...(pendingEditWs || {}),
          ...(localEditWs || {}),
          hasPendingEdits: !!pendingEditWs || !!localEditWs,
        }
      }
    }
    return clonedData
  }, [data, editedWorkerShifts])

  const approveAllButton = !editMode && userCanManageTimesheets && (
    <Button
      variant={ButtonVariant.FILLED}
      disabled={isLoading || disableApproveAll}
      onClick={() => handleApproveAllWorkerShifts()}
      style={{ marginLeft: theme.space.xs }}
    >
      Approve all
    </Button>
  )
  const handleDownloadReport = () => {
    window.analytics.track('Timesheets Download Report Button Clicked', {
      page: currentPage,
    })
    downloadReport({
      ...timeSheetQueryParams,
      hideApproved: undefined,
      limit: DEFAULT_TIMESHEET_DOWNLOAD_REPORT_LIMIT,
    })
  }
  const doPageLeft = () => {
    window.analytics.track(`Timesheets Page Left Clicked`, {
      pageNumber: page,
      page: currentPage,
      screen: isMobileViewOrReactNative ? AppScreen.MOBILE : AppScreen.DESKTOP,
      source: TimeSheetsTableWorkerNameSearchSource.TIMESHEETS,
    })
    if (!resetEditedShifts()) {
      return
    }
    onPageLeft()
  }

  const doPageRight = () => {
    window.analytics.track(`Timesheets Page Right Clicked`, {
      pageNumber: page,
      page: currentPage,
      screen: isMobileViewOrReactNative ? AppScreen.MOBILE : AppScreen.DESKTOP,
      source: TimeSheetsTableWorkerNameSearchSource.TIMESHEETS,
    })
    if (!resetEditedShifts()) {
      return
    }
    onPageRight()
  }

  return (
    <>
      {!isMobileViewOrReactNative && (
        <Row justifyBetween mt={16} mb={40}>
          <Col className="xs-12 xl-8">
            <Text variant="body2">{description}</Text>
          </Col>

          {userCanDownloadReport && (
            <Button
              variant={ButtonVariant.FILLED}
              loading={isLoading || isPending}
              onClick={handleDownloadReport}
            >
              Download Report
            </Button>
          )}
        </Row>
      )}

      {isTotalsView ? (
        <DateRangePicker
          dateRange={timePeriod}
          maxDate={new Date()}
          setDateRange={(newDateRange) => {
            if (!resetEditedShifts()) {
              return
            }
            setTimePeriod(newDateRange)
            window.analytics.track('Timesheets Time Period Changed', {
              dateRange: newDateRange,
              page: TimesheetsPage.TOTALS,
            })
          }}
          granularity="day"
          label="Time period"
          isClearable={false}
          aria-label="Select a time period to view timecards for."
        />
      ) : (
        <DatePicker
          granularity="day"
          defaultDate={day}
          maxDate={new Date()}
          setDate={(newDate) => {
            if (!resetEditedShifts()) {
              return
            }
            if (newDate) {
              setDay(newDate)
              window.analytics.track('Timesheets Time Period Day Changed', {
                date: newDate,
                page: TimesheetsPage.DAILY,
              })
            }
          }}
          label="Day"
          aria-label="Select a day to view timecards for."
        />
      )}

      <MultiShiftSelector
        shifts={shifts}
        selectedShifts={selectedShifts}
        isLoading={isLoadingShifts}
        input={shiftsInput}
        setInput={setShiftsInput}
        setSelectedShiftIds={(shiftIds: string[] | undefined) => {
          setSelectedShiftIds(shiftIds || [])
          window.analytics.track('Timesheets Shifts Filter Selected', {
            shiftsIds: shifts || [],
            page: currentPage,
          })
        }}
      />

      <Row mt={theme.space.xs}>
        <TimeSheetSummaryCard
          timePeriod={timePeriod}
          day={day}
          isTotalsView={isTotalsView}
          locationCount={summaryStats?.uniqueLocations.size ?? 0}
          pendingApprovalCount={summaryStats?.pendingApprovals ?? 0}
          shiftCount={shiftCount}
          workerCount={summaryStats?.uniqueWorkerIds.size ?? 0}
          totalTimeWorked={summaryStats?.totalTimeWorked ?? 0}
        />
      </Row>

      <Row mt={24}>
        <Text variant="h4" mb={theme.space.sm}>
          Timecards
        </Text>
      </Row>

      <Row style={{ flexWrap: 'wrap' }} alignCenter justifyBetween>
        {isMobileViewOrReactNative && (
          <Row mb={theme.space.xxs}>{approveAllButton}</Row>
        )}

        <Row alignCenter mb={theme.space.xxs} style={{ flexWrap: 'wrap' }}>
          <ToggleButtonGroup
            sx={{
              '& .MuiToggleButton-root': {
                backgroundColor: theme.colors.White,
                color: theme.colors.Grey60,
              },
              '& .MuiToggleButton-root:hover': {
                color: theme.colors.brand,
                backgroundColor: theme.colors.Violet30,
              },
              '& .Mui-selected': {
                backgroundColor: theme.colors.Violet10,
                color: theme.colors.brand,
              },
              '& .Mui-selected:hover': {
                backgroundColor: theme.colors.Violet10,
                color: theme.colors.brand,
              },
            }}
          >
            <ToggleButton
              value={TimesheetsGroupBy.SHIFT}
              selected={groupBy === TimesheetsGroupBy.SHIFT}
              onClick={() => {
                if (!resetEditedShifts()) {
                  return
                }
                resetPagination()
                setGroupBy(TimesheetsGroupBy.SHIFT)
                window.analytics.track('Timesheets Group By Toggled', {
                  groupBy: TimesheetsGroupBy.SHIFT,
                  page: currentPage,
                })
              }}
              sx={{
                width: '160px',
              }}
            >
              Group by shift
            </ToggleButton>
            <ToggleButton
              value={TimesheetsGroupBy.WORKER}
              selected={groupBy === TimesheetsGroupBy.WORKER}
              onClick={() => {
                if (!resetEditedShifts()) {
                  return
                }
                resetPagination()
                setGroupBy(TimesheetsGroupBy.WORKER)
                window.analytics.track('Timesheets Group By Toggled', {
                  groupBy: TimesheetsGroupBy.WORKER,
                  page: currentPage,
                })
              }}
              sx={{
                width: '160px',
                color: 'pink',
              }}
            >
              Group by worker
            </ToggleButton>
          </ToggleButtonGroup>
          {userCanManageTimesheets && (
            <FormGroup
              style={{
                marginRight: theme.space.xs,
                justifyContent: 'center',
              }}
            >
              <FormControlLabel
                labelPlacement="start"
                style={{ marginLeft: theme.space.xxs }}
                control={
                  <Switch
                    checked={hideApproved}
                    onChange={() => {
                      if (!resetEditedShifts()) {
                        return
                      }
                      resetPagination()
                      window.analytics.track(
                        'Timesheets Hide Approved Toggled',
                        {
                          hideApproved: !hideApproved,
                          page: currentPage,
                        },
                      )
                      setHideApproved((hideApproved) => !hideApproved)
                    }}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                }
                label={
                  <Text variant="h6" color={theme.colors.MidnightBlue}>
                    Hide Approved
                  </Text>
                }
              />
            </FormGroup>
          )}
        </Row>
        <Row alignCenter>
          {!editMode && userCanViewWages && !isMobileViewOrReactNative && (
            <TimeSheetsChargeRecalculationBadge
              isRecalculating={isRecalculatingCharges ?? false}
              onRefresh={() => {
                window.analytics.track(
                  'Timesheets Recalculation Refresh Button Clicked',
                  {
                    page: currentPage,
                  },
                )
                refetchWorkerShifts()
              }}
              isRefetching={isRefetching}
            />
          )}
          {!!isDirty && (
            <Button
              onClick={() => openUpdateModal()}
              variant={ButtonVariant.FILLED}
              leftIcon={
                <SvgIcon name={'check'} color="white" strokeWidth={2} />
              }
              style={{ marginRight: theme.space.xs }}
            >
              Update
            </Button>
          )}
          {!isMobileViewOrReactNative && (
            <Button
              variant={ButtonVariant.OUTLINED}
              style={{
                marginLeft: theme.space.sm,
              }}
              onClick={() => {
                if (editMode) {
                  setEditedWorkerShifts({})
                }
                window.analytics.track(
                  editMode
                    ? 'Timesheets Bulk Edit Cancel Button Click'
                    : 'Timesheets Bulk Edit Button Click',
                  {
                    page: currentPage,
                  },
                )
                setEditMode((editMode) => !editMode)
              }}
              leftIcon={<Icon name={!editMode ? 'edit' : 'cancel'} />}
              disabled={isLoading || disableApproveAll}
            >
              {!editMode ? 'Edit' : 'Cancel'}
            </Button>
          )}
        </Row>
      </Row>
      <WorkerNameSearch
        resetPagination={resetPagination}
        setFirstName={setFirstName}
        setLastName={setLastName}
        setSupervisorId={setSupervisorId}
        firstName={firstName}
        lastName={lastName}
        supervisorId={supervisorId}
        page={page}
        doPageLeft={doPageLeft}
        doPageRight={doPageRight}
        response={response}
        cachedSupervisorNames={cachedSupervisorNames}
      />

      <TimeSheetsTable
        groupBy={groupBy}
        data={updatedData}
        isLoading={isLoading}
        refetchData={() => refetch()}
        editMode={editMode}
        onEditWorkerShift={(editedWorkerShift) => {
          // cloning to maintain immutability
          const workerShiftIdString = getWorkerShiftStringId(
            editedWorkerShift.workerId,
            editedWorkerShift.shiftId,
          )
          const previousWorkerShift =
            editedWorkerShifts[workerShiftIdString] || {}
          const newEditedWorkerShifts = { ...editedWorkerShifts }
          newEditedWorkerShifts[workerShiftIdString] = {
            ...previousWorkerShift,
            ...editedWorkerShift,
          }
          setEditedWorkerShifts(newEditedWorkerShifts)
        }}
        approveWorkerShifts={approveWorkerShifts}
        isRecalculatingCharges={isRecalculatingCharges}
        hideTotalsRow={isMobileViewOrReactNative}
        loggingContext={{
          source: TimeSheetsTableLoggingSource.TIMESHEETS_TOTALS_SCREEN,
          groupBy,
          page: currentPage,
        }}
      />
      <Modal
        size={MODAL_SIZE.LARGE}
        style={{ overflowY: 'unset' }}
        isOpen={!!isUpdateModalOpen}
        handleClose={() => handleCloseUpdateModal()}
      >
        <UpdateWorkerShiftTimeSheetsModal
          handleClose={() => handleCloseUpdateModal()}
          onUpdate={() => {
            setEditedWorkerShifts({})
            setEditMode(false)
            refetch()
          }}
          editedWorkerShifts={updatedData.flatMap((item) =>
            item.workerShifts.filter(
              (ws) =>
                !!editedWorkerShifts[
                  getWorkerShiftStringId(ws.workerId, ws.shiftId)
                ],
            ),
          )}
        />
      </Modal>
    </>
  )
}
