import * as Sentry from '@sentry/react'
import {
  CompanyWorkerFeedback,
  CreateCompanyWorkerFeedback,
  UpdateCompanyWorkerFeedback,
} from '@traba/types'
import { AxiosError } from 'axios'
import { useMutation, useQuery } from 'react-query'
import { trabaApi } from 'src/api/helpers'

async function getReviews(
  shiftId?: string,
  workerId?: string,
): Promise<CompanyWorkerFeedback[]> {
  const params = new URLSearchParams({
    ...(workerId ? { workerId } : {}),
    ...(shiftId ? { shiftId } : {}),
  })
  const response = await trabaApi.get(
    `/my-company/company-worker-feedback?${params.toString()}`,
  )
  return response.data
}

export async function updateReviewApi(
  id: string,
  review: UpdateCompanyWorkerFeedback,
): Promise<CompanyWorkerFeedback> {
  try {
    const res = await trabaApi.patch(
      `my-company/company-worker-feedback/${id}`,
      review,
    )
    return res.data
  } catch (err) {
    Sentry.captureException(err)
    throw err
  }
}

export async function createReviewApi(
  review: CreateCompanyWorkerFeedback,
): Promise<CompanyWorkerFeedback> {
  try {
    const res = await trabaApi.post(
      `my-company/company-worker-feedback`,
      review,
    )
    return res.data
  } catch (err) {
    Sentry.captureException(err)
    throw err
  }
}

export function useReviews({
  shiftId,
  workerId,
  staleTime,
}: {
  shiftId?: string
  workerId?: string
  staleTime?: number
}) {
  const {
    data: reviews,
    isLoading,
    refetch: refetchReviews,
  } = useQuery<CompanyWorkerFeedback[], Error>(
    ['reviews', shiftId, workerId],
    async () => await getReviews(shiftId, workerId),
    {
      staleTime,
    },
  )

  const createReview = useMutation<
    CompanyWorkerFeedback,
    AxiosError,
    CreateCompanyWorkerFeedback
  >(async (review) => await createReviewApi(review))

  const updateReview = useMutation<
    CompanyWorkerFeedback,
    AxiosError,
    UpdateCompanyWorkerFeedback & { id: string }
  >(async (review) => updateReviewApi(review.id, review))

  return {
    reviews,
    isLoading,
    createReview: createReview.mutateAsync,
    updateReview: updateReview.mutateAsync,
    refetchReviews,
  }
}
