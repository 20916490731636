import * as Sentry from '@sentry/react'
import { FIVE_MINUTES_IN_MS } from '@traba/consts'
import { CertificationEntry } from '@traba/types'
import { useQuery } from 'react-query'
import { trabaApi } from 'src/api/helpers'

export function certificationName(
  type: string,
  certifications: CertificationEntry[],
): string {
  const name = certifications.find((c) => c.type === type)?.name
  if (!name) {
    Sentry.captureMessage(
      `No certification setting found for certification type ${type}`,
    )
  }
  return name || type
}

const getCertifications = async () => {
  try {
    const response = await trabaApi.get(
      `certifications/my-company/certifications?locale=en`,
    )
    return response.data
  } catch (error) {
    console.log(error)
  }
}

export const useCertifications = () => {
  const {
    isLoading,
    isError,
    data: certifications,
    error,
    isFetched,
  } = useQuery<CertificationEntry[], Error>(
    'certifications',
    getCertifications,
    { staleTime: FIVE_MINUTES_IN_MS },
  )

  return {
    isLoading,
    isError,
    error,
    isFetched,
    certifications,
  }
}
