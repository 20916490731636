import {
  TimeSheetsEstimatedChargeResponse,
  TimeSheetsWorkerCharge,
} from 'src/hooks/useTimesheet'

export function combineMultipleEstimatedWorkerShiftsCharges(
  timeSheetsEstimatedCharges: TimeSheetsEstimatedChargeResponse[],
): TimeSheetsWorkerCharge {
  const summedCharges = timeSheetsEstimatedCharges.reduce<{
    amount: number
    updatedAmount: number
  }>(
    (acc, charge) => {
      return {
        amount: acc.amount + (charge.data?.charge?.amount ?? 0),
        updatedAmount:
          acc.updatedAmount +
          (charge.data?.updatedCharge?.amount ??
            charge.data?.charge?.amount ??
            0),
      }
    },
    { amount: 0, updatedAmount: 0 },
  )
  return {
    charge: {
      amount: summedCharges.amount,
      currency: 'USD',
    },
    updatedCharge: {
      amount: summedCharges.updatedAmount,
      currency: 'USD',
    },
  }
}
