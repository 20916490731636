import { InputAdornment, Skeleton } from '@mui/material'
import TextField from '@mui/material/TextField/TextField'
import { Card, CardTile } from '@traba/react-components'
import {
  CompanyCategory,
  ForwardFillMax,
  GenderPreference,
  InvitedWorkers,
  RequiredMultiShiftType,
  ShiftPayType,
} from '@traba/types'
import { addHours, isAfter } from 'date-fns'
import { useEffect, useState } from 'react'
import {
  Button,
  ButtonVariant,
  Col,
  Divider,
  InlineBanner,
  Modal,
  Row,
  SvgIcon,
  Text,
} from 'src/components'
import { IMenuItem } from 'src/components/base/Select/Select'
import { GenderPreferenceSelector } from 'src/components/GenderPreferenceSelector'
import { CreateMemberModal } from 'src/components/Modals/CreateMemberModal'
import { CreateRoleModal } from 'src/components/Modals/CreateRoleModal'
import { RadioButton } from 'src/components/RadioButton'
import { useCompany } from 'src/hooks/useCompany'
import { useConnections } from 'src/hooks/useConnections'
import { useMembers } from 'src/hooks/useMembers'
import { useRoles } from 'src/hooks/useRoles'
import { PAY_RATE_DEFAULT } from 'src/hooks/useShiftRequests'
import { useHotSettings } from 'src/hooks/useSystem'
import { theme } from 'src/libs/theme'
import { BookShiftsInvitationsSection } from 'src/screens/BookShifts/steps/sections/BookShiftsInvitationsSection'
import EditShiftsSaveButton from 'src/screens/EditShifts/EditShiftsSaveButton'
import { calculateSingleWorkerPay } from 'src/utils/moneyUtils'
import { BookShiftsProps } from '../BookShiftsScreen'
import { BookShiftStepSection } from '../components/BookShiftSection'
import { BookShiftsRMSASection } from '../components/BookShiftsRMSASection'
import {
  getMinHourlyPayRate,
  MIN_WORKER_HOURLY_PAY_DEFAULT,
  validatePayRate,
  validateShiftEdits,
  validateWorkersStepShiftDataModel,
} from '../validation'
import { BookShiftsSiteContactSection } from './sections/BookShiftsSiteContactSection'
import { BookShiftsSiteRoleSection } from './sections/BookShiftsSiteRoleSection'
import { BookShiftsWorkersCountSection } from './sections/BookShiftsWorkerCountSection'

type BookShiftsWorkersContentModal = 'ROLE' | 'ROLE_PREVIOUS' | 'CONTACT'

export function BookShiftsWorkersContentShiftDataModel(props: BookShiftsProps) {
  const {
    shiftRequest,
    setShiftRequest,
    onContinue,
    onPrevious,
    onSaveChanges,
    selectedShifts,
    setShowSelectModal,
    shiftUpdates,
    isEdit,
    defaultShiftRequest,
    userHasLimitedShiftManagementAbility,
    existingShiftInvitations,
    getWorkerById,
  } = props
  const {
    invitedWorkers,
    forwardFillMax,
    payRate,
    roleId,
    payType,
    supervisorId,
    requiredMultiShiftType,
  } = shiftRequest
  const schedule = shiftRequest.schedules[0]
  const { company } = useCompany()
  const { roles, isLoading: isLoadingRoles } = useRoles()
  const { members, isLoading: isLoadingMembers } = useMembers()
  const { hotSettings } = useHotSettings()
  const [modalType, setModalType] =
    useState<BookShiftsWorkersContentModal | null>(null)
  function closeModal() {
    setModalType(null)
  }
  const [maskedPayRate, setMaskedPayRate] = useState(payRate.toFixed(2))
  const [touchedPayRate, setTouchedPayRate] = useState(false)
  const [genderPreference, setGenderPreference] = useState<
    GenderPreference | undefined
  >(shiftRequest.genderPreference || undefined)
  const [RMSAEnabled, setRMSAEnabled] = useState<boolean>(
    requiredMultiShiftType === RequiredMultiShiftType.ALL_IN_REQUEST,
  )
  const onRateChange = (masked: string) => {
    const payRate = +Math.abs(+masked).toFixed(2)
    setMaskedPayRate(masked)
    setShiftRequest({ payRate })
  }

  const onRateBlur = () => {
    setMaskedPayRate(payRate.toFixed(2))
    setTouchedPayRate(true)
    window.analytics.track(`User Set Pay Rate`, {
      payRate: payRate,
      isEdit,
    })
  }

  useEffect(() => {
    setMaskedPayRate(payRate.toFixed(2))
    setGenderPreference(shiftRequest.genderPreference || undefined)
  }, [roleId])

  const { favoriteWorkers } = useConnections()
  const noFavorites = favoriteWorkers.length === 0
  const tooLateForFavoritesFirst =
    !schedule.isRecurringSchedule &&
    isAfter(addHours(new Date(), 48), schedule.startTime)
  const favoritesFirstDisabled = noFavorites || tooLateForFavoritesFirst

  // When there are no favorites, force invitedWorkers to all.
  useEffect(() => {
    if (
      favoritesFirstDisabled &&
      invitedWorkers === InvitedWorkers.FAVORITES_FIRST
    ) {
      setShiftRequest({ invitedWorkers: InvitedWorkers.ALL })
    }
  }, [favoritesFirstDisabled, invitedWorkers, setShiftRequest])

  const minHourlyPay = getMinHourlyPayRate({
    companyMinHourlyPay: company?.minHourlyPayRate,
    platformMinHourlyPay:
      hotSettings?.platformMinHourlyPayRate ?? MIN_WORKER_HOURLY_PAY_DEFAULT,
  })
  const validationError = validateWorkersStepShiftDataModel(
    shiftRequest,
    minHourlyPay,
    existingShiftInvitations,
  )
  const editsError =
    selectedShifts &&
    defaultShiftRequest &&
    validateShiftEdits(defaultShiftRequest, selectedShifts, shiftUpdates)
  // Only relevant for PBU shifts
  const payPerWorker =
    shiftRequest.payType === ShiftPayType.UNIT &&
    shiftRequest.payRate &&
    shiftRequest.numberOfUnits &&
    shiftRequest.slotsRequested
      ? calculateSingleWorkerPay(
          {
            ...shiftRequest,
            ...shiftRequest.schedules[0],
            payRate: shiftRequest.payRate,
            payType: shiftRequest.payType,
            numberOfUnits: shiftRequest.numberOfUnits || 0,
          },
          undefined,
        ).amount / 100
      : 0
  const payValidationError = validatePayRate(shiftRequest, minHourlyPay)
  const payTypeOptions: IMenuItem<ShiftPayType>[] = [
    {
      label: 'Hourly',
      value: ShiftPayType.HOURLY,
    },
    {
      label: 'By Unit',
      value: ShiftPayType.UNIT,
    },
  ]

  const isEventCompany = company?.category === CompanyCategory.EVENTS

  function toggleRMSA(required: boolean) {
    setRMSAEnabled(required)
    if (!hotSettings?.allowRequiredMultiShiftInShiftRequest) {
      return
    }
    if (required) {
      setShiftRequest({
        requiredMultiShiftType: RequiredMultiShiftType.ALL_IN_REQUEST,
      })
    } else {
      setShiftRequest({
        requiredMultiShiftType: RequiredMultiShiftType.None,
      })
    }
  }

  function onCreateRole() {
    setModalType('ROLE')
    window.analytics.track(`User Clicked Create New Role`, { isEdit })
  }
  function onCreateRoleFromPrevious() {
    setModalType('ROLE_PREVIOUS')
    window.analytics.track(`User Clicked Create Role From Templates`, {
      isEdit,
    })
  }

  const roleCreationContent = () =>
    userHasLimitedShiftManagementAbility ? (
      roles.length ? null : (
        <InlineBanner
          severity="warning"
          text="Your role does not have permissions to create a new role. Please reach out to your administrator"
          style={{ marginTop: theme.space.xxs }}
        />
      )
    ) : !roles.length || isEventCompany ? (
      <Button
        variant={ButtonVariant.LINK}
        onClick={onCreateRole}
        style={{ marginTop: theme.space.xxs }}
      >
        Create new role
      </Button>
    ) : (
      <Row
        alignCenter
        style={{ gap: theme.space.xxs }}
        mt={theme.space.xxs}
        wrap
      >
        <Button variant={ButtonVariant.LINK} onClick={onCreateRole}>
          Create new role
        </Button>
        <Text variant="body1">OR</Text>
        <Button variant={ButtonVariant.LINK} onClick={onCreateRoleFromPrevious}>
          Create role using your previous roles
        </Button>
      </Row>
    )

  if (isLoadingRoles || isLoadingMembers) {
    return (
      <>
        {Array.from(Array(4)).map(() => (
          <>
            <Skeleton animation="pulse" width="100%" height="60px" />
            <Skeleton animation="pulse" width="100%" height="120px" />
          </>
        ))}
      </>
    )
  }

  return (
    <>
      <BookShiftStepSection
        section={{
          title: 'Role',
          singular: 'role',
          plural: 'roles',
          options: roles
            .map((r) => ({ value: r.roleId, label: r.roleName }))
            .sort((a, b) => a.label.localeCompare(b.label)),
          selected: roleId,
          onChange: (v: string) => {
            const selectedRole = roles.find((r) => r.roleId === v)
            const rolePayRate = selectedRole?.defaultPayRate ?? PAY_RATE_DEFAULT
            setShiftRequest({
              roleId: v,
              ...(payType === ShiftPayType.HOURLY && {
                payRate: rolePayRate,
              }),
              genderPreference: selectedRole?.genderPreference,
            })
            window.analytics.track(`User Selected Role`, {
              roleId: v,
              isEdit,
            })
          },
          onCreate: onCreateRole,
          optional: false,
          hide: false,
          contentExpanded: true,
          errorMessage:
            'The role in the previous shift request was archived. Please select a new role.',
          Content: BookShiftsSiteRoleSection,
          CreationContent: () => roleCreationContent(),
        }}
        bookShiftProps={props}
      />
      {company &&
      company.allowPostingPayByUnit &&
      !userHasLimitedShiftManagementAbility ? (
        <BookShiftStepSection
          section={{
            title: 'Pay Type',
            subtitle: 'How would you like your workers to be compensated?',
            singular: 'pay type',
            plural: 'pay types',
            options: payTypeOptions,
            selected: props.shiftRequest.payType,
            onChange: (v) => {
              setShiftRequest({ payType: v as ShiftPayType })
              if (v === ShiftPayType.UNIT) {
                onRateChange('0') // reset to zero
              }
              window.analytics.track(`User Selected Pay Type`, {
                payType: v,
                isEdit,
              })
            },
            optional: false,
            contentExpanded: true,
            hide: false,
          }}
          bookShiftProps={props}
        />
      ) : null}
      <BookShiftsWorkersCountSection {...props} />
      <Divider
        wrapperStyle={{ margin: `${theme.space.lg}px 0`, width: '100%' }}
      />

      {!userHasLimitedShiftManagementAbility && (
        <>
          <Text variant="h5" style={{ marginBottom: theme.space.xs }}>
            {shiftRequest.payType === ShiftPayType.UNIT
              ? 'How much will the pay be per unit?'
              : 'What hourly pay will the workers receive?'}
          </Text>
          <Row alignCenter gap={theme.space.xs}>
            <TextField
              id="payrate"
              type="number"
              style={{
                width: 130,
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Text variant="body1">$</Text>
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <Text variant="body1">
                      {shiftRequest.payType === ShiftPayType.UNIT
                        ? '  / unit'
                        : ' / hr'}
                    </Text>
                  </InputAdornment>
                ),
              }}
              onChange={(e) => onRateChange(e.currentTarget.value)}
              onBlur={onRateBlur}
              value={maskedPayRate}
            />

            {shiftRequest.payType === ShiftPayType.UNIT && payPerWorker ? (
              <Text variant="body2">
                (Estimated ${payPerWorker.toFixed(2)}/worker)
              </Text>
            ) : null}
          </Row>
          {touchedPayRate && payValidationError ? (
            <InlineBanner
              severity="error"
              text={payValidationError.message}
              style={{ marginTop: theme.space.xs }}
            />
          ) : null}
          <Divider
            wrapperStyle={{ margin: `${theme.space.lg}px 0`, width: '100%' }}
          />
        </>
      )}

      <Text variant="h5" style={{ marginBottom: theme.space.xxs }}>
        Which workers would you like to invite?
      </Text>

      <Text variant="body2" style={{ marginBottom: theme.space.xs }}>
        All Traba workers go through a vetting process and background checks.
      </Text>
      <Card
        isClickable
        onClick={() =>
          setShiftRequest({
            forwardFillMax: ForwardFillMax.NONE,
            shiftInvitations: undefined,
          })
        }
      >
        <Row alignCenter fullWidth justifyBetween>
          <Row style={{ marginRight: theme.space.xxs }}>
            <CardTile
              size="56px"
              style={{ minWidth: 56, marginRight: theme.space.xs }}
            >
              <SvgIcon
                name="twoUser"
                size={theme.space.sm}
                color={theme.colors.Grey50}
              />
            </CardTile>
            <Col>
              <Text color={theme.colors.MidnightBlue} variant="h5">
                All
              </Text>
              <Text variant="body2">
                This option will give you access to the largest number of
                workers in your area who fit the requirements of your shift.
              </Text>
            </Col>
          </Row>
          <RadioButton
            selected={!forwardFillMax || forwardFillMax === ForwardFillMax.NONE}
          />
        </Row>
      </Card>
      <BookShiftsInvitationsSection
        setShiftRequest={setShiftRequest}
        shiftRequest={shiftRequest}
        existingShiftInvitations={existingShiftInvitations}
        getWorkerById={getWorkerById}
      />

      {isEdit && editsError && (
        <InlineBanner
          style={{ marginTop: theme.space.xs }}
          severity={'error'}
          text={editsError.message}
        />
      )}
      {company?.allowGenderPreference && (
        <>
          <Divider
            wrapperStyle={{ margin: `${theme.space.lg}px 0`, width: '100%' }}
          />
          <GenderPreferenceSelector
            setSelectedGenderPreference={(gp) => {
              setGenderPreference(gp)
              setShiftRequest({ genderPreference: gp })
            }}
            selectedGenderPreference={genderPreference}
          />
        </>
      )}
      {schedule.isRecurringSchedule &&
        !isEdit &&
        company?.showRequiredMultiShiftToggle &&
        !!hotSettings?.allowRequiredMultiShiftInShiftRequest && (
          <>
            <Divider
              wrapperStyle={{ margin: `${theme.space.lg}px 0`, width: '100%' }}
            />
            <BookShiftsRMSASection
              selection={RMSAEnabled}
              onClick={toggleRMSA}
            />
          </>
        )}
      <Divider
        wrapperStyle={{ margin: `${theme.space.lg}px 0`, width: '100%' }}
      />
      <BookShiftStepSection
        section={{
          title: 'Onsite contact',
          description:
            "Onsite contact will also receive text messages with shift clock in & out codes 30min before shift start time, unless they've opted out of Traba messages.",
          singular: 'contact',
          plural: 'contacts',
          options: members.map((m) => ({
            value: m.uid!,
            label: `${m.firstName} ${m.lastName}`,
          })),
          selected: supervisorId,
          onChange: (v: string) => {
            setShiftRequest({ supervisorId: v })
            window.analytics.track(`User Selected Contact`, {
              supervisorId: v,
              isEdit,
            })
          },
          onCreate: () => {
            setModalType('CONTACT')
            window.analytics.track(`User Clicked Create New Contact`, {
              isEdit,
            })
          },
          optional: false,
          contentExpanded: true,
          hide: false,
          errorMessage:
            'The contact in the previous shift request was archived. Please select a new contact.',
          Content: BookShiftsSiteContactSection,
        }}
        bookShiftProps={props}
      />
      <Row
        style={{ justifyContent: 'space-between', marginTop: theme.space.lg }}
      >
        {isEdit ? (
          <EditShiftsSaveButton
            onSaveChanges={onSaveChanges}
            validationError={editsError || validationError}
            selectedShifts={selectedShifts}
            setShowSelectModal={setShowSelectModal}
          />
        ) : (
          <>
            <Button onClick={onPrevious} variant={ButtonVariant.OUTLINED}>
              Previous
            </Button>
            <Button onClick={onContinue} disabled={!!validationError}>
              Continue
            </Button>
          </>
        )}
      </Row>
      <Modal isOpen={modalType !== null} handleClose={closeModal}>
        {modalType === 'ROLE' || modalType === 'ROLE_PREVIOUS' ? (
          <CreateRoleModal
            onClose={closeModal}
            onCreate={(r) =>
              setShiftRequest({
                roleId: r.roleId,
                payRate: r.defaultPayRate,
                genderPreference: r.genderPreference,
              })
            }
            isEventCompany={isEventCompany}
            fromPrevious={modalType === 'ROLE_PREVIOUS'}
          />
        ) : modalType === 'CONTACT' ? (
          <CreateMemberModal
            setShowModal={closeModal}
            onCreate={(c) => setShiftRequest({ supervisorId: c.uid })}
          />
        ) : null}
      </Modal>
    </>
  )
}
