import { Fade, Popper } from '@mui/material'
import { MODAL_SIZE } from '@traba/react-components'
import { ShiftStatus } from '@traba/types'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Col, Row } from 'src/components/base'
import { Dialog } from 'src/components/base/Dialog/Dialog'
import {
  RegionalFilterStatus,
  useAppContext,
} from 'src/context/appContext/AppContext'
import useMobile from 'src/hooks/useMobile'
import useCalendarFilters from 'src/hooks/useShiftFilters.hooks'
import { ShiftAndAddress, useShifts } from 'src/hooks/useShifts'
import { useNavigateToTimeSheetDailyView } from 'src/hooks/useTimesheet'
import { useUserPermissions } from 'src/hooks/useUser'
import { theme } from 'src/libs/theme'
import { CalendarFilters } from 'src/screens/Calendar/CalendarFilters'
import { CalendarFindWorker } from 'src/screens/Calendar/CalendarFindWorker'
import { CalendarMobileWidget } from 'src/screens/Calendar/CalendarMobileWidget'
import { CalendarShiftDetails } from 'src/screens/Calendar/CalendarShiftDetails'
import { CalendarView } from 'src/screens/Calendar/CalendarView'
import { UserRolePermission } from 'src/types'
import { getCalendarMonthDisplayRange } from 'src/utils/calendarUtils'

interface ScheduleDetailsShiftCalendarProps {
  shiftRequestParentId: string
}

export const ScheduleDetailsShiftCalendar = (
  props: ScheduleDetailsShiftCalendarProps,
) => {
  const { shiftRequestParentId } = props
  const { isMobileViewOrReactNative } = useMobile()
  const navigate = useNavigate()
  const userCanViewWages = useUserPermissions([UserRolePermission.ViewPay])
  const [selectedShift, setSelectedShift] = useState<
    ShiftAndAddress | undefined
  >()
  const isPastShift = selectedShift && new Date() > selectedShift.endTime
  const navigateToTimeSheetDailyView = useNavigateToTimeSheetDailyView()

  const currDate = new Date()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [endAfter, setEndAfter] = useState<Date>(
    getCalendarMonthDisplayRange(currDate)[0],
  )
  const [startBefore, setStartBefore] = useState<Date>(
    getCalendarMonthDisplayRange(currDate)[1],
  )
  const [filters, dispatch] = useCalendarFilters({
    regionId: '',
    supervisorId: '',
    locationId: '',
    statuses: [ShiftStatus.ACTIVE, ShiftStatus.COMPLETE],
  })

  const { data: shifts, isLoading } = useShifts(
    {
      ...filters,
      startBefore,
      endAfter,
      shiftRequestParentIds: [shiftRequestParentId],
    },
    undefined,
    true,
  )

  //prefetch for previous month
  useShifts(
    {
      ...filters,
      startBefore: getCalendarMonthDisplayRange(endAfter)[1],
      endAfter: getCalendarMonthDisplayRange(endAfter)[0],
      shiftRequestParentIds: [shiftRequestParentId],
    },
    undefined,
    true,
  )
  //prefetch for next month
  useShifts(
    {
      ...filters,
      startBefore: getCalendarMonthDisplayRange(startBefore)[1],
      endAfter: getCalendarMonthDisplayRange(startBefore)[0],
      shiftRequestParentIds: [shiftRequestParentId],
    },
    undefined,
    true,
  )
  const { dispatch: appContextDispatch } = useAppContext()

  useEffect(() => {
    appContextDispatch({
      type: 'SET_REGIONAL_FILTER_STATUS',
      value: RegionalFilterStatus.ENABLED,
    })
    return () => {
      appContextDispatch({
        type: 'SET_REGIONAL_FILTER_STATUS',
        value: RegionalFilterStatus.HIDE,
      })
    }
  }, [appContextDispatch])

  return (
    <>
      <Row fullWidth justifyBetween mt={theme.space.sm}>
        <div
          style={{
            minWidth: isMobileViewOrReactNative ? '100%' : '80%',
            height: '100%',
          }}
        >
          <CalendarView
            isFromScheduleDetails
            isLoading={isLoading}
            setSelectedShift={setSelectedShift}
            setAnchorEl={setAnchorEl}
            selectedShift={selectedShift}
            shifts={shifts ?? []}
            endAfter={endAfter}
            startBefore={startBefore}
            setEndAfter={setEndAfter}
            setStartBefore={setStartBefore}
          />
        </div>
        {!isMobileViewOrReactNative && (
          <Col ml={theme.space.lg} mt={150} width={250}>
            <CalendarFindWorker dispatch={dispatch} />
            <CalendarFilters dispatch={dispatch} filters={filters} />
          </Col>
        )}
      </Row>
      {selectedShift && isMobileViewOrReactNative && (
        <Dialog
          open={!!selectedShift}
          onClose={() => setSelectedShift(undefined)}
          style={{ display: 'flex', flexDirection: 'column' }}
          size={MODAL_SIZE.SMALL}
          dialogTitle={selectedShift.shiftRole}
          onConfirmCTA={
            isPastShift ? 'Go to shift timesheets' : 'Go to shift details'
          }
          onConfirm={() => {
            if (isPastShift) {
              navigateToTimeSheetDailyView(
                selectedShift.shiftId,
                selectedShift.businessStartTime ?? selectedShift.startTime,
              )
            } else {
              navigate(`/calendar/${selectedShift.shiftId}`)
            }
          }}
        >
          <CalendarShiftDetails
            isMobileView={isMobileViewOrReactNative}
            shift={selectedShift}
            setSelectedShift={setSelectedShift}
            userCanViewWages={userCanViewWages}
          />
        </Dialog>
      )}
      {selectedShift && !isMobileViewOrReactNative && (
        <Popper
          anchorEl={anchorEl}
          open={!!anchorEl}
          style={{
            borderRadius: 10,
            boxShadow: `0px 0px 10px 0px ${theme.colors.Violet30}`,
            backgroundColor: theme.colors.White,
            overflow: selectedShift ? 'hidden' : 'auto',
            paddingRight: theme.space.xxs,
            paddingLeft: theme.space.xxs,
            paddingTop: theme.space.xs,
            zIndex: 100,
          }}
          placement="auto"
          transition
          modifiers={[
            {
              name: 'offset',
              options: {
                offset: [0, theme.space.xs],
              },
            },
          ]}
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} in={!!selectedShift} timeout={300}>
              {/* NOTE: You have to have a div directly under Fade, it's a material ui bug */}
              <div>
                <CalendarShiftDetails
                  isMobileView={isMobileViewOrReactNative}
                  shift={selectedShift}
                  setSelectedShift={setSelectedShift}
                  userCanViewWages={userCanViewWages}
                />
              </div>
            </Fade>
          )}
        </Popper>
      )}
      {isMobileViewOrReactNative && (
        <CalendarMobileWidget
          shifts={shifts}
          isLoading={isLoading}
          dispatch={dispatch}
          filters={filters}
          isFromCalendarScreen={true}
        />
      )}
    </>
  )
}
