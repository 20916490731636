import { InputAdornment } from '@mui/material'
import TextField from '@mui/material/TextField/TextField'
import { Card, CardTile } from '@traba/react-components'
import {
  ForwardFillMax,
  GenderPreference,
  InvitedWorkers,
  ShiftPayType,
} from '@traba/types'
import { addHours, isAfter } from 'date-fns'
import { useEffect, useState } from 'react'
import {
  Button,
  ButtonVariant,
  Col,
  Divider,
  InlineBanner,
  Row,
  SvgIcon,
  Text,
} from 'src/components'
import { IMenuItem } from 'src/components/base/Select/Select'
import { GenderPreferenceSelector } from 'src/components/GenderPreferenceSelector'
import { RadioButton } from 'src/components/RadioButton'
import { useCompany } from 'src/hooks/useCompany'
import { useConnections } from 'src/hooks/useConnections'
import { useHotSettings } from 'src/hooks/useSystem'
import { theme } from 'src/libs/theme'
import { BookShiftsInvitationsSection } from 'src/screens/BookShifts/steps/sections/BookShiftsInvitationsSection'
import EditShiftsSaveButton from 'src/screens/EditShifts/EditShiftsSaveButton'
import { calculateSingleWorkerPay } from 'src/utils/moneyUtils'
import { BookShiftsProps } from '../BookShiftsScreen'
import { BookShiftStepSection } from '../components/BookShiftSection'
import {
  getMinHourlyPayRate,
  MIN_WORKER_HOURLY_PAY_DEFAULT,
  validatePayRate,
  validateShiftEdits,
  validateWorkersStep,
} from '../validation'
import { BookShiftsWorkersCountSection } from './sections/BookShiftsWorkerCountSection'
import { BookShiftsWorkerVideosSection } from './sections/BookShiftsWorkerVideosSection'

export function BookShiftsWorkersContent(props: BookShiftsProps) {
  const {
    shiftRequest,
    setShiftRequest,
    onContinue,
    onPrevious,
    onSaveChanges,
    selectedShifts,
    setShowSelectModal,
    shiftUpdates,
    isEdit,
    defaultShiftRequest,
    userHasLimitedShiftManagementAbility,
    existingShiftInvitations,
    getWorkerById,
  } = props
  const { invitedWorkers, forwardFillMax, payRate, roleId } = shiftRequest
  const schedule = shiftRequest.schedules[0]
  const { company } = useCompany()
  const { hotSettings } = useHotSettings()

  const [maskedPayRate, setMaskedPayRate] = useState(payRate.toFixed(2))
  const [touchedPayRate, setTouchedPayRate] = useState(false)
  const [genderPreference, setGenderPreference] = useState<
    GenderPreference | undefined
  >(shiftRequest.genderPreference || undefined)

  const onRateChange = (masked: string) => {
    const payRate = +Math.abs(+masked).toFixed(2)
    setMaskedPayRate(masked)
    setShiftRequest({ payRate })
  }

  const onRateBlur = () => {
    setMaskedPayRate(payRate.toFixed(2))
    setTouchedPayRate(true)
    window.analytics.track(`User Set Pay Rate`, {
      payRate: payRate,
      isEdit,
    })
  }

  useEffect(() => {
    setMaskedPayRate(payRate.toFixed(2))
    setGenderPreference(shiftRequest.genderPreference || undefined)
  }, [roleId])

  const { favoriteWorkers } = useConnections()
  const noFavorites = favoriteWorkers.length === 0
  const tooLateForFavoritesFirst =
    !schedule.isRecurringSchedule &&
    isAfter(addHours(new Date(), 48), schedule.startTime)
  const favoritesFirstDisabled = noFavorites || tooLateForFavoritesFirst

  // When there are no favorites, force invitedWorkers to all.
  useEffect(() => {
    if (
      favoritesFirstDisabled &&
      invitedWorkers === InvitedWorkers.FAVORITES_FIRST
    ) {
      setShiftRequest({ invitedWorkers: InvitedWorkers.ALL })
    }
  }, [favoritesFirstDisabled, invitedWorkers, setShiftRequest])

  const minHourlyPay = getMinHourlyPayRate({
    companyMinHourlyPay: company?.minHourlyPayRate,
    platformMinHourlyPay:
      hotSettings?.platformMinHourlyPayRate ?? MIN_WORKER_HOURLY_PAY_DEFAULT,
  })
  const validationError = validateWorkersStep(
    shiftRequest,
    minHourlyPay,
    existingShiftInvitations,
  )
  const editsError =
    selectedShifts &&
    defaultShiftRequest &&
    validateShiftEdits(defaultShiftRequest, selectedShifts, shiftUpdates)
  // Only relevant for PBU shifts
  const payPerWorker =
    shiftRequest.payType === ShiftPayType.UNIT &&
    shiftRequest.payRate &&
    shiftRequest.numberOfUnits &&
    shiftRequest.slotsRequested
      ? calculateSingleWorkerPay(
          {
            ...shiftRequest,
            ...shiftRequest.schedules[0],
            payRate: shiftRequest.payRate,
            payType: shiftRequest.payType,
            numberOfUnits: shiftRequest.numberOfUnits || 0,
          },
          undefined,
        ).amount / 100
      : 0
  const payValidationError = validatePayRate(shiftRequest, minHourlyPay)
  const payTypeOptions: IMenuItem<ShiftPayType>[] = [
    {
      label: 'Hourly',
      value: ShiftPayType.HOURLY,
    },
    {
      label: 'By Unit',
      value: ShiftPayType.UNIT,
    },
  ]

  return (
    <>
      {company &&
      company.allowPostingPayByUnit &&
      !userHasLimitedShiftManagementAbility ? (
        <BookShiftStepSection
          section={{
            title: 'Pay Type',
            subtitle: 'How would you like your workers to be compensated?',
            singular: 'pay type',
            plural: 'pay types',
            options: payTypeOptions,
            selected: props.shiftRequest.payType,
            onChange: (v) => {
              setShiftRequest({ payType: v as ShiftPayType })
              if (v === ShiftPayType.UNIT) {
                onRateChange('0') // reset to zero
              }
              window.analytics.track(`User Selected Pay Type`, {
                payType: v,
                isEdit,
              })
            },
            optional: false,
            contentExpanded: true,
            hide: false,
          }}
          bookShiftProps={props}
        />
      ) : null}
      <BookShiftsWorkersCountSection {...props} />
      <Divider
        wrapperStyle={{ margin: `${theme.space.lg}px 0`, width: '100%' }}
      />

      {!userHasLimitedShiftManagementAbility && (
        <>
          <Text variant="h5" style={{ marginBottom: theme.space.xs }}>
            {shiftRequest.payType === ShiftPayType.UNIT
              ? 'How much will the pay be per unit?'
              : 'What hourly pay will the workers receive?'}
          </Text>
          <Row alignCenter gap={theme.space.xs}>
            <TextField
              id="payrate"
              type="number"
              style={{
                width: 130,
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Text variant="body1">$</Text>
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <Text variant="body1">
                      {shiftRequest.payType === ShiftPayType.UNIT
                        ? '  / unit'
                        : ' / hr'}
                    </Text>
                  </InputAdornment>
                ),
              }}
              onChange={(e) => onRateChange(e.currentTarget.value)}
              onBlur={onRateBlur}
              value={maskedPayRate}
            />

            {shiftRequest.payType === ShiftPayType.UNIT && payPerWorker ? (
              <Text variant="body2">
                (Estimated ${payPerWorker.toFixed(2)}/worker)
              </Text>
            ) : null}
          </Row>
          {touchedPayRate && payValidationError ? (
            <InlineBanner
              severity="error"
              text={payValidationError.message}
              style={{ marginTop: theme.space.xs }}
            />
          ) : null}
          <Divider
            wrapperStyle={{ margin: `${theme.space.lg}px 0`, width: '100%' }}
          />
        </>
      )}

      <Text variant="h5" style={{ marginBottom: theme.space.xxs }}>
        Which workers would you like to invite?
      </Text>

      <Text variant="body2" style={{ marginBottom: theme.space.xs }}>
        All Traba workers go through a vetting process and background checks.
      </Text>
      <Card
        isClickable
        onClick={() =>
          setShiftRequest({
            forwardFillMax: ForwardFillMax.NONE,
            shiftInvitations: undefined,
          })
        }
      >
        <Row alignCenter fullWidth justifyBetween>
          <Row style={{ marginRight: theme.space.xxs }}>
            <CardTile
              size="56px"
              style={{ minWidth: 56, marginRight: theme.space.xs }}
            >
              <SvgIcon
                name="twoUser"
                size={theme.space.sm}
                color={theme.colors.Grey50}
              />
            </CardTile>
            <Col>
              <Text color={theme.colors.MidnightBlue} variant="h5">
                All
              </Text>
              <Text variant="body2">
                This option will give you access to the largest number of
                workers in your area who fit the requirements of your shift.
              </Text>
            </Col>
          </Row>
          <RadioButton
            selected={!forwardFillMax || forwardFillMax === ForwardFillMax.NONE}
          />
        </Row>
      </Card>
      <BookShiftsInvitationsSection
        setShiftRequest={setShiftRequest}
        shiftRequest={shiftRequest}
        existingShiftInvitations={existingShiftInvitations}
        getWorkerById={getWorkerById}
      />

      {isEdit && editsError && (
        <InlineBanner
          style={{ marginTop: theme.space.xs }}
          severity={'error'}
          text={editsError.message}
        />
      )}
      {company?.allowGenderPreference && (
        <>
          <Divider
            wrapperStyle={{ margin: `${theme.space.lg}px 0`, width: '100%' }}
          />
          <GenderPreferenceSelector
            setSelectedGenderPreference={(gp) => {
              setGenderPreference(gp)
              setShiftRequest({ genderPreference: gp })
            }}
            selectedGenderPreference={genderPreference}
          />
        </>
      )}
      <Divider
        wrapperStyle={{ margin: `${theme.space.lg}px 0`, width: '100%' }}
      />
      <BookShiftsWorkerVideosSection {...props} />
      <Row
        style={{ justifyContent: 'space-between', marginTop: theme.space.lg }}
      >
        {isEdit ? (
          <EditShiftsSaveButton
            onSaveChanges={onSaveChanges}
            validationError={editsError || validationError}
            selectedShifts={selectedShifts}
            setShowSelectModal={setShowSelectModal}
          />
        ) : (
          <>
            <Button onClick={onPrevious} variant={ButtonVariant.OUTLINED}>
              Previous
            </Button>
            <Button onClick={onContinue} disabled={!!validationError}>
              Continue
            </Button>
          </>
        )}
      </Row>
    </>
  )
}
