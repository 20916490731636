import { AutocompleteRenderInputParams, Chip, TextField } from '@mui/material'
import {
  Autocomplete as MuiAutocomplete,
  AutocompleteGetTagProps,
  FilterOptionsState,
} from '@mui/material'
import React, { HTMLProps, useEffect, useState } from 'react'
import { theme } from 'src/libs/theme'

import { ButtonVariant } from '../Button'
import { InputErrorIcon, InputErrorMessage } from '../Input/Input.styles'
import SvgIcon from '../SvgIcon'
import * as S from './Autocomplete.styles'

export const containsText = (text: string, searchText: string) =>
  text.toLowerCase().indexOf(searchText.toLowerCase()) > -1

export interface AutocompleteProps {
  label: string
  options: string[]
  value: string[]
  errorMessage?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChangeValues: (event: React.ChangeEvent<any>, value: string[]) => void
  validateInput: (str: string) => string | undefined
  fullWidth?: boolean
  style?: HTMLProps<HTMLDivElement>['style']
}

export const Autocomplete: React.FC<AutocompleteProps> = (
  props: AutocompleteProps,
) => {
  const [input, setInput] = useState('')
  const [inputValid, setInputValid] = useState(true)
  const [showError, setShowError] = useState(false)
  const { options, value, label, errorMessage, onChangeValues, validateInput } =
    props

  useEffect(() => {
    try {
      validateInput(input)
      setInputValid(true)
    } catch (e) {
      setInputValid(false)
    }
  }, [input, validateInput, setInputValid])

  const validate = (value?: string) => {
    try {
      validateInput(input)
      setInputValid(true)
      value !== undefined && setInput(value)
    } catch (e) {
      setInputValid(false)
      setShowError(true)
      value !== undefined && setInput(input)
    }
  }

  const onFocus = () => {
    if (!inputValid) {
      setShowError(false)
    }
  }

  const onBlur = (e: React.SyntheticEvent<Element, Event>) => {
    if (!inputValid) {
      setShowError(true)
    } else if (!!input && inputValid) {
      onChangeValues(e, [...value, input])
      setInput('')
    }
  }

  const filterOptions = (
    options: string[],
    state: FilterOptionsState<string>,
  ) =>
    options.filter(
      (o) => !value.includes(o) && containsText(o, state.inputValue),
    )

  const renderTags = (value: string[], getTagProps: AutocompleteGetTagProps) =>
    value.map((option: string, index: number) => (
      <Chip
        variant={ButtonVariant.OUTLINED}
        label={option}
        {...getTagProps({ index })}
      />
    ))

  const renderInput = (params: AutocompleteRenderInputParams) => (
    <TextField {...params} label={label} />
  )

  return (
    <>
      <S.SearchBoxStyling />
      <MuiAutocomplete
        style={props.style}
        onFocus={onFocus}
        onBlur={onBlur}
        fullWidth={props.fullWidth}
        multiple
        freeSolo
        filterSelectedOptions
        options={options}
        value={value}
        inputValue={input}
        clearText={input && inputValid ? 'Add' : 'Clear'}
        onInputChange={(event, value: string) => {
          setShowError(false)
          if (event) {
            if (event.type === 'keydown') {
              const nativeEvent = event.nativeEvent as KeyboardEvent
              if (nativeEvent.key === 'Enter') {
                validate(value)
              }
            } else {
              setInput(value)
            }
          }
        }}
        onChange={onChangeValues}
        filterOptions={filterOptions}
        renderTags={renderTags}
        renderInput={renderInput}
      />
      {!inputValid && showError && errorMessage ? (
        <InputErrorMessage style={{ display: 'flex', alignItems: 'center' }}>
          <InputErrorIcon>
            <SvgIcon name="alert" color={theme.colors.Red60} />
          </InputErrorIcon>
          {errorMessage}
        </InputErrorMessage>
      ) : null}
    </>
  )
}
