import { InvoiceDisclaimerAnchor } from './InvoiceDisclaimerText.styles'

const InvoiceDisclaimerText = () => {
  return (
    <>
      <br />
      Invoices not paid by their due date will be subject to{' '}
      <InvoiceDisclaimerAnchor href="https://www.traba.work/customer-agreement">
        late fees of 1.5% per month.
      </InvoiceDisclaimerAnchor>{' '}
    </>
  )
}

export default InvoiceDisclaimerText
