import { isDebugEnv } from 'src/config'
import { useQueryParams } from 'src/helpers'

import useMobile from 'src/hooks/useMobile'
import Anchor from '../Anchor'

export function SeeInOpsConsoleAnchor(props: { linkSuffix: string }) {
  const queryParams = useQueryParams()
  const { isReactNativeApp } = useMobile()
  const isDebugMode = isDebugEnv(queryParams)

  return isDebugMode && !isReactNativeApp ? (
    <Anchor
      target="_blank"
      href={`https://devconsole.traba.work/${props.linkSuffix}`}
    >
      {/* TODOJIKE: Make it work on mobile */}
      See in Ops Console
    </Anchor>
  ) : null
}
