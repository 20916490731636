import axios from 'axios'
import { getAuth } from 'firebase/auth'
import { handleDates } from 'src/shared/utils/dateUtils'

export const BACKEND_URL = import.meta.env.VITE_BACKEND_URL

export let firebaseToken: string | undefined

export const trabaApi = axios.create({
  baseURL: BACKEND_URL,
})

trabaApi.interceptors.request.use(
  async (config) => {
    const auth = getAuth()
    firebaseToken = await auth.currentUser?.getIdToken()

    config.headers['Authorization'] = `Bearer ${firebaseToken}`
    config.headers['Accept'] = 'application/json'
    config.headers['Content-Type'] = 'application/json'

    return config
  },
  (error) => {
    Promise.reject(error)
  },
)

trabaApi.interceptors.response.use(
  (originalResponse) => {
    handleDates(originalResponse.data)
    return originalResponse
  },
  async (error) => {
    if (error.response) {
      if (error.response.status === 401 && !error.config._retry) {
        const auth = getAuth()
        const newToken = await auth.currentUser?.getIdToken(true)

        // Update the auth token in the original request headers
        error.config.headers['Authorization'] = `Bearer ${newToken}`
        error.config._retry = true

        return trabaApi(error.config)
      }
      console.error(error.response.data)
      throw error.response.data
    } else if (error.request) {
      console.error(error.request)
      throw error.request
    } else {
      console.error('Error', error.message)
      throw error
    }
  },
)
