import { FileType } from '@traba/hooks'
import { LoadingSpinner } from '@traba/react-components'
import {
  GenderPreference,
  RoleAttribute,
  RoleAttributeStatus,
  ShiftAttribute,
} from '@traba/types'
import { useFormik } from 'formik'
import { startCase } from 'lodash'
import { useEffect, useState } from 'react'
import { Button, ButtonVariant, Modal, Text } from 'src/components/base'
import { Input, Row } from 'src/components/base'
import { useRoleAttributes } from 'src/hooks/useAttributes'
import { useCertifications } from 'src/hooks/useCertifications'
import { useCompany } from 'src/hooks/useCompany'
import { PAY_RATE_DEFAULT } from 'src/hooks/useShiftRequests'
import { useHotSettings } from 'src/hooks/useSystem'
import { useTrainingVideos } from 'src/hooks/useTrainingVideos'
import { REQUIRED_FIELD_MESSAGE } from 'src/libs/constants'
import { theme } from 'src/libs/theme'
import {
  getMinHourlyPayRate,
  MIN_WORKER_HOURLY_PAY_DEFAULT,
} from 'src/screens/BookShifts/validation'
import { CreateRoleData } from 'src/types'
import * as yup from 'yup'

import { Checkbox } from '../base/CheckboxThemed'
import { InlineMessage } from '../base/InlineBanner'
import SelectableCard from '../base/SelectableCard'
import { GenderPreferenceSelector } from '../GenderPreferenceSelector'
import { UploadVideoModal } from '../Modals/UploadVideoModal'
import { DefaultPayRateComponent } from '../RoleStepper'

interface RoleFormProps {
  onCancel: () => void
  onConfirm: (role: CreateRoleData) => void
  submitLoading: boolean
  initialRoleState?: CreateRoleData
  isEdit: boolean
}

export function RoleForm(props: RoleFormProps) {
  const { onCancel, onConfirm, submitLoading, initialRoleState, isEdit } = props
  const { roleAttributes } = useRoleAttributes(RoleAttributeStatus.Active)
  const { certifications } = useCertifications()
  const { hotSettings } = useHotSettings()
  const { company } = useCompany()
  const [requiredAttributes, setRequiredAttributes] = useState<
    ShiftAttribute[]
  >(initialRoleState?.requiredAttributes ?? [])
  const [requiredCertifications, setRequiredCertifications] = useState<
    string[]
  >(initialRoleState?.requiredCertifications ?? [])
  const { trainingVideos, requiredForAllTrainingVideos } = useTrainingVideos()
  const [selectedVideoIds, setSelectedVideoIds] = useState<string[]>([])
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [genderPreference, setGenderPreference] = useState<
    GenderPreference | undefined
  >(initialRoleState?.genderPreference || undefined)
  const minPayRate = getMinHourlyPayRate({
    companyMinHourlyPay: company?.minHourlyPayRate,
    platformMinHourlyPay:
      hotSettings?.platformMinHourlyPayRate ?? MIN_WORKER_HOURLY_PAY_DEFAULT,
  })

  const shouldShowGenderSelection = company?.allowGenderPreference

  useEffect(() => {
    const preSelectedVideoIds = trainingVideos
      .filter((tv) => tv.requiredForAll)
      .map((tv) => tv.id)
    setSelectedVideoIds(
      Array.from(
        new Set([
          ...preSelectedVideoIds,
          ...(initialRoleState?.videoIds ?? []),
        ]),
      ),
    )
  }, [trainingVideos, initialRoleState?.videoIds])

  function openModal() {
    setIsModalOpen(true)
  }

  function closeModal() {
    setIsModalOpen(false)
  }
  function onUploadSuccess(videoId: string) {
    setSelectedVideoIds([...selectedVideoIds, videoId])
  }

  function onClickCertification(key: string, selected: boolean) {
    const existingCertifications: string[] = requiredCertifications
    const updatedCertifications = !selected
      ? [...existingCertifications, key]
      : existingCertifications.filter((c) => c !== key)
    setRequiredCertifications(updatedCertifications)
  }

  function onClickAttribute(attribute: RoleAttribute, selected: boolean) {
    const { type, category } = attribute
    const existingAttributes: ShiftAttribute[] = requiredAttributes
    const updatedAttributes = !selected
      ? [...existingAttributes, { type, category }]
      : existingAttributes.filter((a) => a.type !== type)
    setRequiredAttributes(updatedAttributes)
  }

  const formik = useFormik({
    initialValues: {
      roleName: initialRoleState?.roleName ?? '',
      requiredAttire: initialRoleState?.requiredAttire ?? '',
      roleDescription: initialRoleState?.roleDescription ?? '',
      defaultPayRate: initialRoleState?.defaultPayRate ?? PAY_RATE_DEFAULT,
    },
    validationSchema: yup.object({
      roleName: yup.string().required(REQUIRED_FIELD_MESSAGE),
      requiredAttire: yup.string().required(REQUIRED_FIELD_MESSAGE),
      roleDescription: yup.string().required(REQUIRED_FIELD_MESSAGE),
      defaultPayRate: yup
        .number()
        .required(REQUIRED_FIELD_MESSAGE)
        .min(minPayRate),
    }),
    onSubmit: (values) => {
      onConfirm({
        ...values,
        requiredAttributes,
        requiredCertifications,
        videoIds: selectedVideoIds,
        genderPreference:
          genderPreference === undefined ? null : genderPreference,
      })
    },
  })
  const { values, errors, touched } = formik
  return !roleAttributes || !certifications ? (
    <div
      style={{
        height: 300,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <LoadingSpinner />
    </div>
  ) : (
    <form onSubmit={formik.handleSubmit}>
      <Text variant="h5">Role details</Text>
      <Input
        label="Role name"
        name="roleName"
        width="100%"
        value={values.roleName}
        onChange={formik.handleChange}
        containerStyle={{ marginTop: theme.space.xs }}
        inputStatus={touched.roleName && errors.roleName ? 3 : 1}
        errorMessage={errors.roleName}
      />
      <Input
        label="Required attire"
        name="requiredAttire"
        width="100%"
        value={values.requiredAttire}
        onChange={formik.handleChange}
        containerStyle={{ marginTop: theme.space.xs }}
        inputStatus={touched.requiredAttire && errors.requiredAttire ? 3 : 1}
        errorMessage={errors.requiredAttire}
      />
      <Input
        label="Role description"
        name="roleDescription"
        rows={4}
        type="textarea"
        defaultValue=""
        width="100%"
        value={values.roleDescription}
        onChange={formik.handleChange}
        containerStyle={{ marginTop: theme.space.xs }}
        inputStatus={touched.roleDescription && errors.roleDescription ? 3 : 1}
        errorMessage={errors.roleDescription}
      />
      <InlineMessage text="Share all the important details so workers are prepared when they arrive." />
      <DefaultPayRateComponent
        defaultPayRate={values.defaultPayRate}
        setDefaultPayRate={(defaultPayRate) =>
          formik.setFieldValue('defaultPayRate', defaultPayRate)
        }
        minHourlyPayRate={minPayRate}
      />
      <Text
        variant="h5"
        style={{ marginTop: theme.space.sm, marginBottom: theme.space.xs }}
      >
        Requirements ({requiredAttributes.length})
      </Text>
      {!!roleAttributes && (
        <Row wrap style={{ gap: theme.space.xxs }}>
          {roleAttributes.map((attribute) => {
            const selected = !!requiredAttributes
              ?.map((attribute: ShiftAttribute) => attribute.type)
              .includes(attribute.type)
            return (
              <SelectableCard
                key={attribute.type}
                label={startCase(attribute?.displayName)}
                onClick={() => onClickAttribute(attribute, selected)}
                selected={selected}
              />
            )
          })}
        </Row>
      )}

      <Text
        variant="h5"
        style={{ marginTop: theme.space.med, marginBottom: theme.space.xs }}
      >
        Certifications ({requiredCertifications.length})
      </Text>
      <Row wrap style={{ gap: theme.space.xxs }}>
        {certifications?.map((c) => {
          const selected = !!requiredCertifications?.includes(c.type)
          return (
            <SelectableCard
              key={c.type}
              label={c.name}
              onClick={() => onClickCertification(c.type, selected)}
              selected={selected}
            />
          )
        })}
      </Row>
      {shouldShowGenderSelection && (
        <GenderPreferenceSelector
          selectedGenderPreference={genderPreference}
          setSelectedGenderPreference={setGenderPreference}
          title="Is there a preferred gender for this role?"
        />
      )}
      <Text
        variant="h5"
        style={{ marginTop: theme.space.med, marginBottom: theme.space.xs }}
      >
        What training videos are required?
        <Text
          style={{ marginLeft: theme.space.xxs }}
          variant="linkUnderline"
          onClick={() => {
            openModal()
          }}
        >
          Upload new video
        </Text>
      </Text>
      {trainingVideos.map((tv) => {
        const isRequired =
          requiredForAllTrainingVideos?.findIndex((v) => v.id === tv.id) !== -1
        return (
          <Row my={theme.space.xxs}>
            <Checkbox
              key={tv.id}
              disabled={isRequired}
              selected={selectedVideoIds.includes(tv.id)}
              onClick={() => {
                if (isRequired) {
                  return
                }
                const idx = selectedVideoIds.findIndex(
                  (videoId) => videoId === tv.id,
                )
                const newVideoIds = [...selectedVideoIds]
                if (idx === -1) {
                  newVideoIds.push(tv.id)
                } else {
                  newVideoIds.splice(idx, 1)
                }
                setSelectedVideoIds(newVideoIds)
              }}
            />
            <Text variant="body1" style={{ marginLeft: theme.space.xs }}>
              {tv.name}
            </Text>
          </Row>
        )
      })}
      <Modal isOpen={isModalOpen} handleClose={closeModal}>
        <UploadVideoModal
          onClose={closeModal}
          onUploadSuccess={onUploadSuccess}
          fileType={FileType.TRAINING_VIDEOS}
        />
      </Modal>
      <Row justifyBetween style={{ marginTop: theme.space.sm }}>
        <Button
          type="button"
          variant={ButtonVariant.OUTLINED}
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button variant={ButtonVariant.FILLED} loading={submitLoading}>
          {isEdit ? 'Save' : 'Create'}
        </Button>
      </Row>
    </form>
  )
}
