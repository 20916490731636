import { useMutation } from '@tanstack/react-query'
import { useAlert } from '@traba/context'
import { AxiosResponse } from 'axios'
import { subDays } from 'date-fns'
import { useState } from 'react'
import { trabaApi } from 'src/api/helpers'
import { WorkerShiftsForTimesheetsProps } from 'src/hooks/useTimesheet'
import { openInNewTab } from 'src/utils/miscUtils'

export enum TimePeriod {
  LAST_7_DAYS = 'LAST_7_DAYS',
  LAST_2_WEEKS = 'LAST_2_WEEKS',
  LAST_MONTH = 'LAST_MONTH',
  LAST_YEAR = 'LAST_YEAR',
  CUSTOM = 'CUSTOM',
}

// TODO(TanzHasan) - 10173: completely delete this hook
export const useDownloadReport = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [selectedPeriod, setSelectedPeriod] = useState(TimePeriod.LAST_7_DAYS)
  const [timePeriod, setTimePeriod] = useState<[Date | null, Date | null]>([
    subDays(new Date(), 7),
    new Date(),
  ])
  const [supervisorId, setSupervisorId] = useState<string>('all')
  const [regionId, setRegionId] = useState<string>('all')

  const [error, setError] = useState<string>()

  return {
    isLoading,
    selectedPeriod,
    setSelectedPeriod,
    timePeriod,
    setTimePeriod,
    supervisorId,
    setSupervisorId,
    regionId,
    setRegionId,
    error,
    setIsLoading,
    setError,
  }
}

export function useDownloadReportMutation() {
  const { showError } = useAlert()

  const mutation = useMutation<
    AxiosResponse<string, string>,
    Error,
    WorkerShiftsForTimesheetsProps
  >({
    mutationFn: (query: WorkerShiftsForTimesheetsProps) => {
      return trabaApi.put(`/my-company/workers-report-updated`, {
        query,
      })
    },
    onSuccess: (response: AxiosResponse<string, string>) => {
      const link = response.data
      if (link) {
        openInNewTab(link)
        return
      }
      throw new Error('There are no worker shifts for the period you selected')
    },
    onError: (err) => {
      showError(
        `There was an error downloading the timesheets report: ${err.message}`,
      )
    },
  })

  const mutationFnWrapper = (payload: WorkerShiftsForTimesheetsProps) =>
    mutation.mutate(payload)

  return {
    mutate: mutationFnWrapper,
    isPending: mutation.isPending,
    isError: mutation.isError,
    isSuccess: mutation.isSuccess,
  }
}
