import {
  ChatArchivalStatus,
  ChatMessageContent,
  ChatReadStatus,
  ChatSummariesResponse,
  ChatSummary,
  UpdateChatRequest,
} from '@traba/types'
import { useQuery } from 'react-query'
import { trabaApi } from 'src/api/helpers'
import { useAppContext } from 'src/context/appContext/AppContext'
import { useHotSettings } from './useSystem'

const getChats = async (
  archivalStatus?: ChatArchivalStatus,
  startTime?: Date,
) => {
  try {
    let path = archivalStatus
      ? `/my-company/chats?archivalStatus=${archivalStatus}`
      : `/my-company/chats?archivalStatus=${ChatArchivalStatus.NONE}`

    if (startTime) {
      path += `&startTime=${startTime}`
    }
    const response = await trabaApi.get(path)
    return response.data
  } catch (error) {
    console.error(error)
  }
}

export const postChats = async (
  request: ChatMessageContent,
  workerIds: string[],
) => {
  const response = await trabaApi.post(
    `my-company/chats?workerIds=${workerIds}`,
    request,
  )
  return response.data
}

export const updateChat = async (
  request: UpdateChatRequest,
  summaryIds: string[],
) => {
  const response = await trabaApi.patch(
    `my-company/chats?summaryIds=${summaryIds}`,
    request,
  )
  return response.data
}

export const useChats = (
  archivalStatus?: ChatArchivalStatus,
  startTime?: Date,
) => {
  const {
    isLoading,
    isError,
    data: chats,
    refetch,
    error,
    isFetched,
  } = useQuery<ChatSummariesResponse, Error>(
    ['chats', archivalStatus, startTime],
    () => getChats(archivalStatus, startTime),
  )

  const { hotSettings } = useHotSettings()
  const { state: appContext } = useAppContext()
  const selectedRegionIds = new Set(
    Object.keys(appContext?.regionalFilter || {}),
  )
  const filteredChats = chats?.summaries.filter((chat: ChatSummary) =>
    selectedRegionIds.has(chat.recipient.regionId),
  )

  return {
    isLoading,
    isError,
    error,
    isFetched,
    refetch,
    // TODO(James): modify after hot setting is enabled
    chats: hotSettings?.regionalFiltersEnabled
      ? filteredChats
      : chats?.summaries,
    unReadChats: chats?.summaries.filter(
      (chat: ChatSummary) => chat.readStatus === ChatReadStatus.UNREAD,
    ),
  }
}
