import { Tooltip } from '@mui/material'
import { theme } from '@traba/theme'
import { IconName } from '@traba/types'
import { HTMLProps } from 'react'
import { SvgIcon } from '../SvgIcon'
import * as S from './Badge.styles'

export interface BadgeProps {
  variant?: S.BadgeVariant
  round?: boolean
  title: string
  style?: HTMLProps<HTMLDivElement>['style']
  iconName?: IconName
  dismissible?: boolean
  onDismiss?: () => void
  'aria-label'?: string | undefined
  displayIconOnRight?: boolean
  tooltipText?: string
}

export default function Badge({
  variant = 'info',
  round,
  title,
  iconName,
  dismissible = false,
  onDismiss,
  displayIconOnRight,
  tooltipText,
  ...props
}: BadgeProps) {
  let icon: JSX.Element | undefined
  if (variant === 'certification') {
    icon = (
      <SvgIcon
        name="award"
        style={{
          ...(displayIconOnRight
            ? { marginLeft: theme.space.xms }
            : { marginRight: theme.space.xms }),
        }}
      />
    )
  }
  if (iconName) {
    icon = (
      <SvgIcon
        name={iconName}
        style={{
          ...(displayIconOnRight
            ? { marginLeft: theme.space.xms }
            : { marginRight: theme.space.xms }),
        }}
      />
    )
  }
  const badge = (
    <S.BadgeContainer
      round={round}
      variant={variant}
      aria-label={props['aria-label']}
      style={{
        ...props.style,
        justifyContent: 'center',
      }}
    >
      {!displayIconOnRight && icon}
      {title}
      {dismissible && (
        <SvgIcon
          onClick={onDismiss}
          name="cancel"
          style={{
            ...(displayIconOnRight ? { marginRight: 10 } : { marginLeft: 10 }),
            cursor: 'pointer',
          }}
          width={9}
          height={9}
        />
      )}
      {displayIconOnRight && icon}
    </S.BadgeContainer>
  )

  return tooltipText ? (
    <Tooltip title={tooltipText}>
      <div>{badge}</div>
    </Tooltip>
  ) : (
    badge
  )
}
