import { useAlert } from '@traba/context'
import { makePlural } from '@traba/string-utils'
import { CreateInvitationsData, ShiftInvitation } from '@traba/types'
import { AxiosError } from 'axios'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { trabaApi } from 'src/api/helpers'
import { SHIFT_ID_IS_INFINITE_SHIFT_INSTANCE_ID_ERROR_CODE } from 'src/libs/constants'

export const WORKER_ALREADY_INVITED_TO_SHIFT_HTTP_CODE = 480

const getShiftInvitations = async (
  shiftIds?: string[],
  workerIds?: string[],
) => {
  if (
    (!shiftIds || shiftIds.length < 1) &&
    (!workerIds || workerIds.length < 1)
  ) {
    return []
  }
  try {
    const params = new URLSearchParams()
    if (shiftIds && shiftIds.length > 0) {
      params.set('shiftIds', shiftIds.join(','))
    }
    if (workerIds && workerIds.length > 0) {
      params.set('workerIds', workerIds.join(','))
    }
    const response = await trabaApi.get(
      `/my-company/shifts/invitations?${params.toString()}`,
    )
    return response.data
  } catch (error) {
    if (
      error instanceof AxiosError &&
      error.response?.status ===
        SHIFT_ID_IS_INFINITE_SHIFT_INSTANCE_ID_ERROR_CODE
    ) {
      return []
    }
    console.error(
      `Error retrieving invitations for shiftsIds ${shiftIds || []} workerIds ${
        workerIds || []
      }`,
      error,
    )
  }
}

const sendInvitations = async ({
  shiftId,
  workerIds,
  isFromChat,
  includeRescinded,
}: CreateInvitationsData) => {
  const response = await trabaApi.post(
    `/my-company/shifts/${shiftId}/invitations`,
    { workerIds, isFromChat, includeRescinded },
  )
  return response.data
}

const rescindInvitation = async ({
  shiftId,
  workerId,
}: {
  shiftId: string
  workerId: string
}) => {
  const response = await trabaApi.patch(
    `/my-company/shifts/${shiftId}/invitations/${workerId}/rescind`,
  )
  return response.data
}

export function useShiftInvitations({
  shiftIds,
  workerIds,
  isEnabled = true,
}: {
  shiftIds?: string[]
  workerIds?: string[]
  isEnabled?: boolean
}) {
  const {
    isLoading,
    isError,
    data: shiftInvitations,
    error,
    refetch,
  } = useQuery<ShiftInvitation[], Error>(
    ['shift-invitations', shiftIds, workerIds],
    () => getShiftInvitations(shiftIds, workerIds),
    { enabled: isEnabled },
  )
  const queryClient = useQueryClient()
  const { handleError, showSuccess } = useAlert()

  const rescindInvitationMutation = useMutation<
    ShiftInvitation,
    AxiosError,
    ShiftInvitation
  >(rescindInvitation, {
    onMutate: (response) => {
      queryClient.setQueryData(
        ['shift-invitations', shiftIds, workerIds],
        (invitations: ShiftInvitation[] | undefined) =>
          invitations?.filter(
            (invitation: { id: string }) => invitation.id !== response.id,
          ) || [],
      )
    },
    onError: (error: AxiosError) => {
      handleError(
        error,
        'useShiftInvitations -> rescindInvitation()',
        'There was an error rescinding the invitation. Please try again or contact support if the issue persists.',
        'Error rescinding invitation',
      )
    },
  })

  const sendInvitationsMutation = useMutation<
    ShiftInvitation[],
    AxiosError,
    CreateInvitationsData
  >(sendInvitations, {
    onSuccess: (_, body: CreateInvitationsData) => {
      showSuccess('', `Invitation${makePlural(body.workerIds)} sent!`, 5000)
      refetch()
    },
    onError: (error: AxiosError, body: CreateInvitationsData) => {
      if (error.status === WORKER_ALREADY_INVITED_TO_SHIFT_HTTP_CODE) {
        handleError(
          error,
          'useShiftInvitations -> sendInvitations()',
          `Error sending invitation${makePlural(body.workerIds)}`,
          body.workerIds.length > 1
            ? `One of those workers is already invited to the shift`
            : 'That worker is already invited to the shift',
          5000,
        )
        return
      }
      handleError(
        error,
        'useShiftInvitations -> sendInvitations()',
        'There was an error sending the invitations. Please try again or contact support if the issue persists.',
        'Error sending invitations',
      )
    },
  })

  return {
    isLoading,
    isError,
    error,
    shiftInvitations: shiftInvitations || [],
    rescindInvitation: rescindInvitationMutation.mutate,
    sendInvitations: sendInvitationsMutation.mutate,
    sendInvitationsAsync: sendInvitationsMutation.mutateAsync,
  }
}
