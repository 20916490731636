import { Badge } from '@mui/material'
import Fab from '@mui/material/Fab'
import Popper from '@mui/material/Popper'
import React, { useState } from 'react'
import { Row, SvgIcon, Text } from 'src/components/base'
import { Dialog } from 'src/components/base/Dialog/Dialog'
import { useCalendarContext } from 'src/hooks/useCalendarContext'
import useMobile from 'src/hooks/useMobile'
import {
  CalendarFilters as CalendarFiltersType,
  FiltersAction,
  initialCalendarFilters,
} from 'src/hooks/useShiftFilters.hooks'
import { ShiftAndAddress } from 'src/hooks/useShifts'
import { theme } from 'src/libs/theme'

import { WebToRNEventName } from 'src/types/events'
import { CalendarFilters } from './CalendarFilters'
import { CalendarFindWorker } from './CalendarFindWorker'
import { CalendarRoleSelector } from './CalendarRoleSelector'

interface Props {
  shifts: ShiftAndAddress[] | undefined
  isLoading: boolean
  dispatch: React.Dispatch<FiltersAction>
  filters: CalendarFiltersType
  isFromCalendarScreen: boolean
  isShowWorkersToggled?: boolean
  setIsShowWorkersToggled?: React.Dispatch<React.SetStateAction<boolean>>
}

export const CalendarMobileWidget = ({
  shifts,
  isLoading,
  filters,
  dispatch,
  isFromCalendarScreen,
  isShowWorkersToggled,
  setIsShowWorkersToggled,
}: Props) => {
  const { isMobileViewOrReactNative, isExclusivelyMobileView } = useMobile()

  const fabRef = React.useRef<HTMLButtonElement>(null)

  const [isWidgetOpen, setIsWidgetOpen] = useState<boolean>(false)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const { dispatch: dispatchCalendarAction } = useCalendarContext()

  const handleClickOnFab = (event: React.MouseEvent<HTMLElement>) => {
    window.ReactNativeWebView?.postMessage(
      JSON.stringify({
        event: isWidgetOpen
          ? WebToRNEventName.FULL_SCREEN_MODAL_CLOSE
          : WebToRNEventName.FULL_SCREEN_MODAL_OPEN,
      }),
    )
    setIsWidgetOpen(!isWidgetOpen)
    setAnchorEl(event.currentTarget)
  }

  const handleResetFilters = () => {
    dispatch({ type: 'SET_FILTERS', filters: initialCalendarFilters })
    dispatchCalendarAction({ type: 'RESET_FILTERS' })
    if (setIsShowWorkersToggled) {
      setIsShowWorkersToggled(true)
    }
  }

  const closeWidget = () => {
    window.ReactNativeWebView?.postMessage(
      JSON.stringify({
        event: WebToRNEventName.FULL_SCREEN_MODAL_CLOSE,
      }),
    )

    setIsWidgetOpen(false)
  }

  return (
    <div>
      <Badge
        color="error"
        style={{
          position: 'fixed',
          bottom: isExclusivelyMobileView ? 70 : 10,
          right: 10,
        }}
      >
        <Fab
          ref={fabRef}
          onClick={handleClickOnFab}
          style={{
            backgroundColor: theme.colors.Violet,
            width: 50,
            height: 50,
            zIndex: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '30%',
            cursor: 'pointer',
          }}
        >
          <SvgIcon name="filter" fill={theme.colors.White} />
        </Fab>
      </Badge>
      <Popper
        anchorEl={anchorEl}
        open={isWidgetOpen}
        style={{
          width: '80%',
          height: isMobileViewOrReactNative ? '80vh' : 600,
          display: 'flex',
          flexDirection: 'column',
          borderRadius: 10,
          boxShadow: `0px 0px 10px 0px ${theme.colors.Violet30}`,
          backgroundColor: theme.colors.White,
          overflow: isWidgetOpen ? 'hidden' : 'auto',
          paddingRight: theme.space.xxs,
          paddingLeft: theme.space.xxs,
          paddingTop: theme.space.xs,
          zIndex: 100, // Popper should be on top of everything
        }}
        placement="top-end"
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [
                0,
                isMobileViewOrReactNative ? theme.space.xxs : theme.space.xs,
              ],
            },
          },
        ]}
      >
        <Dialog
          dialogTitle={
            <Row alignCenter>
              <Text variant="h4" mr={theme.space.xxs}>
                Filters
              </Text>
              <Text variant="linkUnderline" onClick={handleResetFilters}>
                Reset
              </Text>
            </Row>
          }
          open={isWidgetOpen}
          onConfirmCTA="Update"
          onConfirm={closeWidget}
          onClose={closeWidget}
          hideFooter
        >
          {isFromCalendarScreen && (
            <CalendarRoleSelector shifts={shifts} isLoading={isLoading} />
          )}
          <CalendarFindWorker dispatch={dispatch} filters={filters} />
          <CalendarFilters
            dispatch={dispatch}
            filters={filters}
            isShowWorkersToggled={isShowWorkersToggled}
            setIsShowWorkersToggled={setIsShowWorkersToggled}
          />
        </Dialog>
      </Popper>
    </div>
  )
}
