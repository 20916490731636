import { Button, LoadingSpinner } from '@traba/react-components'
import { CreateShiftRequest, RoleInfoForCreateShiftRequest } from '@traba/types'
import { recurringSchedulesEnabled } from '@traba/utils'
import { Dispatch, SetStateAction } from 'react'
import { Col, Divider, Row, Text } from 'src/components/base'
import { useCompany } from 'src/hooks/useCompany'
import { useRoles } from 'src/hooks/useRoles'
import { useHotSettings } from 'src/hooks/useSystem'
import { theme } from 'src/libs/theme'
import { ButtonVariant } from '../../../components/base'
import {
  MIN_WORKER_HOURLY_PAY_DEFAULT,
  getMinHourlyPayRate,
} from '../validation'
import RoleSelector from './RoleSelector'

export default function AddRoleToSchedule({
  shiftRequest,
  recurringRoles,
  onContinue,
  onPrevious,
  addNewRole,
  updateRoleInfoForCreateShiftRequest,
}: {
  shiftRequest: CreateShiftRequest
  recurringRoles: RoleInfoForCreateShiftRequest[]
  onContinue: () => void
  onPrevious: () => void
  setRecurringRoles: Dispatch<SetStateAction<RoleInfoForCreateShiftRequest[]>>
  addNewRole: () => void
  updateRoleInfoForCreateShiftRequest: (
    updatedRoleInfoForCreateShiftRequest: RoleInfoForCreateShiftRequest,
    originalRoleId: string,
  ) => void
}) {
  const { roles, isLoading: isLoadingRoles } = useRoles()
  const { company, isLoading: isLoadingCompany } = useCompany()
  const { hotSettings, isLoading: isLoadingHotSettings } = useHotSettings()

  if (isLoadingRoles || isLoadingCompany || isLoadingHotSettings) {
    return <LoadingSpinner />
  }

  const minHourlyPay = getMinHourlyPayRate({
    companyMinHourlyPay: company?.minHourlyPayRate,
    platformMinHourlyPay:
      hotSettings?.platformMinHourlyPayRate ?? MIN_WORKER_HOURLY_PAY_DEFAULT,
  })

  return (
    <>
      <Text variant="h4" style={{ margin: `0 0 ${theme.space.sm}px 0` }}>
        Add another role or workers to the schedule
      </Text>
      <Divider />
      <Text
        variant="h5"
        style={{
          margin: `${theme.space.lg}px 0 ${theme.space.sm}px`,
        }}
      >
        Increase the number of workers for existing roles, or add another role
      </Text>
      {recurringRoles.map((r) => (
        <RoleSelector
          key={r.roleId}
          selectedRole={r}
          recurringRoles={recurringRoles}
          updateRoleInfoForCreateShiftRequest={
            updateRoleInfoForCreateShiftRequest
          }
          roles={roles}
          minHourlyPay={minHourlyPay}
          schedules={shiftRequest.schedules}
        />
      ))}
      <Col>
        {recurringSchedulesEnabled({
          company,
          hotSettings,
        }) ? (
          <Row justifyEnd mt={theme.space.sm}>
            <Button
              onClick={addNewRole}
              variant={ButtonVariant.OUTLINED}
              disabled={recurringRoles.some((r) => !r.roleId)}
            >
              + Add Role
            </Button>
          </Row>
        ) : null}
        <Row justifyBetween style={{ marginTop: theme.space.lg }}>
          <Button onClick={onPrevious} variant={ButtonVariant.OUTLINED}>
            Previous
          </Button>
          <Button onClick={onContinue}>Continue</Button>
        </Row>
      </Col>
    </>
  )
}
