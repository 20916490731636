import * as Sentry from '@sentry/react'
import { useAlert } from '@traba/context'
import { AxiosError } from 'axios'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { trabaApi } from 'src/api/helpers'
import { WorkerDetails } from './useCompanyWorkers'

export type UpdateRosterDto = {
  rosterId?: string
  rosterName?: string
  addWorkers?: string[]
  removeWorkers?: string[]
}

export type RosterWithWorkers = {
  id: string
  rosterName: string
  companyId: string
  workers: WorkerDetails[]
}

const getRosterById = async (rosterId?: string) => {
  if (!rosterId) {
    return undefined
  }
  try {
    const response = await trabaApi.get(`/my-company/rosters/${rosterId}`)
    return response.data
  } catch (error) {
    console.log('useRoster -> getRosterById() ERROR', error)
    Sentry.captureException(error, {
      tags: { action: 'useRoster -> getRosterById()' },
    })
  }
}

export const useRoster = (rosterId: string) => {
  const {
    isLoading,
    isError,
    data: roster,
    error,
  } = useQuery<RosterWithWorkers, Error>(['roster', rosterId], () =>
    getRosterById(rosterId),
  )
  const queryClient = useQueryClient()
  const { handleError } = useAlert()

  const updateRosterMutation = useMutation<
    RosterWithWorkers,
    AxiosError,
    UpdateRosterDto
  >(
    async (req) => {
      const { rosterId: _ignore, ...rest } = req
      const res = await trabaApi.patch(
        `/my-company/rosters/${rosterId}?includeWorkers=true`,
        rest,
      )
      return res.data
    },
    {
      onSuccess: (response) => {
        queryClient.setQueryData(['roster', rosterId], () => response)
      },
      onError: (error) => {
        handleError(
          error,
          'useRosters -> updateRoster()',
          'There was an error updating the roster. Please try again or contact support if the issue persists.',
          'Error updating roster',
        )
      },
    },
  )

  const workersMap = new Map(
    roster?.workers.map((worker) => [worker.worker.uid, worker]),
  )

  return {
    isLoading,
    isError,
    error,
    roster,
    workersMap,
    updateRoster: updateRosterMutation.mutate,
  }
}
