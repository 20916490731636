import { theme } from '@traba/theme'
import { ShiftRequestParentWithShiftRequest } from '@traba/types'
import { ScheduleTileDateTimeInfo } from './ScheduleTileDateTimeInfo'
import { ScheduleTileHeader } from './ScheduleTileHeader'
import { ScheduleTileLocation } from './ScheduleTileLocation'
import { ScheduleTileRolesInfo } from './ScheduleTileRolesInfo'

interface ScheduleTileProps {
  shiftRequestParent: ShiftRequestParentWithShiftRequest
}

export const ScheduleTile: React.FC<ScheduleTileProps> = ({
  shiftRequestParent,
}) => {
  // We should only have shift request parent objects with at least one shift
  // request, and each of those shift requests should have 1-2 schedule objects.
  // This filtering is performed on the backend.
  const anyShiftRequestFromParent = shiftRequestParent.shiftRequests[0]
  const schedules = anyShiftRequestFromParent.schedules

  if (!schedules.length) {
    return null
  }
  // All shifts in a schedule is of one location, so we can get from first one
  const scheduleLocationId = shiftRequestParent.shiftRequests[0].locationId

  return (
    <div
      style={{
        marginBottom: theme.space.sm,
        border: `1px solid ${theme.colors.Grey20}`,
        borderRadius: theme.space.xxs,
      }}
    >
      <ScheduleTileHeader
        scheduleTitle={shiftRequestParent.title}
        shiftRequestParentId={shiftRequestParent.shiftRequestParentId}
      />
      <ScheduleTileLocation locationId={scheduleLocationId} />
      <ScheduleTileDateTimeInfo
        schedules={schedules}
        timezone={anyShiftRequestFromParent.timezone ?? 'utc'}
      />
      <ScheduleTileRolesInfo shiftRequests={shiftRequestParent.shiftRequests} />
    </div>
  )
}
