import { Skeleton } from '@mui/material'
import { CardTile } from '@traba/react-components'
import { DotMenu } from '@traba/react-components'
import { isEmpty } from 'lodash'
import React from 'react'
import { Text } from 'src/components/base'
import { theme } from 'src/libs/theme'
import { RoleData } from 'src/types'
import { getMoneyString } from 'src/utils/moneyUtils'

import { Icon } from '../base'
import GenderBadge from '../GenderBadge'
import { RoleBadges } from '../RoleBadges'
import { DescriptionText } from './Role.styles'

// This is where the logic meets the UI. This separation allows use to pass the data directly to the UI in StoryBook

export type RoleProps = {
  role: RoleData
  edit?: (role: RoleData) => void
  archive?: (role: RoleData) => void
  userCanEdit?: boolean
  userCanViewWages?: boolean
  selected?: boolean
  radioButton?: boolean
  condensed?: boolean
  loading?: boolean
  handleSelect?: (roleId: string) => void
  showGenderPreference?: boolean
}

export const Role: React.FC<RoleProps> = (props) => {
  const { role, condensed, loading, handleSelect, edit, archive } = props

  const roleActionMenuItems = []

  if (edit) {
    roleActionMenuItems.push({
      title: 'Edit role',
      onClick: () => {
        edit(role)
        window.analytics.track(`User Clicked Edit Role`, {
          role: role,
        })
      },
    })
  }

  if (archive) {
    roleActionMenuItems.push({
      title: 'Archive role',
      onClick: () => {
        archive(role)
        window.analytics.track(`User Clicked Archive Role`, {
          role: role,
        })
      },
      color: theme.colors.Red60,
    })
  }

  if (loading || !role) {
    return <Skeleton />
  }

  if (condensed) {
    return (
      <div>
        <div
          style={{
            justifyContent: 'space-between',
            alignItems: 'center',
            display: 'flex',
          }}
        >
          <div
            style={{
              justifyContent: 'flex-start',
              alignItems: 'center',
              display: 'flex',
            }}
          >
            <CardTile size="56px">
              <Icon name="backPack" />
            </CardTile>
            <span style={{ marginLeft: '25px' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Text variant="h5">{role.roleName}</Text>
                <RoleBadges
                  requiredCertifications={role.requiredCertifications}
                />
              </div>
              <Text variant="body2">{role.roleDescription}</Text>
              {!isEmpty(role.requiredAttributes) && (
                <RoleBadges requiredAttributes={role.requiredAttributes} />
              )}
            </span>
          </div>
        </div>
      </div>
    )
  }

  const actionable = props.userCanEdit && roleActionMenuItems.length > 0

  return (
    <div>
      <div
        style={{ cursor: handleSelect ? 'pointer' : '' }}
        onClick={() => (handleSelect ? handleSelect(role.roleId) : undefined)}
      >
        <div
          style={{
            justifyContent: 'space-between',
            alignItems: 'center',
            display: 'flex',
          }}
        >
          <div
            style={{
              justifyContent: 'flex-start',
              alignItems: 'center',
              display: 'flex',
            }}
          >
            <CardTile size="56px">
              <Icon name="backPack" />
            </CardTile>
            <span style={{ marginLeft: '25px' }}>
              <Text variant="h5">{role.roleName}</Text>
            </span>
            <RoleBadges requiredCertifications={role.requiredCertifications} />
          </div>
          {actionable && (
            <div style={{ paddingLeft: '8px' }}>
              <DotMenu
                type="role"
                dotMenuKey={role.roleId}
                menuItems={roleActionMenuItems}
              />
            </div>
          )}
        </div>
        <div style={{ marginTop: theme.space.xs }}>
          <Text variant="caption">DESCRIPTION</Text>
          <DescriptionText>{role.roleDescription}</DescriptionText>
        </div>
        {roleActionMenuItems.length > 0 && (
          <>
            <div style={{ marginTop: theme.space.xs }}>
              <Text variant="caption">REQUIRED ATTIRE</Text>
              <DescriptionText>{role.requiredAttire}</DescriptionText>
            </div>
            {!isEmpty(role.requiredAttributes) && (
              <div style={{ marginTop: theme.space.xs }}>
                <Text variant="caption">REQUIREMENTS</Text>
                <RoleBadges requiredAttributes={role.requiredAttributes} />
              </div>
            )}
            {props.showGenderPreference && !!role.genderPreference && (
              <div style={{ marginTop: theme.space.xs }}>
                <Text variant="caption">GENDER PREFERENCE</Text>
                <GenderBadge gender={role.genderPreference} />
              </div>
            )}
            {props.userCanViewWages && (
              <div style={{ marginTop: theme.space.xs }}>
                <Text variant="caption">DEFAULT PAY RATE</Text>
                <DescriptionText>
                  {getMoneyString(role.defaultPayRate)}/hr
                </DescriptionText>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  )
}
