import { ScheduleDaySquares, SvgIcon, Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { Schedule } from '@traba/types'
import { getMonthFromDate, getShiftTimeString } from '@traba/utils'
import { utcToZonedTime } from 'date-fns-tz'
import { Icon, Row } from 'src/components'

function getStartAndEndDates(start: Date, end?: Date) {
  const startDay = start.getDate()
  const startMonth = getMonthFromDate(start)
  const startYear = start.getFullYear()
  const startDate = `${startMonth} ${startDay}, ${startYear}`

  if (!end) {
    return `${startDate} - ongoing`
  }

  const endDay = end.getDate()
  const endMonth = getMonthFromDate(end)
  const endYear = end.getFullYear()

  if (startYear !== end.getFullYear()) {
    return `${startMonth} ${startDay}, ${startYear} — ${endMonth} ${endDay}, ${endYear}`
  } else if (start.getMonth() !== end.getMonth()) {
    return `${startMonth} ${startDay} — ${endMonth} ${endDay}, ${endYear}`
  } else {
    return `${startMonth} ${startDay} — ${endDay}, ${endYear}`
  }
}

// TODO(polyphilz): This is a placeholder; figure out how to get number of shifts
function getNumShiftsInSchedule() {
  const numShifts = 'X'
  return ` (${numShifts} shifts)`
}

interface ScheduleTileDateTimeInfoProps {
  schedules: Schedule[]
  timezone: string
}

export const ScheduleTileDateTimeInfo: React.FC<
  ScheduleTileDateTimeInfoProps
> = ({ schedules, timezone }) => {
  if (!schedules.length) {
    return null
  }

  const isBiWeeklySchedule = schedules.length > 1

  // It doesn't matter which schedule we pull; even if it's a bi-weekly and this
  // schedules array has 2 objects, they both will have the same **times** (but
  // of course, the actual **days** will be different). For the purposes of this
  // component, we only care about the times and dates, and not the individual
  // days.
  const schedule = schedules[0]
  if (!schedule.recurringSchedule || !schedule.isRecurringSchedule) {
    return null
  }

  const scheduleStartDate: Date = utcToZonedTime(schedule.startTime, timezone)

  // If undefined, this means the schedule has no end date and is infinite
  const scheduleEndDate: Date | undefined = schedule.recurringSchedule.endDate
  const shiftTimeString = getShiftTimeString(
    schedule.startTime,
    schedule.endTime,
    timezone,
  )

  const startEndDatesAndNumShifts = (
    <Row alignCenter>
      <SvgIcon name="calendar" color={theme.colors.MidnightBlue} />
      <Text
        style={{ marginLeft: theme.space.xxxs }}
        variant="body2"
        color={theme.colors.MidnightBlue}
      >
        {`${getStartAndEndDates(scheduleStartDate, scheduleEndDate)}${scheduleEndDate ? getNumShiftsInSchedule() : ''}`}
      </Text>
    </Row>
  )

  return (
    <div
      style={{
        padding: theme.space.sm,
        borderBottom: `1px solid ${theme.colors.Grey20}`,
        alignItems: 'center',
      }}
    >
      <Row>
        <Row>
          <Row alignCenter mr={theme.space.xs}>
            <SvgIcon name="clock" color={theme.colors.MidnightBlue} />
            <Text
              style={{ marginLeft: theme.space.xxxs }}
              variant="body2"
              color={theme.colors.MidnightBlue}
            >
              {shiftTimeString}
            </Text>
          </Row>
          <Row alignCenter mr={theme.space.xs}>
            <span>•</span>
          </Row>
          {startEndDatesAndNumShifts}
        </Row>
      </Row>

      <Row alignCenter mt={theme.space.xs} mr={theme.space.xs} wrap>
        <Icon name="rotate_left" width={18} height={18} />
        <Row mr={theme.space.xs}>
          <Text
            style={{ marginLeft: theme.space.xxxs }}
            variant="body2"
            color={theme.colors.MidnightBlue}
          >
            Repeats {isBiWeeklySchedule ? 'bi-weekly' : 'weekly'}
          </Text>
        </Row>

        <Row>
          <ScheduleDaySquares
            repeatOn={schedules[0].recurringSchedule?.repeatOn || []}
            instance={0}
          />
        </Row>

        {isBiWeeklySchedule && (
          <>
            <Row alignCenter ml={theme.space.xxs} mr={theme.space.xs}>
              <span>|</span>
            </Row>
            <Row>
              <ScheduleDaySquares
                repeatOn={schedules[1].recurringSchedule?.repeatOn || []}
                instance={1}
              />
            </Row>
          </>
        )}
      </Row>
    </div>
  )
}
