import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Button,
  ButtonVariant,
  Col,
  Row,
  SvgIcon,
  Text,
} from 'src/components/base'
import { theme } from 'src/libs/theme'

import { FeatureAnnouncementModalProps } from './utils'

export const TimesheetsAnnouncementContent = (
  props: FeatureAnnouncementModalProps,
) => {
  const navigate = useNavigate()
  const [page, setPage] = useState(0)
  const pageOneContent = (
    <>
      <Col my={theme.space.med} mx={theme.space.xl}>
        <Text variant="h3" mb={theme.space.xs}>
          <span role="img" aria-label="light_emoji">
            💡
          </span>{' '}
          New Timesheets: Approve faster & easier
        </Text>
        <img
          src="/img/timesheets_announcement.png"
          alt="Timesheets"
          style={{
            width: '100%',
            marginTop: theme.space.lg,
            alignSelf: 'center',
          }}
        />
      </Col>
      <Row justifyEnd alignCenter mx={theme.space.med} mb={theme.space.med}>
        <Button
          variant={ButtonVariant.TEXT}
          aria-label="skip"
          onClick={props.handleClose}
          trackingEvent={{
            message: 'User Dismissed Timesheets Feature Announcement',
          }}
        >
          Skip
        </Button>
        <Button
          onClick={() => {
            setPage(1)
          }}
          trackingEvent={{
            message: 'User Clicked Next on Timesheets Feature Announcement',
          }}
        >
          Next
        </Button>
      </Row>
    </>
  )

  const pageTwoContent = (
    <>
      <Col my={theme.space.med} mx={theme.space.xl}>
        <Text variant="h3" mb={theme.space.xs}>
          <span role="img" aria-label="light_emoji">
            💡
          </span>{' '}
          New Timesheets: Customize how you view times
        </Text>
        <img
          src="/img/timesheets_announcement_2.png"
          alt="Timesheets"
          style={{
            width: '100%',
            marginTop: theme.space.lg,
            alignSelf: 'center',
          }}
        />
      </Col>
      <Row justifyBetween alignCenter mx={theme.space.med} mb={theme.space.med}>
        <Button
          variant={ButtonVariant.OUTLINED}
          onClick={() => {
            setPage(0)
          }}
          trackingEvent={{
            message: 'User Clicked Previous on Timesheets Feature Announcement',
          }}
        >
          Previous
        </Button>
        <Row>
          <Button
            variant={ButtonVariant.TEXT}
            onClick={props.handleClose}
            trackingEvent={{
              message: 'User Dismissed Timesheets Feature Announcement',
            }}
          >
            Maybe later
          </Button>
          <Button
            onClick={() => {
              navigate({
                pathname: '/timesheet/approval',
              })
            }}
            aria-label="skip"
            trackingEvent={{
              message: 'User Clicked Go to Timesheets on Feature Announcement',
            }}
          >
            Go to Timesheets
          </Button>
        </Row>
      </Row>
    </>
  )

  return (
    <div
      style={{
        background: `linear-gradient(to bottom, ${theme.colors.Violet20}, ${theme.colors.White})`,
        flexDirection: 'column',
        display: 'flex',
        height: 840,
      }}
    >
      {page === 0 ? pageOneContent : pageTwoContent}
      <div
        onClick={props.handleClose}
        style={{
          cursor: 'pointer',
          position: 'absolute',
          right: theme.space.med,
          top: theme.space.med,
        }}
        id="modal-close-icon"
      >
        <SvgIcon name="cancel" size={24} color={theme.colors.MidnightBlue} />
      </div>
    </div>
  )
}
