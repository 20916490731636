import * as Sentry from '@sentry/react'
import {
  Roster,
  WorkerCertification,
  WorkerProfile,
  TrainingStatus,
  WorkerTrainingStatus,
} from '@traba/types'
import { useQuery } from 'react-query'
import { trabaApi } from 'src/api/helpers'

import { useWorkerById } from './useWorker'
import { getWorkerCertificationsById } from './useWorkerCertifications'

export async function getRoleAttributes() {
  try {
    const res = await trabaApi.get(
      `/role-attributes/?locale=en-US&status=ACTIVE`, //&VISIBILITY=VISIBLE
    )
    return res
  } catch (error) {
    console.error('useWorker -> getRoleAttributes() ERROR', error)
    Sentry.captureException(error, {
      tags: { action: 'useWorker -> getRoleAttributes()' },
    })
  }
}

async function getWorkerRosters(
  workerId: string,
): Promise<Roster[] | undefined> {
  try {
    const res = await trabaApi.get(`my-company/workers/${workerId}/rosters`)
    return res.data
  } catch (error) {
    console.error('useWorker -> getWorkerRosters() ERROR', error)
    Sentry.captureException(error, {
      tags: { action: 'useWorker -> getWorkerRosters()' },
    })
  }
}

async function getWorkerProfileById(
  workerId: string,
  category?: WorkerProfile['category'],
  profileField?: WorkerProfile['profileField'],
): Promise<WorkerProfile[] | undefined> {
  try {
    const res = await trabaApi.get(`workers/${workerId}/profile`, {
      params: { category, profileField },
    })
    return res.data
  } catch (error) {
    console.error('useWorker -> getWorkerProfileById() ERROR', error)
    Sentry.captureException(error, {
      tags: { action: 'useWorker -> getWorkerProfileById()' },
    })
  }
}

async function getWorkerTrainingStatusByWorkerId(
  workerId: string,
): Promise<WorkerTrainingStatus[] | undefined> {
  try {
    const res = await trabaApi.get(`worker-training-status/${workerId}`)
    return res.data
  } catch (error) {
    console.error(
      'useWorker -> getWorkerTrainingStatusByWorkerId() ERROR',
      error,
    )
    Sentry.captureException(error, {
      tags: { action: 'useWorker -> getWorkerTrainingStatusByWorkerId()' },
    })
  }
}

export function useWorkerProfileCertsRosters(workerId: string) {
  const {
    isLoading: isLoadingWorker,
    isError: isErrorWorker,
    data: worker,
    error: errorWorker,
    isFetched: isFetchedWorker,
  } = useWorkerById(workerId)

  const {
    isLoading: isLoadingRosters,
    isError: isErrorRosters,
    data: rosters,
    error: errorRosters,
    isFetched: isFetchedRosters,
  } = useQuery<Roster[] | undefined, Error>(['worker-rosters', workerId], () =>
    getWorkerRosters(workerId),
  )

  const {
    isLoading: isLoadingProfile,
    isError: isErrorProfile,
    data: profile,
    error: errorProfile,
    isFetched: isFetchedProfile,
  } = useQuery<WorkerProfile[] | undefined, Error>(
    ['workerProfileById', workerId],
    () => getWorkerProfileById(workerId),
  )

  const {
    isLoading: isLoadingWorkerTrainingStatus,
    isError: isErrorWorkerTrainingStatus,
    data: workerTrainingStatus,
    error: errorWorkerTrainingStatus,
    isFetched: isFetchedWorkerTrainingStatus,
  } = useQuery<WorkerTrainingStatus[] | undefined, Error>(
    ['workerTrainingStatusByWorkerId', workerId],
    () => getWorkerTrainingStatusByWorkerId(workerId),
  )
  const completedTrainings = workerTrainingStatus?.filter(
    (training) => training.status === TrainingStatus.COMPLETED,
  )
  const {
    isLoading: isLoadingCertifications,
    isError: isErrorCertifications,
    data: certifications,
    error: errorCertifications,
    isFetched: isFetchedCertifications,
  } = useQuery<WorkerCertification[] | undefined, Error>(
    ['getWorkerCertificationsById', workerId],
    () => getWorkerCertificationsById(workerId),
  )

  return {
    isLoading:
      isLoadingProfile ||
      isLoadingWorker ||
      isLoadingCertifications ||
      isLoadingRosters ||
      isLoadingWorkerTrainingStatus,
    isError:
      isErrorWorker ||
      isErrorProfile ||
      isErrorCertifications ||
      isErrorRosters ||
      isErrorWorkerTrainingStatus,
    worker,
    profile,
    certifications,
    rosters,
    workerTrainingStatus,
    completedTrainings,
    errorProfile,
    errorWorker,
    errorCertifications,
    errorRosters,
    errorWorkerTrainingStatus,
    isFetchedWorker,
    isFetchedCertifications,
    isFetchedProfile,
    isFetchedRosters,
    isFetchedWorkerTrainingStatus,
  }
}
