import { LoadingSpinner } from '@traba/react-components'
import { useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { Col, Row, Text } from 'src/components/base'
import { Checkbox } from 'src/components/base/CheckboxThemed'
import { EmptyDataLocationSubtitle } from 'src/components/base/RegionalFilterSelect/EmptyDataLocationSubtitle'
import { useCalendarContext } from 'src/hooks/useCalendarContext'
import { ShiftAndAddress } from 'src/hooks/useShifts'
import { useHotSettings } from 'src/hooks/useSystem'
import { MEDIA, theme } from 'src/libs/theme'

interface Props {
  shifts: ShiftAndAddress[] | undefined
  isLoading: boolean
}

const MAX_SHIFTS = 3

export const CalendarRoleSelector = ({ shifts, isLoading }: Props) => {
  const {
    state: { rolesMap },
    dispatch,
  } = useCalendarContext()
  const [showAll, setShowAll] = useState<boolean>(
    shifts !== undefined && shifts?.length > MAX_SHIFTS,
  )

  const roleOptions = Array.from(Object.entries(rolesMap)).map(
    ([roleId, entry]) => {
      return {
        label: entry.name,
        value: roleId,
      }
    },
  )
  const { hotSettings } = useHotSettings()
  const isMobileView = useMediaQuery({
    maxWidth: MEDIA.LARGE,
  })

  return (
    <Col>
      <Text variant="h5" mb={theme.space.xxs}>
        Roles
      </Text>
      {isLoading ? (
        <LoadingSpinner style={{ width: theme.space.lg }} />
      ) : roleOptions.length === 0 ? (
        <>
          <Text variant="body2">
            You don't have any shifts in current view.
          </Text>
          {hotSettings?.regionalFiltersEnabled && (
            <EmptyDataLocationSubtitle
              isMobileView={isMobileView}
              extraText="to see shifts in those locations, or book a new shift."
              style={{
                textAlign: 'left',
                flexDirection: 'column',
                marginTop: theme.space.xxxs,
              }}
            />
          )}
        </>
      ) : (
        <>
          {roleOptions
            .slice(0, showAll ? roleOptions.length : MAX_SHIFTS)
            ?.map((sq) => (
              <Row alignCenter mt={theme.space.xxs} key={sq.value}>
                <Checkbox
                  selected={!rolesMap[sq.value]?.hideFromView}
                  onClick={() => {
                    dispatch({
                      type: 'HIDE_ROLE',
                      payload: {
                        roleId: sq.value,
                        hideFromView: !rolesMap[sq.value]?.hideFromView,
                      },
                    })
                  }}
                  selectedColor={rolesMap[sq.value]?.color.borderColor}
                />
                <Text ml={theme.space.xxs} variant="body1">
                  {sq.label}
                </Text>
              </Row>
            ))}
          {roleOptions.length > MAX_SHIFTS && (
            <Row mt={theme.space.xxs}>
              <Text
                variant="link"
                onClick={() => {
                  setShowAll(!showAll)
                }}
              >
                {showAll ? 'Show less' : 'Show all'}
              </Text>
            </Row>
          )}
        </>
      )}
    </Col>
  )
}
