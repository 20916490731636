export enum ShiftTag {
  VIP = 'VIP',
  NoBackfill = 'NO_BACKFILL',
  Ignore = 'IGNORE',
}

export enum ForwardFillMax {
  NONE = 'NONE',
  // All eligible workers with experience at the business notified
  EXPERIENCED = 'EXPERIENCED', // DEPRECATED
  EXPERIENCED_FIRST = 'EXPERIENCED_FIRST',
  EXPERIENCED_ONLY = 'EXPERIENCED_ONLY',
  // All favorites at the business notified
  FAVORITES = 'FAVORITES',
  INVITED_ONLY = 'INVITED_ONLY',
  INVITED_FIRST = 'INVITED_FIRST',
  INEXPERIENCED_ONLY = 'INEXPERIENCED_ONLY',
}

export enum AssigneeDisplay {
  MARKET_OPS = 'MARKET_OPS',
  SCALED_OPS = 'SCALED_OPS',
}

export enum ForwardFillRenotificationMethod {
  NONE = 'NONE',
  SMS = 'SMS',
}

export enum PaymentType {
  Manual = 'MANUAL',
  Auto = 'AUTO',
}

export enum InvitedWorkers {
  ALL = 'ALL',
  FAVORITES_ONLY = 'FAVORITES_ONLY',
  FAVORITES_FIRST = 'FAVORITES_FIRST',
}

export enum ShiftStatus {
  ACTIVE = 'ACTIVE',
  CANCELED = 'CANCELED',
  COMPLETE = 'COMPLETE',
}

export enum ShiftSignupStatus {
  ALLOWED = 'ALLOWED',
  DISALLOWED = 'DISALLOWED',
}

export enum ShiftNotificationStatus {
  ALLOWED = 'ALLOWED',
  DISALLOWED = 'DISALLOWED',
}
