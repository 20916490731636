import { Worker } from './workers'

export enum ConnectionType {
  BLOCK = 'BLOCK',
  FAVORITE = 'FAVORITE',
  INELIGIBLE = 'INELIGIBLE',
  SCHEDULED_BLOCK = 'SCHEDULED_BLOCK',
}

export enum ConnectionSource {
  COMPANY = 'COMPANY',
  WORKER = 'WORKER',
}

export enum ConnectionReason {
  CANNOT_PERFORM_REQUIRED_TASK = 'Cannot perform required task',
  DOES_NOT_WANT_TO_WORK = 'Does not want to work',
  DOES_NOT_FOLLOW_INSTRUCTIONS = 'Does not follow instructions',
  WORKER_ACTIVELY_AVOIDS_WORKING = 'Worker actively avoids working',
  CANNOT_WORK_WELL_WITH_OTHERS = 'Cannot work well with others',
  WORKS_TOO_SLOWLY = 'Works too slowly',
  CREATES_DISRUPTIVE_WORK_ENVIRONMENT = 'Creates disruptive work environment',
  ABANDONED_SHIFT = 'Abandoned shift',
  ABUSIVE_BEHAVIOR = 'Abusive behavior',
  UNDER_THE_INFLUENCE = 'Under the influence',
  CONSISTENTLY_LATE = 'Consistently late',
  NO_SHOW_AT_SHIFT = 'No Show at Shift',
  // LATEST
  UNABLE_TO_PERFORM_REQUIREMENTS = 'UNABLE_TO_PERFORM_REQUIREMENTS',
  UNPROFESSIONAL_UNCOOPERATIVE = 'UNPROFESSIONAL_UNCOOPERATIVE',
  SERIOUS_INCIDENT = 'SERIOUS_INCIDENT',
}

export type WorkerConnections = {
  blocked: string[]
  ineligible: string[]
  workerFavorites: string[]
  companyFavorites: string[]
}

export interface Connection {
  id: string
  companyId: string
  connectionType: ConnectionType
  createdAt: string
  source: ConnectionSource
  workerId: string
  initiatedBy?: string
  note?: string
  connectionReason?: ConnectionReason
  connectionReasons?: ConnectionReason[]
  updatedAt: string
}

export interface WorkerConnectionResponse extends Connection {
  employerName: string
}

export interface CompanyConnectionResponse extends Connection {
  worker: Worker
}

export interface BaseConnection {
  connectionType: ConnectionType
}

export interface CompanyConnectionDto extends BaseConnection {
  workerId: string
}

export interface WorkerConnectionDto extends BaseConnection {
  companyId: string
}

export interface CompanyConnectionOnBehalfDto extends CompanyConnectionDto {
  initiatedBy: string
  note: string
  bypassRestrictions?: boolean
}

export interface ConnectionDto extends Connection {
  bypassRestrictions?: boolean
}

export interface ConnectionQueryDto {
  companyId: string
  workerId?: string
  connectionType?: ConnectionType
  source?: ConnectionSource
}
