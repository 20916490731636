import * as Sentry from '@sentry/react'
import { Announcement } from '@traba/types'
import { omitBy } from 'lodash'
import { useQuery, UseQueryResult } from 'react-query'
import { trabaApi } from 'src/api/helpers'
import { useAppContext } from 'src/context/appContext/AppContext'
import { SearchParams } from 'src/types'

import { useHotSettings } from './useSystem'

const queryCompanyAnnouncements = async (body: SearchParams = {}) => {
  // Remove empty string fields because post request will take them as empty strings
  const sanitizedBody = {
    ...omitBy(body, (value) => value === ''),
    limit: body.limit?.toString(),
  }
  try {
    const res = await trabaApi.post(
      'my-company/query-announcements',
      sanitizedBody,
    )
    return res.data
  } catch (error) {
    Sentry.captureException(error)
  }
}

export const useCompanyAnnouncements = (
  searchParams: SearchParams = {},
): UseQueryResult<Announcement[], Error> => {
  const { hotSettings } = useHotSettings()
  const { state: appContextState } = useAppContext()
  const activeLocationIds = Object.values(
    appContextState?.regionalFilter || {},
  ).flat()

  const queryKey = [
    'company-announcements',
    searchParams.startBefore?.getDate(),
    searchParams.startAfter?.getDate(),
    searchParams.endBefore?.getDate(),
    searchParams.endAfter?.getDate(),
    searchParams.startAtIndex,
    searchParams.sortDir,
    activeLocationIds,
  ]

  return useQuery<Announcement[], Error>(queryKey, () => {
    // TODO(James): modify after hot setting is enabled
    if (hotSettings?.regionalFiltersEnabled) {
      searchParams.activeLocationIds = activeLocationIds
    }
    return queryCompanyAnnouncements(searchParams)
  })
}
