import { useAlert } from '@traba/context'
import { AxiosError } from 'axios'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { trabaApi } from 'src/api/helpers'
import {
  CreateRoleData,
  RoleData,
  UpdateRoleDto,
  UpdateRoleResponseDto,
} from 'src/types'

const getRoles = async () => {
  try {
    const response = await trabaApi.get(`/my-company/roles`)
    return response.data.roles
  } catch (error) {
    // will catch later
  }
}

const editRole = async ({
  roleId,
  updatedRole,
}: {
  roleId: string
  updatedRole: UpdateRoleDto
}) => {
  const response = await trabaApi.patch(
    `my-company/roles/${roleId}`,
    updatedRole,
  )
  return response.data
}

const archiveRole = async (roleId: string) => {
  const response = await trabaApi.patch(`my-company/roles/${roleId}/archive`)
  return response.data
}

export const useRoles = () => {
  const queryClient = useQueryClient()
  const { showSuccess } = useAlert()
  const {
    isLoading,
    isError,
    data: roles,
    error,
    isFetched: isRolesFetched,
    refetch,
  } = useQuery<RoleData[], Error>('roles', getRoles)
  const createRoleMutation = useMutation<RoleData, AxiosError, CreateRoleData>(
    async (newRole) => {
      const res = await trabaApi.post(`/my-company/roles`, newRole)
      return res.data
    },
    {
      onSuccess: (data) => {
        queryClient.setQueryData(
          'roles',
          (currentRoles: RoleData[] | undefined) => {
            return currentRoles ? [...currentRoles, data] : [data]
          },
        )
      },
    },
  )

  const editRoleMutation = useMutation<UpdateRoleResponseDto, AxiosError, any>(
    editRole,
  )

  const archiveRoleMutation = useMutation<RoleData, AxiosError, string>(
    archiveRole,
    {
      onSuccess: (response: any) => {
        queryClient.setQueryData(
          'roles',
          (currentRoles: RoleData[] | undefined) => {
            return currentRoles
              ? currentRoles.filter(
                  (role: RoleData) => role.roleId !== response.roleId,
                )
              : []
          },
        )
        showSuccess(
          'Role will no longer be suggested when creating shifts.',
          'Role archived!',
        )
      },
    },
  )

  const getRoleById = (id: string) => roles?.find((role) => role.roleId === id)

  return {
    isLoading,
    isError,
    error,
    isRolesFetched,
    roles: roles || [],
    getRoleById,
    createRole: createRoleMutation.mutate,
    archiveRole: archiveRoleMutation.mutate,
    editRole: editRoleMutation.mutate,
    refetchRoles: refetch,
  }
}
