import { useAlert } from '@traba/context'
import { LoadingSpinner } from '@traba/react-components'
import { makePlural } from '@traba/string-utils'
import { LocationResponse, RecordStatus, Region } from '@traba/types'
import { isEqual } from 'lodash'
import { useMemo, useState } from 'react'
import { useAppContext } from 'src/context/appContext/AppContext'
import { useUserContext } from 'src/context/user/UserContext'
import { useLocationRegions } from 'src/hooks/useLocationRegions'
import { useLocations } from 'src/hooks/useLocations'
import {
  ARCHIVED_REGION_ID,
  getRegionalFilterFeatureKey,
  useRegionalFilterSelectAll,
} from 'src/hooks/useRegionalFilter'
import { OUTSIDE_REGION_ID } from 'src/hooks/useRegions'
import { theme } from 'src/libs/theme'
import { WebToRNEventName } from 'src/types/events'
import { getAddressString } from 'src/utils/stringUtils'
import { Button, ButtonVariant } from '../Button'
import Input from '../Input/Input'
import Row from '../Row'
import SvgIcon from '../SvgIcon'
import { Text } from '../Text'
import CheckboxGroups from './CheckboxGroups'

export const RegionalFilter: React.FC = () => {
  const {
    state: { userProfile },
  } = useUserContext()
  const { state: appState, dispatch: appDispatch } = useAppContext()
  const { showSuccess } = useAlert()
  const { isLoading: isRegionLoading, regions = [] } = useLocationRegions()
  const { isLoading: isLocationLoading, uniqueLocations } = useLocations()
  const { enableRegionalFilterSelectAll, disableRegionalFilterSelectAll } =
    useRegionalFilterSelectAll()

  const initialSelectedLocations = useMemo(
    () => new Set(Object.values(appState.regionalFilter ?? {}).flat()),
    [appState.regionalFilter],
  )

  const [selectedLocations, setSelectedLocations] = useState<Set<string>>(
    initialSelectedLocations,
  )
  const locationSelectionChanged = useMemo(() => {
    return !isEqual(selectedLocations, initialSelectedLocations)
  }, [selectedLocations, initialSelectedLocations])

  const [expandedRegions, setExpandedRegions] = useState<
    Record<string, boolean>
  >({})
  const [searchText, setSearchText] = useState('')

  const isLoading = isRegionLoading || isLocationLoading

  const regionMap = useMemo(() => {
    // Don't show outside region
    const validRegions = regions.filter(
      (region) => region.regionId !== OUTSIDE_REGION_ID,
    )
    return validRegions.reduce<Record<string, Region>>((acc, region) => {
      acc[region.regionId] = region
      return acc
    }, {})
  }, [regions])

  const [validLocations, regionLocationMap] = useMemo(() => {
    // Don't show locations in outside region
    const validLocations = uniqueLocations.filter(
      (location) => location.regionId !== OUTSIDE_REGION_ID,
    )
    return [
      validLocations,
      validLocations.reduce<Record<string, LocationResponse[]>>(
        (acc, location) => {
          const key =
            location.recordStatus === RecordStatus.Archived
              ? ARCHIVED_REGION_ID
              : location.regionId
          if (!acc[key]) {
            acc[key] = []
          }
          acc[key].push(location)
          return acc
        },
        {},
      ),
    ]
  }, [uniqueLocations])

  const allLocations = useMemo(() => {
    return new Set(validLocations.map((location) => location.locationId))
  }, [validLocations])

  const allExpandedRegions = useMemo(() => {
    return Object.keys(regionLocationMap).reduce<Record<string, boolean>>(
      (acc, regionId) => {
        acc[regionId] = true
        return acc
      },
      {},
    )
  }, [regionLocationMap])

  // RegionId to locations that match searchText
  const regionIdToFilteredLocations = useMemo(() => {
    return validLocations.reduce<Record<string, LocationResponse[]>>(
      (acc, location) => {
        const lowerCaseSearchText = searchText.toLowerCase()
        if (
          (location.name ?? 'N/A')
            .toLowerCase()
            .includes(lowerCaseSearchText) ||
          getAddressString(location.address)
            .toLowerCase()
            .includes(lowerCaseSearchText)
        ) {
          const key =
            location.recordStatus === RecordStatus.Archived
              ? ARCHIVED_REGION_ID
              : location.regionId
          if (!acc[key]) {
            acc[key] = []
          }
          acc[key].push(location)
          // addedActiveLocationIds.add(location.locationId)
        }
        return acc
      },
      {},
    )
  }, [searchText, validLocations])

  // Location ids that will be selected when "select all" is clicked
  const filteredLocationIds = useMemo(() => {
    if (searchText === '') {
      return Array.from(allLocations)
    }
    return Object.values(regionIdToFilteredLocations).reduce<string[]>(
      (acc, locations) => {
        locations.forEach((location) => acc.push(location.locationId))
        return acc
      },
      [],
    )
  }, [regionIdToFilteredLocations, allLocations, searchText])

  const selectAllFilteredLocations = () => {
    // If user is not searching, select or unselect all entire locations
    if (searchText === '') {
      if (selectedLocations.size === allLocations.size) {
        setSelectedLocations(new Set())
        setExpandedRegions({})
        return
      }
      setSelectedLocations(allLocations)
      setExpandedRegions(allExpandedRegions)
      return
    }

    // Check if all of the searched locations are already selected, if it is, unselect them
    const newSelectedLocations = new Set(selectedLocations)
    if (
      filteredLocationIds.every((locationId) =>
        selectedLocations.has(locationId),
      )
    ) {
      for (const locationId of filteredLocationIds) {
        newSelectedLocations.delete(locationId)
      }
    } else {
      for (const locationId of filteredLocationIds) {
        newSelectedLocations.add(locationId)
      }
    }

    setSelectedLocations(newSelectedLocations)
  }

  const handleEditedLocation = (
    locationId: string,
    newStoredRegionalFilter: Record<string, string[]>,
  ) => {
    // If there are non-active locations that are selected, we check if the location was edited and set the new edited location id (activeLocationId) in filter
    const selectedArchivedLocation = regionLocationMap[ARCHIVED_REGION_ID].find(
      (l) => l.locationId === locationId,
    )
    if (selectedArchivedLocation) {
      const activeLocationExists = uniqueLocations.some(
        (l) => l.locationId === selectedArchivedLocation.activeLocationId,
      )
      if (activeLocationExists) {
        const { regionId } = selectedArchivedLocation
        newStoredRegionalFilter[regionId] =
          newStoredRegionalFilter[regionId] || []
        newStoredRegionalFilter[regionId].push(locationId)
      }
    }
  }

  const handleValidLocation = (
    locationData: LocationResponse,
    newStoredRegionalFilter: Record<string, string[]>,
  ) => {
    if (locationData?.regionId) {
      if (!newStoredRegionalFilter[locationData.regionId]) {
        newStoredRegionalFilter[locationData.regionId] = []
      }
      newStoredRegionalFilter[locationData.regionId].push(
        locationData.locationId,
      )
    }
  }

  const saveSelectedLocations = async () => {
    // Set select all option to true if all locations are selected, otherwise, disable
    validLocations.length === selectedLocations.size
      ? enableRegionalFilterSelectAll()
      : disableRegionalFilterSelectAll()

    // RegionId to locationIds map
    const newStoredRegionalFilter: Record<string, string[]> = {}

    // Construct the new newStoredRegionalFilter from selectedLocations
    for (const locationId of selectedLocations) {
      const locationData = validLocations.find(
        (l) => l.locationId === locationId,
      )

      // If location is not found, check if the location was edited
      // If there are non-active locations that are selected, we check if the location was edited and set the new edited location id (activeLocationId) in filter
      if (!locationData) {
        handleEditedLocation(locationId, newStoredRegionalFilter)
        continue
      }

      handleValidLocation(locationData, newStoredRegionalFilter)
    }
    if (locationSelectionChanged) {
      // Update the state in case there is edited location
      setSelectedLocations(
        new Set(Object.values(newStoredRegionalFilter).flat()),
      )

      // Update the local storage and context
      appDispatch({
        type: 'SAVE_REGIONAL_FILTER',
        value: newStoredRegionalFilter,
        uid: userProfile?.uid ?? '', // User logged in so userProfile should not be null
      })
      // Reload the webview screens in supervisor app to fetch new data
      window.ReactNativeWebView?.postMessage(
        JSON.stringify({
          event: WebToRNEventName.RELOAD_WEBVIEW_SCREENS,
        }),
      )
    }

    showSuccess(
      'Your default location settings have been updated successfully.',
    )
    appDispatch({ type: 'HIDE_REGIONAL_FILTER' })
    window.analytics.track(`User Clicked Save Regional Filter`, {
      selectedLocations: selectedLocations,
    })

    // Mark that the user used regional filter feature so that they don't see the announcement again
    localStorage.setItem(
      getRegionalFilterFeatureKey(userProfile?.uid ?? ''),
      new Date().toISOString(),
    )
  }

  return isLoading ? (
    <LoadingSpinner />
  ) : (
    <>
      <Row
        justifyBetween
        alignCenter
        fullWidth
        style={{
          backgroundColor: theme.colors.White,
          borderBottomColor: theme.colors.Grey20,
          borderBottomWidth: 1,
          borderStyle: 'solid',
          paddingBottom: theme.space.xs,
        }}
      >
        <Text
          variant="h5"
          style={{
            marginLeft: theme.space.xs,
            marginTop: theme.space.xxs,
          }}
        >
          Select your default location
        </Text>
        <SvgIcon
          name={'cancel'}
          color={theme.colors.Grey60}
          size={20}
          style={{
            marginRight: theme.space.xs,
          }}
          onClick={() => appDispatch({ type: 'HIDE_REGIONAL_FILTER' })}
        />
      </Row>
      <div
        style={{
          paddingRight: theme.space.xxs,
          paddingLeft: theme.space.xxs,
        }}
      >
        <Input
          type="text"
          containerStyle={{
            marginTop: theme.space.xs,
            width: '20%',
          }}
          onChange={(e) => {
            setSearchText(e.target.value)

            if (e.target.value === '') {
              // When the search is not happening, recalculate the expanded regions
              setExpandedRegions(
                Object.keys(regionLocationMap).reduce<Record<string, boolean>>(
                  (acc, regionId) => {
                    const locations = regionLocationMap[regionId]
                    for (const location of locations) {
                      if (selectedLocations.has(location.locationId)) {
                        acc[regionId] = true
                        break
                      }
                    }
                    return acc
                  },
                  {},
                ),
              )
            } else {
              // When a search is ongoing, display all locations that match the search criteria
              setExpandedRegions(allExpandedRegions)
            }
          }}
          placeholder="Search for location"
          leftIconName="search"
        />

        <Row>
          <Text
            variant="h6"
            style={{
              marginLeft: theme.space.xxs,
              marginTop: theme.space.xxs,
              marginRight: theme.space.xs,
            }}
          >
            {filteredLocationIds.length}
            {' result'}
            {makePlural(filteredLocationIds.length)}
          </Text>
          <Text
            variant="link"
            color={theme.colors.brand}
            onClick={selectAllFilteredLocations}
            style={{ marginTop: theme.space.xxs }}
          >
            {selectedLocations.size === allLocations.size
              ? 'Unselect all'
              : 'Select all'}
          </Text>
        </Row>

        <div
          style={{
            overflowY: 'auto',
            height: !appState.isReactNativeApp ? '220px' : '60vh',
          }}
        >
          {validLocations.length === 0 ? (
            <Text
              variant="body2"
              style={{
                marginTop: theme.space.xxs,
                marginLeft: theme.space.xxs,
                color: theme.colors.MidnightBlue,
              }}
            >
              All of your company's current locations are not in active regions.
              Please create a new location where Traba is active by clicking
              "Account Settings" on the sidebar or contact support to inquire
              about using Traba in an inactive region.
            </Text>
          ) : (
            <CheckboxGroups
              selectedLocations={selectedLocations}
              setSelectedLocations={setSelectedLocations}
              regionMap={regionMap}
              expandedRegions={expandedRegions}
              setExpandedRegions={setExpandedRegions}
              searchText={searchText}
              regionIdToFilteredLocations={regionIdToFilteredLocations}
            />
          )}
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          paddingBottom: theme.space.xs,
        }}
      >
        <Row
          style={{
            justifyContent: 'space-between',
            marginTop: theme.space.zero,
            paddingTop: theme.space.xs,
            borderTopWidth: 1,
            borderTopColor: theme.colors.Grey20,
            borderTopStyle: 'solid',
          }}
        >
          <Row
            style={{
              justifyContent: 'flex-start',
              marginTop: theme.space.xxs,
            }}
          >
            {selectedLocations.size > 0 && (
              <>
                <Text variant="h6" style={{ marginLeft: theme.space.xs }}>
                  {selectedLocations.size} locations selected
                </Text>
                {!appState.isReactNativeApp && (
                  <>
                    <Text
                      variant="link"
                      style={{ marginLeft: theme.space.xs }}
                      onClick={() => {
                        setSelectedLocations(new Set())
                        if (searchText === '') {
                          setExpandedRegions({})
                        }
                      }}
                    >
                      Clear all
                    </Text>
                    <SvgIcon
                      name="cancel"
                      color={theme.colors.brand}
                      style={{
                        marginLeft: theme.space.xxxs,
                        marginTop: theme.space.xms,
                      }}
                      size={12}
                    />
                  </>
                )}
              </>
            )}
          </Row>

          <Row style={{ justifyContent: 'flex-end' }}>
            <Button
              onClick={() => appDispatch({ type: 'HIDE_REGIONAL_FILTER' })}
              variant={ButtonVariant.OUTLINED}
              style={{
                fontSize: appState.isReactNativeApp ? 'small' : undefined,
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={saveSelectedLocations}
              style={{
                marginLeft: theme.space.xs,
                marginRight: theme.space.xs,
                fontSize: appState.isReactNativeApp ? 'small' : undefined,
              }}
            >
              Save
            </Button>
          </Row>
        </Row>
      </div>
    </>
  )
}
