import { ClickAwayListener, Popper } from '@mui/material'
import { useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import {
  RegionalFilterStatus,
  useAppContext,
} from 'src/context/appContext/AppContext'
import useAppAuth from 'src/hooks/authHook'
import { useCompany } from 'src/hooks/useCompany'
import useMobile from 'src/hooks/useMobile'
import { useHotSettings } from 'src/hooks/useSystem'
import { theme } from 'src/libs/theme'
import { UserProfile } from 'src/types'

import { CompanyLogo, Row, Text } from '../base'
import { RegionalFilter } from '../base/RegionalFilterSelect/RegionalFilter'
import { RegionalFilterButton } from '../base/RegionalFilterSelect/RegionalFilterButton'
import VerticalLine from '../base/VerticalLine'
import { ShortLogo } from '../svgs/ShortLogo'
import * as S from './Header.styles'
import HeaderMenu from './HeaderMenu/HeaderMenu'

export type HeaderProps = {
  userProfile?: UserProfile
  showNotificationsBadge?: boolean
  className?: string
  style?: any
}

export function Header({ userProfile, ...rest }: HeaderProps) {
  const [isHeaderMenuOpened, setIsHeaderMenuOpened] = useState(false)
  const regionalFilterButtonRef = useRef(null)
  const { hotSettings } = useHotSettings()
  const {
    setIsSideBarOpen,
    state: { isSideBarOpen, regionalFilterStatus, isRegionalFilterOpened },
    dispatch: appContextDispatch,
  } = useAppContext()
  const { isReactNativeApp, isMobileViewOrReactNative } = useMobile()

  const closeHeaderMenu = () => setIsHeaderMenuOpened(false)
  const { handleLogout } = useAppAuth()
  const { company } = useCompany()

  const navigate = useNavigate()

  const RegionalFilterPopper = (
    <Popper
      anchorEl={regionalFilterButtonRef.current}
      open={isRegionalFilterOpened}
      placement="bottom-end"
      style={{
        width: isMobileViewOrReactNative ? '100%' : 553,
        height: 460,
        display: 'flex',
        flexDirection: 'column',
        borderRadius: 10,
        boxShadow: `0px 0px 10px 0px ${theme.colors.Violet30}`,
        backgroundColor: theme.colors.White,
        paddingTop: theme.space.xs,
        zIndex: 80, // Popper should be on top of everything
      }}
    >
      <RegionalFilter />
    </Popper>
  )

  return (
    <S.HeaderWrapper {...rest}>
      {/* ****** */}
      {/* Header */}
      {/* ****** */}
      <S.HamburgerWrapper onClick={() => setIsSideBarOpen(!isSideBarOpen)}>
        <S.HamburgerBox>
          <S.HamburgerInner $isOpen={isSideBarOpen} />
        </S.HamburgerBox>
      </S.HamburgerWrapper>

      <Row>
        {!isMobileViewOrReactNative && (
          <>
            <Link
              to="/"
              style={{
                display: 'flex',
                alignItems: 'center',
                marginRight: theme.space.sm,
              }}
            >
              <ShortLogo />
            </Link>

            <VerticalLine />

            <Row alignCenter>
              <div style={{ marginLeft: theme.space.sm }}>
                <CompanyLogo
                  style={{ width: 32, height: 32 }}
                  companyName={company?.employerName}
                  companyLogoUrl={company?.companyLogo}
                />
              </div>
              <div
                style={{
                  marginLeft: theme.space.xs,
                  marginRight: theme.space.sm,
                }}
              >
                <Text variant="h5">{company?.employerName}</Text>
              </div>
            </Row>
          </>
        )}

        {hotSettings?.regionalFiltersEnabled &&
          regionalFilterStatus !== RegionalFilterStatus.HIDE && (
            <>
              {!isMobileViewOrReactNative && (
                <VerticalLine style={{ marginRight: theme.space.xs }} />
              )}

              <RegionalFilterButton
                regionalFilterButtonRef={regionalFilterButtonRef}
              />
            </>
          )}
      </Row>

      {/* <Icon name="bell" width={18} /> */}
      {/* {showNotificationsBadge && <S.Badge />} */}
      <div style={{ display: 'flex' }}>
        <S.Text
          variant="h7"
          className="xs-hide md-show"
          style={{ alignItems: 'center' }}
        >
          {userProfile?.firstName} {userProfile?.lastName}
        </S.Text>

        {userProfile?.photoUrl ? (
          <S.ProfileImage
            src={userProfile?.photoUrl}
            className="meticulous-ignore"
          />
        ) : (
          <S.ProfileIcon name="userProfile" />
        )}
        <S.ChevronDownWrapper
          id="menu-dropdown-icon"
          onClick={() => setIsHeaderMenuOpened(!isHeaderMenuOpened)}
        >
          <S.ChevronDown $isActive={isHeaderMenuOpened} />
        </S.ChevronDownWrapper>
      </div>

      {/* Regional Filters */}
      {hotSettings?.regionalFiltersEnabled &&
        isRegionalFilterOpened &&
        (isReactNativeApp ? (
          RegionalFilterPopper
        ) : (
          <ClickAwayListener
            // Force onClickAway to be triggered when link is clicked
            mouseEvent="onMouseDown"
            touchEvent="onTouchStart"
            onClickAway={() => {
              if (isRegionalFilterOpened) {
                appContextDispatch({
                  type: 'HIDE_REGIONAL_FILTER',
                })
              }
            }}
          >
            {RegionalFilterPopper}
          </ClickAwayListener>
        ))}

      {/* HeaderMenu */}
      <HeaderMenu
        handleCloseHeaderMenu={closeHeaderMenu}
        isHeaderMenuOpened={isHeaderMenuOpened}
        menuOptions={[
          {
            label: 'Settings',
            iconName: 'settings',
            onClick: () => navigate('/account-settings'),
          },
          {
            label: 'Log out',
            iconName: 'logout',
            onClick: handleLogout,
          },
        ]}
      />
    </S.HeaderWrapper>
  )
}
