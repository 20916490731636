import { useQuery } from 'react-query'
import { trabaApi } from 'src/api/helpers'

export interface WorkHistoryItem {
  roleId: string
  count: number
  roleName: string
  roleDescription: string
  industry?: string
}

export interface WorkerWorkHistoryResponseDto {
  history: WorkHistoryItem[]
}

const getWorkerWorkHistory = async (
  workerId: string | undefined,
): Promise<WorkerWorkHistoryResponseDto | undefined> => {
  if (!workerId) {
    return undefined
  }
  try {
    const response = await trabaApi.get(
      `/my-company/workers/${workerId}/work-history`,
    )
    return response.data
  } catch (error) {
    console.log(error)
  }
}

export const useWorkerWorkHistory = (workerId: string | undefined) => {
  const {
    isLoading,
    isError,
    data: workHistory,
    error,
    isFetched,
  } = useQuery<WorkerWorkHistoryResponseDto | undefined, Error>(
    ['worker-history', workerId],
    () => getWorkerWorkHistory(workerId),
  )

  return {
    isLoading,
    isError,
    error,
    isFetched,
    workHistory,
  }
}
