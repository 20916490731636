import { LoadingSpinner, Row, Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { DailyViewShiftMetadata, JobStatus, Roster } from '@traba/types'
import { anyToDate } from '@traba/utils'
import { isAfter } from 'date-fns'
import { useState } from 'react'
import { ShiftInvitationsTable } from 'src/components/UpcomingShiftDetails/components/ShiftInvitationsTable'
import { useRoster } from 'src/hooks/useRoster'
import { useShiftInvitations } from 'src/hooks/useShiftInvitations'
import { useShiftById } from 'src/hooks/useShifts'
import { useWorkerShifts } from 'src/hooks/workerShiftHooks'
import { BookShiftsInvitationsModal } from 'src/screens/BookShifts/steps/sections/BookShiftsInvitationsModal'
import { ScheduleDailyViewShiftTitle } from './ScheduleDailyViewShiftTitle'

interface Props {
  shiftId: string
  individualMetadata: DailyViewShiftMetadata
}

export const ScheduleDailyViewShiftInvitation = (props: Props) => {
  const { shiftId, individualMetadata } = props
  const { shift, isLoading } = useShiftById(shiftId)
  const { shiftInvitations, rescindInvitation, sendInvitations } =
    useShiftInvitations({ shiftIds: [shiftId] })
  const { data: workerShifts, isLoading: workerShiftsLoading } =
    useWorkerShifts(shiftId)
  const [isInviteAdditionalOpen, setIsInviteAdditionalOpen] = useState(false)
  const [selectedRoster, setSelectedRoster] = useState<Roster | undefined>()
  const [selectedWorkers, setSelectedWorkers] = useState(new Set<string>())

  const { roster } = useRoster(selectedRoster?.id || '')
  const onClickWorker = (workerId: string) => {
    const newWorkers = new Set(selectedWorkers)
    if (selectedWorkers.has(workerId)) {
      newWorkers.delete(workerId)
    } else {
      newWorkers.add(workerId)
    }
    setSelectedWorkers(newWorkers)
  }

  const sendInvitationsToWorkers = async () => {
    sendInvitations({
      shiftId,
      workerIds: Array.from(selectedWorkers),
      includeRescinded: true,
    })
    setIsInviteAdditionalOpen(false)
  }

  if (isLoading || workerShiftsLoading) {
    return <LoadingSpinner />
  }

  if (!shift || !workerShifts) {
    return null
  }
  const isShiftInTheFuture = isAfter(anyToDate(shift?.startTime), new Date())

  return (
    <>
      <Row alignCenter justifyBetween>
        <ScheduleDailyViewShiftTitle individualMetadata={individualMetadata} />
        {isShiftInTheFuture && (
          <Text
            variant="link"
            onClick={() => {
              setIsInviteAdditionalOpen(true)
            }}
          >
            + Invite workers
          </Text>
        )}
      </Row>
      <ShiftInvitationsTable
        shift={shift}
        shiftInvitations={shiftInvitations}
        canceledWorkerShifts={workerShifts.filter(
          (ws) => ws.jobStatus === JobStatus.Canceled,
        )}
        rescindInvitation={rescindInvitation}
        onClickInviteWorker={() => {
          setIsInviteAdditionalOpen(true)
        }}
        styles={{ marginBottom: theme.space.sm, minHeight: theme.space.lg }}
        hideTitle
      />
      <BookShiftsInvitationsModal
        isOpen={isInviteAdditionalOpen}
        setIsOpen={setIsInviteAdditionalOpen}
        selectedRoster={selectedRoster}
        setSelectedRoster={setSelectedRoster}
        selectedWorkers={selectedWorkers}
        setSelectedWorkers={setSelectedWorkers}
        onConfirm={sendInvitationsToWorkers}
        roster={roster}
        disabledWorkerIdsSet={
          new Set(shiftInvitations?.map((invitation) => invitation.workerId))
        }
        onClickWorker={onClickWorker}
        locationId={shift.locationId}
        roleId={shift.roleId}
      />
    </>
  )
}
