import { Button, ButtonGroup } from '@mui/material'
import { LoadingSpinner } from '@traba/react-components'
import { NavigateAction, View } from 'react-big-calendar'
import {
  Button as TrabaButton,
  Row,
  SvgIcon,
  Text,
  ButtonVariant,
} from 'src/components/base'
import { RegionalFilterButton } from 'src/components/base/RegionalFilterSelect/RegionalFilterButton'
import useMobile from 'src/hooks/useMobile'
import { theme } from 'src/libs/theme'

interface Props {
  localizer: any // Adjust the type based on your localizer type
  onView: (view: View) => void
  onNavigate: (navigate: NavigateAction, date?: Date | undefined) => void
  label: string
  view: string
  isLoading: boolean
  regionalFiltersEnabled: boolean
  isFromScheduleDetails?: boolean
}
export const CalendarViewCustomToolbar = ({
  view,
  label,
  onView,
  onNavigate,
  isLoading,
  regionalFiltersEnabled,
  isFromScheduleDetails,
}: Props) => {
  const { isMobileViewOrReactNative, isReactNativeApp } = useMobile()

  const leftNavButton = (
    <TrabaButton
      onClick={() => onNavigate('PREV')}
      style={{
        paddingLeft: theme.space.xs,
        paddingRight: theme.space.xs,
        marginRight: theme.space.xxs,
      }}
      variant={ButtonVariant.OUTLINED}
      slim={isMobileViewOrReactNative}
    >
      <SvgIcon name="left" color={theme.colors.Grey60} />
    </TrabaButton>
  )

  const rightNavButton = (
    <Row style={{ maxWidth: '80px' }} justifyEnd alignCenter>
      {isLoading && <LoadingSpinner style={{ height: theme.space.med }} />}
      <TrabaButton
        onClick={() => onNavigate('NEXT')}
        style={{
          paddingRight: theme.space.xs,
        }}
        variant={ButtonVariant.OUTLINED}
        slim={isMobileViewOrReactNative}
      >
        <SvgIcon name="right" color={theme.colors.Grey60} />
      </TrabaButton>
    </Row>
  )

  return (
    <div
      style={
        isReactNativeApp && !isFromScheduleDetails
          ? {
              width: '100%',
              paddingLeft: theme.space.sm,
              paddingRight: theme.space.sm,
              alignItems: 'center',
              top: 0,
              paddingTop: theme.space.sm,
              right: 0,
              position: 'fixed',
              backgroundColor: 'white',
              zIndex: 100,
            }
          : {}
      }
    >
      <Row justifyBetween alignCenter>
        <Row
          style={{
            height: isMobileViewOrReactNative ? theme.space.lg : theme.space.xl,
          }}
        >
          <ButtonGroup variant={ButtonVariant.OUTLINED} fullWidth>
            <Button
              variant={view === 'month' ? 'contained' : 'outlined'}
              onClick={() => {
                onView('month')
              }}
            >
              Month
            </Button>
            <Button
              variant={view === 'week' ? 'contained' : 'outlined'}
              onClick={() => {
                onView('week')
              }}
            >
              Week
            </Button>
            <Button
              variant={view === 'day' ? 'contained' : 'outlined'}
              onClick={() => {
                onView('day')
              }}
            >
              Day
            </Button>
          </ButtonGroup>
        </Row>
        <Row>
          <TrabaButton
            onClick={() => onNavigate('TODAY')}
            style={{
              paddingLeft: theme.space.xs,
              paddingRight: theme.space.xs,
              marginRight: isMobileViewOrReactNative
                ? undefined
                : theme.space.sm,
              height: isMobileViewOrReactNative
                ? theme.space.lg
                : theme.space.xl,
            }}
            variant={ButtonVariant.OUTLINED}
          >
            TODAY
          </TrabaButton>
          {!isMobileViewOrReactNative && (
            <>
              {leftNavButton}
              {rightNavButton}
            </>
          )}
        </Row>
      </Row>
      <Row
        alignCenter
        justifyBetween={isMobileViewOrReactNative}
        style={{
          marginBottom: isMobileViewOrReactNative
            ? theme.space.zero
            : undefined,
        }}
      >
        {isMobileViewOrReactNative && leftNavButton}
        <Text
          variant={isMobileViewOrReactNative ? 'h5' : 'h3'}
          mt={theme.space.med}
          mb={theme.space.med}
        >
          {label}
        </Text>
        {isLoading && !isMobileViewOrReactNative && (
          <LoadingSpinner style={{ height: theme.space.med }} />
        )}
        {isMobileViewOrReactNative && rightNavButton}
      </Row>
      {/*
       * We only want to show the regional filter dropdown on the supervisor
       * app and not the mobile web view, since on mobile web the dropdown
       * should already exist and be visible on the header.
       */}
      {regionalFiltersEnabled && isReactNativeApp && (
        <Row
          style={{
            marginTop: theme.space.zero,
            marginBottom: theme.space.xxs,
          }}
        >
          <RegionalFilterButton />
        </Row>
      )}
    </div>
  )
}
