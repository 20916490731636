import { Region } from '@traba/types'
import { useQuery } from 'react-query'
import { trabaApi } from 'src/api/helpers'

const getRegions = async () => {
  try {
    const response = await trabaApi.get(`/my-company/location-regions`)
    return response.data
  } catch (error) {
    console.log(error)
  }
}

export const useLocationRegions = () => {
  const {
    isLoading,
    isError,
    data: regions,
    error,
    isFetched,
  } = useQuery<Region[], Error>('location-regions', getRegions)

  return {
    isLoading,
    isError,
    error,
    isFetched,
    regions,
  }
}
