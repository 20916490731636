import { useAlert } from '@traba/context'
import { LoadingSpinner } from '@traba/react-components'
import { recurringSchedulesEnabled } from '@traba/utils'
import { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import { MainLayout } from 'src/components'
import { useCompany } from 'src/hooks/useCompany'
import { useShiftRequestParent } from 'src/hooks/useShiftRequestParent'
import { useHotSettings } from 'src/hooks/useSystem'
import { DesktopScheduleDetails } from './DesktopScheduleDetails'

type ScheduleParams = {
  shiftRequestParentId: string
}

export default function ScheduleDetailsScreen() {
  const { shiftRequestParentId } = useParams<ScheduleParams>()
  const [searchParams] = useSearchParams()
  const targetTab = searchParams.get('tabs')

  const navigate = useNavigate()
  const { hotSettings } = useHotSettings()
  const {
    isError: errorWhenFetchingShiftRequestParent,
    isLoading,
    shiftRequestParent,
  } = useShiftRequestParent(shiftRequestParentId || '')
  const { showError } = useAlert()
  const { company } = useCompany()

  useEffect(() => {
    if (
      !recurringSchedulesEnabled({
        company,
        hotSettings,
      })
    ) {
      navigate('/dashboard')
    }
  }, [company, hotSettings, navigate])

  if (isLoading || !shiftRequestParentId || !shiftRequestParent) {
    return (
      <MainLayout title="Schedule Details">
        <LoadingSpinner />
      </MainLayout>
    )
  }

  if (
    errorWhenFetchingShiftRequestParent ||
    !shiftRequestParent.shiftRequests ||
    !shiftRequestParent.shiftRequests.length
  ) {
    showError(
      'Unable to fetch schedule information. Please try again in a few minutes...',
    )
    return null
  }

  return (
    <MainLayout title="Schedule Details">
      <DesktopScheduleDetails
        shiftRequestParent={shiftRequestParent}
        targetTab={targetTab}
      />
    </MainLayout>
  )
}
