import { Card, LoadingSpinner } from '@traba/react-components'
import { Roster, Worker } from '@traba/types'
import { compact } from 'lodash'
import { useState } from 'react'
import { Col, Row, SvgIcon, Text } from 'src/components'
import { Dialog } from 'src/components/base/Dialog/Dialog'
import { SelectWorkers } from 'src/components/SelectWorkers/SelectWorkers'
import { WorkerDetails } from 'src/hooks/useCompanyWorkers'
import { RosterWithWorkers } from 'src/hooks/useRoster'
import { useRosters } from 'src/hooks/useRosters'
import { useVirtualRosters } from 'src/hooks/useVirtualRosters'
import { theme } from 'src/libs/theme'

const RosterRow = ({
  id,
  name,
  numWorkers,
  onClickRoster,
}: {
  id: string
  name: string
  numWorkers: number
  onClickRoster: () => void
}) => {
  return (
    <Card
      key={id}
      style={{
        padding: theme.space.xs,
        marginBottom: theme.space.xxs,
      }}
      isClickable
      onClick={onClickRoster}
    >
      <Row alignCenter fullWidth justifyBetween>
        <Row alignCenter style={{ marginRight: theme.space.xxs }}>
          <SvgIcon
            name="twoUser"
            size={theme.space.sm}
            color={theme.colors.Grey50}
          />
          <Col
            style={{
              marginLeft: theme.space.xs,
            }}
          >
            <Text
              color={theme.colors.MidnightBlue}
              variant="h6"
              style={{
                textOverflow: 'ellipsis',
                overflow: 'hidden',
              }}
            >
              {`${name}`}
            </Text>
            <Text
              variant="body3"
              style={{
                textOverflow: 'ellipsis',
                overflow: 'hidden',
              }}
            >
              {`${numWorkers} workers`}
            </Text>
          </Col>
        </Row>
        <SvgIcon
          name="right"
          size={theme.space.sm}
          color={theme.colors.Grey50}
        />
      </Row>
    </Card>
  )
}

export function BookShiftsInvitationsModal({
  isOpen,
  setIsOpen,
  selectedRoster,
  setSelectedRoster,
  selectedWorkers,
  setSelectedWorkers,
  onConfirm,
  roster,
  onClickWorker,
  disabledWorkerIdsSet,
  confirmOnDismiss,
  locationId,
  roleId,
  isConfirming,
  onCloseModal,
}: {
  isOpen: boolean
  setIsOpen: (value: React.SetStateAction<boolean>) => void
  selectedRoster?: Roster
  setSelectedRoster: (value: React.SetStateAction<Roster | undefined>) => void
  selectedWorkers: Set<string>
  setSelectedWorkers: (value: React.SetStateAction<Set<string>>) => void
  onConfirm: () => void
  roster?: RosterWithWorkers
  onClickWorker: (workerId: string) => void
  disabledWorkerIdsSet?: Set<string>
  confirmOnDismiss?: boolean
  locationId?: string
  roleId?: string
  isConfirming?: boolean
  onCloseModal?: () => void
}) {
  const { virtualRosters, virtualRostersWorkersMap } = useVirtualRosters(
    locationId,
    roleId,
  )
  const [selectedVirtualRoster, setSelectedVirtualRoster] = useState<Roster>()
  const { rosters } = useRosters()

  const rosterSelected = selectedRoster || selectedVirtualRoster

  function SelectRosterSection() {
    return (
      <>
        {virtualRosters.map((roster) => (
          <RosterRow
            key={roster.id}
            id={roster.id}
            name={roster.rosterName}
            numWorkers={roster.workers.length}
            onClickRoster={() => setSelectedVirtualRoster(roster)}
          />
        ))}
        {rosters.map((roster) => (
          <RosterRow
            key={roster.id}
            id={roster.id}
            name={roster.rosterName}
            numWorkers={roster.workers.length}
            onClickRoster={() => setSelectedRoster(roster)}
          />
        ))}
      </>
    )
  }

  function createSelectWorkersSection(workers?: (Worker | WorkerDetails)[]) {
    return (
      <SelectWorkers
        selectedWorkers={selectedWorkers}
        setSelectedWorkers={setSelectedWorkers}
        onClickWorker={onClickWorker}
        workers={workers}
        disabledWorkerIdsSet={disabledWorkerIdsSet}
      />
    )
  }

  function SelectWorkersSection() {
    if (selectedVirtualRoster && virtualRosters) {
      return createSelectWorkersSection(
        compact(
          selectedVirtualRoster.workers.map((w) =>
            virtualRostersWorkersMap.get(w.workerId),
          ),
        ),
      )
    } else if (selectedRoster && roster) {
      return createSelectWorkersSection(roster?.workers.map((w) => w.worker))
    } else {
      return <LoadingSpinner />
    }
  }

  function getRosterName() {
    if (selectedVirtualRoster) {
      return selectedVirtualRoster.rosterName
    }
    if (selectedRoster) {
      return selectedRoster.rosterName
    }
  }

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      scroll="paper"
      open={isOpen}
      onDismiss={() => {
        setIsOpen(false)
        confirmOnDismiss && onConfirm()
      }}
      onClose={() => {
        setIsOpen(false)
        setSelectedRoster(undefined)
        setSelectedWorkers(new Set())
        if (onCloseModal) {
          onCloseModal()
        }
      }}
      onConfirmCTA="Invite Workers"
      onConfirm={() => {
        onConfirm()
        if (onCloseModal) {
          onCloseModal()
        }
      }}
      confirming={isConfirming}
      dialogTitle={
        rosterSelected ? (
          <Row alignCenter>
            <SvgIcon
              name="back"
              color={theme.colors.MidnightBlue}
              onClick={() => {
                setSelectedRoster(undefined)
                setSelectedWorkers(new Set())
                setSelectedVirtualRoster(undefined)
              }}
            />
            <Text ml="xxs" variant="h4" style={{ flex: 1 }}>
              {`Select workers from ${getRosterName()}`}
            </Text>
          </Row>
        ) : (
          'Select a roster'
        )
      }
      confirmDisabled={!selectedWorkers.size}
      formId="invite-workers"
    >
      {rosterSelected ? <SelectWorkersSection /> : <SelectRosterSection />}
    </Dialog>
  )
}
