import * as Sentry from '@sentry/react'
import { FileType, useFileUploader } from '@traba/hooks'
import { MODAL_SIZE } from '@traba/react-components'
import { LocationResponse } from '@traba/types'
import React, { useState } from 'react'
import { Dialog } from 'src/components/base/Dialog/Dialog'
import {
  LocationCreateOrEditForm,
  LocationCreateOrEditFormData,
} from 'src/components/LocationCreateOrEditForm/LocationCreateOrEditForm'
import { useLocations } from 'src/hooks/useLocations'
import { useUser } from 'src/hooks/useUser'

export type CreateLocationModalProps = {
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>
  onCreate?: (l: LocationResponse) => void
  /**
   * if editing a location, pass in the location to edit
   */
  location?: LocationResponse
}

export const CreateOrEditLocationModal = (props: CreateLocationModalProps) => {
  const { createLocation, editLocation } = useLocations()
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState<
    LocationCreateOrEditFormData | undefined
  >()
  const { user } = useUser()
  const { handleUpload } = useFileUploader()
  const [isValid, setIsValid] = useState(false)

  async function uploadFiles(files: File[]) {
    return await Promise.all(
      files.map((f: File) => {
        return handleUpload({
          fileType: FileType.COMPANY_IMAGES,
          media: f,
          userId: user?.uid,
        })
      }),
    )
  }

  async function handleCreate(
    createLocationFormData: LocationCreateOrEditFormData,
  ): Promise<void> {
    try {
      setLoading(true)
      const mediaFilePaths =
        createLocationFormData.newMediaFiles &&
        (await uploadFiles(createLocationFormData.newMediaFiles))
      const location = await createLocation({
        shortLocation: createLocationFormData.shortLocation || '',
        name: createLocationFormData.name,
        address: createLocationFormData.address,
        coords: createLocationFormData.coords,
        locationInstructions: createLocationFormData.locationInstructions,
        locationMedia: mediaFilePaths,
      })
      if (props.onCreate) {
        props.onCreate(location)
      }
      closeModal()
    } catch (err) {
      Sentry.captureException(err, { tags: { action: 'creating location' } })
    } finally {
      setLoading(false)
    }
  }

  async function handleEdit(
    editLocationFormData: LocationCreateOrEditFormData,
    location: LocationResponse,
  ): Promise<void> {
    try {
      setLoading(true)
      const newMediaFilePaths = editLocationFormData.newMediaFiles
        ? await uploadFiles(editLocationFormData.newMediaFiles)
        : []
      await editLocation({
        locationId: location.locationId,
        updatedLocation: {
          shortLocation: editLocationFormData.shortLocation,
          name: editLocationFormData.name,
          address: editLocationFormData.address,
          coords: editLocationFormData.coords,
          locationInstructions: editLocationFormData.locationInstructions,
          media: [
            ...editLocationFormData.existingMediaFiles,
            ...newMediaFilePaths,
          ],
        },
      })
      closeModal()
    } catch (err) {
      Sentry.captureException(err, { tags: { action: 'editing location' } })
    } finally {
      setLoading(false)
    }
  }

  function closeModal() {
    props.setShowModal(false)
  }

  return (
    <Dialog
      size={MODAL_SIZE.EXTRA_LARGE}
      dialogTitle={props.location ? 'Edit location' : 'Create location'}
      dialogTitleIcon={'location'}
      onConfirm={() => {
        if (formData) {
          if (props.location) {
            handleEdit(formData, props.location)
          } else {
            handleCreate(formData)
          }
        }
      }}
      onClose={closeModal}
      confirmDisabled={!formData || !isValid}
      onConfirmCTA={props.location ? 'Submit' : 'Create'}
      open={true}
      confirming={loading}
    >
      <LocationCreateOrEditForm
        onChange={(formData, error) => {
          setFormData(formData)
          if (error) {
            setIsValid(false)
          } else {
            setIsValid(true)
          }
        }}
        onCancel={closeModal}
        location={props.location}
      />
    </Dialog>
  )
}
