import { theme } from '@traba/theme'
import { InputStatus } from '@traba/types'
import { User, UserRole } from '@traba/types'
import { startCase } from 'lodash'
import React, { useCallback, useState } from 'react'
import { Button, ButtonVariant } from '../../../base-components/Button/Button'
import { ModalPaddingContainer } from '../../../base-components/Modal/Modal.styles'
import Row from '../../../base-components/Row'
import { SelectDropdown } from '../../../base-components/Select/Select'
import { SvgIcon } from '../../../base-components/SvgIcon'
import { Text } from '../../../base-components/Text'
import { MemberCard } from '../../MemberCard'

export type ArchiveUserModalContentProps = {
  member: Pick<User, 'email' | 'firstName' | 'lastName'> & {
    role?: UserRole
    uid?: string
  }
  replacementMembers: (Pick<User, 'firstName' | 'lastName'> & {
    uid?: string
  })[]
  handleClose: () => void
  onArchiveMember: (replacementUserId?: string) => void
  loading?: boolean
  activeShiftsError: boolean
}

const ARCHIVE_MEMBER_DESCRIPTION =
  'Archiving a member will deactivate their Traba account and remove them from the supervisor dropdown list when creating a shift. However, their information will continue to show up for any previous shifts they supervised.'
const ARCHIVE_CONTACT_DESCRIPTION =
  'Archiving a contact will remove them from the supervisor dropdown list when creating a shift. However, their information will continue to show up for any previous shifts they supervised.'

export function ArchiveUserModalContent(props: ArchiveUserModalContentProps) {
  const {
    member,
    handleClose,
    onArchiveMember,
    loading,
    replacementMembers,
    activeShiftsError,
  } = props
  const { firstName, lastName, email, role, uid } = member

  const [replacementUserId, setReplacementUserId] = useState<string>('')

  const handleArchiveMember = useCallback(() => {
    onArchiveMember(replacementUserId)
  }, [onArchiveMember, replacementUserId])

  const userType = role ? 'member' : 'contact'

  const showNoReplacementForActiveShiftError =
    activeShiftsError && replacementUserId === ''

  const availableReplacementUsersOptions = replacementMembers
    .filter((m) => !!m.uid && m.uid !== uid)
    .map((m) => ({
      value: m.uid as string,
      label: `${m.firstName} ${m.lastName}`,
    }))

  return (
    <ModalPaddingContainer>
      <Row justifyBetween pb={theme.space.xs}>
        <Text variant="h2">Archive {startCase(userType)}</Text>
        <SvgIcon
          size={24}
          color={theme.colors.Grey50}
          name="cancel"
          onClick={handleClose}
        />
      </Row>
      <Text variant="body2">
        {role ? ARCHIVE_MEMBER_DESCRIPTION : ARCHIVE_CONTACT_DESCRIPTION}
      </Text>

      <Row justifyBetween pt={theme.space.xs} pb={theme.space.xxs}>
        <Text variant="h5"> {startCase(userType)} to Archive</Text>
      </Row>
      <MemberCard
        firstName={firstName}
        lastName={lastName}
        email={email}
        role={role}
      />

      <Text
        style={{ marginTop: theme.space.xs, marginBottom: theme.space.xxs }}
        variant="h5"
      >
        Replacement Supervisor
      </Text>
      <Text variant="body2">
        You can select a replacement supervisor if you believe this {userType}{' '}
        is a supervisor on a current or upcoming shifts.
      </Text>
      <Row pt={theme.space.xs}>
        <SelectDropdown
          placeholder="Select a replacement supervisor"
          value={replacementUserId}
          handleSelect={setReplacementUserId}
          menuItems={[
            { label: `--`, value: '' },
            ...availableReplacementUsersOptions,
          ]}
          inputStatus={
            showNoReplacementForActiveShiftError ? InputStatus.error : undefined
          }
          errorMessage={`This ${userType} is a supervisor on current or upcoming shifts, please select a replacement.`}
          style={{ width: '100%' }}
        />
      </Row>

      <Row justifyBetween pt={theme.space.med}>
        <Button
          type="button"
          variant={ButtonVariant.OUTLINED}
          onClick={handleClose}
        >
          Back
        </Button>
        <Button
          variant={ButtonVariant.CANCEL}
          onClick={handleArchiveMember}
          loading={loading}
          disabled={showNoReplacementForActiveShiftError}
        >
          Archive
        </Button>
      </Row>
    </ModalPaddingContainer>
  )
}
