import { ShiftStatus } from '@traba/types'
import { useReducer } from 'react'

export interface CalendarFilters {
  regionId: string
  supervisorId: string
  locationId?: string
  workerId?: string
  workerIds?: string[]
  status?: ShiftStatus
  statuses?: ShiftStatus[]
}

interface SetFiltersAction {
  type: 'SET_FILTERS'
  filters: CalendarFilters
}

interface SetRegionAction {
  type: 'SET_REGION'
  regionId: CalendarFilters['regionId']
}

interface SetSupervisorAction {
  type: 'SET_SUPERVISOR'
  supervisorId: CalendarFilters['supervisorId']
}

interface SetStatusAction {
  type: 'SET_STATUS'
  status: CalendarFilters['status']
}

interface SetStatusesAction {
  type: 'SET_STATUSES'
  statuses: CalendarFilters['statuses']
}

interface SetLocationAction {
  type: 'SET_LOCATION'
  locationId: CalendarFilters['locationId']
}

interface SetWorkerAction {
  type: 'SET_WORKER'
  workerId: CalendarFilters['workerId']
}

interface SetWorkersAction {
  type: 'SET_WORKERS'
  workerIds: CalendarFilters['workerIds']
}

export type FiltersAction =
  | SetFiltersAction
  | SetRegionAction
  | SetSupervisorAction
  | SetStatusAction
  | SetLocationAction
  | SetStatusesAction
  | SetWorkerAction
  | SetWorkersAction

function filtersReducer(
  state: CalendarFilters,
  action: FiltersAction,
): CalendarFilters {
  switch (action.type) {
    case 'SET_FILTERS': {
      return action.filters
    }
    case 'SET_REGION': {
      return {
        ...state,
        regionId: action.regionId,
      }
    }
    case 'SET_SUPERVISOR': {
      return {
        ...state,
        supervisorId: action.supervisorId,
      }
    }
    case 'SET_LOCATION': {
      return {
        ...state,
        locationId: action.locationId,
      }
    }
    case 'SET_STATUS': {
      return {
        ...state,
        status: action.status,
      }
    }
    case 'SET_STATUSES': {
      return {
        ...state,
        statuses: action.statuses,
      }
    }
    case 'SET_WORKER': {
      return {
        ...state,
        workerId: action.workerId,
      }
    }
    case 'SET_WORKERS': {
      return {
        ...state,
        workerIds: action.workerIds,
      }
    }
    default: {
      return state
    }
  }
}
export const initialCalendarFilters: CalendarFilters = {
  regionId: '',
  supervisorId: '',
  statuses: [ShiftStatus.ACTIVE, ShiftStatus.COMPLETE],
}

export default function useCalendarFilters(
  initialFilters: CalendarFilters = initialCalendarFilters,
) {
  return useReducer(filtersReducer, initialFilters)
}
