import { useState } from 'react'
import { Col, InlineBanner, Row, Text } from 'src/components/base'
import { RadioButton } from 'src/components/RadioButton'
import { theme } from 'src/libs/theme'

interface Props {
  selection: boolean
  onClick: (selection: boolean) => void
}
export const BookShiftsRMSASection = ({ selection, onClick }: Props) => {
  const [selected, setSelected] = useState<boolean>(selection)
  return (
    <>
      <Text
        variant="h5"
        style={{
          margin: `${theme.space.sm}px 0 ${theme.space.xxs}px 0`,
        }}
      >
        Require workers to sign up for all shifts in this recurring schedule?
      </Text>
      <Text variant="body2" style={{ marginBottom: theme.space.xs }}>
        This will require workers to sign up for all of shifts in this schedule.
        They will not be able to sign up for just a single shift.
      </Text>
      <Row alignCenter style={{ columnGap: theme.space.xl }}>
        <Col>
          <Row
            alignCenter
            style={{ columnGap: theme.space.xs, cursor: 'pointer' }}
            onClick={() => {
              onClick(false)
              setSelected(false)
            }}
          >
            <RadioButton selected={!selected} />
            <Text variant="body1">No</Text>
          </Row>
        </Col>
        <Col>
          <Row
            alignCenter
            style={{ columnGap: theme.space.xs, cursor: 'pointer' }}
            onClick={() => {
              onClick(true)
              setSelected(true)
            }}
          >
            <RadioButton selected={selected} />
            <Text variant="body1">Yes</Text>
          </Row>
        </Col>
      </Row>
      {selected && (
        <InlineBanner
          text="Please note that getting the same workers is not always guaranteed due to cancellations, no shows or other circumstances."
          style={{ marginTop: theme.space.sm }}
        />
      )}
    </>
  )
}
