export enum TransitStatus {
  NotLeft = 'NOT_LEFT',
  InTransit = 'IN_TRANSIT',
  Arrived = 'ARRIVED',
}

export enum OnTimeStatus {
  ExpectedOnTime = 'EXPECTED_ON_TIME',
  ExpectedLate = 'EXPECTED_LATE',
  OnTime = 'ON_TIME',
  Late = 'LATE',
}

export type WorkerShiftTimeToDestination = {
  shiftId: string
  workerId: string
  transitStatus: TransitStatus
  timeToDestinationSeconds: number
  onTimeStatus: OnTimeStatus
  arrivedAt?: Date
  recordedAt: Date
}

export type WorkerShiftTimeToDestinationResponse = {
  shiftId: string
  workerId: string
  transitStatus: TransitStatus
  timeToDestinationSeconds: number
  onTimeStatus: OnTimeStatus
  arrivedAt?: string
  recordedAt: string
}

export type ShiftWorkerShiftTimeToDestinationResponse = {
  workerShiftTimeToDestinations: WorkerShiftTimeToDestinationResponse[]
}

export interface WorkerShiftTimeToDestinationResponseDto {
  shiftId: string
  workerId: string
  transitStatus: TransitStatus
  timeToDestinationSeconds: number
  onTimeStatus: OnTimeStatus
  arrivedAt?: string
}

export interface ShiftWorkerShiftTimeToDestinationResponseDto {
  workerShiftTimeToDestinations?: WorkerShiftTimeToDestinationResponseDto[]
}
