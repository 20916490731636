import { CreateShiftRequest, RoleInfoForCreateShiftRequest } from '@traba/types'

export function duplicateShiftRequest(
  shiftRequest: CreateShiftRequest,
  minHourlyPay?: number,
): CreateShiftRequest {
  const { roleId: _roleId, payRate, ...restOfShiftRequest } = shiftRequest
  return {
    ...restOfShiftRequest,
    payRate: minHourlyPay ?? payRate,
    roleId: '',
  }
}

export function getRoleInfoForCreateShiftRequest(
  shiftRequest: CreateShiftRequest,
  minHourlyPay?: number,
): RoleInfoForCreateShiftRequest {
  return {
    roleId: '',
    payType: shiftRequest.payType,
    payRate: minHourlyPay ?? shiftRequest.payRate,
    numberOfUnits: shiftRequest.numberOfUnits,
    slotsRequested: shiftRequest.slotsRequested,
    minSlotsRequested: shiftRequest.minSlotsRequested,
  }
}
