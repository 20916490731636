import { compact } from 'lodash'
import { Text } from 'src/components'
import { Autocomplete } from 'src/components/base/Autocomplete/Autocomplete'
import { SelectDropdown } from 'src/components/base/Select/Select'
import { EditableContentCard } from 'src/components/EditableContentCard/EditableContentCard'
import {
  matchesOutstandingOrAcceptedInvitation,
  MemberRoleInfoButton,
} from 'src/components/MembersProfile'
import { useInvitations, useMembers } from 'src/hooks/useMembers'
import { useUserPermissions } from 'src/hooks/useUser'
import { theme } from 'src/libs/theme'
import { USER_ROLE_OPTIONS, UserRole, UserRolePermission } from 'src/types'
import { validateEmail, validateUniqueEmails } from 'src/utils/stringUtils'

import { BookShiftsProps } from '../../BookShiftsScreen'
import { getContactCardEntries } from '../../utils'

export function BookShiftsSiteContactSection({
  shiftRequest,
  setShiftRequest,
  shiftRequestMetadata,
  setShiftRequestMetadata,
  isEdit,
}: BookShiftsProps) {
  const { supervisorId, additionalEmails } = shiftRequest
  const { invitedContactRole } = shiftRequestMetadata
  const { invitations } = useInvitations()
  const { members } = useMembers()
  const userCanInvite = useUserPermissions([UserRolePermission.ManageUserRoles])
  const contact = supervisorId
    ? members.find((m) => m.uid === supervisorId)
    : undefined

  const showInvitationOption =
    userCanInvite &&
    contact &&
    !contact.role &&
    !invitations?.find((i) =>
      matchesOutstandingOrAcceptedInvitation(contact, i),
    )

  if (!contact) {
    return null
  }

  return (
    <>
      <EditableContentCard
        style={{ marginTop: theme.space.xs, marginBottom: theme.space.sm }}
        sections={[{ entries: getContactCardEntries(contact) }]}
      />
      {showInvitationOption ? (
        <>
          <Text variant="h7">Invite contact to join Traba (optional)</Text>
          <Text style={{ marginTop: theme.space.xxs }} variant="body2">
            The member you‘ve selected as onsite contact is not currently on
            Traba. Select a role below if you'd like to invite them.
          </Text>
          <SelectDropdown<UserRole | ''>
            label="Role"
            style={{ marginTop: theme.space.xs }}
            className="xs-12 sm-6"
            menuItems={[{ value: '', label: 'None' }, ...USER_ROLE_OPTIONS]}
            value={invitedContactRole}
            handleSelect={(r) => {
              setShiftRequestMetadata({ invitedContactRole: r })
              window.analytics.track(`User Selected Role For Invited Contact`, {
                invitedContactRole: r,
                isEdit,
              })
            }}
          />
          <MemberRoleInfoButton
            style={{ marginTop: theme.space.xs, marginBottom: theme.space.sm }}
          />
        </>
      ) : null}
      <Text style={{ marginBottom: theme.space.xs }} variant="h7">
        Share shift details with additional team members (optional)
      </Text>
      <Autocomplete
        label="Email addresses"
        value={additionalEmails}
        options={compact(
          members.filter((m) => m.uid !== contact?.uid).map((m) => m.email),
        )}
        onChangeValues={(_, value) => {
          setShiftRequest({ additionalEmails: validateUniqueEmails(value) })
          window.analytics.track(`User Added Additional Emails for Shift`, {
            additionalEmails: value,
            isEdit,
          })
        }}
        errorMessage="Email address is invalid"
        validateInput={validateEmail}
      />
    </>
  )
}
