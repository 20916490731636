import { LoadingSpinner } from '@traba/react-components'
import { EmptyPlaceholderTile, Row } from 'src/components/base'
import { EmptyDataLocationSubtitle } from 'src/components/base/RegionalFilterSelect/EmptyDataLocationSubtitle'
import useMobile from 'src/hooks/useMobile'
import { useHotSettings } from 'src/hooks/useSystem'

interface Props {
  isLoading: boolean
  viewArchived?: boolean
}

export const MessageEmptyLoading = ({ isLoading, viewArchived }: Props) => {
  const { hotSettings } = useHotSettings()
  const { isMobileViewOrReactNative } = useMobile()
  return isLoading ? (
    <LoadingSpinner />
  ) : (
    <Row center>
      <EmptyPlaceholderTile
        iconName="info"
        title={
          viewArchived
            ? "You currently don't have any archived chats."
            : `You currently don't have any active messages${hotSettings?.regionalFiltersEnabled ? ' in the selected regions' : ''}.`
        }
        subtitle={
          hotSettings?.regionalFiltersEnabled && (
            <EmptyDataLocationSubtitle
              isMobileView={isMobileViewOrReactNative}
              extraText="to see messaging in those regions."
              style={{ textAlign: 'left' }}
            />
          )
        }
      />
    </Row>
  )
}
