export const DEFAULT_FENCE_RADIUS_METERS = 450
export const DEFAULT_TIMEZONE = 'America/New_York'
export const ONE_HUNDRED_DOLLARS_MONEY_DTO = { currency: 'USD', amount: 10000 }
export const BIZ_REFERRAL_AMOUNT = '500'

// TIME CONSTANTS
export const DAYS_IN_YEAR = 365
export const FIVE_MINUTES_IN_MS = 5 * 60 * 1000
export const ONE_MINUTE_IN_MS = 60000
export const ONE_HOUR_IN_MS = 3600000
export const THREE_WEEKS_IN_DAYS = 21

// PAGINATION CONSTANTS
export const DEFAULT_PAGE_SIZE = 10

export const INFINITE_SHIFT_REQ_CREATE_SHIFT_LEAD_TIME_WEEKS = 3

// FORM CONSTANTS
export const REQUIRED_FIELD_MESSAGE = 'This field is required'
export const INPUT_PASSWORD_MISMATCH = 'Must match password.'
export const INPUT_INVALID_EMAIL = 'Must be a valid email address.'
export const INPUT_INVALID_PASSWORD = 'Must be a valid password.'
export const INPUT_INVALID_PHONE = 'Must be a valid phone number.'
export const INPUT_INVALID_ZIPCODE = 'Must be a valid ZIP code.'
export const VALID_ADDRESS = 'Please choose an address from the dropdown'
