import * as Sentry from '@sentry/react'
import { useAlert } from '@traba/context'
import { User, getAuth, onAuthStateChanged } from 'firebase/auth'
import React from 'react'
import { trabaApi } from 'src/api/helpers'
import { useUserContext } from 'src/context/user/UserContext'
import { useQueryParams } from 'src/helpers'
import { decodeInvitation } from 'src/hooks/useMembers'

const errorMessageMap: Record<
  string,
  { errorTitle: string; errorDescription: string }
> = {
  'invitation/invalid-email': {
    errorTitle: 'There was an error with your invitation',
    errorDescription:
      'Please ensure that the email address matches the email on the invitation.',
  },
  'invitation/user-in-other-company': {
    errorTitle: 'There was an error with your invitation',
    errorDescription:
      'This email address is already associated with another company. Please contact your company’s admin for further details.',
  },
  'user-creation/okta-must-login-with-invitation': {
    errorTitle: 'There was an error with your sign-in',
    errorDescription:
      'You need to sign up using the Traba invitation. Please check your email for the Traba invitation. If you don’t have it, please contact the Traba admin at your company for assistance.',
  },
}

function parseUpsertError(errorMessage: string): {
  errorTitle: string
  errorDescription: string
} {
  if (errorMessage in errorMessageMap) {
    return errorMessageMap[errorMessage]
  }
  const errorPrefix = errorMessage.split('/')[0]
  if (errorPrefix === 'invitation') {
    return {
      errorTitle: 'There was an error with your invitation',
      errorDescription:
        'Please contact your company’s admin for further details.',
    }
  }
  return {
    errorTitle: 'There was an error during login',
    errorDescription:
      'Please contact our support team or your company’s admin for further details.',
  }
}

export default function AuthProvider({ children }: { children: JSX.Element }) {
  const userContext = useUserContext()
  const { handleError } = useAlert()
  const auth = getAuth()
  const query = useQueryParams()
  const invitation = query.get('invitation')

  /**
   * Fetches traba user object from api ad
   */
  async function fetchUserAndUpdateContext() {
    try {
      const trabaApiUser = (await trabaApi.get('/me')).data
      let company
      try {
        company = (await trabaApi.get('/my-company')).data
      } catch (err) {
        Sentry.captureException(err)
      }

      if (!trabaApiUser.uid) {
        throw new Error('UserLoginError')
      }

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { onboarding, createdAt, communicationPreferences, ...traits } =
        trabaApiUser // Removes onboarding

      const {
        employerName,
        companyId,
        category,
        createdAt: companyCreatedAt,
      } = company || {}

      window.analytics.identify(trabaApiUser.uid, {
        ...traits,
        phone: traits.phoneNumber,
        created: createdAt?.toISOString(),
        company: {
          name: employerName,
          id: companyId,
          industry: category,
          created: companyCreatedAt?.toISOString(),
        },
      })

      userContext.dispatch({ type: 'USER_LOGIN', value: trabaApiUser })
    } catch (error) {
      handleError(
        error,
        'AuthProvider -> fetchUserAndUpdateContext()',
        'Please try again.',
        'Failed to complete login',
        10000,
      )
      throw error
    }
  }

  async function upsertUser(user: User) {
    try {
      const email = user.email
      const fullName = user.displayName
      const fullNameArray = fullName?.split(' ')
      const firstName = fullNameArray ? fullNameArray[0] : ''
      const lastName = fullNameArray ? fullNameArray[1] : ''
      const uid = user.uid
      const photoUrl = user.photoURL
      const phoneNumber = user.phoneNumber
      await trabaApi.post('/users/upsert', {
        email,
        firstName,
        lastName,
        uid,
        photoUrl,
        phoneNumber,
        ...(invitation ? { invitation: decodeInvitation(invitation) } : {}),
      })
    } catch (error: any) {
      const errorMessage = error.message ?? ''
      if (errorMessage === 'invitation/already-accepted') {
        return
      }
      const { errorTitle, errorDescription } = parseUpsertError(errorMessage)
      handleError(
        error,
        'AuthProvider -> upsertUser()',
        errorDescription,
        errorTitle,
        10000,
      )
      throw error
    }
  }

  async function completeTokenExchange() {
    try {
      await trabaApi.post(`/users/auth-token`)
      await auth.currentUser?.getIdToken(true)
    } catch (error) {
      handleError(
        error,
        'AuthProvider -> completeTokenExchange()',
        'Please try again.',
        'Failed to complete login',
        10000,
      )
      throw error
    }
  }

  /**
   * Adds onAuthStateChanged listener that checks if user is
   * logged in and sets user context accordingly
   */
  React.useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          const email = user.email
          await upsertUser(user)
          window.analytics.identify(user.uid, {
            userId: user.uid,
            email,
          })
          window.analytics.track('User Logged In', {
            userId: user.uid,
            email,
          })

          // Exchange firebase token for traba token with custom claims
          await completeTokenExchange()
          await fetchUserAndUpdateContext()
        } catch (error: any) {
          userContext.dispatch({ type: 'USER_LOGOUT' })
          auth.signOut()
        }
      } else {
        userContext.dispatch({ type: 'USER_LOGOUT' })
      }
    })
    return unsubscribe
  }, [invitation])

  return children
}
