import { Skeleton } from '@mui/material'
import { recurringSchedulesEnabled } from '@traba/utils'
import { useState } from 'react'
import { Button, InlineBanner, Modal, Row } from 'src/components'
import { CreateOrEditInvoiceGroupModal } from 'src/components/Modals/InvoiceGroups/CreateOrEditInvoiceGroupModal'
import { useCompany } from 'src/hooks/useCompany'
import { useInvoiceGroups } from 'src/hooks/useInvoiceGroups'
import { useHotSettings } from 'src/hooks/useSystem'
import { theme } from 'src/libs/theme'
import EditShiftsSaveButton from 'src/screens/EditShifts/EditShiftsSaveButton'

import { BookShiftsProps } from '../BookShiftsScreen'
import { BookShiftStepSection } from '../components/BookShiftSection'
import {
  validateShiftEdits,
  validateSiteStep,
  validateSiteStepShiftDataModel,
} from '../validation'
import { BookShiftsInvoiceGroupSection } from './sections/BookShiftsInvoiceGroupSection'

type BookShiftsInvoiceContentModal = 'INVOICE_GROUP'

export function BookShiftsInvoiceContent(props: BookShiftsProps) {
  const {
    shiftRequest,
    setShiftRequest,
    shiftRequestMetadata,
    onContinue,
    onSaveChanges,
    selectedShifts,
    setShowSelectModal,
    shiftUpdates,
    isEdit,
    defaultShiftRequest,
  } = props
  const { hotSettings } = useHotSettings()
  const { company } = useCompany()

  const {
    activeInvoiceGroups: invoiceGroups,
    isLoading: isLoadingInvoiceGroups,
  } = useInvoiceGroups()

  const [modalType, setModalType] =
    useState<BookShiftsInvoiceContentModal | null>(null)
  function closeModal() {
    setModalType(null)
  }

  const { parentInvoiceGroupId } = shiftRequest

  const validationError = recurringSchedulesEnabled({
    company,
    hotSettings,
  })
    ? validateSiteStepShiftDataModel(shiftRequest, shiftRequestMetadata)
    : validateSiteStep(shiftRequest, shiftRequestMetadata)

  const editsError =
    selectedShifts &&
    defaultShiftRequest &&
    validateShiftEdits(defaultShiftRequest, selectedShifts, shiftUpdates)

  if (isLoadingInvoiceGroups) {
    return (
      <>
        {Array.from(Array(4)).map(() => (
          <>
            <Skeleton animation="pulse" width="100%" height="60px" />
            <Skeleton animation="pulse" width="100%" height="120px" />
          </>
        ))}
      </>
    )
  }

  return (
    <>
      <BookShiftStepSection
        section={{
          title: 'Invoice group',
          singular: 'invoice group',
          plural: 'invoice groups',
          options: invoiceGroups.map((group) => ({
            value: group.id,
            label: group.name,
          })),
          selected: parentInvoiceGroupId,
          onChange: (groupId: string) => {
            setShiftRequest({
              parentInvoiceGroupId: groupId,
            })
            window.analytics.track(`User Selected Invoice Group`, {
              parentInvoiceGroupId: groupId,
              isEdit,
            })
          },
          onCreate: () => {
            setModalType('INVOICE_GROUP')
            window.analytics.track(`User Clicked Create New Invoice Group`, {
              isEdit,
            })
          },
          description:
            "Use invoice groups to customize how you are invoiced and better reconcile your spend. If the group's shifts span over a week, it will be split into multiple invoices. Choose from an existing invoice group or create a new one below.",
          tooltip:
            'You can choose which shifts are grouped together to receive separate invoices by role, location, or event if desired. For example, if you had two locations for your company, you could create one reusable invoice group for each. Then, you would add all shifts at Location 1 to the Location 1 invoice group and would receive invoices grouped by location.',
          optional: true,
          contentExpanded: true,
          hide: false,
          Content: BookShiftsInvoiceGroupSection,
        }}
        bookShiftProps={props}
      />

      {isEdit && editsError && (
        <InlineBanner
          style={{ marginTop: theme.space.xs }}
          severity={'error'}
          text={editsError.message}
        />
      )}

      <Row style={{ justifyContent: 'flex-end', marginTop: theme.space.lg }}>
        {isEdit ? (
          <EditShiftsSaveButton
            onSaveChanges={onSaveChanges}
            validationError={editsError || validationError}
            selectedShifts={selectedShifts}
            setShowSelectModal={setShowSelectModal}
          />
        ) : (
          <Button onClick={onContinue} disabled={!!validationError}>
            Continue
          </Button>
        )}
      </Row>
      <Modal isOpen={modalType !== null} handleClose={closeModal}>
        <CreateOrEditInvoiceGroupModal
          onClose={closeModal}
          onCreateOrEdit={(newGroup) =>
            setShiftRequest({
              parentInvoiceGroupId: newGroup.id,
            })
          }
        />
      </Modal>
    </>
  )
}
