import { useHotSettings } from '@traba/hooks'
import { useCompany } from './useCompany'
import { useLocationRegions } from './useLocationRegions'

export function useShouldShowSearch(): {
  isLoading: boolean
  showSearch?: boolean
  error?: Error
} {
  const {
    company,
    isLoading: companyIsLoading,
    error: companyError,
  } = useCompany()
  const {
    regions,
    isLoading: regionsAreLoading,
    error: regionsError,
  } = useLocationRegions()
  const { hotSettings } = useHotSettings()
  if (companyIsLoading || regionsAreLoading) {
    return { isLoading: true }
  }
  if (companyError || regionsError) {
    return {
      isLoading: companyIsLoading || regionsAreLoading,
      error: companyError || regionsError || undefined,
    }
  }
  if (!company || companyIsLoading) {
    return {
      isLoading: true,
    }
  }
  if (!company.isApproved) {
    return {
      isLoading: false,
      showSearch: false,
    }
  }

  // Company specific override, created for DoorDash
  if (
    hotSettings?.disableFindNewWorkersForCompanyIds &&
    hotSettings.disableFindNewWorkersForCompanyIds.includes(company.companyId)
  ) {
    return {
      isLoading: false,
      showSearch: false,
    }
  }

  let hasShowSearchRegion = false
  let hasActivateSearchRegion = false
  for (const region of regions || []) {
    if (region.showSearch) {
      hasShowSearchRegion = true
    }
    if (region.activateSearch) {
      hasActivateSearchRegion = true
    }
  }

  return {
    isLoading: false,
    showSearch:
      (hasShowSearchRegion || company.showSearch) && hasActivateSearchRegion,
  }
}
