import * as Sentry from '@sentry/react'
import { useAlert } from '@traba/context'
import {
  Button,
  ButtonVariant,
  LoadingSpinner,
  SvgIcon,
  Text,
  ToText,
} from '@traba/react-components'
import { theme } from '@traba/theme'
import { ShiftRequestEditType } from '@traba/types'
import { getShiftTimeString } from '@traba/utils'
import { differenceInHours } from 'date-fns'
import { toUpper } from 'lodash'
import { Dispatch, SetStateAction, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Col, Row } from 'src/components'
import useMobile from 'src/hooks/useMobile'
import { createShiftRequestEdits } from 'src/hooks/useShiftRequestEdits'
import { ShiftAndAddress } from 'src/hooks/useShifts'
import { getDailyViewUrlSlug } from 'src/shared/utils/dateUtils'
import TimeField from '../base/AriaDatePicker/TimeField'
import { RoleIdToShiftMap } from './utils'

interface ScheduleDetailsShiftsMultiShiftTileProps {
  dateOfShift: Date
  roleIdsToShift: RoleIdToShiftMap
  shiftRequestParentId: string
  setShiftIdInEdit: Dispatch<SetStateAction<string | null>>
  shiftIdInEdit: string | null
  refetchShifts: () => void
}

export const ScheduleDetailsShiftsMultiShiftTile: React.FC<
  ScheduleDetailsShiftsMultiShiftTileProps
> = ({
  dateOfShift,
  roleIdsToShift,
  shiftRequestParentId,
  shiftIdInEdit,
  setShiftIdInEdit,
  refetchShifts,
}) => {
  const navigate = useNavigate()
  const [newStartTime, setNewStartTime] = useState<Date | null>(null)
  const [newEndTime, setNewEndTime] = useState<Date | null>(null)
  const [isUpdating, setIsUpdating] = useState(false)

  const STRINGIFIED_DATE_OF_SHIFT = JSON.stringify(dateOfShift)
  const { isMobileViewOrReactNative } = useMobile()
  const { showError, showSuccess } = useAlert()
  const MIN_HEIGHT_OF_COLS = isMobileViewOrReactNative ? '10%' : 110
  const border = `1px solid ${theme.colors.Grey20}`

  const options: Intl.DateTimeFormatOptions = {
    weekday: 'short',
    day: 'numeric',
  }
  const [day, weekday] = dateOfShift
    .toLocaleDateString('en-us', options)
    .split(' ')
  const roleArray = useMemo(
    () => Object.values(roleIdsToShift),
    [roleIdsToShift],
  )

  const onConfirmUpdate = async (shift: ShiftAndAddress) => {
    if (isUpdating) {
      return
    }
    if (!newStartTime && !newEndTime) {
      setShiftIdInEdit(null)
      return
    }
    setIsUpdating(true)
    try {
      await createShiftRequestEdits({
        edit: {
          originalStartTime: shift.originalStartTime,
          shiftRequestId: shift.shiftRequestId,
          editType: ShiftRequestEditType.SINGLE,
          ...(newStartTime && {
            startTimeHoursUtc: newStartTime.getUTCHours(),
            startTimeMinutes: newStartTime.getUTCMinutes(),
          }),
          ...(newEndTime && {
            endTimeHoursUtc: newEndTime.getUTCHours(),
            endTimeMinutes: newEndTime.getUTCMinutes(),
          }),
        },
      })
      refetchShifts()
      setNewEndTime(null)
      setNewStartTime(null)
      setShiftIdInEdit(null)
      setIsUpdating(false)
      showSuccess('Shift time updated successfully')
    } catch (error) {
      console.error(error, 'error')
      Sentry.captureException(error)
      setIsUpdating(false)
      showError('Failed to update shift time, please try again')
    }
  }

  return (
    <Row
      style={{
        minHeight: MIN_HEIGHT_OF_COLS,
        border,
        borderRadius: theme.space.xxs,
        overflow: 'hidden',
      }}
    >
      {/* Weekday and day */}
      <Col
        style={{
          width: MIN_HEIGHT_OF_COLS,
          borderRight: border,
          minHeight: MIN_HEIGHT_OF_COLS,
          padding: theme.space.xs,
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Text variant="caption" style={{ marginBottom: theme.space.xxs }}>
          {toUpper(weekday)}
        </Text>
        <Text variant="h4" color={theme.colors.MidnightBlue}>
          {day}
        </Text>
      </Col>

      {/* Start/end time and roles breakdown */}
      <Row
        wrap
        alignCenter
        style={{
          borderRight: border,
          flex: 1,
          rowGap: theme.space.xs,
          columnGap: theme.space.sm,
        }}
        px={theme.space.sm}
        py={theme.space.sm}
      >
        <Col>
          <Text variant="caption" style={{ marginBottom: theme.space.xxs }}>
            START TIME - END TIME
          </Text>
          {roleArray.map((shift, index) => (
            <Row alignCenter gap={theme.space.xxs}>
              {shiftIdInEdit === shift.shiftId ? (
                <>
                  <Col>
                    <TimeField
                      time={
                        newStartTime ??
                        shift.businessStartTime ??
                        shift.startTime
                      }
                      setTime={(newDate) => {
                        setNewStartTime(newDate)
                      }}
                      timezone={shift.timezone}
                    />
                  </Col>

                  <ToText />

                  <Col>
                    <TimeField
                      time={newEndTime ?? shift.endTime}
                      setTime={(newDate) => {
                        setNewEndTime(newDate)
                      }}
                      timezone={shift.timezone}
                    />
                  </Col>
                  {isUpdating ? (
                    <LoadingSpinner style={{ width: theme.space.sm }} />
                  ) : (
                    <SvgIcon
                      name="check"
                      onClick={() => onConfirmUpdate(shift)}
                    />
                  )}
                </>
              ) : (
                <Row alignCenter>
                  <Text
                    key={`${STRINGIFIED_DATE_OF_SHIFT}-startendtime-${index}`}
                    variant="body1"
                    color={theme.colors.MidnightBlue}
                  >
                    {getShiftTimeString(
                      shift.businessStartTime ?? shift.startTime,
                      shift.endTime,
                      shift.timezone,
                    )}
                  </Text>
                  {differenceInHours(shift.startTime, new Date()) > 18 && (
                    <SvgIcon
                      name={'edit'}
                      color={theme.colors.brand}
                      style={{ marginLeft: theme.space.xxs }}
                      onClick={() => {
                        setShiftIdInEdit(shift.shiftId)
                      }}
                    />
                  )}
                </Row>
              )}
            </Row>
          ))}
        </Col>
        <Col>
          <Text variant="caption" style={{ marginBottom: theme.space.xxs }}>
            ROLES
          </Text>
          {roleArray.map((shift, index) => (
            <Text
              key={`${STRINGIFIED_DATE_OF_SHIFT}-role-${index}`}
              variant="h6"
            >
              {shift.role?.roleName || shift.shiftRole}
            </Text>
          ))}
        </Col>
      </Row>
      {/* Workers information */}
      <Row
        alignCenter
        style={{
          padding: theme.space.xs,
          width: '40%',
        }}
        gap={theme.space.lg}
        justifyBetween
        wrap
      >
        <Col>
          <Text variant="caption" style={{ marginBottom: theme.space.xxs }}>
            WORKERS
          </Text>
          {roleArray.map((shift, index) => (
            <Text
              key={`${STRINGIFIED_DATE_OF_SHIFT}-slots-${index}`}
              variant="body1"
              color={theme.colors.MidnightBlue}
            >
              {`${shift.slotsFilled} / ${shift.slotsRequested}`}
            </Text>
          ))}
        </Col>

        <Button
          variant={ButtonVariant.TEXT}
          onClick={() => {
            navigate(
              `/schedule/${shiftRequestParentId}/${getDailyViewUrlSlug(dateOfShift)}`,
            )
          }}
          style={{
            color: theme.colors.brand,
            padding: 0,
          }}
        >
          Shift Details
          <SvgIcon
            name={'link'}
            color={theme.colors.brand}
            size={theme.space.xs}
            style={{ marginLeft: theme.space.xxs }}
          />
        </Button>
      </Row>
    </Row>
  )
}
