import { HTMLAttributes } from 'react'
import { theme } from 'src/libs/theme'

import { Button, ButtonVariant, Text } from '../base'
import * as S from './EditableContentCard.styles'

interface EditableContentCardHeaderProps {
  title: string
  onEdit?: () => void
}

function EditableContentCardHeader(props: EditableContentCardHeaderProps) {
  return (
    <S.EditableContentCardHeader justifyBetween>
      <Text variant="h5">{props.title}</Text>
      {props.onEdit ? (
        <Button
          onClick={props.onEdit}
          variant={ButtonVariant.TEXT}
          style={{
            fontSize: 12,
            padding: 0,
            color: theme.colors.brand,
          }}
        >
          Edit
        </Button>
      ) : null}
    </S.EditableContentCardHeader>
  )
}

interface EditableContentCardLabeledValueProps {
  label?: string
  value?: string | React.ReactNode
}

function EditableContentCardLabeledValue(
  props: EditableContentCardLabeledValueProps,
) {
  return (
    <S.EditableContentCardEntry>
      <div
        style={{
          textTransform: 'uppercase',
          fontSize: 11,
          fontWeight: 500,
          color: theme.colors.Grey50,
        }}
      >
        {props.label}
      </div>
      <Text variant="body1" style={{ fontSize: 13, lineHeight: '21px' }}>
        {props.value}
      </Text>
    </S.EditableContentCardEntry>
  )
}

interface EditableContentCardProps extends HTMLAttributes<HTMLDivElement> {
  sections: {
    title?: string
    onEdit?: () => void
    entries: { label: string; value: string | React.ReactNode }[]
  }[]
}

export function EditableContentCard(props: EditableContentCardProps) {
  return (
    <S.EditableContentCard {...props}>
      {props.sections.map((s) => (
        <S.EditableContentCardSection>
          {s.title ? (
            <EditableContentCardHeader title={s.title} onEdit={s.onEdit} />
          ) : null}
          <div>
            {s.entries.map((e) => (
              <EditableContentCardLabeledValue {...e} />
            ))}
          </div>
        </S.EditableContentCardSection>
      ))}
    </S.EditableContentCard>
  )
}
