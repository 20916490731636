import { Skeleton } from '@mui/material'
import { recurringSchedulesEnabled } from '@traba/utils'
import { useEffect, useState } from 'react'
import { Button, InlineBanner, Modal, Row } from 'src/components'
import { CreateOrEditLocationModal } from 'src/components/Modals/CreateOrEditLocationModal/CreateOrEditLocationModal'
import { useCompany } from 'src/hooks/useCompany'
import { getActiveRegion, useLocations } from 'src/hooks/useLocations'
import { useHotSettings } from 'src/hooks/useSystem'
import { theme } from 'src/libs/theme'
import EditShiftsSaveButton from 'src/screens/EditShifts/EditShiftsSaveButton'

import { BookShiftsProps } from '../BookShiftsScreen'
import { BookShiftStepSection, Section } from '../components/BookShiftSection'
import {
  validateShiftEdits,
  validateSiteStep,
  validateSiteStepShiftDataModel,
} from '../validation'
import { BookShiftsParkingLocationSection } from './sections/BookShiftsParkingLocationSection'
import { BookShiftsSiteLocationSection } from './sections/BookShiftsSiteLocationSection'

type BookShiftsSiteContentModal = 'LOCATION' | 'PARKING_LOCATION'

export function BookShiftsDetailsContentShiftDataModel(props: BookShiftsProps) {
  const { company } = useCompany()
  const {
    shiftRequest,
    setShiftRequest,
    shiftRequestMetadata,
    setShiftRequestMetadata,
    onContinue,
    onSaveChanges,
    selectedShifts,
    setShowSelectModal,
    shiftUpdates,
    isEdit,
    defaultShiftRequest,
  } = props

  const { activeLocations, isLoading: isLoadingLocations } = useLocations()
  const { hotSettings } = useHotSettings()

  const [modalType, setModalType] = useState<BookShiftsSiteContentModal | null>(
    null,
  )
  function closeModal() {
    setModalType(null)
  }

  const { locationId, parkingLocationId } = shiftRequest

  const postalCode = activeLocations.find((l) => l.locationId === locationId)
    ?.address?.postalCode

  const parkingPostalCode = activeLocations.find(
    (l) => l.locationId === parkingLocationId,
  )?.address?.postalCode

  useEffect(() => {
    async function checkActiveRegion() {
      if (postalCode) {
        const active = await getActiveRegion(postalCode)
        setShiftRequestMetadata({ activeRegion: !!active })
        !active &&
          window.analytics.track(
            `User Selected Location Outside of Active Regions`,
            { postalCode, isEdit },
          )
      }
    }

    checkActiveRegion()
  }, [postalCode, setShiftRequestMetadata, isEdit])

  useEffect(() => {
    async function checkActiveRegion() {
      if (parkingPostalCode) {
        const active = await getActiveRegion(parkingPostalCode)
        setShiftRequestMetadata({ activeParkingRegion: !!active })
        !active &&
          window.analytics.track(
            `User Selected Parking Location Outside of Active Regions`,
            { parkingPostalCode, isEdit },
          )
      }
    }

    checkActiveRegion()
  }, [parkingPostalCode, setShiftRequestMetadata, isEdit])

  const baseLocationOptions = {
    singular: 'location',
    plural: 'locations',
    options: activeLocations.map((l) =>
      l.name
        ? {
            value: l.locationId,
            label: l.name,
            subtitle: `${l.address.street1}, ${l.address.city}, ${l.address.state}`,
          }
        : {
            value: l.locationId,
            label: `${l.address.street1}, ${l.address.city}, ${l.address.state}`,
          },
    ),
  }

  const sections: Array<Section> = [
    {
      ...baseLocationOptions,
      title: 'Work site location',
      selected: locationId,
      onChange: (v: string) => {
        const location = activeLocations.find((l) => l.locationId === v)
        setShiftRequest({
          locationId: v,
          schedules: location
            ? [
                {
                  ...shiftRequest.schedules[0],
                  timeZone: location.timezone,
                },
              ]
            : shiftRequest.schedules,
        })
        setShiftRequestMetadata({
          locationName: location?.name || location?.shortLocation,
        })
        window.analytics.track(`User Selected Location`, {
          locationId: v,
          isEdit,
        })
      },
      onCreate: () => {
        setModalType('LOCATION')
        window.analytics.track(`User Clicked Create New Location`, { isEdit })
      },
      optional: false,
      hide: false,
      contentExpanded: true,
      errorMessage:
        'The location in the previous shift request was archived. Please select a new location.',
      Content: BookShiftsSiteLocationSection,
    },
    {
      ...baseLocationOptions,
      title: 'Parking location',
      selected: parkingLocationId || '',
      onChange: (v: string) => {
        setShiftRequest({
          parkingLocationId: v,
        })
        window.analytics.track(`User Selected Parking Location`, {
          parkingLocationId: v,
          isEdit,
        })
      },
      onCreate: () => {
        setModalType('PARKING_LOCATION')
        window.analytics.track(`User Clicked Create New Parking Location`, {
          isEdit,
        })
      },
      hide: false,
      contentExpanded: shiftRequestMetadata.parkingLocationExpanded,
      checkboxLabel: 'Same as work site location',
      errorMessage:
        'The parking location in the previous shift request was archived. Please select a new parking location.',
      onClickCheckbox: () => {
        shiftRequestMetadata.parkingLocationExpanded &&
          setShiftRequest({ parkingLocationId: '' })
        setShiftRequestMetadata({
          parkingLocationExpanded:
            !shiftRequestMetadata.parkingLocationExpanded,
        })
      },
      Content: BookShiftsParkingLocationSection,
    },
  ]

  const validationError = recurringSchedulesEnabled({
    company,
    hotSettings,
  })
    ? validateSiteStepShiftDataModel(shiftRequest, shiftRequestMetadata)
    : validateSiteStep(shiftRequest, shiftRequestMetadata)

  const editsError =
    selectedShifts &&
    defaultShiftRequest &&
    validateShiftEdits(defaultShiftRequest, selectedShifts, shiftUpdates)

  if (isLoadingLocations) {
    return (
      <>
        {Array.from(Array(4)).map(() => (
          <>
            <Skeleton animation="pulse" width="100%" height="60px" />
            <Skeleton animation="pulse" width="100%" height="120px" />
          </>
        ))}
      </>
    )
  }

  return (
    <>
      {sections
        .filter((s) => !s.hide)
        .map((s, i, arr) => (
          <BookShiftStepSection
            section={s}
            bookShiftProps={props}
            noDivider={i === arr.length - 1}
          />
        ))}

      {isEdit && editsError && (
        <InlineBanner
          style={{ marginTop: theme.space.xs }}
          severity={'error'}
          text={editsError.message}
        />
      )}

      <Row style={{ justifyContent: 'flex-end', marginTop: theme.space.lg }}>
        {isEdit ? (
          <EditShiftsSaveButton
            onSaveChanges={onSaveChanges}
            validationError={editsError || validationError}
            selectedShifts={selectedShifts}
            setShowSelectModal={setShowSelectModal}
          />
        ) : (
          <Button onClick={onContinue} disabled={!!validationError}>
            Continue
          </Button>
        )}
      </Row>
      <Modal isOpen={modalType !== null} handleClose={closeModal}>
        {modalType === 'LOCATION' ? (
          <CreateOrEditLocationModal
            setShowModal={closeModal}
            onCreate={(l) =>
              setShiftRequest({
                locationId: l.locationId,
                schedules: [
                  {
                    ...shiftRequest.schedules[0],
                    timeZone: l.timezone,
                  },
                ],
              })
            }
          />
        ) : modalType === 'PARKING_LOCATION' ? (
          <CreateOrEditLocationModal
            setShowModal={closeModal}
            onCreate={(l) =>
              setShiftRequest({ parkingLocationId: l.locationId })
            }
          />
        ) : null}
      </Modal>
    </>
  )
}
