import { Badge } from '@mui/material'
import Fab from '@mui/material/Fab'
import Popper from '@mui/material/Popper'
import { ChatSummary } from '@traba/types'
import React, { useEffect, useState } from 'react'
import { Divider, Modal, Row, SvgIcon, Text } from 'src/components/base'
import { useAppContext } from 'src/context/appContext/AppContext'
import { postChats, useChats } from 'src/hooks/useChats'
import useMobile from 'src/hooks/useMobile'
import { theme } from 'src/libs/theme'
import { openInNewTab } from 'src/utils/miscUtils'

import MessageListItem from './MessageListItem'
import { MessagesPanel } from './MessagesPanel'

export const ChatWidget = () => {
  const appContext = useAppContext()
  const {
    state: { isChatWidgetOpen, selectedWorkerId },
    setIsChatWidgetOpen,
  } = appContext
  const fabRef = React.useRef<HTMLButtonElement>(null)
  const [selectedImageUrl, setSelectedImageUrl] = useState<string | undefined>()
  const [selectedChat, setSelectedChat] = useState<ChatSummary | undefined>(
    undefined,
  )
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const isPanelOpen = selectedChat !== undefined
  const { chats, refetch, unReadChats } = useChats()
  const { isReactNativeApp, isMobileViewOrReactNative } = useMobile()

  const handleClickOnFab = (event: React.MouseEvent<HTMLElement>) => {
    if (!selectedWorkerId) {
      setIsChatWidgetOpen(!isChatWidgetOpen)
    }
    setAnchorEl(event.currentTarget)
  }

  const handleGoToMessagingCenterClick = () => {
    openInNewTab('/messaging')
    setIsChatWidgetOpen(false)
  }

  const handleClickClose = () => {
    setIsChatWidgetOpen(false)
    setSelectedChat(undefined)
  }

  useEffect(() => {
    const chat = chats?.find(
      (chat) => chat.recipient.workerId === selectedWorkerId,
    )

    if (chat) {
      fabRef.current?.click()
      setSelectedChat(chat)
    } else if (selectedWorkerId) {
      postChats({}, [selectedWorkerId])
      refetch()
    }
  }, [chats, refetch, selectedWorkerId])

  return isReactNativeApp ? null : (
    <>
      <Badge
        badgeContent={unReadChats?.length}
        color="error"
        style={{
          position: 'fixed',
          bottom: isMobileViewOrReactNative ? 10 : 40,
          right: isMobileViewOrReactNative ? 10 : 60,
          zIndex: 2,
        }}
      >
        <Fab
          ref={fabRef}
          onClick={handleClickOnFab}
          style={{
            backgroundColor: theme.colors.Violet,
            width: 50,
            height: 50,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '30%',
            cursor: 'pointer',
          }}
        >
          <SvgIcon name="message" fill={theme.colors.White} />
        </Fab>
      </Badge>
      <Popper
        anchorEl={anchorEl}
        open={isChatWidgetOpen}
        style={{
          width: isMobileViewOrReactNative ? '100vw' : 400,
          height: isMobileViewOrReactNative ? '80vh' : 600,
          display: 'flex',
          flexDirection: 'column',
          borderRadius: 10,
          boxShadow: `0px 0px 10px 0px ${theme.colors.Violet30}`,
          backgroundColor: theme.colors.White,
          overflow: isPanelOpen ? 'hidden' : 'auto',
          paddingRight: theme.space.xxs,
          paddingLeft: theme.space.xxs,
          paddingTop: theme.space.xs,
          zIndex: 100, // Popper should be on top of everything
        }}
        placement="top-end"
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [
                0,
                isMobileViewOrReactNative ? theme.space.xxs : theme.space.xs,
              ],
            },
          },
        ]}
      >
        {isPanelOpen ? (
          <MessagesPanel
            isMobileView={isMobileViewOrReactNative}
            setSelectedChat={setSelectedChat}
            handleGoToMessagingCenterClick={handleGoToMessagingCenterClick}
            handleClickClose={handleClickClose}
            selectedChat={selectedChat}
            refetchChats={refetch}
          />
        ) : (
          <>
            <Row
              pl={theme.space.xs}
              pb={theme.space.xs}
              alignCenter
              justifyBetween
              fullWidth
            >
              <Text variant="h4">Messages</Text>
              <Row>
                <SvgIcon
                  name={'link'}
                  color={theme.colors.Grey60}
                  size={20}
                  style={{ marginRight: theme.space.xxs }}
                  onClick={handleGoToMessagingCenterClick}
                />
                <SvgIcon
                  name={'cancel'}
                  color={theme.colors.Grey60}
                  size={20}
                  onClick={handleClickClose}
                />
              </Row>
            </Row>
            <Divider />
            <div style={{ flexGrow: 1, overflowY: 'scroll' }}>
              {chats?.map((chat: ChatSummary, index: number) => (
                <MessageListItem
                  chat={chat}
                  key={index}
                  selected={false}
                  isPanelOpen={false}
                  onClick={() => {
                    setSelectedChat(chat)
                  }}
                  isFromChatWidget
                />
              ))}
            </div>
          </>
        )}
      </Popper>
      <Modal
        isOpen={!!selectedImageUrl}
        handleClose={() => setSelectedImageUrl(undefined)}
      >
        <img
          src={selectedImageUrl}
          style={{ maxHeight: 800, padding: theme.space.xl }}
        />
      </Modal>
    </>
  )
}
