import { MODAL_SIZE } from '@traba/react-components'
import { isBefore } from 'date-fns'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Button,
  ButtonVariant,
  EmptyPlaceholderTile,
  Icon,
} from 'src/components/base'
import { Modal } from 'src/components/base/Modal/Modal'
import { SupportModalContent } from 'src/components/SupportModal/SupportModalContent'
import { useUserPermissions } from 'src/hooks/useUser'
import { UserRolePermission } from 'src/types'

import InvoiceStatusBadge from '../InvoiceStatusBadge/InvoiceStatusBadge.ui'
import Skeletons from '../Skeletons/Skeletons'
import { Table, Td, Tr } from '../Table/Table'
import { InvoiceTile } from './InvoiceButtons/InvoiceButtons.ui'
import { StripeInvoiceButton } from './InvoiceButtons/StripeInvoiceButton'
import { InvoiceFilters } from './InvoiceFilters'
import * as S from './InvoiceTable.styles'
import { Filters, FiltersAction } from './useInvoiceFilters.hooks'
import { headers, InvoiceTableRow } from './utils'

export interface InvoiceTableProps {
  isLoading: boolean
  isError: boolean
  data: InvoiceTableRow[]
  error: any
  index: number
  filters: Filters
  dispatch: React.Dispatch<FiltersAction>
}

export const InvoiceTableUI: React.FC<InvoiceTableProps> = (props) => {
  const userCanManageInvoices = useUserPermissions([
    UserRolePermission.ManageInvoices,
  ])
  const navigate = useNavigate()
  const [stripeInvoiceNumber, setStripeInvoiceNumber] = useState<
    string | null | undefined
  >(null)
  const { data = [], isLoading, isError } = props

  if (isLoading) {
    return <Skeletons count={1} component="INVOICE_TABLE" />
  }

  if (isError) {
    return <div>There was an issue retrieving your invoices</div>
  }

  return (
    <>
      <S.InvoiceTableContainer>
        <InvoiceFilters {...props} />
        {data?.length < 1 ? (
          <EmptyPlaceholderTile
            title="Your invoices will appear here"
            subtitle="Invoices are created weekly for the prior week's booked shifts, and have a 30 day payment policy."
            iconName="billing_active"
          />
        ) : (
          <Table headers={headers}>
            {data?.map((row: InvoiceTableRow, index: number) => {
              const isPastDueDate =
                row.dueDate && isBefore(row.dueDate, new Date())
              const isInvoicePaid = row.status === 'paid'
              return (
                <Tr key={index}>
                  {row.cells.map((cell: string, index: number) =>
                    index == 0 ? (
                      <InvoiceTile
                        invoiceNumber={cell}
                        invoiceId={row.invoiceId}
                        key={index}
                      />
                    ) : (
                      <Td
                        onClick={() => navigate(`/billing/${row.invoiceId}`)}
                        style={{ cursor: 'pointer' }}
                        key={index}
                      >
                        {cell}
                      </Td>
                    ),
                  )}
                  <Td>
                    <InvoiceStatusBadge
                      invoiceStatus={row.status}
                      isPastDueDate={isPastDueDate}
                    />
                  </Td>
                  <S.TdMultiRight>
                    {userCanManageInvoices ? (
                      <Button
                        slim
                        variant={ButtonVariant.OUTLINED}
                        disabled={isInvoicePaid}
                        leftIcon={<Icon name="send" />}
                        onClick={() =>
                          setStripeInvoiceNumber(row.stripeInvoiceNumber)
                        }
                      >
                        Dispute
                      </Button>
                    ) : null}
                    <StripeInvoiceButton
                      isInvoicePaid={isInvoicePaid}
                      stripeInvoiceId={row.stripeInvoiceId}
                      invoiceUrl={row.hostedInvoiceUrl}
                      slim={true}
                      userCanManageInvoices={userCanManageInvoices}
                    />
                  </S.TdMultiRight>
                </Tr>
              )
            })}
          </Table>
        )}
      </S.InvoiceTableContainer>
      <Modal
        isOpen={!!stripeInvoiceNumber}
        size={MODAL_SIZE.SMALL}
        handleClose={() => setStripeInvoiceNumber(null)}
      >
        <SupportModalContent
          stripeInvoiceNumber={stripeInvoiceNumber}
          handleClose={() => setStripeInvoiceNumber(null)}
        />
      </Modal>
    </>
  )
}
