import { ScheduleStrip } from '@traba/react-components'
import { theme } from '@traba/theme'
import { ShiftRequestParentWithShiftRequest } from '@traba/types'
import React, { Dispatch, SetStateAction } from 'react'
import { Col, Divider, Icon, Row, Text } from 'src/components'
import { RadioButton } from 'src/components/RadioButton'
import styled from 'styled-components'

type SelectScheduleCardProps = {
  shiftRequestParent: ShiftRequestParentWithShiftRequest
  onSelect: Dispatch<SetStateAction<string | null>>
  selected: boolean
}

const HoverCol = styled(Col)`
  border: 1px solid ${theme.colors.Grey20};
  border-radius: ${theme.space.xxs}px;
  &:hover {
    border-color: ${theme.colors.Grey30};
    cursor: pointer;
  }
`

export function SelectScheduleCard(props: SelectScheduleCardProps) {
  const { shiftRequestParent, selected, onSelect } = props
  const { shortLocation, schedules } = shiftRequestParent.shiftRequests[0]

  return (
    <Row
      pt={theme.space.xs}
      fullWidth
      gap={theme.space.xxs}
      onClick={() => onSelect(shiftRequestParent.shiftRequestParentId)}
    >
      <RadioButton size={theme.space.sm} selected={selected} />
      <HoverCol>
        <Text variant="h6" style={{ padding: '4px 12px' }}>
          {shiftRequestParent.title}
        </Text>
        <Divider />
        <Row
          alignCenter
          py={theme.space.ms}
          px={theme.space.xsmed}
          gap={theme.space.ms}
        >
          <Icon name="location" />
          <Text variant="body3">{shortLocation}</Text>
        </Row>
        <Divider />
        <Row alignCenter py={theme.space.ms} px={theme.space.xsmed}>
          <ScheduleStrip schedules={schedules} />
        </Row>
        <Divider />
        {/* Role Rows */}
        {shiftRequestParent.shiftRequests.map((shiftRequest) => (
          <React.Fragment key={shiftRequest.shiftRequestId}>
            <Row
              alignCenter
              py={theme.space.ms}
              px={theme.space.xsmed}
              gap={theme.space.ms}
            >
              <Icon name="workers" />
              <Text variant="body3">{`${shiftRequest.slotsRequested} ${shiftRequest.shiftRole} @ $${shiftRequest.payRate}/hr`}</Text>
            </Row>
            <Divider />
          </React.Fragment>
        ))}
      </HoverCol>
    </Row>
  )
}
