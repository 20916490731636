import { ModalPaddingContainer } from '@traba/react-components'
import React from 'react'
import { Row, Text } from 'src/components/base'
import { theme } from 'src/libs/theme'
import { UserData } from 'src/types'

import { CreateMember } from '../../CreateMember'
import { ButtonIcon } from '../../PersonalProfile/PersonalProfile.styles'

export type CreateMemberModalProps = {
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>
  onCreate?: (m: UserData) => void
}

export const CreateMemberModal = (props: CreateMemberModalProps) => {
  return (
    <ModalPaddingContainer>
      <Row justifyBetween style={{ marginBottom: theme.space.xs }}>
        <Text variant="h2">Create contact</Text>
        <ButtonIcon name="cancel" onClick={() => props.setShowModal(false)} />
      </Row>
      <CreateMember
        setShowModal={props.setShowModal}
        onCreate={props.onCreate}
      />
    </ModalPaddingContainer>
  )
}
