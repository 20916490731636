import { TabPanel, Tab, Tabs, TabsContainer } from '@traba/react-components'
import { useEffect, useState } from 'react'
import { MainLayout } from 'src/components'
import { Text } from 'src/components/base'
import InvoiceDisclaimerText from 'src/components/InvoiceDetailsTable/InvoiceDisclaimerText/InvoiceDisclaimerText'
import { InvoiceTable } from 'src/components/InvoiceTable/InvoiceTable'
import {
  RegionalFilterStatus,
  useAppContext,
} from 'src/context/appContext/AppContext'

export default function BillingScreen() {
  const { dispatch: appContextDispatch } = useAppContext()
  const [currentTab, setCurrentTab] = useState(0)
  useEffect(() => {
    appContextDispatch({
      type: 'SET_REGIONAL_FILTER_STATUS',
      value: RegionalFilterStatus.DISABLED,
    })
    return () => {
      appContextDispatch({
        type: 'SET_REGIONAL_FILTER_STATUS',
        value: RegionalFilterStatus.HIDE,
      })
    }
  }, [appContextDispatch])

  return (
    <MainLayout title="Invoices">
      <Text variant="h2">Invoices</Text>
      <Text variant="body2" style={{ marginTop: '16px', marginBottom: '56px' }}>
        You can find your weekly invoices for past shifts here. Invoices are
        created weekly and must be paid within 30 days.
        <InvoiceDisclaimerText />
      </Text>
      <TabsContainer>
        <Tabs
          variant="scrollable"
          value={currentTab}
          onChange={(_, v) => setCurrentTab(v)}
        >
          <Tab label="All Invoices" />
          <Tab label="Outstanding" />
          <Tab label="Past Due" />
          <Tab label="Paid" />
        </Tabs>
      </TabsContainer>
      <TabPanel value={currentTab} index={0}>
        <InvoiceTable index={0} isActive={currentTab === 0} />
      </TabPanel>
      <TabPanel value={currentTab} index={1}>
        <InvoiceTable index={1} isActive={currentTab === 1} />
      </TabPanel>
      <TabPanel value={currentTab} index={2}>
        <InvoiceTable index={2} isActive={currentTab === 2} />
      </TabPanel>
      <TabPanel value={currentTab} index={3}>
        <InvoiceTable index={3} isActive={currentTab === 3} />
      </TabPanel>
    </MainLayout>
  )
}
