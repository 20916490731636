import { UserRole } from './user-role'

export enum CompanyInvitationStatus {
  PENDING = 'PENDING', // Post-creation, pre-send
  SENT = 'SENT',
  ACCEPTED = 'ACCEPTED',
  RESCINDED = 'RESCINDED',
}

export interface CompanyInvitation {
  invitationId: string
  companyId: string
  role: UserRole
  email: string
  status: CompanyInvitationStatus
  createdAt: Date
  expiresAt: Date
  sentAt?: Date
  rescindedAt?: Date
  acceptedAt?: Date
  acceptedBy?: string
  invitedUserId?: string // If this invitation is sent to someone with an existing user doc (i.e. a POC), this field will be defined.
}
