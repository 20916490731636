import styled from 'styled-components'

export const enum MODAL_SIZE {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
  EXTRA_LARGE = 'extra_large',
  EXTRA_EXTRA_LARGE = 'extra_extra_large',
  DEFAULT = 'default',
}

export const modalWidths: Record<MODAL_SIZE, string> = {
  [MODAL_SIZE.SMALL]: 'min(100%, 528px)',
  [MODAL_SIZE.MEDIUM]: 'min(100%, 660px)',
  [MODAL_SIZE.LARGE]: 'min(100%, 832px)',
  [MODAL_SIZE.EXTRA_LARGE]: 'min(100%, 1000px)',
  [MODAL_SIZE.EXTRA_EXTRA_LARGE]: 'min(100%, 1200px)',
  [MODAL_SIZE.DEFAULT]: 'min(100%, 832px)',
}

export const modalHeights: Record<MODAL_SIZE, number> = {
  [MODAL_SIZE.SMALL]: 150,
  [MODAL_SIZE.MEDIUM]: 300,
  [MODAL_SIZE.LARGE]: 600,
  [MODAL_SIZE.EXTRA_LARGE]: 800,
  [MODAL_SIZE.EXTRA_EXTRA_LARGE]: 1000,
  [MODAL_SIZE.DEFAULT]: 300,
}

type ModalContainerProps = {
  size: MODAL_SIZE
}

export const ModalContainer = styled.div<ModalContainerProps>`
  display: flex;
  justify-content: center;
  background-color: #ffffff;
  align-items: flex-start;
  max-height: 90%;
  overflow-y: scroll;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 16px;
  position: absolute;

  width: ${({ size }) => `${modalWidths[size]}`};

  @media only screen and (max-width: (${({ theme }) =>
      theme.media.maxExtraSmall})) {
    width: 98%;
  }

  @media only screen and (max-width: ${({ theme }) => theme.media.maxSmall}) {
    width: 100%;
    border-radius: 0;
    top: 0;
    left: 0;
    transform: none;
    height: 100%;
    max-height: 100%;
  }
`

export const ModalPaddingContainer = styled.div`
  padding: ${({ theme }) => theme.space.lg}px;
  width: 100%;
`

export const WorkerImgContainer = styled.div`
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const WorkerImg = styled.img`
  max-width: 100%;
  max-height: 300px;
`
