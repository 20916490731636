import { DEFAULT_PAGE_SIZE } from '@traba/consts'
import { Region } from '@traba/types'
import React, { Dispatch } from 'react'
import { EmptyPlaceholderTile, Row, Text } from 'src/components/base'
import useMobile from 'src/hooks/useMobile'
import { ShiftAndAddress } from 'src/hooks/useShifts'
import { useHotSettings } from 'src/hooks/useSystem'
import { theme } from 'src/libs/theme'
import { UserData } from 'src/types'

import {
  CalendarFilters,
  FiltersAction,
} from '../../hooks/useShiftFilters.hooks'
import { PaginationDeprecated } from '../base/PaginationDeprecated'
import { EmptyDataLocationSubtitle } from '../base/RegionalFilterSelect/EmptyDataLocationSubtitle'
import { TextVariant } from '../base/Text'
import { ShiftFilters } from '../ShiftFilters/ShiftFilters'
import { ShiftStats } from '../ShiftStats/ShiftStats'
import { ShiftTile } from '../ShiftTile'
import { BookShiftButtonText } from '../Sidebar/Sidebar'
import Skeletons from '../Skeletons/Skeletons'
import * as S from './ShiftCalendar.styles'
import { ShowWorkersToggle } from './ShowWorkersToggle'

export type ShiftCalendarProps = {
  shifts: ShiftAndAddress[]
  isShiftsFetched: boolean
  regions: Region[]
  isRegionsFetched: boolean
  members: UserData[]
  isMembersFetched: boolean
  filters: CalendarFilters
  dispatch: Dispatch<FiltersAction>
  page: number
  onPageLeft: () => void
  onPageRight: () => void
  showDesktopCard: boolean
  hideWorkers?: boolean
  hideFilters?: boolean
  sectionTitleTextVariant?: TextVariant
  isShowWorkersToggled: boolean
  setIsShowWorkersToggled: React.Dispatch<React.SetStateAction<boolean>>
}

type TmpDict = {
  [key: string]: Section
}

type Section = {
  title: string
  date: Date
  data: ShiftAndAddress[]
}

export const ShiftCalendarUI: React.FC<ShiftCalendarProps> = (props) => {
  const {
    shifts,
    isShiftsFetched,
    isRegionsFetched,
    isMembersFetched,
    page,
    onPageLeft,
    onPageRight,
    showDesktopCard,
    hideFilters,
    hideWorkers,
    sectionTitleTextVariant,
    isShowWorkersToggled,
    setIsShowWorkersToggled,
  } = props

  const { isMobileViewOrReactNative, isReactNativeApp } = useMobile()
  const { hotSettings } = useHotSettings()

  // need to use the total shifts array length to determine if we need to show pagination
  const CalendarPagination = () => (
    <PaginationDeprecated
      page={page}
      pageSize={DEFAULT_PAGE_SIZE}
      onPageLeft={onPageLeft}
      onPageRight={onPageRight}
      dataSize={shifts?.length || 0}
    />
  )

  // only show the first {DEFAULT_PAGE_SIZE} shifts
  const shiftsToDisplay = shifts?.slice(0, DEFAULT_PAGE_SIZE) || []

  function getSections(shifts: ShiftAndAddress[]) {
    const sectionMap: TmpDict = {}
    shifts.forEach((shift) => {
      const startTime = new Date(shift.businessStartTime ?? shift.startTime)
      const localeDateString: string = startTime.toLocaleDateString('en-US', {
        timeZone: shift.timezone,
      })
      if (sectionMap[localeDateString] === undefined) {
        sectionMap[localeDateString] = {
          title: startTime.toLocaleDateString('en-us', {
            timeZone: shift.timezone,
            weekday: 'short',
            month: 'short',
            day: 'numeric',
          }),
          date: startTime,
          data: [shift],
        }
      } else {
        sectionMap[localeDateString].data.push(shift)
      }
    })

    return Object.values(sectionMap)
  }
  const organizedShifts = getSections(shiftsToDisplay)

  if (!isShiftsFetched || !isRegionsFetched || !isMembersFetched) {
    const defaultSectionTitle = new Date().toLocaleDateString('en-us', {
      weekday: 'short',
      month: 'short',
      day: 'numeric',
    })
    return (
      <>
        {hideFilters ? undefined : (
          <Row alignCenter>
            {!isReactNativeApp && (
              <>
                <ShiftFilters {...props} />
                <ShowWorkersToggle
                  isShowWorkersToggled={isShowWorkersToggled}
                  setIsShowWorkersToggled={setIsShowWorkersToggled}
                />
              </>
            )}
          </Row>
        )}
        <div
          style={{
            marginRight: theme.space.xl,
            paddingTop: theme.space.xxs,
          }}
        >
          <Text
            variant={sectionTitleTextVariant ? sectionTitleTextVariant : 'h3'}
          >
            {defaultSectionTitle}
          </Text>
        </div>
        <Row fullWidth style={{ marginTop: '8px' }}>
          <Skeletons count={3} component="SHIFT_TILE" />
        </Row>
      </>
    )
  }

  return (
    <S.ShiftCalendarContainer>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {hideFilters ? undefined : (
          <Row
            alignCenter
            style={{
              overflowX: 'auto',
              maxWidth: '100vw',
              paddingBottom: showDesktopCard ? theme.space.sm : 0,
            }}
          >
            {!isMobileViewOrReactNative && (
              <>
                <Row justifyBetween fullWidth>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <ShiftFilters {...props} />
                    <ShowWorkersToggle
                      isShowWorkersToggled={isShowWorkersToggled}
                      setIsShowWorkersToggled={setIsShowWorkersToggled}
                    />
                  </div>
                </Row>
                <CalendarPagination />
              </>
            )}
            {isMobileViewOrReactNative && (
              <Row ml={'auto'}>
                <CalendarPagination />
              </Row>
            )}
          </Row>
        )}
        {organizedShifts.length ? (
          <>
            {organizedShifts.map((section: Section, i) => (
              <div
                key={`organizedShifts_section_${i}`}
                style={{
                  flexDirection: 'row',
                  ...(i !== 0
                    ? { marginTop: theme.space.med }
                    : { marginTop: theme.space.xxs }), // apply small gap margin for the top section
                }}
              >
                {isMobileViewOrReactNative && (
                  <Row justifyBetween mb={theme.space.xxs}>
                    <Text
                      variant={
                        sectionTitleTextVariant ? sectionTitleTextVariant : 'h3'
                      }
                    >
                      {section.title}
                    </Text>
                  </Row>
                )}
                <ShiftStats
                  title={section.title}
                  date={section.date}
                  filters={props.filters}
                  showDesktopCard={showDesktopCard}
                />
                <div className="shiftTiles" style={{ flex: 'auto' }}>
                  {section.data
                    // Start time is currently not properly sorted on the backend
                    // so sorting by start time here [ENG-6201]
                    .sort(
                      (shiftA, shiftB) =>
                        (
                          shiftA.businessStartTime ?? shiftA.startTime
                        ).getTime() -
                        (
                          shiftB.businessStartTime ?? shiftB.startTime
                        ).getTime(),
                    )
                    .map((shift: ShiftAndAddress, j) => (
                      <div
                        key={`organizedShifts_shiftTile_${j}`}
                        style={{ marginTop: theme.space.sm }}
                      >
                        <ShiftTile
                          shift={shift}
                          showClockCodes={true}
                          showDesktopCard={showDesktopCard}
                          showTimeBar={true}
                          showWorkerShifts={true}
                          defaultShowWorkerShifts={
                            hideWorkers ? false : isShowWorkersToggled
                          }
                          alwaysHideWorkers={hideWorkers}
                        />
                      </div>
                    ))}
                </div>
              </div>
            ))}
          </>
        ) : (
          <EmptyPlaceholderTile
            title="View your ongoing and future shifts here!"
            subtitle={
              hotSettings?.regionalFiltersEnabled ? (
                <EmptyDataLocationSubtitle
                  isMobileView={isMobileViewOrReactNative}
                  extraText="to see shifts in those locations, or book a new shift."
                />
              ) : isReactNativeApp ? (
                "Create a shift by clicking on 'Book shift' in the bottom tab"
              ) : (
                `Create a shift by clicking on '${BookShiftButtonText}' at the top right!`
              )
            }
            iconName="folder"
          />
        )}
      </div>
    </S.ShiftCalendarContainer>
  )
}
