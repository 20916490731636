import { useAlert } from '@traba/context'
import axios from 'axios'
import {
  EmailAuthProvider,
  GoogleAuthProvider,
  OAuthProvider,
  getAuth,
  reauthenticateWithCredential,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
} from 'firebase/auth'
import { useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { BACKEND_URL } from 'src/api/helpers'
import { useAppContext } from 'src/context/appContext/AppContext'
import { useUserContext } from 'src/context/user/UserContext'
// Meticulous doesn't work with IDP log in so we need to stop recording during sign in with
// Google/Apple, etc. In prod, window.__meticulous is undefined so we need to check if it exists
function stopMeticulous() {
  if (window.__meticulous?.stopRecording) {
    window.__meticulous.stopRecording()
  }
}

export default function useAppAuth() {
  const auth = getAuth()
  const userContext = useUserContext()
  const { dispatch: appContextDispatch } = useAppContext()
  const queryClient = useQueryClient()
  const { showError, handleError } = useAlert()
  const navigate = useNavigate()

  function handleLoginError(error: any) {
    if ((error.message ?? '').indexOf('must-login-with-okta') !== -1) {
      window.analytics.track('User Login Failed', {
        reason:
          "Attempted to log in with other provider while Okta is required'",
        error,
      })
      showError(
        'Your company requires Okta SSO to sign in. Please try again with Okta credentials',
      )
    } else if ((error.message ?? '').indexOf('requires-invite') !== -1) {
      window.analytics.track('User Creation Failed', {
        reason: "Attempted to sign up without an invitation'",
        error,
      })
      handleError(
        error,
        'useAppAuth -> handleLoginError()',
        "Your company's email domain is already registered. Please check your inbox for an invite or contact your company administrator to invite you",
        'Error creating user',
      )
      navigate('/require-invitation')
    } else {
      window.analytics.track('User Login Failed', {
        error,
      })
      handleError(
        error,
        'useAppAuth -> handleLoginError()',
        'There was an issue with the sign-in. Please try again or contact support.',
        'Error logging in user',
      )
    }
  }

  /**
   * Logs user in firebase auth service
   * @param email user firebase email
   * @param password user password
   */
  async function handleLogin(email: string, password: string) {
    try {
      await signInWithEmailAndPassword(auth, email, password)
      const uid = auth.currentUser?.uid
      if (uid) {
        window.analytics.track('User Logged In', {
          userId: uid,
          email,
          source: 'email',
        })
      }
    } catch (err) {
      handleLoginError(err)
      console.error('authHooks -> handleLogin() ERROR', err)
      throw err
    }
  }

  /**
   * Logs user in firebase auth service with Google SSO
   */
  const handleLoginWithGoogle = async () => {
    window.analytics.track('User clicked on Google SSO button')
    stopMeticulous()
    const provider = new GoogleAuthProvider()
    const auth = getAuth()
    try {
      await signInWithPopup(auth, provider)
    } catch (error: any) {
      handleLoginError(error)
    }
  }

  /**
   * Logs user in firebase auth service with Apple SSO
   */
  const handleLoginWithApple = async () => {
    window.analytics.track('User clicked on Apple SSO button')
    stopMeticulous()
    const provider = new OAuthProvider('apple.com')
    provider.addScope('email')
    const auth = getAuth()
    try {
      await signInWithPopup(auth, provider)
    } catch (error: any) {
      handleLoginError(error)
    }
  }

  /**
   * Logs user in firebase auth service with Okta SSO
   */
  const handleLoginWithOkta = async (providerId: string) => {
    window.analytics.track('User clicked on Okta SSO button')
    stopMeticulous()
    const provider = new OAuthProvider(providerId)
    const auth = getAuth()
    signInWithPopup(auth, provider)
      .then((_) => {
        window.analytics.track('Okta SSO login success')
      })
      .catch((error) => {
        handleLoginError(error)
      })
  }

  /**
   * Logs user out in firebase auth service
   */
  async function handleLogout() {
    userContext.dispatch({ type: 'SET_IS_LOADING', value: true })
    // Need to pull auth token before sign out because it will return undefined after
    const authToken = await auth.currentUser?.getIdToken()
    await signOut(auth)

    // Need to use axios because trabaApi would override auth token passed in headers
    await axios.post(
      `${BACKEND_URL}/users/invalidate-auth-token`,
      {},
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      },
    )
    userContext.dispatch({ type: 'SET_IS_LOADING', value: false })

    // Reset regional filter so that newly logged in user can load from local storage
    appContextDispatch({
      type: 'RESET_REGIONAL_FILTER_CONTEXT',
    })

    queryClient.clear() // Clear react query cache after logout
  }

  async function validateExistingPassword(password: string) {
    const currentUser = auth.currentUser
    if (!currentUser || !currentUser.email) {
      throw new Error('Auth user does not exist or does not have email')
    }
    const credentials = EmailAuthProvider.credential(
      currentUser.email,
      password,
    )
    return reauthenticateWithCredential(currentUser, credentials)
  }

  function userHasEmailProvider() {
    const currentUser = auth.currentUser
    if (!currentUser) {
      return false
    }
    return currentUser.providerData.some(
      (provider) => provider.providerId === 'password',
    )
  }

  return {
    handleLogin,
    handleLoginWithGoogle,
    handleLoginWithApple,
    handleLoginWithOkta,
    handleLogout,
    validateExistingPassword,
    userHasEmailProvider,
    handleLoginError,
  }
}
