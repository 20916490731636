import { Button, ButtonVariant } from '@traba/react-components'
import { theme } from '@traba/theme'
import { useNavigate } from 'react-router-dom'

interface Props {
  shiftRequestParentId: string
}

export const ScheduleInvitationButton = (props: Props) => {
  const { shiftRequestParentId } = props
  const navigate = useNavigate()
  return (
    <Button
      variant={ButtonVariant.TEXT}
      onClick={() => {
        navigate(`/schedule/${shiftRequestParentId}?tabs=3`)
      }}
      style={{
        paddingLeft: theme.space.xxxs,
        color: theme.colors.brand,
        cursor: 'pointer',
        fontSize: 'inherit',
      }}
    >
      going to schedule details.
    </Button>
  )
}
