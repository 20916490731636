import {
  Button,
  ScheduleDaySquares,
  SvgIcon,
  Text,
} from '@traba/react-components'
import { theme } from '@traba/theme'
import { useNavigate } from 'react-router-dom'
import { ButtonVariant, Col, Row } from 'src/components'
import { ScheduleDetailsTileInfo } from 'src/utils/scheduleUtils'
import { ColHeaderAndContent } from './ColHeaderAndContent'
import { MonthAndDayTile } from './MonthAndDayTile'

function getRolesForShiftString(roleToQuantityMap: Map<string, number>) {
  const quantityOfRole: string[] = []
  roleToQuantityMap.forEach((quantity, role) => {
    quantityOfRole.push(`${quantity} ${role}${quantity > 1 ? 's' : ''}`)
  })
  return quantityOfRole.join(', ')
}

interface ScheduleDetailsTileProps {
  scheduleDetailsTileInfo: ScheduleDetailsTileInfo
}

export const ScheduleDetailsTile: React.FC<ScheduleDetailsTileProps> = ({
  scheduleDetailsTileInfo,
}) => {
  const {
    title,
    location,
    roleToQuantityMap,
    slotsRequestedPerShift,
    scheduleIsBiWeekly,
    scheduleHasEndDate,
    scheduleRepeatsOnDays,
    scheduleStartMonthAndDay,
    scheduleEndMonthAndDay,
    totalShiftsInSchedule,
    totalSlotsRequestedForAllShiftsInSchedule,
    shiftTimeString,
  } = scheduleDetailsTileInfo
  const navigate = useNavigate()
  const ROW_1_COL_HEIGHTS = 100
  const ROW_2_COL_HEIGHTS = 60

  return (
    <div
      style={{
        border: `1px solid ${theme.colors.Grey}`,
        borderRadius: theme.space.xxs,
      }}
    >
      <Row
        justifyBetween
        style={{
          padding: theme.space.sm,
          borderBottom: `1px solid ${theme.colors.Grey}`,
        }}
      >
        <Col>
          <Text variant="h3">{title}</Text>
          <Row alignCenter mt={theme.space.xxs}>
            <SvgIcon name="location" color={theme.colors.MidnightBlue} />
            <Text style={{ marginLeft: theme.space.xxxs }} variant="h6">
              {location}
            </Text>
          </Row>
        </Col>
        <Row alignCenter wrap justifyEnd>
          <Row>
            <Button
              variant={ButtonVariant.TEXT}
              onClick={() => {
                // TODO(polyphilz): Update
                navigate(`/dashboard`)
              }}
              style={{
                color: theme.colors.brand,
                paddingTop: 0,
                paddingBottom: 0,
              }}
            >
              <SvgIcon
                name="plus"
                color={theme.colors.brand}
                size={theme.space.xs}
                style={{ marginRight: theme.space.xxs }}
              />
              Add to schedule
            </Button>
          </Row>
          <Row>
            <Button
              variant={ButtonVariant.TEXT}
              onClick={() => {
                // TODO(polyphilz): Update
                navigate(`/dashboard`)
              }}
              style={{
                color: theme.colors.brand,
                paddingTop: 0,
                paddingBottom: 0,
              }}
            >
              <SvgIcon
                name="edit"
                color={theme.colors.brand}
                size={theme.space.xs}
                style={{ marginRight: theme.space.xxs }}
              />
              Edit schedule
            </Button>
          </Row>
          <Row>
            <Button
              variant={ButtonVariant.TEXT}
              onClick={() => {
                // TODO(polyphilz): Update
                navigate(`/dashboard`)
              }}
              style={{
                color: theme.colors.brand,
                paddingTop: 0,
                paddingBottom: 0,
              }}
            >
              <SvgIcon
                name="cancel"
                color={theme.colors.brand}
                size={theme.space.xs}
                style={{ marginRight: theme.space.xxs }}
              />
              Cancel schedule
            </Button>
          </Row>
        </Row>
      </Row>

      <Row
        justifyBetween
        style={{
          padding: theme.space.sm,
          width: '75%',
          rowGap: theme.space.lg,
        }}
        wrap
      >
        <Col>
          {scheduleStartMonthAndDay?.month && scheduleStartMonthAndDay?.day && (
            <Col style={{ minHeight: ROW_1_COL_HEIGHTS }}>
              <Row>
                <Text variant="caption">
                  {scheduleHasEndDate
                    ? 'SCHEDULE START — END'
                    : 'SCHEDULE START'}
                </Text>
              </Row>
              <Row mt={theme.space.xxs} alignCenter>
                <MonthAndDayTile
                  month={scheduleStartMonthAndDay?.month || ''}
                  day={scheduleStartMonthAndDay?.day || 0}
                />
                {scheduleHasEndDate && (
                  <>
                    <Row ml={theme.space.xs} mr={theme.space.xs}>
                      <Text variant="h6">-</Text>
                    </Row>
                    <MonthAndDayTile
                      month={scheduleEndMonthAndDay?.month || ''}
                      day={scheduleEndMonthAndDay?.day || 0}
                    />
                  </>
                )}
              </Row>
            </Col>
          )}
          <Col style={{ minHeight: ROW_2_COL_HEIGHTS }}>
            <Col>
              <Row>
                <Text variant="caption">
                  {scheduleIsBiWeekly ? 'BI-WEEKLY PATTERN' : 'WEEKLY PATTERN'}
                </Text>
              </Row>
              <Row mt={theme.space.xxs} alignCenter>
                <ScheduleDaySquares
                  repeatOn={
                    scheduleRepeatsOnDays.length ? scheduleRepeatsOnDays[0] : []
                  }
                  instance={0}
                />
              </Row>
              {scheduleIsBiWeekly && scheduleRepeatsOnDays.length > 1 && (
                <Row mt={theme.space.xxs} alignCenter>
                  <ScheduleDaySquares
                    repeatOn={scheduleRepeatsOnDays[1]}
                    instance={1}
                  />
                </Row>
              )}
            </Col>
          </Col>
          <Col>
            {shiftTimeString && (
              <Row mt={theme.space.med}>
                <ColHeaderAndContent
                  header="START AND END TIMES FOR EACH SHIFT"
                  content={shiftTimeString}
                />
              </Row>
            )}
          </Col>
        </Col>

        <Col>
          <Col style={{ minHeight: ROW_1_COL_HEIGHTS }}>
            <ColHeaderAndContent
              header="SLOTS REQUESTED FOR EACH SHIFT"
              content={`${slotsRequestedPerShift}`}
            />
          </Col>
          <Col style={{ minHeight: ROW_2_COL_HEIGHTS }}>
            <ColHeaderAndContent
              header="ROLES FOR EACH SHIFT"
              content={getRolesForShiftString(roleToQuantityMap)}
            />
          </Col>
        </Col>

        {scheduleHasEndDate && (
          <Col>
            <Col style={{ minHeight: ROW_1_COL_HEIGHTS }}>
              <ColHeaderAndContent
                header="TOTAL SHIFTS"
                content={`${totalShiftsInSchedule || 0}`}
              />
            </Col>
            <Col style={{ minHeight: ROW_2_COL_HEIGHTS }}>
              <ColHeaderAndContent
                header="TOTAL SLOTS REQUESTED"
                content={`${totalSlotsRequestedForAllShiftsInSchedule || 0}`}
              />
            </Col>
          </Col>
        )}
      </Row>
    </div>
  )
}
