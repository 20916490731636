import { useAlert } from '@traba/context'
import { trabaApi } from 'src/api/helpers'
import { openInNewTab } from 'src/utils/miscUtils'

const getStripeInvoice = async (stripeInvoiceId: string) => {
  const response = await trabaApi.get(`/stripe/invoices/${stripeInvoiceId}`)
  return response.data
}

export const useStripeInvoiceButton = () => {
  const { handleError } = useAlert()

  async function handleGetInvoiceUrl(stripeInvoiceId: string) {
    try {
      const stripeInvoice = await getStripeInvoice(stripeInvoiceId)
      const redirectUrl = stripeInvoice.hosted_invoice_url
      openInNewTab(redirectUrl)
    } catch (error) {
      handleError(
        error,
        'useStripeInvoiceButton -> handleGetInvoiceUrl()',
        `Unable to fetch stripe invoice`,
      )
    }
  }

  return { handleGetInvoiceUrl }
}

export const downloadStripeInvoice = async (
  stripeInvoiceId: string,
  companyId?: string,
) => {
  const stripeInvoice = await getStripeInvoice(stripeInvoiceId)
  openInNewTab(stripeInvoice.invoice_pdf)
  window.analytics?.track(`Downloaded Stripe Invoice`, {
    stripeInvoiceId,
    downloadedAt: new Date(),
    ...(companyId ? { companyId } : undefined),
  })
}
