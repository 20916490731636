import styled, { css } from 'styled-components'

export const CardPointer = styled.div`
  border-radius: 50%;
  width: 30px;
  height: 30px;
  border: 2px solid ${({ theme }) => theme.colors.Grey20};
  background: #ffffff;
  display: flex;
  justify-self: flex-end;
`

export const Card = styled.div<{
  disabled?: boolean
  isClickable?: boolean
  isActive?: boolean
  backgroundColor?: string
  borderColor?: string
}>`
  border-radius: 10px;
  border: 2px solid ${({ theme }) => theme.colors.Grey20};
  width: 100%;
  display: flex;
  padding: 30px;
  flex-direction: column;
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-color: ${({ borderColor }) => borderColor};
  color: ${() => '#000'};
  ${({ disabled }) => {
    if (!disabled) {
      return
    }
    return css`
      pointer-events: none;
      > * ${CardPointer} {
        background-color: ${({ theme }) => theme.colors.Grey20};
      }
    `
  }}
  ${({ isClickable }) => {
    if (isClickable) {
      return css`
        cursor: pointer;
        :hover {
          border-color: ${({ theme }) => theme.colors.Grey30};
        }
        :hover div:not(${RaisedCardTile}) {
          border-color: ${({ theme }) => theme.colors.Grey30};
        }
      `
    }
  }}
  ${({ isActive }) => {
    if (isActive) {
      return css`
        border-color: ${({ theme }) => theme.colors.Grey30};
        div {
          border-color: ${({ theme }) => theme.colors.Grey30};
        }
      `
    }
  }}
`

interface ICardTile {
  size?: string
  style?: { [key: string]: string | number }
  isClickable?: boolean
  isActive?: boolean
}

export const CardTile = styled.div<ICardTile>`
  border-radius: 8px;
  border: 2px solid ${({ theme }) => theme.colors.Grey20};
  width: ${(props) => (props.size ? props.size : '80px')};
  height: ${(props) => (props.size ? props.size : '80px')};
  display: flex;
  justify-content: center;
  align-items: center;
  ${(props) => props.style && props.style}
  ${({ isClickable }) => {
    if (isClickable) {
      return css`
        cursor: pointer;
        :hover {
          border-color: ${({ theme }) => theme.colors.Grey30};
        }
      `
    }
  }}
  ${({ isActive }) => {
    if (isActive) {
      return css`
        border-color: ${({ theme }) => theme.colors.Grey30};
      `
    }
  }}
`

export const RaisedCardTile = styled(CardTile)`
  box-shadow: 0px 1px 2px rgba(196, 204, 212, 0.5);
`

export const RadioButtonInside = styled.div`
  border-radius: 50%;
  width: 10px;
  height: 10px;
  background: #ffffff;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
`
