import { SvgIconProps } from '@traba/types'

export default function RotateLeft({ size, color, ...rest }: SvgIconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 12 12" fill="none" {...rest}>
      <path
        d="M1.5 6.07627C1.5 8.51943 3.51472 10.5 6 10.5C8.48528 10.5 10.5 8.51943 10.5 6.07627C10.5 3.63311 8.5 1.65254 6 1.65254C3 1.65254 1.5 4.11017 1.5 4.11017M1.5 4.11017L1.5 1.5M1.5 4.11017H3.82755"
        stroke={color ?? '#6600CC'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
