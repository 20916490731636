import { useAlert } from '@traba/context'
import { Card } from '@traba/react-components'
import { useForm } from '@traba/utils'
import { getAuth, updatePassword } from 'firebase/auth'
import { useFormik } from 'formik'
import React from 'react'
import { trabaApi } from 'src/api/helpers'
import {
  Alert,
  Button,
  ButtonVariant,
  Input,
  Row,
  Text,
} from 'src/components/base'
import useAppAuth from 'src/hooks/authHook'
import { REQUIRED_FIELD_MESSAGE } from 'src/libs/constants'
import { theme } from 'src/libs/theme'
import { password, passwordConfirm } from 'src/utils/formUtils'
import * as yup from 'yup'

interface Props {
  setEditMode: React.Dispatch<
    React.SetStateAction<'information' | 'password' | undefined>
  >
}

export const UpdatePasswordSection = ({ setEditMode }: Props) => {
  const { handleOnSubmitError } = useForm()
  const { showError, showSuccess } = useAlert()
  const { validateExistingPassword } = useAppAuth()

  const formik = useFormik({
    initialValues: { OldPassword: '', Password: '', PasswordConfirmation: '' },
    validationSchema: yup.object({
      OldPassword: yup.string().required(REQUIRED_FIELD_MESSAGE), // not applying min length since we weren't validating this properly before Dec 2023
      Password: password(), // applying proper validation for any new passwords
      PasswordConfirmation: passwordConfirm(),
    }),
    onSubmit: async (values) => {
      try {
        const auth = getAuth()
        const user = auth.currentUser
        if (user) {
          try {
            await validateExistingPassword(values.OldPassword)
          } catch (err) {
            showError('Old password is incorrect', 'Password Error')
            return
          }
          if (values.Password === values.PasswordConfirmation) {
            try {
              await updatePassword(user, values.Password)

              window.analytics.track('Business User Changed Password')

              showSuccess('Successfully updated password', 'Success')
              await trabaApi.post('users/invalidate-auth-token')
            } catch (e) {
              showError('Error updating password', 'Password Error')
              //TODO Send error to sentry
            }
          } else {
            showError('Passwords must match', 'Password Error')
          }
        }
        setEditMode(undefined)
      } catch (err) {
        handleOnSubmitError(err, formik)
      }
    },
  })
  const { errors, touched } = formik

  return (
    <Card>
      <Text variant="h5">Update password</Text>
      <form onSubmit={formik.handleSubmit}>
        <div
          style={{
            marginTop: '32px',
          }}
        >
          <Input
            label="Old password"
            full
            type="password"
            {...formik.getFieldProps('OldPassword')}
            inputStatus={touched.OldPassword && errors.OldPassword ? 3 : 1}
            errorMessage={formik.errors.OldPassword}
          />
          <Input
            label="New password"
            full
            type="password"
            {...formik.getFieldProps('Password')}
            inputStatus={touched.Password && errors.Password ? 3 : 1}
            errorMessage={formik.errors.Password}
          />
          <Input
            label="Confirm new password"
            full
            type="password"
            {...formik.getFieldProps('PasswordConfirmation')}
            inputStatus={
              touched.PasswordConfirmation && errors.PasswordConfirmation
                ? 3
                : 1
            }
            errorMessage={formik.errors.PasswordConfirmation}
          />
          {formik.status && formik.status.error && (
            <Alert mt={24}>{formik.status.message}</Alert>
          )}
          <Row my={24}>
            <Button
              style={{ marginRight: theme.space.xs, height: 40 }}
              variant={ButtonVariant.OUTLINED}
              full
              onClick={() => {
                setEditMode(undefined)
              }}
            >
              Cancel
            </Button>
            <Button type="submit" full style={{ height: 40 }}>
              Submit
            </Button>
          </Row>
        </div>
      </form>
    </Card>
  )
}
